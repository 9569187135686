import React from 'react';
import {CardForm, Form, Section, TitleStep, Span, Input, BtnNext, BtnText} from './styles';
import {Link} from 'react-router-dom';
import {Modal} from "../../../molecules/Modals/Modal";
import {useParams} from 'react-router-dom';



export const FormRecoverPassword = (props) => {

    const {token} = useParams();


    const [formValues, setFormValues] = React.useState({
        password:'',
        password_confirmation:''
    })


    const [confirmPassword, setConfirmPassword] = React.useState(false)

    React.useEffect(() => {
        function passwordConfirm() {
            // console.log("passwordConfirm")
            // console.log(confirmPassword)
            if (formValues.password.length > 0 && formValues.password === formValues.password_confirmation){
                setConfirmPassword(true)
                // console.log("confirmPasswordddddd")
                // console.log(confirmPassword)
            } else {
                setConfirmPassword(false)
            }
            return passwordConfirm
        }
        passwordConfirm()
    }, [formValues.password, formValues.password_confirmation])


    const [activeModal, setActiveModal] = React.useState(false);
    const [modalData, setModalData] = React.useState({
        title:'',
        text: '',
        btn:''
    });

    const handleClosed = () => {
        setActiveModal(false)
    }



    const handleChange = (event) => {
        const {name, value} = event.target
        // console.log(name, value)

        setFormValues({ ...formValues, [name]: value })
    }

    const _handleSubmit = (event) => {
        // console.log("confirmPassword _handleSubmit")
        // console.log(confirmPassword)
        if(confirmPassword === false ) {
            event.preventDefault();
            setActiveModal(true)
            setModalData({
                title:'Contraseñas incorrectas',
                text:"Las contraseñas registradas no coinciden, intentelo nuevamente.",
                btn:"Aceptar"

            })

        } else {

            event.preventDefault();
            props.handleSubmit({ ...formValues, token })
            // console.log("handle submit RecoverPassword")
            // console.log(formValues)
            
            event.target.reset()
            setFormValues({
                password:'',
                password_confirmation:''
            })
        }
    }

    return (
        <>
            <CardForm>
                <Form onSubmit={_handleSubmit}>
                    <>
                        <Section>
                            <TitleStep>Ingresar una nueva contraseña</TitleStep>
                            <Input
                                placeholder="Nueva contraseña*"
                                type="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                                required
                            />
                            <Input
                                placeholder="Confirmar contraseña*"
                                type="password"
                                name="password_confirmation"
                                value={formValues.password_confirmation}
                                onChange={handleChange}
                                required
                            />
                        </Section>
                        <BtnNext
                            /* onClick={completeFormStep} */
                            type="submit"
                        >Actualizar contraseña</BtnNext>
                        <Span>
                            <Link to={props.url} style={{textDecoration:"none"}}>
                                <BtnText>{props.redirect}</BtnText>
                            </Link>
                        </Span>
                    </>
                </Form>
                <Modal
                    activeModal={activeModal}
                    handleClosed={handleClosed}
                    title={modalData.title}
                    text={modalData.text}
                    btn={modalData.btn}
                />
            </CardForm>
        </>
    )
}
import React, { Fragment } from "react"
import {
  ContainerLink,
  Container,
  FigureCircle,
  Button,
  Section,
  CardTitle,
  TitleMonth,
  CardValue,
  SubtitlePage,
  Row,
  Column,
  Line,
  LinkStyle,
  RowLink,
  RowButton,
  DetailLink,
  CardDesc,
} from "./styles.js"

import Popover from "@material-ui/core/Popover"
import { MdMoreVert, MdNavigateNext } from "react-icons/md"

export const CardInitialInfo = ({
  icon,
  color,
  title,
  value,
  path,
  actualMonth,
  detailCard,
  detail,
  cardType,
  skeleton,
  handleClick,
  handleClose,
  row,
  showPop,
  isText,
}) => {
  const selectedMonthNumber = Number(new Date().getMonth())

  // eslint-disable-next-line
  const month = new Array()
  month[0] = "Enero"
  month[1] = "Febrero"
  month[2] = "Marzo"
  month[3] = "Abril"
  month[4] = "Mayo"
  month[5] = "Junio"
  month[6] = "Julio"
  month[7] = "Agosto"
  month[8] = "Septiembre"
  month[9] = "Octubre"
  month[10] = "Noviembre"
  month[11] = "Diciembre"
  const dateMonth = month[selectedMonthNumber]

  return (
    <>
      {!skeleton && (
        <ContainerLink to={path}>
          {color === "verde" ? <FigureCircle verde>{icon}</FigureCircle> : ""}
          {color === "rojo" ? <FigureCircle rojo>{icon}</FigureCircle> : ""}
          {color === "naranja" ? (
            <FigureCircle naranja>{icon}</FigureCircle>
          ) : (
            ""
          )}
          {color === "azul" ? <FigureCircle azul>{icon}</FigureCircle> : ""}
          {color === "cancel" ? <FigureCircle cancel>{icon}</FigureCircle> : ""}
          {color === "pink" ? <FigureCircle pink>{icon}</FigureCircle> : ""}
          {color === "third" ? <FigureCircle third>{icon}</FigureCircle> : ""}
          {!color ? <FigureCircle>{icon}</FigureCircle> : ""}
          {!detailCard ? (
            <Fragment>
              <Section>
                {color === "rojo" || color === "naranja" ? (
                  <>
                    <CardTitle>
                      {title}{" "}
                      {actualMonth && <TitleMonth rojo>{dateMonth}</TitleMonth>}
                    </CardTitle>
                    <CardValue rojo>
                      {Intl.NumberFormat("es-CO", {
                        maximumFractionDigits: "0",
                        style: "currency",
                        currency: "COP",
                      }).format(value)}
                    </CardValue>
                  </>
                ) : (
                  <>
                    <CardTitle>
                      {title}{" "}
                      {actualMonth && <TitleMonth>{dateMonth}</TitleMonth>}
                    </CardTitle>
                    {isText ? (
                      <CardValue sm>{value}</CardValue>
                    ) : (
                      <CardValue>
                        {Intl.NumberFormat("es-CO", {
                          maximumFractionDigits: "0",
                          style: "currency",
                          currency: "COP",
                        }).format(value)}
                      </CardValue>
                    )}
                  </>
                )}
              </Section>
              <LinkStyle to={path}>
                <MdNavigateNext size="2rem" />
              </LinkStyle>
            </Fragment>
          ) : (
            <Fragment>
              <Section>
                <Row>
                  <Column size="70" align="left">
                    <CardTitle align="left">{title}</CardTitle>
                  </Column>
                </Row>
                {cardType === 2 ? (
                  <DetailLink to={detail.path}>
                    <Row>
                      <CardDesc size="10px">{detail.description}</CardDesc>
                    </Row>
                    <Row>
                      <Column align="left">
                        <SubtitlePage>{detail.date}</SubtitlePage>
                      </Column>
                      <Column align="right">
                        <CardTitle align="right" custom>
                          <TitleMonth
                            align="center"
                            credit
                            status={detail.status}
                          >
                            {detail.status}
                          </TitleMonth>
                        </CardTitle>
                      </Column>
                    </Row>
                    <Line />
                    <Row>
                      <Column align="left">
                        <CardTitle align="left" color="text_light">
                          Abonos
                        </CardTitle>
                        <CardTitle align="left" color="third" fontSize="13px">
                          {Intl.NumberFormat("es-CO", {
                            maximumFractionDigits: "0",
                            style: "currency",
                            currency: "COP",
                          }).format(detail.payment)}
                        </CardTitle>
                      </Column>
                      <Column>
                        <CardTitle color="text_light">Crédito</CardTitle>
                        <CardTitle color={detail.credit_color} fontSize="13px">
                          {Intl.NumberFormat("es-CO", {
                            maximumFractionDigits: "0",
                            style: "currency",
                            currency: "COP",
                          }).format(detail.credit)}
                        </CardTitle>
                      </Column>
                    </Row>
                  </DetailLink>
                ) : (
                  <Fragment>
                    <Row>
                      <CardDesc size="10px">{detail.description}</CardDesc>
                    </Row>
                    <Row>
                      <SubtitlePage>{detail.date}</SubtitlePage>
                    </Row>
                    <Row>
                      <Column align="left">
                        <CardTitle custom>
                          <TitleMonth status={detail.status}>
                            {detail.status}
                          </TitleMonth>
                        </CardTitle>
                      </Column>
                      <Column align="right" custom>
                        <CardValue align="right" custom>
                          {Intl.NumberFormat("es-CO", {
                            maximumFractionDigits: "0",
                            style: "currency",
                            currency: "COP",
                          }).format(value)}
                        </CardValue>
                      </Column>
                    </Row>
                  </Fragment>
                )}
              </Section>
              {showPop ? (
                <Button aria-describedby={row.id_pop} onClick={handleClick}>
                  <MdMoreVert size="1.5rem" />
                </Button>
              ) : (
                <Button>
                  <MdMoreVert size="1.5rem" color="white" />
                </Button>
              )}
            </Fragment>
          )}
          {showPop && (
            <Popover
              id={row.id_pop}
              open={row.status}
              anchorEl={row.anchor_el}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              {detail.edit && (
                <RowLink to={detail.edit.path}>{detail.edit.title}</RowLink>
              )}
              {detail.inactivate && (
                <RowButton onClick={detail.inactivate.handleDelete}>
                  {detail.inactivate.title}
                </RowButton>
              )}
              {detail.download && (
                <RowButton onClick={detail.download.handleDownload}>
                  {detail.download.title}
                </RowButton>
              )}
            </Popover>
          )}
        </ContainerLink>
      )}
      {skeleton && (
        <Container>
          <FigureCircle skeleton></FigureCircle>
          <Section>
            <CardTitle skeleton>prueba</CardTitle>
            <CardValue skeleton>0</CardValue>
          </Section>
        </Container>
      )}
    </>
  )
}

import React, { useEffect, useState } from "react"
import { Routes } from "../../../routes"
import { getAssetItem } from "../../../api/requests"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { Container, Section } from "./styles"
import { useLocation, useParams } from "react-router-dom"
import { FormEditAssetsContainer } from "../../../containers/FormEditAssetsContainer"

export const EditInitialAssetsForm = () => {
  const { id } = useParams()

  const location = useLocation()
  const captureId = location.pathname.toString().split("/")
  const idCategory = captureId[2]

  const [assetItem, setAssetItem] = useState({})

  const loadAssetItem = async (id) => {
    const response = await getAssetItem(id)
    if (response.status === 200) {
      setAssetItem(response.data)
      return response.data
    }
  }

  useEffect(() => {
    async function fetchAssetItem() {
      await loadAssetItem(id)
    }
    fetchAssetItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={Routes.app_initial_assets}
          titlePage="Editar activo inicial"
          tooltip="assets"
          darktheme
        />
        <FormEditAssetsContainer
          idEdit={idCategory}
          objtItem={assetItem}
          loadAssetItem={loadAssetItem}
        />
      </Section>
    </Container>
  )
}

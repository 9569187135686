import React from "react"
import {
  Form,
  Section,
  Input,
  Select,
  Label,
  ContainerBtns,
  BtnNext,
  SubSection,
  Option,
  TitleStep,
} from "./styles"

export const AddInventory = (props) => {
  return (
    <Form>
      <Section>
        <SubSection>
          <TitleStep>Información del proveedor</TitleStep>
        </SubSection>

        <SubSection>
          <Label>Categoría *</Label>
          <Select
            ghost
            type="select"
            name="category"
            onChange={props.handleChangeInventory}
            value={props.formInventory.category}
            required
            disabled
          >
            <Option value="PRODUCTO COMPRADO" selected>
              Producto comprado
            </Option>
            <Option value="PRODUCTO FABRICADO">Producto fabricado</Option>
            <Option value="SERVICIOS">Servicios</Option>
          </Select>
        </SubSection>

        <SubSection>
          <Label>Nombre *</Label>
          <Input
            type="text"
            name="name"
            onChange={props.handleChangeInventory}
            onFocus={props.handleFocus}
            value={props.formInventory.name}
            required
          />
        </SubSection>

        <SubSection>
          <Label>Descripción (opcional)</Label>
          <Input
            type="text"
            name="description"
            onChange={props.handleChangeInventory}
            onFocus={props.handleFocus}
            value={props.formInventory.description}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Costo *</Label>
          <Input
            type="number"
            name="cost_value"
            onChange={props.handleChangeInventory}
            onFocus={props.handleFocus}
            value={props.formInventory.cost_value}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Precio de venta *</Label>
          <Input
            type="number"
            name="sale_value"
            onChange={props.handleChangeInventory}
            onFocus={props.handleFocus}
            value={props.formInventory.sale_value}
            required
          />
        </SubSection>

        <SubSection>
          <Label>Referencia *</Label>
          <Input
            type="text"
            name="sku"
            onChange={props.handleChangeInventory}
            onFocus={props.handleFocus}
            value={props.formInventory.sku}
            required
          />
        </SubSection>
      </Section>

      <ContainerBtns>
        <BtnNext second onClick={props.closeFormInventory} type="button">
          Cancelar
        </BtnNext>
        <BtnNext second onClick={props.handleCreate} type="button" darktheme>
          Crear
        </BtnNext>
      </ContainerBtns>
    </Form>
  )
}

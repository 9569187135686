import styled from 'styled-components'

// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Subtitle = styled.h5`
    padding-bottom:1.5rem;
    ${mobile} {
        padding-top:.3rem;
    }
`
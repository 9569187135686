import React from "react";
import { Text, Span, WelcomeText } from "./styles";

export const SubscribeInvitation = () => {

  return (
    <>
      <Text>
        <Span>Accede a nuestros planes </Span>
      </Text>
      <WelcomeText>
        Obten los mejores beneficios y ventajas con nuestros planes Premium
      </WelcomeText>
    </>
  );
};

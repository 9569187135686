import React, { useEffect } from "react"
import moment from "moment"
import "moment/locale/es"
import { Routes } from "../../../../routes"
import { MdAttachMoney, MdBusiness } from "react-icons/md"
import {
  Container,
  Card,
  Title,
  Subtitle,
  Text,
  TextRequest,
  Button,
  FigureCircle,
} from "./styles"
import { postSubscriptionBilling } from "../../../../api/requests"
import { Utilities } from "../../../../utils/utilities"

export const ModalSubscriptionBilling = (props) => {
  const [billingData, setBillingData] = React.useState({
    user_id: "",
    subscription_id: "",
    initial_date: "2022-01-01",
    final_date: "2022-12-31",
    subscription_active: false,
    reminder_date: "2022-12-29",
    reminder_send: false,
  })

  const [wompiData, setWompiData] = React.useState({
    "public-key": "1111",
    "amount-in-cents": 0.0,
    currency: "COP",
    reference: 0,
  })

  // eslint-disable-next-line
  const [activeModal, setActiveModal] = React.useState(false)
  // eslint-disable-next-line
  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })
  const [userInfo, setUserInfo] = React.useState({
    email: "",
    name: "",
    cellphone: 0,
  })

  let handleBilling = () => {
    const dateNow = new Date()
    const dateNowFormat = new Date().toISOString().split("T")[0]

    function formatDate() {
      let duration = props.options.duration
      let dateFormat = moment(dateNow)
        .add(duration, "months")
        .format("YYYY-MM-DD")

      return dateFormat
    }

    function reminderDate() {
      const dateReminder = moment(formatDate())
        .add(-2, "days")
        .format("YYYY-MM-DD")
      return dateReminder
    }
    setUserInfo({
      email: props.userDat[0].user.email,
      name:
        props.userDat[0].user.first_name +
        " " +
        props.userDat[0].user.last_name,
      cellphone: props.userDat[0].cellphone,
    })

    setBillingData({
      user_id: props.userDat[0].user.id,
      subscription_id: props.options.id,
      initial_date: dateNowFormat,
      final_date: formatDate(),
      subscription_active: false,
      reminder_date: reminderDate(),
      reminder_send: false,
    })
  }

  async function submitSubscription(data) {
    await postSubscriptionBilling(data).then((response) => {
      if (response.status === 200) {
        const final_date_format = moment(data.final_date).format("LL")
        showMessage(
          "Suscripción exitosa",
          `Suscripción valida hasta: ${final_date_format}`,
          false
        )
        handleWompi(data)
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Suscripción no creada", array, true, true)
      } else {
        showMessage(
          "Suscripción no creada",
          ` La factura de suscripción ${data} no pudo ser registrada. Por favor, inténtelo de nuevo.`,
          true
        )
      }
    })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const cadenaAleatoria = (longitud) => {
    const banco =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let aleatoria = ""
    for (let i = 0; i < longitud; i++) {
      aleatoria += banco.charAt(Math.floor(Math.random() * banco.length))
    }
    return aleatoria
  }

  const handleWompi = (data) => {
    let cadena = cadenaAleatoria(10)

    setWompiData({
      "public-key": process.env.REACT_APP_KEY_WOMPI,
      "amount-in-cents": props.options.amount.toFixed(2),
      currency: "COP",
      reference:
        data.user_id +
        "-" +
        data.subscription_id +
        "-" +
        data.initial_date +
        cadena,
    })
  }

  useEffect(() => {
    if (billingData.user_id !== "") {
      submitSubscription(billingData)
    }
    handleWompi(billingData)
    // eslint-disable-next-line
  }, [billingData])

  useEffect(() => {}, [wompiData])

  return (
    <>
      <Container
        className={props.activeModal === true ? " activate" : ""}
        onClick={props.click}
      >
        <Card>
          <div>
            <FigureCircle color="third">
              {props.icon === "bulling" ? (
                <MdAttachMoney size="2.5rem" color="#0A2733"></MdAttachMoney>
              ) : (
                <MdBusiness size="2.5rem"> </MdBusiness>
              )}
            </FigureCircle>
            <Title> {props.title} </Title> <Subtitle> {props.text} </Subtitle>
          </div>
          <div>
            <Text> Tiempo de suscripción: </Text>
            <TextRequest> {props.options.name} </TextRequest>
            <Text> Valor de la suscripción: </Text>
            <TextRequest>
              $ {(props.options.amount / 1000).toFixed(3)}
            </TextRequest>
          </div>
          <form action="https://checkout.wompi.co/p/" method="GET">
            <input
              type="hidden"
              name="public-key"
              value={wompiData["public-key"]}
            />
            <input type="hidden" name="currency" value={wompiData.currency} />
            <input
              type="hidden"
              name="amount-in-cents"
              value={props.options.amount * 100}
            />
            <input type="hidden" name="reference" value={wompiData.reference} />
            {/* opcionales*/}
            <input
              type="hidden"
              name="redirect-url"
              value={`${
                Routes.app_url_base + Routes.app_subscription_validated + "/"
              }`}
            />
            <input
              type="hidden"
              name="customer-data:email"
              value={userInfo.email}
            />
            <input
              type="hidden"
              name="customer-data:full-name"
              value={userInfo.name}
            />
            <input
              type="hidden"
              name="customer-data:phone-number"
              value={userInfo.cellphone}
            />
            <Button type="submit" onClick={handleBilling}>
              Pagar con Wompi
            </Button>
          </form>
        </Card>
      </Container>
      {/* <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
        is_error={modalData.is_error}
        iconSuccess={iconSuccess}
        iconError={iconError}
      /> */}
    </>
  )
}

import styled from 'styled-components'

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`
const mobile_sm = `@media (max-width: 768px)`
const mobile_xs = `@media (max-width: 350px)`


export const Card = styled.div`
    width:100%;
    text-align: center;
    justify-content:center;
    align-items:center;
    margin: 1rem 0;
`

export const TextCard = styled.p`
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.dark};
    font-size: ${props => props.sm ? '14px' : '16px'};
    font-weight: ${props => props.bold ? 'bold' : ''};
    display: block;
`

export const Row = styled.div`
    width:100%;
    display:flex;
    margin-bottom: .2rem;
`

export const Column = styled.div`
    display: ${props => props.right ? 'flex' : ''};
    text-align: ${props => props.align || 'left'};
    justify-content: ${props => props.align || 'left'};
    align-items: ${props => props.align || 'left'};
    margin: auto;
    width: ${props => props.lg ? props.lg : '50'}%;
    ${mobile_sm} {
        width: ${props => props.sm ? props.sm : '50'}%;
    }
    ${mobile_xs} {
        width: ${props => props.xs ? props.xs : '50'}%;
    }
    ${mobile} {
        margin-left: .5rem !important;
        margin-right: .5rem !important;
    }
`

export const FigureCircle = styled.figure`
    width:3rem;
    height:3rem;
    background: ${props => (
        props.verde ? props.theme.colors.green_light :
        props.rojo ? props.theme.colors.red_light :
        props.naranja ? props.theme.colors.orange_light :
        props.azul ? props.theme.colors.blue_light : 
        props.pink ? props.theme.colors.pink : 
        props.dark ? props.theme.colors.dark : 
        props.border ? 'rgba(0, 0, 0, 0)' : 
        props.cancel ? props.theme.colors.text : props.theme.colors.deactive

    )};
    border: ${props => props.border ? `2px solid ${props.theme.colors.deactive}` : ''};
    opacity: ${props => (props.skeleton ? '50%' : '')};
    box-shadow: ${props => (props.skeleton ? '' : ' 0 10px 14px rgba(0, 0, 0, .05)')};
    color:#fff;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
`



import React from 'react';
import {TitleAndInfo} from '../../../components/molecules/TitleAndInfo';
import {ListInitialInfo} from '../../../components/molecules/ListInitialInfo';
import {Container, Section, ContainerBtn, SectionList, Btn} from './styles';

import {useHistory} from 'react-router-dom';
import { Routes } from '../../../routes';

export const InitialInformation = () => {

    const history = useHistory();
    const [initialInfo, setInitialInfo] = React.useState(false)

    React.useEffect(() => {
        setInitialInfo(sessionStorage.getItem('initial_info') === 'true')
    }, [])

    const handleOmitir = () => {
        history.push(Routes.app_my_profile)
    }
    const handleClick = () => {
        history.push(Routes.app_initial_inventory)
    }

    return(
        <Container navbar={initialInfo}>
            <Section>
                <TitleAndInfo
                    btnPrev = {initialInfo} 
                    pathPrev={Routes.app_my_profile}
                    titlePage='Ingresando mi información inicial'
                    subtitleInfo=''
                    displayDate={true}
                    dateColor="dark"
                />
            </Section>
            <SectionList>
                <ListInitialInfo  />
                {!initialInfo &&
                    <ContainerBtn>
                        <Btn onClick={handleOmitir} ghost second >Omitir</Btn>
                        <Btn onClick={handleClick} second >Siguiente</Btn>
                    </ContainerBtn>
                }
            </SectionList>
        </Container>
    )
}
import React from 'react';

// import {Btn} from '../../atoms/Btn';

import {Container, Card, Title, Text, ContainerBtns, Button} from './styles';

export const ModalDeleteConfirm = (props) => {

  

  return (
    <Container className={props.activeModal === true ? " activate" : ""}>
      <Card>
        <Title>{props.title}</Title>
        {/* <figure> */}
        {/*   <img src={props.icon} alt=""/> */}
        {/* </figure> */}
        <Text>{props.text}</Text>

        <ContainerBtns>
            <Button 
              onClick={props.handleClosed}
              name="Cancelar"
              second="true"
              primary="true"

              className={props.boton === true ? " success" : ""}
            >{props.btn}</Button>
            <Button 
              onClick={props.deleteCard}
              name="Eliminar"
              second="true"
              white="true"
              className={props.boton === true ? " success" : ""}
            >{props.btnSubmit}</Button>
        </ContainerBtns>

      </Card>
    </Container>
  );
};
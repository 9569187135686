import styled from "styled-components"

export const Container = styled.div`
  background: ${(props) => props.theme.colors.dark};
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 100;
  padding: 5px 8px;
  border-radius: 0 0 5px 5px;
`

export const Text = styled.h4`
  font-size: 11px;
  color: ${(props) => props.theme.colors.third_clear};
`

import React from "react"
import { Container, Section, SectionList } from "./styles"
import { MdAttachMoney } from "react-icons/md"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../../components/atoms/cards/BtnNewCard"
import { CardBudget } from "../../../../components/atoms/cards/CardBudget"
import { deletePayment, getIncomesDetail } from "../../../../api/requests"
import { useParams } from "react-router-dom"
import { CardTotal } from "../../../../components/atoms/cards/CardTotal"
import { CustomDate } from "../../../../utils/date"
import { CardInitialInfo } from "../../../../components/atoms/cards/CardInitialInfo"
import { Utilities } from "../../../../utils/utilities"
import { DataUtil } from "../../../../utils/data"
import { ModalDeleteConfirm } from "../../../../components/molecules/Modals/ModalDeleteConfirm"
import { Modal } from "../../../../components/molecules/Modals/Modal"
import { CardEmpty } from "../../../../components/atoms/cards/CardEmpty"

export const PaymentDetail = () => {
  const { type, id } = useParams()
  const [paymentList, setPaymentList] = React.useState([])
  const [credit, setCredit] = React.useState({
    credit: 0,
    payment: 0,
    difference: 0,
  })
  const [modalErrors, setModalErrors] = React.useState({
    errors: "",
    status: false,
  })
  const [modalDelete, setModalDelete] = React.useState({
    id: null,
    status: false,
  })

  React.useEffect(() => {
    async function fetchData() {
      await getIncomeDetail(id)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  async function getIncomeDetail(id) {
    const response = await getIncomesDetail(id)
    if (response.status === 200) {
      let resp = response.data
      setCredit({
        credit: resp.value,
        payment: resp.payment,
        difference: resp.value - resp.payment,
      })
      // eslint-disable-next-line array-callback-return
      resp.payments.map((obj) => {
        obj.id_pop = undefined
        obj.status = false
        obj.anchor_el = null
      })
      setPaymentList(resp.payments)
    }
  }

  async function handleClick(event, status, data, index) {
    if (status) {
      let array = [...paymentList]
      array[index].id_pop = `simple-popover-${data.id}`
      array[index].status = true
      array[index].anchor_el = event.currentTarget
      setPaymentList(array)
    }
  }

  const handleClose = (index) => {
    let array = [...paymentList]
    array[index].id_pop = undefined
    array[index].status = false
    array[index].anchor_el = null
    setPaymentList(array)
  }

  async function handleOpenDelete(id, index) {
    handleClose(index)
    setModalDelete({ id: id, status: true })
  }

  async function handleDelete(selected_id) {
    const response = await deletePayment(id, selected_id)

    if (response.status === 204) {
      await getIncomeDetail(id)

      setModalErrors({
        title: "Operación exitosa",
        errors: `El abono ${id} ha sido eliminado satisfactoriamente`,
        status: true,
      })
      handleCloseDelete()
    } else {
      if (response.data.detail) {
        handleCloseDelete()
        setModalErrors({
          title: "Error",
          errors: response.data.detail,
          status: true,
        })
      }
      if (response.data.validations_error) {
        handleCloseDelete()
        setModalErrors({
          title: "Error",
          errors: response.data.validations_error[0],
          status: true,
        })
      }
    }
  }

  async function handleCloseDelete() {
    setModalDelete({ id: "", status: false })
  }

  async function handleCloseErrors() {
    setModalErrors({ errors: "", status: false })
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={DataUtil.getData(type, id).prev_route}
          titlePage={`Crédito`}
          displayDate={true}
          dateColor="dark"
          tooltip="income"
        />
        <CardBudget
          path="#"
          first={{ title: "Crédito", value: credit.credit }}
          second={{ title: "Abonado", value: credit.payment }}
          cardColor="linear_gradient_pink_red"
        />
        <CardTotal
          data={{ title: "Cuentas por cobrar:", value: credit.difference }}
          titleColor="dark"
          valueColor={!credit.difference ? "third" : "red_brand"}
        />
        <BtnNewCard
          title="Agregar nuevo ABONO"
          path={DataUtil.getData("payment", id).route}
        />
      </Section>
      <SectionList count={paymentList.length < 4}>
        {!paymentList.length && <CardEmpty></CardEmpty>}
        {paymentList.map((row, index) => (
          <CardInitialInfo
            key={index}
            title={`Abono: ${row.payment_method}`}
            value={row.value}
            path="#"
            detailCard={true}
            showPop={row.deleted ? false : true}
            cardType={1}
            detail={{
              date: CustomDate.date1(row.date),
              reference: `${Utilities.addZeros(id, 4)}-${Utilities.addZeros(
                row.id,
                2
              )}`,
              payment: row.payment,
              status: row.deleted
                ? "Anulado"
                : row.is_finished
                ? "Finalizado"
                : "hide",
              edit: row.deleted
                ? false
                : {
                    title: "Editar",
                    path: DataUtil.getData("edit_payment", id, row.id).route,
                  },
              inactivate: row.deleted
                ? false
                : {
                    title: "Anular",
                    path: "#",
                    handleDelete: (e) => handleOpenDelete(row.id, index),
                  },
            }}
            row={row}
            handleClick={(e) => handleClick(e, true, row, index)}
            handleClose={(e) => handleClose(index)}
            icon={<MdAttachMoney size="2rem" />}
            color={row.deleted ? "rojo" : ""}
          />
        ))}
      </SectionList>

      <Modal
        activeModal={modalErrors.status}
        title={modalErrors.title}
        text={modalErrors.errors}
        btn="Cerrar"
        handleClosed={handleCloseErrors}
      />

      <ModalDeleteConfirm
        activeModal={modalDelete.status}
        title="Anular abono"
        text={`¿Está seguro de que desea anular el abono?`}
        btn="Cancelar"
        btnSubmit="Anular"
        deleteCard={(e) => handleDelete(modalDelete.id)}
        handleClosed={handleCloseDelete}
      />
    </Container>
  )
}

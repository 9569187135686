import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  transition: all 0.5s ease-in;
  cursor: pointer;
  margin-bottom: 1rem;
  &:hover {
    opacity: 0.8;
  }
`

export const FigureCircle = styled.figure`
  width: 2.5rem;
  height: 2.5rem;
  background: ${(props) =>
    props.verde
      ? props.theme.colors.green_light
      : props.rojo
      ? props.theme.colors.red_light
      : props.naranja
      ? props.theme.colors.orange_light
      : props.azul
      ? props.theme.colors.blue_light
      : props.pink
      ? props.theme.colors.pink
      : props.cancel
      ? props.theme.colors.text
      : props.theme.colors.deactive};
  opacity: ${(props) => (props.skeleton ? "50%" : "")};
  box-shadow: ${(props) =>
    props.skeleton ? "" : " 0 10px 14px rgba(0, 0, 0, .05)"};
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Section = styled.section`
  padding: 0 0 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CardTitle = styled.h6`
  display: ${(props) => (props.align ? "" : "flex")};
  align-items: center;
  flex-wrap: wrap;
  opacity: ${(props) => (props.skeleton ? "0" : "")};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : "")};
  font-size: 0.9rem;
  margin-top: ${(props) => (props.custom ? "4px" : "2px")};
`

export const CardValue = styled.p`
  font-size: ${(props) => (props.sm ? ".8rem" : "1.2rem")};
  color: ${(props) =>
    props.rojo
      ? props.theme.colors.red_brand
      : props.naranja
      ? props.theme.colors.red_brand
      : props.theme.colors.text};
  opacity: ${(props) => (props.skeleton ? "0" : "")};
  text-align: ${(props) => props.align || "left"};
  position: ${(props) => (props.custom ? "absolute" : "")};
  bottom: 0;
  right: 0;
`

import React from "react"
import {
  Container,
  BtnItem,
  TitleItem,
  FigureItem,
  Figure,
  Logo,
} from "./styles"
import { MdAccountBalanceWallet } from "react-icons/md"
import { MdStore } from "react-icons/md"
import { MdEqualizer } from "react-icons/md"
import { MdLocalOffer } from "react-icons/md"
import { MdPerson } from "react-icons/md"
import { MdDns } from "react-icons/md"
import { Routes } from "../../../routes"
import logo from "../../../assets/img/finku-logo.png"

export const NavBar = () => {
  return (
    <Container>
      <Figure>
        <Logo src={logo} alt="Logo Finku" />
      </Figure>
      <BtnItem to={Routes.app_my_profile} activeClassName="item_active">
        <FigureItem>
          <MdPerson size="2rem" />
        </FigureItem>
        <TitleItem>Perfil</TitleItem>
      </BtnItem>
      <BtnItem to={Routes.app_my_inventory} activeClassName="item_active">
        <FigureItem>
          <MdDns size="2rem" />
        </FigureItem>
        <TitleItem>Inventario</TitleItem>
      </BtnItem>
      <BtnItem to={Routes.app_income} activeClassName="item_active">
        <FigureItem>
          <MdStore size="2rem" />
        </FigureItem>
        <TitleItem>Ingresos</TitleItem>
      </BtnItem>
      <BtnItem to={Routes.app_shopping} activeClassName="item_active">
        <FigureItem>
          <MdLocalOffer size="2rem" />
        </FigureItem>
        <TitleItem>Compras</TitleItem>
      </BtnItem>
      <BtnItem to={Routes.app_expenses} activeClassName="item_active">
        <FigureItem>
          <MdAccountBalanceWallet size="2rem" />
        </FigureItem>
        <TitleItem>Gastos</TitleItem>
      </BtnItem>
      <BtnItem to={"/my-reports/report"} activeClassName="item_active">
        <FigureItem>
          <MdEqualizer size="2rem" />
        </FigureItem>
        <TitleItem>Informes</TitleItem>
      </BtnItem>
    </Container>
  )
}

import React from "react"
import {
  Container,
  FigureCircle,
  Section,
  CardTitle,
  CardValue,
} from "./styles.js"

export const CardModal = ({ icon, color, title, value, click }) => {
  return (
    <Container onClick={click}>
      {color === "verde" ? <FigureCircle verde>{icon}</FigureCircle> : ""}
      {color === "rojo" ? <FigureCircle rojo>{icon}</FigureCircle> : ""}
      {color === "naranja" ? <FigureCircle naranja>{icon}</FigureCircle> : ""}
      {color === "azul" ? <FigureCircle azul>{icon}</FigureCircle> : ""}
      {color === "cancel" ? <FigureCircle cancel>{icon}</FigureCircle> : ""}
      {color === "pink" ? <FigureCircle pink>{icon}</FigureCircle> : ""}
      {color === "third" ? <FigureCircle third>{icon}</FigureCircle> : ""}
      {!color ? <FigureCircle>{icon}</FigureCircle> : ""}
      <Section>
        <CardTitle>{title}</CardTitle>
        <CardValue sm>{value}</CardValue>
      </Section>
    </Container>
  )
}

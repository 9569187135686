import React from 'react';
import { MdHdrWeak, MdInfo, MdMoveToInbox } from 'react-icons/md'
import {Container, TitleCard, ElementsContainer, Button} from './styles';

export const ReportPrepare = ({message, top, loading, click}) => {

    return (
        <Container loading>
            <ElementsContainer top={top}>
                {
                    loading === 'processing' ? <MdHdrWeak size="3rem" color="#899DBE"/> : 
                    loading === 'processed' ? <MdMoveToInbox size="3rem" color="#899DBE"/>
                    : <MdInfo size="2rem" color="#899DBE"></MdInfo> 
                }
                <TitleCard>{message ? message : 'No hay información disponible'}</TitleCard>
                {loading === 'processed' && <Button onClick={click}>Descargar informe</Button>}
            </ElementsContainer>
        </Container>
    )
}

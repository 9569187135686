import styled, { css } from 'styled-components'
import {Link} from 'react-router-dom'
import { fadeIn } from '../../../../styles/animation';

const mobile_lg = `@media (max-width: 1000px)`
const mobile_md = `@media (max-width: 500px)`
const mobile_sm = `@media (max-width: 400px)`
const mobile_xs = `@media (max-width: 330px)`

export const ContainerLink = styled(Link)`
    /* ${fadeIn({time:'2s'})}; */
    width:100%;
    background:#fff;
    padding:.7rem 1rem;
    border-radius:1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:1rem;
    transition:all .5s ease-in;
    &:hover {
        opacity:.8;
    }
`

export const Container = styled.div`
    ${fadeIn({time:'1s'})};
    width:100%;
    background:#fff;
    padding:1rem;
    border-radius:.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:1rem;
    &:hover {
        opacity:.8;
    }
`

export const DetailLink = styled(Link)`
    width:100%;
`

export const FigureCircle = styled.figure`
    width:2.5rem;
    height:2.5rem;
    background: ${props => (
        props.verde ? props.theme.colors.green_light :
        props.rojo ? props.theme.colors.red_light :
        props.naranja ? props.theme.colors.orange_light :
        props.azul ? props.theme.colors.blue_light : 
        props.pink ? props.theme.colors.pink : 
        props.third ? props.theme.colors.third : 
        props.cancel ? props.theme.colors.text : props.theme.colors.deactive

    )};
    opacity: ${props => (props.skeleton ? '50%' : '')};
    box-shadow: ${props => (props.skeleton ? '' : ' 0 10px 14px rgba(0, 0, 0, .05)')};
    color:#fff;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const Section = styled.section`
    padding:0 0 0 1rem;
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:center;    
`

export const CardTitle = styled.h6`
    display: ${props => (props.align ? '' : 'flex')};
    align-items:center;
    flex-wrap:wrap;
    opacity: ${props => (props.skeleton ? '0' : '')};
    color: ${props => (props.color ? props.theme.colors[props.color] : '')};
    font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
    margin-top: ${props => (props.custom ? '4px' : '2px')};
`

export const TitleMonth = styled.h6`
    color: #fff;
    background: ${props => (
        props.rojo ? props.theme.colors.red_brand :
        props.status === 'Anulado' ? props.theme.colors.text :
        props.status === 'Finalizado' ? props.theme.colors.blue_brand : 
        props.status === 'hide' ? '#FFF' : props.theme.colors.text
    )};
    text-align:${props => props.align ? props.align : 'left'};
    margin-left: ${props => props.status ? '' : '.5em'};
    border-radius:5px;
    font-size:.9em;
    padding:0.07em .5em 0.1em;
    font-weight:600;
    
    width: ${props => props.credit ? '50%' : ''};
    margin: ${props => props.credit ? 'auto' : ''};
    right: ${props => props.credit ? 0 : ''};
    margin-right: ${props => props.credit ? 0 : ''};
`

export const CardValue = styled.p`
    font-size: ${props => props.sm ? '.9rem' : '1.2rem'};
    font-weight:bold;
    color: ${props => (
        props.rojo ? props.theme.colors.red_brand
        :
        props.naranja ? props.theme.colors.red_brand
        :
        props.theme.colors.text
    )};
    opacity: ${props => (props.skeleton ? '0' : '')};
    text-align:${props => props.align ? props.align : 'left'};
    position: ${props => props.custom ? 'absolute' : ''};
    bottom: 0;
    right: 0;
`

export const SubtitlePage = styled.span`
    align-items:left;
    margin-top:3px;
    font-size: ${props => props.size ? props.size : '11px'};
    color: ${props => props.theme.colors.text};
`

export const CardDesc = styled.span`
    color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.dark)};
    font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
    margin-top: ${props => (props.custom ? '4px' : '2px')};
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    
    ${mobile_lg} {
        width: 300px;
    }
    ${mobile_md} {
        width: 200px;
    }
    ${mobile_sm} {
        width: 160px;
    }
    ${mobile_xs} {
        width: 120px;
    }
`

export const Row = styled.div`
    width:100%;
    display:flex;
`

export const Column = styled.div`
    text-align: ${props => props.align || 'left'};
    position: ${props => props.custom ? 'relative' : ''};
    width: ${props => (props.size ? props.size : '50')+'%'};
    margin-right:0;
    right:0;
`

export const Line = styled.div`
    margin:.4em 0 ;
    border-bottom:1px solid ${props => props.theme.colors.text_light};
`

export const Button = styled.button`
    width:auto;
    height:auto;
    color: ${props => props.theme.colors.deactive};
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left: 1px;
    transition:all 500ms ease;
    &:hover {
        color: ${props => props.theme.colors.third};
    }
`

export const LinkStyle = styled(Link)`
    width:auto;
    height:auto;
    color: ${props => props.theme.colors.deactive};
    display:flex;
    justify-content:center;
    align-items:center;
`

const RowStyle = css`
    font-family: ${props => props.theme.fonts.family};  
    font-size: 12px;
    padding: .5rem 1rem;  
    width: 100%;
    display: block; 
    text-align: left;
    color: ${props => props.theme.colors.text};   
    &:hover {
        background: rgba(194, 231, 255, .3); 
    } 
`

export const RowLink = styled(Link)`
    ${RowStyle}
`

export const RowButton = styled.button`
    ${RowStyle}
`

export const RowDiv = styled.div`
    ${RowStyle}  
    a {
        color: ${props => props.theme.colors.text};  
    } 
`


import React, { useEffect, useState } from "react"
import { ModalSubscriptionBilling } from "../../../../components/molecules/Modals/ModalSubscriptionBilling"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { useHistory } from "react-router-dom"
import {
  Container,
  Section,
  SectionPlans,
  CardPlan,
  TitleCard,
  CostCard,
  BtnCard,
  Description,
  DescriptionText,
} from "./styles"
import {
  getProfile,
  getSubscriptionPlans,
  getSubscriptionBilling,
  postSubscriptionBilling,
} from "../../../../api/requests"

import moment from "moment"
import "moment/locale/es"
import { Routes } from "../../../../routes"

export const SubscriptionPlans = () => {
  const [subscriptionsData, setSubscriptionsData] = useState([
    {
      id: 0,
      name: "",
      duration: 0,
      amount: 0,
      is_active: false,
    },
  ])
  const [userData, setUserData] = useState([])
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [open, setOpen] = useState(false)
  const [planSelect, setPlanSelect] = useState({
    id: 0,
    name: "",
    duration: 0,
    amount: 0,
    is_active: false,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()

  async function callSubscriptionBilling() {
    if (userData.length === 0) return
    return await getSubscriptionBilling().then((subscriptions) => {
      setUserSubscriptions(
        subscriptions.filter((sub) => {
          return sub.user.email === userData[0].user.email
        })
      )
    })
  }

  useEffect(() => {
    async function fetchData() {
      await getSubscriptions(true)
    }
    fetchData()

    async function fetchDataUser() {
      await getUser(true)
    }
    fetchDataUser()
  }, [])

  async function getSubscriptions() {
    const response = await getSubscriptionPlans()
    if (response.status === 200) {
      if (response.data) {
        let resp = response.data
        // Se ordena el arreglo por medio de su id para tener orden en las subscripciones
        setSubscriptionsData(
          resp.sort((o1, o2) => {
            if (o1.id < o2.id) {
              return -1
            } else if (o1.id > o2.id) {
              return 1
            } else {
              return 0
            }
          })
        )
      }
    }
  }

  async function getUser() {
    const response = await getProfile()

    if (response.status === 200) {
      if (response.data) {
        let resp = response.data
        setUserData(resp)
      }
    }
  }

  let handleOpenOptions = async (openModal, planId, event) => {
    const selectedPlan = subscriptionsData[planId - 1]
    setPlanSelect(selectedPlan)
    setError(false)
    if (selectedPlan.amount === 0) {
      event.target.innerText = "Cargando..."
      setLoading(true)

      const dateNow = new Date()
      const formatDate = moment(dateNow).add(selectedPlan.duration, "months").format("YYYY-MM-DD")
      const reminderDate = moment(formatDate)
        .add(-2, "days")
        .format("YYYY-MM-DD")
      const subscriptionData = {
        user_id: userData[0].user.id,
        subscription_id: selectedPlan.id,
        initial_date: new Date().toISOString().split("T")[0],
        final_date: formatDate,
        subscription_active: true,
        reminder_date: reminderDate,
        reminder_send: false,
      }

      await postSubscriptionBilling(subscriptionData).then((response) => {
        if (response.status === 200) {
          setLoading(false)
          history.push(Routes.app_welcome_user)
        } else {
          setError(true)
          setLoading(false)
        }
        event.target.innerText = "Elegir"
      })
    } else {
      setOpen(openModal)
    }
  }

  useEffect(() => {
    async function fetchSubscriptionsUser() {
      await callSubscriptionBilling()
    }
    fetchSubscriptionsUser()
    // eslint-disable-next-line
  }, [userData])

  const filteredSubscriptionsData = subscriptionsData
    .filter((plan) => {
      let show = true
      if (plan.amount === 0 && userSubscriptions.length > 0) {
        show = false
      }
      return plan.is_active && show
    })
    .sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount))

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev={false}
          titlePage="Accede a nuestros planes"
          darktheme
        />
      </Section>
      <Description>
        El paquete premium incluye el acceso a todas las funcionales del
        aplicativo web tales como:
      </Description>
      <DescriptionText>
        Registro de ingresos, compras, gastos, pagos, inventarios, informes,
        consultas y graficas.
      </DescriptionText>
      {subscriptionsData[0].name !== "" ? (
        <SectionPlans>
          {filteredSubscriptionsData.map((subscription, index) => (
            <CardPlan key={index}>
              <TitleCard> {subscription.name} </TitleCard>
              {subscription.amount > 0 && (
                <CostCard>
                  $ {(subscription.amount / 1000).toFixed(3)}{" "}
                </CostCard>
              )}
              <BtnCard
                disabled={loading}
                onClick={(event) =>
                  handleOpenOptions(true, subscription.id, event)
                }
              >
                Elegir
              </BtnCard>
            </CardPlan>
          ))}{" "}
        </SectionPlans>
      ) : (
        ""
      )}
      {error && (
        <DescriptionText>
          Algo salio mal 😢 por favor vuelve a intentarlo o comunicate con
          soporte.
        </DescriptionText>
      )}
      <ModalSubscriptionBilling
        activeModal={open}
        options={planSelect}
        userDat={userData}
        title="Detalle del Plan"
        text="Checkout"
        click={(e) => setOpen(false)}
        icon="bulling"
      />
    </Container>
  )
}

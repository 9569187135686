import styled from 'styled-components';

const desktopStartWidth = 1023;
const tabletStartWidth = 768;
const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
    min-height:100%;
    height:100%;
    background: ${props => props.theme.colors.third};
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:2rem;
`
export const Figure = styled.figure`
  margin:0;
  padding:0;
  display:flex;
  justify-content:flex-end;
`

export const Logo = styled.img`
  margin-bottom:5em;
  padding:0;
  height:200px;
  ${tablet} {
    /* height:200px; */
}
  ${mobile} {
    height:200px;
  }
`

export const Btn = styled.div`
  width:70%; 
  max-width:400px;
   /*width: ${props => ( props.second ? "45%" : "70%")}; */
  color: ${props => ( props.white ? props.theme.colors.main : props.ghost ? props.theme.colors.main : "#fff" )};
  background: ${props => ( props.white ? "#fff" : props.ghost ? "transparent" : props.theme.colors.main )};
  border:2px solid ${props => props.theme.colors.main};
  /* margin:0 auto; */
  margin-top:1em;
  padding:1em 3em;
  display:flex;
  justify-content:center;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.text};
    border:2px solid ${props => props.theme.colors.text};

    color: #fff;


  }
`
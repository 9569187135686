import styled from "styled-components"

const tabletStartWidth = 768
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
  &.activate {
    opacity: 1;
    visibility: visible;
  }
`

export const Card = styled.div`
  width: 400px;
  min-height: 500px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.main};
  padding: 4em 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  transition: all 2s ease;
  box-shadow: 1px 7px 25px rgba(0, 0, 0, 0.6);
  ${mobile} {
    width: 85%;
  }
`

export const Title = styled.h2`
  font-size: 1.4rem;
  color: #fff;
  line-height: 0rem;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
`

export const Subtitle = styled.h3`
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #ffffff;
  ${mobile} {
    font-size: 0.9rem;
  }
`

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  ${mobile} {
    font-size: 0.9rem;
  }
`
export const TextRequest = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.third};
  padding-bottom: 3rem;
  ${mobile} {
    font-size: 1.5rem;
  }
`

export const FigureCircle = styled.figure`
  width: 4rem;
  height: 4rem;
  background: ${(props) => props.theme.colors[props.color] || "#FFF"};
  color: #fff;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

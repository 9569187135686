import React from "react";
import { SubtitleInfo } from "../../atoms/SubtitleInfo";
import {
  Container,
  Section,
  SectionTitle,
  Subsection,
  TitlePage,
  SubtitlePage,
  BtnInfo,
  BtnPrev,
} from "./styles";
import {
  MdLiveHelp,
  MdFileDownload,
  MdNotificationsNone,
  MdNotificationsActive,
} from "react-icons/md";
import { MdArrowBack } from "react-icons/md";
import { CustomDate } from "../../../utils/date";
import { getNotifications } from "../../../api/requests";
import { useHistory } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import { Messages } from "../../../utils/messages";
import { shareLink } from "../../../utils/fakeData";

export const TitleAndInfo = ({
  titlePage,
  subtitleInfo,
  btnPrev,
  pathPrev,
  darktheme,
  displayDate,
  displayDownload,
  download,
  handleChange,
  dateColor,
  tooltip,
  share,
  hideNotifications,
}) => {
  const history = useHistory();
  const [hasNotifications, setHasNotifications] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      await getNotification();
    }
    fetchData();
  }, []);

  async function getNotification() {
    const response = await getNotifications();
    if (response.status === 200) {
      if (response.data.length) setHasNotifications(true);
    }
  }

  async function handleNotification() {
    history.push("/notifications");
  }

  const copyToClipboard = (text, event) => {
    let sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text;
    sampleTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);

    let passButtonText = event.target.innerText;
    event.target.innerText = "Copiado!";

    setTimeout(() => {
      event.target.innerText = passButtonText;
    }, 1500);
  };

  const modal = {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 100000,
    width: "100%",
    background: "#00000066",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const modalBackground = {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
  };

  const modalContent = {
    position: "relative",
    zIndex: 1,
    background: "#fff",
    padding: "2rem 1rem",
    borderRadius: "10px",
    textAlign: "center",
    width: "95%",
    maxWidth: "fit-content",
  };

  const modalLink = {
    background: "#cdcdcd",
    padding: "10px",
    borderRadius: "10px",
    maxWidth: "400px",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    margin: "10px auto",
    fontSize: "0.9rem",
  };

  const modalButton = {
    width: "100%",
    maxWidth: "300px",
    background: "#0F3849",
    color: "#fff",
    fontSize: "1rem",
    padding: "1rem",
    borderRadius: "10px",
    fontWeight: "bold",
  };

  return (
    <Container>
      <Section>
        {btnPrev ? (
          <BtnPrev to={pathPrev} darktheme={darktheme} onClick={handleChange}>
            <MdArrowBack size="1.5rem" />
          </BtnPrev>
        ) : (
          ""
        )}
        <Subsection>
          <SectionTitle>
            <TitlePage darktheme={darktheme}>{titlePage}</TitlePage>

            {tooltip && (
              <Tooltip
                title={Messages.general(tooltip)}
                enterTouchDelay={1}
                leaveDelay={2000}
              >
                <BtnInfo darktheme={darktheme} info>
                  <MdLiveHelp size="1.8rem" />
                </BtnInfo>
              </Tooltip>
            )}

            {displayDownload && (
              <BtnInfo color="main" onClick={download}>
                <MdFileDownload size="1.8rem" />
              </BtnInfo>
            )}
            {!hideNotifications && (
              <BtnInfo darktheme={darktheme} onClick={handleNotification}>
                {hasNotifications ? (
                  <MdNotificationsActive size="1.8rem" />
                ) : (
                  <MdNotificationsNone size="1.8rem" />
                )}
              </BtnInfo>
            )}
          </SectionTitle>
          {displayDate && (
            <SubtitlePage color={dateColor}>
              {CustomDate.date3("")}
            </SubtitlePage>
          )}
        </Subsection>
      </Section>
      {subtitleInfo && <SubtitleInfo subtitleInfo={subtitleInfo} />}
      {shareModal && (
        <div style={modal}>
          <div
            onClick={(_) => setShareModal(false)}
            style={modalBackground}
          ></div>
          <div style={modalContent}>
            <h1 style={{ color: "#000" }}>Compartir</h1>
            <p style={{ fontSize: "1rem" }}>
              Aqui tienes el link para compartir tu catalogo.
            </p>
            <span style={modalLink}>{shareLink()}</span>
            <button
              style={modalButton}
              onClick={(e) => copyToClipboard(shareLink(), e)}
            >
              Copiar
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

import React, { useEffect, useState } from "react"
import { Container, Section, SectionList } from "./styles"

import { MdAttachMoney } from "react-icons/md"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../../components/atoms/cards/BtnNewCard"

import { Routes } from "../../../../routes"
import {
  deleteShopping,
  getFinancialCategoriesByParam,
  getShoppingsByPayment,
  getShoppingsDetail,
} from "../../../../api/requests"

import { useParams } from "react-router-dom"
import { CardTotal } from "../../../../components/atoms/cards/CardTotal"
import { CustomDate } from "../../../../utils/date"
import { Utilities } from "../../../../utils/utilities"
import { CardInitialInfo } from "../../../../components/atoms/cards/CardInitialInfo"
import { ModalDeleteConfirm } from "../../../../components/molecules/Modals/ModalDeleteConfirm"
import { Modal } from "../../../../components/molecules/Modals/Modal"
import { CardEmpty } from "../../../../components/atoms/cards/CardEmpty"
import { DataUtil } from "../../../../utils/data"
import { ModalOptions } from "../../../../components/molecules/Modals/ModalOptions"

export const ShoppingDetail = () => {
  const { type } = useParams()
  const [shoppingDetail, setShoppingDetail] = useState([])
  const [shopping, setShopping] = useState({})
  const [options, setOptions] = useState([])
  const [open, setOpen] = useState(false)
  const [modalDelete, setModalDelete] = useState({
    id: null,
    status: false,
  })
  const [modalErrors, setModalErrors] = useState({
    errors: "",
    status: false,
  })
  const [total, setTotal] = useState(0)
  const [title, setTitle] = useState("")

  useEffect(() => {
    setShopping(DataUtil.getShopping(type))
    let selected = DataUtil.getShopping(type)

    if (type === "credit") setTitle("Cuentas por pagar:")
    else setTitle(`Total ${selected.title}:`)

    async function fetchData() {
      await getShopping(
        selected.payment_type,
        selected.payment_method,
        selected.type
      )
      await getFinancialShopping()
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  async function getFinancialShopping() {
    const response = await getFinancialCategoriesByParam({ my_shopping: true })

    if (response.status === 200) {
      let resp = response.data
      let array = []

      for (let i = 0; i < resp.length; i++) {
        resp[i].route = DataUtil.getShopping(
          type,
          DataUtil.getCategory(resp[i].id).value
        ).route
        array.push({ title: resp[i].name, route: resp[i].route })
      }
      setOptions(array)
    }
  }

  async function getShopping(payment_type, payment_method, type) {
    const response = await getShoppingsByPayment({
      payment_type: payment_type,
      payment_method: payment_method,
      payment: true,
    })
    if (response.status === 200) {
      let resp = response.data
      let total_sum = 0

      // eslint-disable-next-line array-callback-return
      resp.map((obj) => {
        if (!obj.deleted) {
          total_sum += obj.value - obj.payment
        }

        if (type === "credit") obj.title = CustomDate.date2(obj.date)
        else obj.title = obj.is_payment ? `Abono: ${type}` : `Compra: ${type}`

        obj.id_pop = undefined
        obj.status = false
        obj.anchor_el = null
        obj.data = { details: [] }
      })
      setShoppingDetail(resp)
      setTotal(total_sum)
    }
  }

  async function getShoppingById(id) {
    const response = await getShoppingsDetail(id)

    if (response.status === 200) {
      let resp = response.data

      resp.name = resp.provider.name
      resp.doc_type = resp.provider.doc_type.name
      resp.doc_number = resp.provider.doc_number
      resp.address = resp.provider.address
      resp.phone = resp.provider.phone
      resp.cellphone = resp.provider.cellphone
      resp.city = resp.provider.city
        ? resp.provider.city.name
        : resp.provider.city

      // eslint-disable-next-line array-callback-return
      resp.details.map((obj) => {
        obj.unit_value = Intl.NumberFormat("es-CO", {
          maximumFractionDigits: "0",
          style: "currency",
          currency: "COP",
        }).format(obj.value)
        obj.total_value = Intl.NumberFormat("es-CO", {
          maximumFractionDigits: "0",
          style: "currency",
          currency: "COP",
        }).format(obj.value * obj.quantity)
      })

      resp.total_value = Intl.NumberFormat("es-CO", {
        maximumFractionDigits: "0",
        style: "currency",
        currency: "COP",
      }).format(resp.value)

      return resp
    }
  }

  async function handleClick(event, status, data, index) {
    if (status) {
      let response = await getShoppingById(data.id)

      let array = [...shoppingDetail]
      array[index].id_pop = `simple-popover-${data.id}`
      array[index].status = true
      array[index].anchor_el = event.currentTarget
      array[index].category = DataUtil.getCategory(response.category.id).value
      array[index].data = response ? response : { details: [] }

      setShoppingDetail(array)
    }
  }

  const handleClose = (index) => {
    let array = [...shoppingDetail]
    array[index].id_pop = undefined
    array[index].status = false
    array[index].anchor_el = null
    array[index].data = { details: [] }

    setShoppingDetail(array)
  }

  async function handleOpenDelete(id, index) {
    handleClose(index)
    setModalDelete({ id: id, status: true })
  }

  async function handleDelete(id) {
    const response = await deleteShopping(id)

    if (response.status === 204) {
      await getShopping(
        shopping.payment_type,
        shopping.payment_method,
        shopping.type
      )

      setModalErrors({
        title: "Operación exitosa",
        errors: `La compra ${id} ha sido anulada satisfactoriamente`,
        status: true,
      })
      handleCloseDelete()
    } else {
      if (response.data.detail) {
        handleCloseDelete()
        setModalErrors({
          title: "Error",
          errors: response.data.detail,
          status: true,
        })
      }
      if (response.data.validations_error) {
        handleCloseDelete()
        setModalErrors({
          title: "Error",
          errors: response.data.validations_error[0],
          status: true,
        })
      }
    }
  }

  async function handleCloseDelete() {
    setModalDelete({ id: "", status: false })
  }

  async function handleCloseErrors() {
    setModalErrors({ errors: "", status: false })
  }

  let handleOpenModal = (value) => {
    setOpen(value)
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={Routes.app_shopping}
          titlePage={shopping.title}
          displayDate={true}
          dateColor="dark"
          tooltip="shopping"
        />
        <BtnNewCard
          title={`Registrar nueva compra en ${shopping.type}`}
          path="#"
          isModal={true}
          click={(e) => handleOpenModal(true)}
        />
        <CardTotal
          data={{ title: title, value: total }}
          titleColor="dark"
          valueColor={type === "credit" ? "red_brand" : "text"}
        />
      </Section>
      <SectionList count={shoppingDetail.length < 4}>
        {!shoppingDetail.length && <CardEmpty></CardEmpty>}
        {shoppingDetail.map((row, index) => (
          <CardInitialInfo
            key={index}
            title={row.title}
            value={row.value}
            detailCard={true}
            showPop={row.is_payment || row.deleted ? false : true}
            cardType={type !== "credit" ? 1 : 2}
            path={"#"}
            detail={{
              path: row.deleted
                ? "#"
                : DataUtil.getShopping(`edit_${type}`, row.category, row.id)
                    .detail,
              date: CustomDate.date1(row.date),
              reference: Utilities.addZeros(row.id, 4),
              payment: row.payment,
              status: row.deleted
                ? "Anulado"
                : row.paid_out
                ? "Finalizado"
                : "hide",
              credit: row.value,
              credit_color:
                row.payment !== row.value ? "red_brand" : "text_light",
              edit:
                row.is_payment || row.deleted
                  ? false
                  : {
                      title: "Editar",
                      path: DataUtil.getShopping(
                        `edit_${type}`,
                        row.category,
                        row.id
                      ).route,
                    },
              inactivate:
                row.is_payment || row.deleted
                  ? false
                  : {
                      title: "Anular",
                      path: "#",
                      handleDelete: (e) => handleOpenDelete(row.id, index),
                    },
            }}
            row={row}
            handleClick={(e) => handleClick(e, true, row, index)}
            handleClose={(e) => handleClose(index)}
            icon={<MdAttachMoney size="2rem" />}
            color={row.deleted ? "cancel" : ""}
          />
        ))}
      </SectionList>

      <ModalOptions
        activeModal={open}
        options={options}
        title="Nueva compra"
        text="Seleccione la categoría a la que pertenece la compra"
        click={(e) => handleOpenModal(false)}
      />
      <Modal
        activeModal={modalErrors.status}
        title={modalErrors.title}
        text={modalErrors.errors}
        btn="Cerrar"
        handleClosed={handleCloseErrors}
      />
      <ModalDeleteConfirm
        activeModal={modalDelete.status}
        title="Anular compra"
        text={`¿Está seguro de que desea anular la compra?`}
        btn="Cancelar"
        btnSubmit="Anular"
        deleteCard={(e) => handleDelete(modalDelete.id)}
        handleClosed={handleCloseDelete}
      />
    </Container>
  )
}

import React from 'react';
import {Container, Section, Header, Title, Figure, Logo, FormContainer} from "./styles.js";
import logo from "../../assets/img/finku-logo.png";
import {FormRecoverPassword} from "../../components/organisms/forms/FormRecoverPassword";
import {Modal} from "../../components/molecules/Modals/Modal";
import {ModalLoading} from "../../components/molecules/Modals/ModalLoading";
import { recoverPassword } from '../../api/requests.js';
import {Routes} from '../../routes';
import {useHistory} from 'react-router-dom';


export const RecoverPassword = () => {

    // const [email, setEmail] = React.useState("");
    
    const history = useHistory();


    const handleSubmit = (data) => {
        // console.log("data")
        // console.log(data)
        setIsLoading(true)
        setActiveModalLoading(true)
        // console.log('handlesumbit resetPassword')
        // console.log(email)
        recoverPassword(data).then(response => {
            if(response.status === 200) {
                setActiveModal(true)
                setModalData({
                    title:'Contraseña actualizada',
                    text:"Tu nueva contraseña fue actualizada correctamente",
                    btn:"Aceptar"
                    
                })
                // setTimeout(setIsLoading(false), 500000)
                // setTimeout(setActiveModalLoading(false), 500000)
                // setIsLoading(false)
                // setActiveModalLoading(false)
                setTimeout(() => {
                    history.push(Routes.app_login)
                }, 5000)


            } else if(response.status === 400) {
                // setIsLoading(false)
                // setActiveModalLoading(false)
                setActiveModal(true)
                setModalData({
                    title:'Solicitud rechazada',
                    text:"Su contraseña no pudo ser actualizada, intentelo nuevamente.",
                    btn:"Aceptar"

                })

            } 
        })

        
    }

    const [isLoading, setIsLoading] = React.useState(false);
    const [activeModalLoading, setActiveModalLoading] = React.useState(false);
    const [activeModal, setActiveModal] = React.useState(false);
    const [modalData, setModalData] = React.useState({
        title:'',
        text: '',
        btn:''
    });
    
    const handleClosed = () => {
      setActiveModal(false)
      setIsLoading(false)
      setActiveModalLoading(false)
    }
  
    React.useEffect(() => {
      document.title = 'Actualizar contraseña';
      // console.log("isLoading")
      // console.log(isLoading)
    }, [])
  

    return (
        <>
            {isLoading ? 
                <ModalLoading
                    activeModalLoading={activeModalLoading}
                    title='Cargando...'
                />
                :
                ''
            }
            <Container>
                <Section>
                    <Header>
                        <Title>Actualizar<br/> Contraseña</Title>
                    </Header>
                    <FormContainer>
                        <FormRecoverPassword
                            btn="Iniciar Sesión"
                            redirect="¿Deseas regresar a iniciar sesión? Ingresa aquí."
                            url="/login"
                            handleSubmit = {handleSubmit}
                            // email={email}
                            // onChangeEmail={function (e) {setEmail(e.target.value)}}

                            onSubmit={function () {handleSubmit()}}
                        />
                    </FormContainer>
                </Section>
            </Container>
            <Modal
                activeModal={activeModal}
                handleClosed={handleClosed}
                title={modalData.title}
                text={modalData.text}
                btn={modalData.btn}
            />
        </>
    )
}
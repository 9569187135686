import React from "react"
import { Container, Section } from "./styles"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { FormEditInventoriesContainer } from "../../../../containers/FormEditInventoriesContainer"
import { useParams } from "react-router-dom"
import { getInventoryItem } from "../../../../api/requests"

export const EditInventoryForm = () => {
  const { id } = useParams()

  const [inventoryItem, setInventoryItem] = React.useState({})

  const loadInventoryItem = async (id) => {
    const response = await getInventoryItem(id)
    if (response.status === 200) {
      setInventoryItem(response.data)
      return response.data
    }
  }

  React.useEffect(() => {
    async function fetchLoadInventoryItem() {
      await loadInventoryItem(id)
    }
    fetchLoadInventoryItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        <TitleAndInfo
          // pathPrev={Routes.app_my_inventory}
          btnPrev
          pathPrev={`/inventory-detail/${id}`}
          titlePage="Editar inventario"
          darktheme="true"
          tooltip="inventory"
        />
        <FormEditInventoriesContainer
          idEdit={id}
          objtItem={inventoryItem}
          loadInventoryItem={loadInventoryItem}
        />
      </Section>
    </Container>
  )
}

import React from 'react';
import {Container, Section} from './styles';

import {TitleAndInfo} from '../../../../components/molecules/TitleAndInfo';
import { Routes } from '../../../../routes';
import { FormProfile } from '../../../../components/organisms/forms/myProfile/FormProfile';

export const ProfileForm = () => {

    return (
        <Container>
            <Section>
                <TitleAndInfo 
                    titlePage="Editar información del perfil"
                    pathPrev={Routes.app_my_profile}
                    btnPrev
                    darktheme
                />
                <FormProfile/>
            </Section>
        </Container>
    )
}

import React, { useEffect, useState } from "react"
import { Container, Section, SectionList } from "./styles"
import { MdAttachMoney } from "react-icons/md"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../components/atoms/cards/BtnNewCard"
import { EmptyMessage } from "../../../components/atoms/EmptyMessage"
import { CardFinancialInfo } from "../../../components/atoms/cards/CardFinancialInfo"
import { Routes } from "../../../routes"
import { getAssets } from "../../../api/requests"

export const InitialAssets = () => {
  const [listAssets, setListAssets] = useState([])
  const [reList, setReList] = useState(false)
  const [skeleton, setSkeleton] = useState(false)
  const [initialInfo, setInitialInfo] = useState(false)

  useEffect(() => {
    setInitialInfo(sessionStorage.getItem("initial_info") !== "true")
  }, [])

  function updateItems() {
    loadAssets()
  }

  async function loadAssets() {
    setSkeleton(true)
    const response = await getAssets()
    if (response.status === 200) {
      setListAssets(response.data.financial_info)
      setTimeout(() => {
        setSkeleton(false)
      }, 1000)
    }
  }

  useEffect(() => {
    loadAssets()
  }, [reList])

  return (
    <Container navbar={initialInfo}>
      <Section>
        <TitleAndInfo
          btnPrev={true}
          pathPrev={Routes.app_initial_information}
          titlePage="Activos iniciales"
          subtitleInfo={
            initialInfo
              ? "Es momento de registrar todos tus activos."
              : "La información inicial es solo de lectura. No se puede editar, ni eliminar información."
          }
          tooltip="assets"
        />
        {initialInfo && (
          <BtnNewCard
            title="Agregar nuevo activo."
            path={Routes.app_initial_assets_form}
          />
        )}
      </Section>
      <SectionList count={listAssets.length < 4}>
        {skeleton && (
          <>
            {[1, 2, 3].map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <CardFinancialInfo
                    title="prueba"
                    value="0"
                    skeleton={skeleton}
                  />
                </React.Fragment>
              )
            })}
          </>
        )}
        {!skeleton && listAssets.length === 0 && (
          <EmptyMessage message="Aún no existen Activos registrados." />
        )}
        {!skeleton &&
          listAssets.map((assetItem, i) => {
            return (
              <React.Fragment key={i}>
                <CardFinancialInfo
                  id={assetItem.id}
                  hide_delete={true}
                  title={assetItem.category.name}
                  value={assetItem.value}
                  color="verde"
                  icon={<MdAttachMoney size="2rem" />}
                  reList={reList}
                  setReList={setReList}
                  redirect={Routes.app_initial_assets}
                  redirectEdit={`/edit-initial-assets-form/${assetItem.id}`}
                  updateItems={updateItems}
                  showIcons={initialInfo}
                />
              </React.Fragment>
            )
          })}
      </SectionList>
    </Container>
  )
}

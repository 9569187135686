import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
      width:100%;
      padding-left:0;
    }
`

export const Section = styled.section`
    max-width:1000px;
    width:100%;
    height:100%;
    flex:0;
    display:flex;
    flex-direction:column;
    ${mobile} {
      width:100%;
    }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  border-radius: 8px;
  background: ${props => props.total ? props.color : ''};
  margin-bottom: ${props => props.total ? '1rem' : ''};
  text-transform: ${props => props.total ? 'uppercase' : ''};
  align-items:center;
`

export const Label = styled.label`
  margin: auto;
  width: ${props => props.full ? '100%' : '60%'};
  color: ${props => props.theme.colors.dark};
  font-size: ${props => props.title ? '.7rem' : '.8rem'};
  border-bottom: ${props => props.title ? `1px solid ${props.theme.colors.deactive}` : ''};
  margin-left: ${props => props.title ? '' : '1rem'};
  font-weight: bold;
  line-height: 2;
`

export const Value = styled.span`
  margin: auto;
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.text};
  font-size: ${props => props.fontSize ? '1rem' : '.8rem'};
  margin-right: ${props => props.title ? '' : '1rem'};
  font-weight: bold;
  line-height: 2;
  text-align: right;
`

export const Button = styled.button`
  width: 100%;
  margin: auto;
  color: ${props => props.theme.colors.third};
  background: ${props => props.theme.colors.dark};
  font-weight: bold;
  padding: 1.2rem;
  border-radius: 2rem;
  margin: 1rem 0;
`

export const AButton = styled.a`
  width: 100%;
  margin: auto;
  color: ${props => props.theme.colors.third};
  background: ${props => props.theme.colors.dark};
  font-weight: bold;
  font-size: .8rem;
  padding: 1.2rem;
  border-radius: 2rem;
  margin: 1rem 0;
  text-align: center;
`

export const RowCard = styled.div`
  width: 100%;
  display: flex;
  border-radius: 8px;
  background: ${props => props.theme.colors.white};
  padding: .7rem;
  margin: auto;
  margin-bottom: .5rem;
`

export const RowDiv = styled.div`
  font-family: ${props => props.theme.fonts.family};  
  font-size: 12px;
  padding: .5rem 1rem;  
  width: 100%;
  display: block; 
  color: ${props => props.theme.colors.text};   
  &:hover {
    background: rgba(194, 231, 255, .3); 
  } 
`

import styled from "styled-components"
import { MdAddCircleOutline } from "react-icons/md"

export const CardTitle = styled.h6`
  text-align: center;
  font-size: 0.8rem;
  margin-left: 1rem;
`

export const Figure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`

export const CircleIcon = styled(MdAddCircleOutline)``

export const Container = styled.div`
  cursor: pointer;
  width: 100%;
  color: ${(props) =>
    props.darktheme
      ? props.theme.colors.second
      : props.ghost
      ? props.theme.colors.third
      : props.theme.colors.second};
  background: ${(props) =>
    props.darktheme
      ? props.theme.colors.third
      : props.ghost
      ? props.theme.colors.main
      : props.theme.colors.main};
  border: ${(props) =>
    props.darktheme
      ? "2px solid " + props.theme.colors.main
      : props.ghost
      ? "2px solid " + props.theme.colors.third
      : "2px solid " + props.theme.colors.main};
  padding: ${(props) =>
    props.darktheme
      ? ".7rem 1rem"
      : props.ghost
      ? ".7rem .4rem"
      : ".7rem 1rem"};
  border-radius: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "1rem"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "1rem")};
  ${CardTitle} {
    color: ${(props) => (props.darktheme ? props.theme.colors.main : "#fff")};
  }
  ${Figure} {
    color: ${(props) =>
      props.darktheme ? props.theme.colors.main : props.theme.colors.second};
  }
  ${CircleIcon} {
    color: ${(props) =>
      props.darktheme
        ? props.theme.colors.main
        : props.ghost
        ? props.theme.colors.third
        : props.theme.colors.third};
    width: ${(props) => (props.darktheme ? "" : props.ghost ? "1px" : "")};
  }
`

export const Section = styled.section`
  padding: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

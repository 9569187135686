import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
    height:100%;
    padding-left:23%;
    background: ${props => props.theme.colors.green_table};
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
        width:100%;
        padding-left:0;
  }
`

export const Section = styled.section`
    max-width:1000px;
    width:100%;
    height:fit-content;
    flex:0;
    background: ${props => props.theme.colors.green_table};
    padding: 2rem 2rem 1.5rem;
    display:flex;
    flex-direction:column;

    ${mobile} {
        width:100%;
  }
`

export const SectionList = styled.div`
    background: ${props => props.theme.colors.light};
    max-width:1000px;
    display:flex;
    width:100%;
    height: 100%;
    flex-direction:row;
    flex-wrap:wrap;
    padding: 0 2rem 2rem;
    padding-top: 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content:space-between;
    ${mobile} {
      padding-bottom: 3.5rem;
  }
`
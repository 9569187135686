import styled from 'styled-components'

// const desktopStartWidth = 1023;
// const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
// const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Text = styled.h2`
`
export const Span = styled.span`
    font-weight:700;
`

export const WelcomeSubtitle = styled.h4`
    padding-top:1em;
`

export const WelcomeText = styled.p`
    padding:3em 2em;
    font-size:1.5em;
    text-align:center;

`
import React from 'react';
import {Container, Section, SectionList} from './styles';

import { MdAttachMoney } from "react-icons/md";
import {TitleAndInfo} from '../../../../components/molecules/TitleAndInfo';
import {BtnNewCard} from '../../../../components/atoms/cards/BtnNewCard';

import { getShoppings, getShoppingsExport } from '../../../../api/requests';

import { CardBudget } from '../../../../components/atoms/cards/CardBudget';
import { CardInitialInfo } from '../../../../components/atoms/cards/CardInitialInfo';
import { ModalOptions } from '../../../../components/molecules/Modals/ModalOptions';
import { DataUtil } from '../../../../utils/data';

export const MyShopping = () => {

    const [shoppingData, setShoppingData] = React.useState(
        {
            bank: 0,
            budget: 0,
            cash: 0,
            credit: 0,
            date: "",
            difference: 0,
            real: 0,
            shoppingList: []
        }
    )
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])

    React.useEffect(() => {
        async function fetchData() {
            await getShopping();
        }
        fetchData();

        let array = []
        array.push({title: 'Efectivo', route: '/shopping-detail/cash'})
        array.push({title: 'Bancos', route: '/shopping-detail/bank'})
        array.push({title: 'Créditos', route: '/shopping-detail/credit'})
        setOptions(array)
    }, [])

    async function getShopping() {
        const response = await getShoppings(true)
        if (response.status === 200) {
            let resp = response.data;
            resp.shoppingList = [
                {title: 'Efectivo', value: resp.cash, code: 'cash'},
                {title: 'Bancos', value: resp.bank, code: 'bank'},
                {
                    title: 'Créditos (cuentas por pagar)',
                    value: resp.credit,
                    code: 'credit',
                    fontColor: 'rojo',
                    iconColor: 'rojo'
                }
            ]
            setShoppingData(resp);
        }
    }

    let handleOpenOptions = (event) => {
        setOpen(event)
    }

    async function handleDownload() {
        const response = await getShoppingsExport()

        if (response.status === 200) {
            let file = new File([response.data], '', { type: response.headers["Content-Type"] });
            let url = window.URL.createObjectURL(file);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Reporte - Mis Compras.xlsx';
            a.click();
        }
    }

    return (
        <Container>
            <Section>
                <TitleAndInfo
                    btnPrev = {false}
                    titlePage='Mis compras'
                    displayDate={shoppingData.date}
                    displayDownload={true}
                    download={handleDownload}
                    dateColor="dark"
                    tooltip="shopping"
                />
                <CardBudget
                    path = "#"
                    first={{title: "Real", value: shoppingData.real}}
                />
                <BtnNewCard
                    title='Agregar nueva compra'
                    path={'my-shopping'}
                    isModal={true}
                    click={e => handleOpenOptions(true)}
                />
                <ModalOptions
                    activeModal={open}
                    options={options}
                    title="Nueva compra"
                    text="Seleccione el tipo de compra a registrar"
                    click={e => handleOpenOptions(false)}
                />
            </Section>
            <SectionList>
                {shoppingData.shoppingList.map((row, index) => (
                    <CardInitialInfo
                        key={index}
                        title={row.title}
                        value={row.value}
                        icon={<MdAttachMoney size='2rem' />}
                        color={row.iconColor}
                        fontColor={row.fontColor}
                        path={DataUtil.getShopping(row.code).prev_route}
                    />
                ))}
            </SectionList>
        </Container>
    )
}

import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Section,
  Line,
  SectionList,
  ContainerBtn,
  Btn,
  BtnCancel,
} from "./styles";
import { MdCached } from "react-icons/md";
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo";
import { BtnNewCard } from "../../../components/atoms/cards/BtnNewCard";
import { FilterInventories } from "../../../components/atoms/FilterInventories";
import { CardLog } from "../../../components/atoms/cards/CardLog";
import { EmptyMessage } from "../../../components/atoms/EmptyMessage";
import { CardInventory } from "../../../components/atoms/cards/CardInventory";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { getInventories, getLogs } from "../../../api/requests";

export const InitialInventory = () => {
  const [listInventories, setListInventories] = useState([]);
  const [listLogs, setListLogs] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [reList, setReList] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [filterAll, setFilterAll] = useState(true);
  const [filterProducts, setFilterProducts] = useState(false);
  const [filterServices, setFilterServices] = useState(false);
  const [filterSoldOut, setFilterSoldOut] = useState(false);
  const [filterHistory, setFilterHistory] = useState(false);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const handleFilterAll = () => {
    setFilterAll(true);
    setFilterProducts(false);
    setFilterServices(false);
    setFilterSoldOut(false);
    setFilterHistory(false);
  };

  const handleFilterProducts = () => {
    setFilterProducts(true);
    setFilterAll(false);
    setFilterServices(false);
    setFilterSoldOut(false);
    setFilterHistory(false);
  };

  const handleFilterServices = () => {
    setFilterServices(true);
    setFilterAll(false);
    setFilterProducts(false);
    setFilterSoldOut(false);
    setFilterHistory(false);
  };

  const handleFilterSoldOut = () => {
    setFilterSoldOut(true);
    setFilterAll(false);
    setFilterProducts(false);
    setFilterServices(false);
    setFilterHistory(false);
  };

  const handleFilterHistory = () => {
    setFilterSoldOut(false);
    setFilterAll(false);
    setFilterProducts(false);
    setFilterServices(false);
    setFilterHistory(true);
  };

  async function loadInventories() {
    setSkeleton(true);
    const response = await getInventories();
    if (response.status === 200) {
      let resp = response.data;
      let array_prods = [];
      let array_servs = [];

      // eslint-disable-next-line array-callback-return
      resp.map((obj) => {
        if (obj.category === "PRODUCTO COMPRADO") array_prods.push(obj);
        if (obj.category === "PRODUCTO FABRICADO") array_prods.push(obj);
        if (obj.category === "SERVICIO") array_servs.push(obj);
      });

      setListInventories(response.data);
      setProducts(array_prods);
      setServices(array_servs);
    }
    setTimeout(() => {
      setSkeleton(false);
    }, 1000);
  }

  useEffect(() => {
    loadInventories();
  }, [reList]);

  async function loadLogs() {
    setSkeleton(true);
    const response = await getLogs();
    // console.log("loadLogs function")
    // console.log(response)
    if (response.status === 200) {
      setListLogs(response.data);
      // console.log("List logs")
      // console.log(listLogs)
    }
    setTimeout(() => {
      setSkeleton(false);
    }, 1000);
  }

  useEffect(() => {
    loadLogs();
  }, [reList]);

  const history = useHistory();

  const handleOmitir = () => {
    history.push(Routes.app_my_profile);
  };
  const handleClick = () => {
    history.push(Routes.app_my_inventory);
  };

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev={false}
          titlePage="Mi inventario"
          tooltip="inventory"
        />
        <BtnNewCard
          title="Agregar nuevo inventario."
          path="/inventory-form/initial"
        />
        {!skeleton && listInventories.length > 0 && (
          <FilterInventories
            filterAll={filterAll}
            filterProducts={filterProducts}
            filterServices={filterServices}
            filterSoldOut={filterSoldOut}
            filterHistory={filterHistory}
            handleFilterAll={handleFilterAll}
            handleFilterProducts={handleFilterProducts}
            handleFilterServices={handleFilterServices}
            handleFilterSoldOut={handleFilterSoldOut}
            handleFilterHistory={handleFilterHistory}
          />
        )}
        <Line />
      </Section>

      {!skeleton && listInventories == null && (
        <SectionList count={true}>
          <EmptyMessage message="Error de conexión." />
        </SectionList>
      )}
      {!skeleton && filterAll === true && (
        <Fragment>
          {listInventories.length > 0 ? (
            <SectionList count={listInventories.length < 4}>
              {listInventories.map((inventoryItem, i) => {
                return (
                  <React.Fragment key={i}>
                    <CardInventory
                      id={inventoryItem.id}
                      category={inventoryItem.category}
                      sku={inventoryItem.sku}
                      name={inventoryItem.name}
                      description={inventoryItem.description}
                      is_active={inventoryItem.is_active}
                      is_initial={inventoryItem.stock.is_initial}
                      quantity={inventoryItem.stock.quantity}
                      cost_value={inventoryItem.stock.cost_value}
                      sale_value={inventoryItem.stock.sale_value}
                      // path={Routes.app_inventory_detail}
                      path={`/inventory-detail/${inventoryItem.id}`}
                      image={inventoryItem.image}
                    />
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="Aún no existen Inventarios registrados." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterProducts === true && (
        <Fragment>
          {products.length > 0 ? (
            <SectionList count={products.length < 4}>
              {products.map((inventoryItem, i) => {
                return (
                  <React.Fragment key={i}>
                    {inventoryItem.category === "PRODUCTO FABRICADO" && (
                      <CardInventory
                        id={inventoryItem.id}
                        category={inventoryItem.category}
                        sku={inventoryItem.sku}
                        name={inventoryItem.name}
                        description={inventoryItem.description}
                        is_active={inventoryItem.is_active}
                        is_initial={inventoryItem.stock.is_initial}
                        quantity={inventoryItem.stock.quantity}
                        cost_value={inventoryItem.stock.cost_value}
                        sale_value={inventoryItem.stock.sale_value}
                        // path={Routes.app_inventory_detail}
                        path={`/inventory-detail/${inventoryItem.id}`}
                      />
                    )}
                    {inventoryItem.category === "PRODUCTO COMPRADO" && (
                      <CardInventory
                        id={inventoryItem.id}
                        category={inventoryItem.category}
                        sku={inventoryItem.sku}
                        name={inventoryItem.name}
                        description={inventoryItem.description}
                        is_active={inventoryItem.is_active}
                        is_initial={inventoryItem.stock.is_initial}
                        quantity={inventoryItem.stock.quantity}
                        cost_value={inventoryItem.stock.cost_value}
                        sale_value={inventoryItem.stock.sale_value}
                        // path={Routes.app_inventory_detail}
                        path={`/inventory-detail/${inventoryItem.id}`}
                        image={inventoryItem.image}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="Aún no existen Inventarios registrados." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterServices === true && (
        <Fragment>
          {services.length > 0 ? (
            <SectionList count={services.length < 4}>
              {services.map((inventoryItem, i) => {
                return (
                  <React.Fragment key={i}>
                    {inventoryItem.category === "SERVICIO" && (
                      <CardInventory
                        id={inventoryItem.id}
                        category={inventoryItem.category}
                        sku={inventoryItem.sku}
                        name={inventoryItem.name}
                        description={inventoryItem.description}
                        is_active={inventoryItem.is_active}
                        is_initial={inventoryItem.stock.is_initial}
                        quantity={inventoryItem.stock.quantity}
                        cost_value={inventoryItem.stock.cost_value}
                        sale_value={inventoryItem.stock.sale_value}
                        // path={Routes.app_inventory_detail}
                        path={`/inventory-detail/${inventoryItem.id}`}
                        image={inventoryItem.image}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="Aún no existen Inventarios registrados." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterSoldOut === true && (
        <Fragment>
          {listInventories.length > 0 ? (
            <SectionList count={listInventories.length < 4}>
              {listInventories.map((inventoryItem, i) => {
                console.log(inventoryItem);
                return (
                  <React.Fragment key={i}>
                    {inventoryItem.category === "SERVICIO" &&
                      inventoryItem.is_active === false && (
                        <CardInventory
                          id={inventoryItem.id}
                          category={inventoryItem.category}
                          sku={inventoryItem.sku}
                          name={inventoryItem.name}
                          description={inventoryItem.description}
                          is_active={inventoryItem.is_active}
                          is_initial={inventoryItem.stock.is_initial}
                          quantity={inventoryItem.stock.quantity}
                          cost_value={inventoryItem.stock.cost_value}
                          sale_value={inventoryItem.stock.sale_value}
                          image={inventoryItem.image}
                          // path={Routes.app_inventory_detail}
                          path={`/inventory-detail/${inventoryItem.id}`}
                        />
                      )}
                    {inventoryItem.category === "PRODUCTO FABRICADO" &&
                      inventoryItem.stock.quantity === 0 && (
                        <CardInventory
                          id={inventoryItem.id}
                          category={inventoryItem.category}
                          sku={inventoryItem.sku}
                          name={inventoryItem.name}
                          description={inventoryItem.description}
                          is_active={inventoryItem.is_active}
                          is_initial={inventoryItem.stock.is_initial}
                          quantity={inventoryItem.stock.quantity}
                          cost_value={inventoryItem.stock.cost_value}
                          image={inventoryItem.image}
                          sale_value={inventoryItem.stock.sale_value}
                          path={Routes.app_inventory_detail}
                        />
                      )}
                    {inventoryItem.category === "PRODUCTO COMPRADO" &&
                      inventoryItem.stock.quantity === 0 && (
                        <CardInventory
                          id={inventoryItem.id}
                          category={inventoryItem.category}
                          sku={inventoryItem.sku}
                          name={inventoryItem.name}
                          description={inventoryItem.description}
                          is_active={inventoryItem.is_active}
                          is_initial={inventoryItem.stock.is_initial}
                          quantity={inventoryItem.stock.quantity}
                          cost_value={inventoryItem.stock.cost_value}
                          image={inventoryItem.image}
                          sale_value={inventoryItem.stock.sale_value}
                          path={Routes.app_inventory_detail}
                        />
                      )}
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="No hay información para mostrar." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterHistory === true && (
        <Fragment>
          {listLogs.length > 0 ? (
            <SectionList count={listLogs.length < 4}>
              {listLogs.map((logItem, i) => {
                return (
                  <React.Fragment key={i}>
                    <CardLog
                      id={logItem.id}
                      created={logItem.created}
                      fiel_change={logItem.fiel_change}
                      type_change={logItem.type_change}
                      inventory={logItem.inventory}
                      new_data={logItem.new_data}
                      old_data={logItem.old_data}
                      icon={<MdCached size="2rem" />}
                      skeleton={skeleton}
                    />
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="No hay información para mostrar." />
            </SectionList>
          )}
        </Fragment>
      )}

      <ContainerBtn>
        <BtnCancel onClick={handleOmitir} ghost>
          Omitir
        </BtnCancel>
        <Btn onClick={handleClick}>Continuar</Btn>
      </ContainerBtn>
    </Container>
  );
};

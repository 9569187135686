import { MdAccountBalanceWallet } from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    min-height:100%;
    height:100hv;
    background: ${props => props.theme.colors.dark};
    display:flex;
    align-items:center;
    justify-content:center;
`

export const Section = styled.section`
    width:800px;
    height:100hv;
    background: ${props => props.theme.colors.main};
    padding: 2rem;
    display:flex;
    flex-direction:column;
`

export const Title = styled.h4`
    flex:1;
    display:flex;
    align-items:center;
    color: ${props => props.theme.colors.third};
    margin-top: 2rem;
    margin-bottom: 1rem;
`

export const IconWallet = styled(MdAccountBalanceWallet)`
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.third};
`
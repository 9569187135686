import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
    background: ${props => props.theme.colors.third_clear};
    width:100%;
    height:100%;
    padding-left: ${props => props.navbar ? '23%' : ''};
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
        width:100%;
        padding-left:0;
  }
`

export const Section = styled.section`
    background: ${props => props.theme.colors.third_clear};
    max-width:1000px;
    display:flex;
    flex-direction:column;
    flex:0;
    width:100%;
    height:100%;
    padding: 2rem 2rem 1.5rem;
    ${mobile} {
      width:100%;
  }
`

export const SectionList = styled.div`
  background: ${props => props.theme.colors.light};
  height: ${props => props.count ? '100%' : ''};
  max-width:1000px;
  width:100%;
  padding: 0 2rem 2rem;
  padding-top: 1.4rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${mobile} {
    padding-bottom: 3.2rem;
  }
`

export const Line = styled.div`
    margin:2em 2em 0;
    border-bottom:2px solid ${props => props.theme.colors.deactive};
`
import React, { useEffect, useState } from "react"
import { CardForm } from "./styles"
import { Modal } from "../../../../molecules/Modals/Modal"
import {
  postIncomesInventories,
  getListCities,
  getListDocuments,
  getListStates,
  getIncomesInventories,
} from "../../../../../api/requests"
import { useParams, useHistory } from "react-router-dom"
import { DataUtil } from "../../../../../utils/data"
import { CustomDate } from "../../../../../utils/date"
import { AddItem } from "../../general/AddItem"
import { ClientInformation } from "../../general/ClientInformation"
import { BillingInformation } from "../../general/BillingInformation"

export const FormIncomeSteps = (props) => {
  const history = useHistory()
  const { type, id, payment_id } = useParams()
  const [cancel, setCancel] = useState("")
  const [isPayment, setIsPayment] = useState(false)
  const [payment, setPayment] = useState({})

  const [activeModal, setActiveModal] = useState(false)
  const [alertModal, setAlertModal] = useState(false)

  const [inventory, setInventory] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [documents, setDocuments] = useState([])

  const [selected, setSelected] = useState({
    index: 0,
    name: "",
    amount: 1,
    is_product: false,
  })
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })
  const [modalInformation, setModalInformation] = useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })

  useEffect(() => {
    if (type === "payment") setIsPayment(true)
    else setIsPayment(false)

    setCancel(DataUtil.getData(type, id).prev_route)
    setPayment(DataUtil.getData(type, id, payment_id))

    async function fetchData() {
      await getInventory()
      await getStates()
      await getDocuments()
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (props.form.state && !cities.length) await getCities(props.form.state)
    }
    fetchData()
    // eslint-disable-next-line
  }, [props.form])

  async function getInventory() {
    const response = await getIncomesInventories()
    if (response.status === 200) {
      setInventory(response.data)
    }
  }

  async function getDocuments() {
    const response = await getListDocuments()
    if (response.status === 200) {
      setDocuments(response.data)
    }
  }

  async function getStates() {
    const response = await getListStates()
    if (response.status === 200) {
      setStates(response.data)
    }
  }

  async function getCities(id) {
    const response = await getListCities(id)
    if (response.status === 200) {
      setCities(response.data)
    }
  }

  async function getValidationStock(id, quantity) {
    const response = await postIncomesInventories({
      id: id,
      quantity: parseInt(quantity),
    })
    if (response.status === 200) {
      return response.data
    } else if (response.status === 400) {
      return response.data
    }
  }

  const handleClosed = () => {
    setActiveModal(false)
  }

  const handleCloseAlertModal = () => {
    setAlertModal(false)
  }

  async function handleChange(event, select_value) {
    let e = event.target

    if (e.name === "category") {
      setSelected({
        ...selected,
        index: parseInt(e.value),
        name: inventory[e.value].name,
        is_product: inventory[e.value].category !== "SERVICIO",
        value: inventory[e.value].value,
      })
      // eslint-disable-next-line
      props.setForm({ ...props.form, ["amount"]: 0 })
    } else if (e.name === "value") {
      setSelected({ ...selected, value: e.value })
    } else if (e.name === "amount") {
      setSelected({ ...selected, amount: e.value })
    } else if (e.name === "state") {
      props.setForm({ ...props.form, [e.name]: e.value })

      if (e.value) await getCities(e.value)
      else setCities([])
    } else if (e.name === "client_name") {
      if (e.value.length > 2) {
        await props.handleClient(null, null, e.value)
      }
    }

    if (e.name === "name") {
      if (select_value) {
        props.setForm({
          ...props.form,
          name: select_value,
          doc_type: select_value.doc_type.id,
          doc_number: select_value.doc_number,
        })
        setCities([])
        await _handleClient(null, select_value)
      }
    } else {
      props.setForm({ ...props.form, [e.name]: e.value })
    }
  }

  async function _handleClient(e, data) {
    if (data) {
      await props.handleClient(data.doc_type.id, data.doc_number)
    } else if (props.form.doc_type && props.form.doc_number) {
      await props.handleClient(props.form.doc_type, props.form.doc_number)
    }
  }

  async function handleAdd() {
    // Valida si el producto/servicio a agregar ya se encuentra en la tabla
    let array = [...props.form.selected_categories]
    let found = array.some((el) => el.id === inventory[selected.index].id)

    if (!parseInt(selected.amount)) {
      selected.amount = 1
      // eslint-disable-next-line
      setSelected({ ...selected, ["amount"]: 1 })
    }
    if (!found) {
      let resp = await getValidationStock(
        inventory[selected.index].id,
        selected.amount
      )

      if (resp.validations_errors) {
        showInformation("Inventario insuficiente", resp.validations_errors)
        return
      } else {
        array.push({
          id: inventory[selected.index].id,
          name: inventory[selected.index].name,
          amount: selected.amount ? selected.amount : 1,
          cost_value: selected.value,
          stock_id: resp[0].stock,
        })

        let total_sum = 0
        // eslint-disable-next-line
        array.map((obj) => {
          total_sum += obj.amount * obj.cost_value
        })

        props.setForm({
          ...props.form,
          // eslint-disable-next-line
          ["selected_categories"]: array,
          // eslint-disable-next-line
          ["total"]: total_sum,
        })
      }
    }
  }

  async function handleRemove(e, index) {
    e.preventDefault()

    let array = [...props.form.selected_categories]
    array.splice(index, 1)

    let total_sum = 0
    // eslint-disable-next-line
    array.map((obj) => {
      total_sum += obj.amount * obj.cost_value
    })

    props.setForm({
      ...props.form,
      // eslint-disable-next-line
      ["selected_categories"]: array,
      // eslint-disable-next-line
      ["total"]: total_sum,
    })
  }

  const handleFocus = (event) => event.target.select()

  async function _handleSubmit(event, register) {
    if (!payment.is_payment) {
      if (props.form.selected_categories.length <= 0) {
        showAlert(event, "Debe agregar mínimo un producto o servicio")
        return
      } else if (!props.form.name && props.formStep === 1) {
        if (!props.form.client_name) {
          showAlert(event, "Debe indicar el nombre del cliente")
          return
        }
      } else if (!props.form.doc_type && props.formStep === 1) {
        showAlert(event, "Debe seleccionar un tipo de documento")
        return
      } else if (!props.form.doc_number && props.formStep === 1) {
        showAlert(event, "Debe indicar el número de documento")
        return
      } else if (!props.form.date && props.formStep === 2) {
        showAlert(event, "Debe seleccionar la fecha")
        return
      } else if (parseInt(props.form.payment) && props.formStep === 2) {
        if (!props.form.payment_method) {
          showAlert(event, "Debe seleccionar el método de pago")
          return
        }
      }
    } else {
      // eslint-disable-next-line
      props.setForm({ ...props.form, ["is_payment"]: true })

      if (!props.form.date && props.formStep === 2) {
        showAlert(event, "Debe seleccionar la fecha")
        return
      } else if (!parseInt(props.form.payment) && props.formStep === 2) {
        showAlert(event, "Debe indicar el valor del abono")
        return
      } else if (!props.form.payment_method && props.formStep === 2) {
        showAlert(event, "Debe seleccionar el método de pago")
        return
      }
    }

    if (register) {
      props.handleSubmit(props.form)
    } else nextStep()
  }

  const showAlert = (event, text) => {
    event.preventDefault()
    setAlertModal(true)
    setModalData({
      title: "¡Alerta!",
      text: text,
      btn: "Aceptar",
    })
    return
  }

  const showInformation = (title, text) => {
    setActiveModal(true)
    setModalInformation({
      title: title,
      text: text,
      btn: "Aceptar",
    })
    return
  }

  const nextStep = () => {
    props.setFormStep((cur) => cur + 1)
  }

  const backStep = () => {
    if (payment.is_payment) history.push(cancel)
    else props.setFormStep((cur) => cur - 1)
  }

  return (
    <>
      <CardForm onSubmit={_handleSubmit}>
        {props.formStep === 0 && (
          <AddItem
            form={props.form}
            selected={selected}
            inventory={inventory}
            activeModal={activeModal}
            modalInformation={modalInformation}
            type={type}
            income={true}
            payment={payment}
            cancel={cancel}
            handleChange={handleChange}
            handleFocus={handleFocus}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
            handleClosed={handleClosed}
            handleSubmit={_handleSubmit}
          />
        )}
        {props.formStep === 1 && (
          <ClientInformation
            form={props.form}
            clients={props.clients}
            documents={documents}
            states={states}
            cities={cities}
            backStep={backStep}
            handleChange={handleChange}
            handleSearch={_handleClient}
            handleSubmit={_handleSubmit}
          />
        )}
        {props.formStep === 2 && (
          <BillingInformation
            form={props.form}
            type={type}
            payment={payment}
            isPayment={isPayment}
            handleChange={handleChange}
            handleSubmit={_handleSubmit}
            backStep={backStep}
            date={CustomDate.date1(props.form.date)}
          />
        )}
      </CardForm>
      <Modal
        activeModal={alertModal}
        handleClosed={handleCloseAlertModal}
        title={modalData.title}
        text={modalData.text}
        errors={modalData.errors}
        btn={modalData.btn}
      />
    </>
  )
}

import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
    background: ${props => props.theme.colors.third_clear};
    width:100%;
    height:100%;
    padding-left: ${props => props.navbar ? '23%' : ''};
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
        width:100%;
        padding-left:0;
  }
`

export const Section = styled.section`
    background: ${props => props.theme.colors.third_clear};
    max-width:1000px;
    display:flex;
    flex-direction:column;
    flex:0;
    width:100%;
    height:100%;
    padding: 2rem 2rem 1.5rem;
    ${mobile} {
      width:100%;
  }
`

export const SectionList = styled.div`
  background: ${props => props.theme.colors.light};
  height: 100%;
  max-width:1000px;
  width:100%;
  padding: 0 2rem 2rem;
  padding-top: 1.4rem;
  margin-top: 1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${mobile} {
    padding-bottom: 3.2rem;
  }
`

export const ContainerBtn = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom: 2rem;
`

export const Btn = styled.button`
  width: ${props => ( props.second ? "45%" : "70%")}; 
  color: ${props => ( props.white ? props.theme.colors.main : props.ghost ? props.theme.colors.main : "#fff" )};
  background: ${props => ( props.white ? "#fff" : props.ghost ? "transparent" : props.theme.colors.main )};
  border:2px solid ${props => props.theme.colors.main};
  margin-top:2em;
  padding:1em .5em;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.text};
    border:2px solid ${props => props.theme.colors.text};
    color: #fff;
  }
  ${mobile} {
    margin-top:4em;
  }
`
import React from 'react'
import { Container, Title, Text, Row, Column, FigureCircle, IconCash } from './styles.js'


export const CardSummary = ({background, title, data}) => {

    return (
        <>
            {title && <Title color="third">{title}</Title>}
            <Container bg={background}> 
                <Row>
                    <Column xs="30" sm="20" lg="10">
                        <FigureCircle color="">
                            <IconCash size='1.6rem'></IconCash>
                        </FigureCircle>
                    </Column>
                    <Column xs="70" sm="80" lg="90" marginTop=".2rem">
                        <Text fontSize="12px" color="third">{data.subTitle}</Text>
                        <Text fontSize="11.5px" marginTop=".2rem">{data.name}</Text>
                        {data.category && <Text fontSize="11.5px" marginTop=".2rem" color="third" capitalize>{data.category}</Text>}
                    </Column>
                </Row>
                <Row>
                    <Column xs="100" sm="100" lg="100">
                        <Text fontSize="11px" align="right">{data.date}</Text>
                    </Column>
                </Row>
                <Row>
                    <Column lg="50">
                        <Text color="third" fontSize="12px">Total {data.tags.title}: </Text>
                    </Column>
                    <Column lg="50">
                        <Text fontSize="16px" fontWeight="bold" color="third" align="right">
                            {Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(data.total)}
                        </Text>
                    </Column>
                </Row>
            </Container>
        </>
    )
}

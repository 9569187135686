import styled from 'styled-components'

/* const desktopStartWidth = 1023; */
const tabletStartWidth = 768;
/* const tablet = `@media (max-width: ${desktopStartWidth}px)` */
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const CardForm = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
  /* padding:3em 2em; */
  text-align:center;
  /* background:pink; */

`

export const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  /* background:red; */
  height:100%;

`
export const Section = styled.section`
  width:100%;
  /* height:40vh; */
  display:flex;
  flex-direction:column;
  align-items:center;
`

export const TitleStep = styled.h3`
  padding-bottom:2rem;

`

export const Input = styled.input`
  padding:.5rem 0;
  width:100%;
  /* border:1px solid #999999; */
  border-bottom: 1px solid #fff;
  color:#fff;
  placeholder:#fff;
  background:transparent;
  outline:none;
  font-size: 1em;
  margin-bottom:1em;
  &::-webkit-input-placeholder {
    color: #fff;
  }
  ${mobile} {
    font-size: .9rem;
  }
`


export const Select = styled.select`
  padding:.5rem 0;
  width:100%;
  /* border:1px solid #999999; */
  border-bottom: 1px solid #fff;
  color:#fff;
  font-family: ${props => props.theme.fonts.family};
  font-size: 1em;
  /* background:rgba(255, 255, 255,.2); */
  background:transparent;
  outline:none;
  margin-bottom:1em;

  /* appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none; */
  ${mobile} {
    font-size: .9rem;
  }
    option {
        color: ${props => props.theme.colors.main};
        background: ${props => props.theme.colors.light};
        font-weight: small;
        display: flex;
        white-space: pre;
        min-height: 20px;
        padding: 0px 2px 1px;
    }
    option:checked {
        /*background: red linear-gradient(0deg, red 0%, red 100%); */
        background: ${props => props.theme.colors.second};
    }

`

export const Span = styled.span`
  /* padding: 1em 0 0; */
`

export const ContainerCheckbox = styled.div`
  display:flex;
  width:307px;
  /* padding:3em 2em; */
  padding:1rem 0;
  /* background:pink; */
  align-items:center;
  background: #F9F9F9;
  border-radius:3px;
  margin-bottom:2rem;

`

export const InputCheckbox = styled.input`
  padding:.5rem 0;
  /* border:1px solid #999999; */
  placeholder:#fff;
  outline:none;
  font-size: 1em;
  margin-bottom:.5rem;
  margin:0 .8rem;
  height: 40px;
  width: 40px;

`

export const Label = styled.label`
  color:${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.family};
  font-size: .8rem;
  font-weight:500;
  text-align:left;

`

export const BtnTerms = styled.button`
    color: ${props => props.theme.colors.second};
    font-weight:500;
    font-size:.8rem;
    text-decoration:none;
    transition: all 0.3s ease-in;
    text-decoration:underline;
    margin:0 .3rem;
    &:hover {
        transition: all 0.5s ease-in-out;
        color: ${props => props.theme.colors.main};
    }
`
export const BtnPolitics = styled(BtnTerms)`

    margin:0;

`

export const ContainerCaptcha = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
`

export const ContainerBtns = styled.div`
  display:flex;
  width:100%;
  /* padding:3em 2em; */
  padding-bottom:1em;
  /* background:pink; */
  justify-content:space-between;

`

export const BtnNext = styled.button`
  width: ${props => ( props.second ? "45%" : "70%")};
  color: ${props => ( props.ghost ? "#fff" : props.theme.colors.main)};
  background: ${props => ( props.ghost ? "transparent" : "#fff")};
  border:3px solid #fff;
  margin-top:1em;
  padding:1em .5em;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.second};
    color: #fff;
  }
`

export const BtnText = styled.p`
    display:flex;
    align-items:center;
    justify-content:center;
    color: #fff;
    font-style:italic;
    text-decoration:none;
    font-size: .8rem;
    transition: all 0.3s ease-in;
    &:hover {
        transition: all 0.5s ease-in-out;
        text-decoration:underline;
    }
`


import React, { useEffect, useState } from "react"
import {
  Figure,
  Logo,
  Container,
  Title,
  Text,
  TextRequest,
  Button,
} from "./styles"
import image from "../../../assets/img/fef_acceder_a_planes.png"
import { WompiTransactionValidated } from "../../../utils/validateWompiTransaction"
import { getProfile, getSubscriptionBilling } from "../../../api/requests"

export const SubscriptionValidated = () => {
  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userSubscriptions, setUserSubscriptions] = useState([])

  // Se valida el pago de la subscripcion y se activa la cuenta
  async function wompiValidated() {
    try {
      await getProfile().then(async (response) => {
        if (response.status === 200) {
          let userId = response.data[0].user.id
          await WompiTransactionValidated.wompiValidation(userId)
          await callSubscriptionBilling(response)
        } else {
          console.log("No se ha podido activar la subscripción")
        }
      })
    } catch (error) {}
  }

  async function callSubscriptionBilling(userData) {
    return await getSubscriptionBilling().then((subscriptions) => {
      setLoading(true)
      let userEmail = userData.data[0].user.email
      let user_subscription = []
      // Comprueba si el correo logeado tiene suscripción activa
      let isActive = false
      subscriptions.reverse()

      for (let i in subscriptions) {
        if (subscriptions[i].user.email === userEmail) {
          if (subscriptions[i].subscription_active === true) {
            user_subscription.push(subscriptions[i])
            isActive = true

            setUserSubscriptions(user_subscription)
            setIsActive(true)
          }
        }
      }
      setLoading(false)
      return isActive
    })
  }

  useEffect(() => {
    async function fetchData() {
      await wompiValidated()
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container>
        {!loading && (
          <>
            {isActive ? (
              <>
                <Figure>
                  <Logo src={image} alt="Bienvenido a Finanzas en Forma" />
                </Figure>
                <Title> Suscripción exitosa </Title>
                <Text> Tiempo de suscripción </Text>
                <TextRequest>
                  {userSubscriptions[0].subscription.name}
                </TextRequest>
                <Text> Suscripción válida hasta : </Text>
                <TextRequest> {userSubscriptions[0].final_date} </TextRequest>
                <Button to="/my-profile"> Finalizar proceso </Button>
              </>
            ) : (
              <>
                <Figure>
                  <Logo src={image} alt="Bienvenido a Finanzas en Forma" />
                </Figure>
                <Title> Subscripción Fallida </Title>
                <Text> Por favor verificar su información </Text>
                <Button to="/subscription-plans"> Ver Planes </Button>
              </>
            )}
          </>
        )}
      </Container>
    </>
  )
}

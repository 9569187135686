import React, { useEffect, useState } from "react"
import { Container, Section, SectionList } from "./styles"
import { MdMoneyOff } from "react-icons/md"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../components/atoms/cards/BtnNewCard"
import { EmptyMessage } from "../../../components/atoms/EmptyMessage"
import { CardFinancialInfo } from "../../../components/atoms/cards/CardFinancialInfo"
import { Routes } from "../../../routes"
import { getLiabilities } from "../../../api/requests"

export const InitialLiabilities = () => {
  const [listLiabilities, setListLiabilities] = useState([])
  const [reList, setReList] = useState(false)
  const [skeleton, setSkeleton] = useState(false)
  const [initialInfo, setInitialInfo] = useState(false)

  useEffect(() => {
    setInitialInfo(sessionStorage.getItem("initial_info") !== "true")
  }, [])

  function updateItems() {
    loadLiabilities()
  }

  async function loadLiabilities() {
    setSkeleton(true)
    const response = await getLiabilities()
    if (response.status === 200) {
      setListLiabilities(response.data.financial_info)
      setTimeout(() => {
        setSkeleton(false)
      }, 1000)
    }
  }

  useEffect(() => {
    loadLiabilities()
  }, [reList])

  return (
    <Container navbar={initialInfo}>
      <Section>
        <TitleAndInfo
          btnPrev={true}
          pathPrev={Routes.app_initial_information}
          titlePage="Pasivos iniciales"
          subtitleInfo={
            initialInfo
              ? "Es momento de registrar todos tus pasivos."
              : "La información inicial es solo de lectura. No se puede editar, ni eliminar información."
          }
          tooltip="passives"
        />
        {initialInfo && (
          <BtnNewCard
            title="Agregar nuevo pasivo."
            path={Routes.app_initial_liabilities_form}
          />
        )}
      </Section>
      <SectionList count={listLiabilities.length < 4}>
        {skeleton && (
          <>
            {[1, 2, 3].map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <CardFinancialInfo
                    title="prueba"
                    value="0"
                    skeleton={skeleton}
                  />
                </React.Fragment>
              )
            })}
          </>
        )}
        {!skeleton && listLiabilities.length === 0 && (
          <EmptyMessage message="Aún no existen Pasivos registrados." />
        )}
        {!skeleton &&
          listLiabilities.map((liabilityItem, i) => {
            return (
              <React.Fragment key={i}>
                <CardFinancialInfo
                  hide_delete={true}
                  id={liabilityItem.id}
                  title={liabilityItem.category.name}
                  value={liabilityItem.value}
                  color="rojo"
                  icon={<MdMoneyOff size="2rem" />}
                  reList={reList}
                  setReList={setReList}
                  redirect={Routes.app_initial_liabilities}
                  redirectEdit={`/edit-initial-liabilities-form/${liabilityItem.id}`}
                  updateItems={updateItems}
                  showIcons={initialInfo}
                />
              </React.Fragment>
            )
          })}
      </SectionList>
    </Container>
  )
}

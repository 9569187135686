import React, { useEffect, useState } from "react"
import { Container, Section, SectionList } from "./styles"
import { MdAttachMoney } from "react-icons/md"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../../components/atoms/cards/BtnNewCard"
import { Routes } from "../../../../routes"
import {
  deleteIncomesDetail,
  getIncomeDetail,
  getIncomesDetail,
  getIncomePdf,
} from "../../../../api/requests"
import { useParams } from "react-router-dom"
import { CardTotal } from "../../../../components/atoms/cards/CardTotal"
import { CustomDate } from "../../../../utils/date"
import { DataUtil } from "../../../../utils/data"
import { Utilities } from "../../../../utils/utilities"
import { CardInitialInfo } from "../../../../components/atoms/cards/CardInitialInfo"
import { ModalDeleteConfirm } from "../../../../components/molecules/Modals/ModalDeleteConfirm"
import { Modal } from "../../../../components/molecules/Modals/Modal"
import { CardEmpty } from "../../../../components/atoms/cards/CardEmpty"

export const IncomeDetail = () => {
  const { type } = useParams()
  const [incomeDetail, setIncomeDetail] = useState([])
  const [payment, setPayment] = useState({})
  const [modalDelete, setModalDelete] = useState({
    id: null,
    status: false,
  })
  const [modalErrors, setModalErrors] = useState({
    errors: "",
    status: false,
  })
  const [total, setTotal] = useState(0)
  const [title, setTitle] = useState("")

  useEffect(() => {
    setPayment(DataUtil.getData(type))
    let selected = DataUtil.getData(type)

    if (type === "credit") setTitle("Cuentas por cobrar:")
    else setTitle(`Total ${selected.title}:`)

    async function fetchData() {
      await getIncome(
        selected.payment_type,
        selected.payment_method,
        selected.type
      )
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  async function getIncomeById(id) {
    const response = await getIncomesDetail(id)

    if (response.status === 200) {
      let resp = response.data

      resp.name = resp.client.name
      resp.doc_type = resp.client.doc_type.name
      resp.doc_number = resp.client.doc_number
      resp.address = resp.client.address
      resp.phone = resp.client.phone
      resp.cellphone = resp.client.cellphone
      resp.city = resp.client.city ? resp.client.city.name : resp.client.city

      // eslint-disable-next-line array-callback-return
      resp.details.map((obj) => {
        obj.unit_value = Intl.NumberFormat("es-CO", {
          maximumFractionDigits: "0",
          style: "currency",
          currency: "COP",
        }).format(obj.value)
        obj.total_value = Intl.NumberFormat("es-CO", {
          maximumFractionDigits: "0",
          style: "currency",
          currency: "COP",
        }).format(obj.value * obj.quantity)
      })

      resp.total_value = Intl.NumberFormat("es-CO", {
        maximumFractionDigits: "0",
        style: "currency",
        currency: "COP",
      }).format(resp.value)

      return resp
    }
  }

  async function getIncome(payment_type, payment_method, selected_type) {
    const response = await getIncomeDetail({
      payment_type: payment_type,
      payment_method: payment_method,
      payment: true,
    })
    if (response.status === 200) {
      let resp = response.data
      let total_sum = 0

      // eslint-disable-next-line array-callback-return
      resp.map((obj) => {
        if (!obj.deleted) {
          total_sum += obj.value - obj.payment
        }

        if (type === "credit") obj.title = CustomDate.date2(obj.date)
        else
          obj.title = obj.is_payment
            ? `Abono: ${selected_type}`
            : `Ingreso: ${selected_type}`

        obj.id_pop = undefined
        obj.status = false
        obj.anchor_el = null
        obj.data = { details: [] }
      })
      setIncomeDetail(resp)
      setTotal(total_sum)
    }
  }

  async function handleClick(event, status, data, index) {
    if (status) {
      let response = await getIncomeById(data.id)

      let array = [...incomeDetail]
      array[index].id_pop = `simple-popover-${data.id}`
      array[index].status = true
      array[index].anchor_el = event.currentTarget

      response.image = null
      response.tradename = null
      if (response.user.profile.logo) {
        response.image = `https://fef-api.pcaingenieria.co${response.user.profile.logo.image}`

        response.tradename = response.user.profile.tradename
        response.user_address = response.user.profile.address
        response.user_cellphone = response.user.profile.cellphone
        response.user_phone = response.user.profile.phone

        response.client_name = response.client.name
        response.client_doc_type = response.client.doc_type.name
        response.client_doc_number = response.client.doc_number
        response.client_phone = response.client.phone
        response.client_cellphone = response.client.cellphone
        response.client_address = response.client.address
      }

      array[index].data = response ? response : { details: [] }

      setIncomeDetail(array)
    }
  }

  const handleClose = (index) => {
    let array = [...incomeDetail]
    array[index].id_pop = undefined
    array[index].status = false
    array[index].anchor_el = null
    array[index].data = { details: [] }

    setIncomeDetail(array)
  }

  async function handleOpenDelete(id, index) {
    handleClose(index)
    setModalDelete({ id: id, status: true })
  }

  async function handleDelete(id) {
    const response = await deleteIncomesDetail(id)

    if (response.status === 204) {
      await getIncome(
        payment.payment_type,
        payment.payment_method,
        payment.type
      )

      setModalErrors({
        title: "Operación exitosa",
        errors: `El ingreso ${id} ha sido eliminado satisfactoriamente`,
        status: true,
      })
      handleCloseDelete()
    } else {
      if (response.data.detail) {
        handleCloseDelete()
        setModalErrors({
          title: "Error",
          errors: response.data.detail,
          status: true,
        })
      }
      if (response.data.validations_error) {
        handleCloseDelete()
        setModalErrors({
          title: "Error",
          errors: response.data.validations_error[0],
          status: true,
        })
      }
    }
  }

  async function handleCloseDelete() {
    setModalDelete({ id: "", status: false })
  }

  async function handleCloseErrors() {
    setModalErrors({ errors: "", status: false })
  }

  async function handleDownload(row) {
    const response = await getIncomePdf(row.id)

    if (response.status === 200) {
      let file = new File([response.data], "", {
        type: response.headers["Content-Type"],
      })
      let url = window.URL.createObjectURL(file)
      let a = document.createElement("a")
      a.href = url
      a.download = `Factura ${Utilities.addZeros(row.id, 4)}.pdf`
      a.click()
    }
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={Routes.app_income}
          titlePage={payment.title}
          displayDate={true}
          dateColor="dark"
          tooltip="income"
        />
        <BtnNewCard
          title={`Registrar nuevo ingreso en ${payment.type}`}
          path={payment.route}
        />
        <CardTotal
          data={{ title: title, value: total }}
          titleColor="dark"
          valueColor={type === "credit" ? "red_brand" : "text"}
        />
      </Section>
      <SectionList count={incomeDetail.length < 4}>
        {!incomeDetail.length && <CardEmpty></CardEmpty>}
        {incomeDetail.map((row, index) => (
          <CardInitialInfo
            key={index}
            title={row.title}
            value={row.value}
            detailCard={true}
            showPop={row.is_payment || row.deleted ? false : true}
            cardType={type !== "credit" ? 1 : 2}
            path={"#"}
            detail={{
              path: row.deleted
                ? "#"
                : DataUtil.getData(`edit_${type}`, row.id).detail,
              date: CustomDate.date1(row.date),
              reference: Utilities.addZeros(row.id, 4),
              payment: row.payment,
              credit: row.value,
              credit_color:
                row.payment !== row.value ? "red_brand" : "text_light",
              status: row.deleted
                ? "Anulado"
                : row.paid_out
                ? "Finalizado"
                : "hide",
              edit:
                row.is_payment || row.deleted
                  ? false
                  : {
                      title: "Editar",
                      path: DataUtil.getData(`edit_${type}`, row.id).route,
                    },
              inactivate:
                row.is_payment || row.deleted
                  ? false
                  : {
                      title: "Anular",
                      path: "#",
                      handleDelete: (e) => handleOpenDelete(row.id, index),
                    },
              download:
                row.is_payment || row.deleted
                  ? false
                  : {
                      title: "Descargar",
                      path: "#",
                      handleDownload: (e) => handleDownload(row),
                    },
            }}
            row={row}
            handleClick={(e) => handleClick(e, true, row, index)}
            handleClose={(e) => handleClose(index)}
            icon={<MdAttachMoney size="2rem" />}
            color={row.deleted ? "rojo" : ""}
          />
        ))}
      </SectionList>

      <Modal
        activeModal={modalErrors.status}
        title={modalErrors.title}
        text={modalErrors.errors}
        btn="Cerrar"
        handleClosed={handleCloseErrors}
      />

      <ModalDeleteConfirm
        activeModal={modalDelete.status}
        title="Anular ingreso"
        text={`¿Está seguro de que desea anular el ingreso?`}
        btn="Cancelar"
        btnSubmit="Anular"
        deleteCard={(e) => handleDelete(modalDelete.id)}
        handleClosed={handleCloseDelete}
      />
    </Container>
  )
}

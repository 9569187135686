import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const CardGradient = styled(Link)`
    width:100%;
    background: ${props => props.bg ? props.theme.colors[props.bg] : props.theme.colors.linear_gradient_blue};
    border-radius:.7rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    display:flex;
    align-items:center;
    margin-bottom:1rem;
    margin-top:1rem;
    padding:.7rem 1rem;
`

export const Divider = styled.div`
    border:1px solid #fff; 
    margin:auto;
    height:100%;
    margin-left:10px;
    margin-right:10px;
`

export const HalfCard = styled.div`
    width:50%;
    padding:6px;
    text-align:${props => props.align || ""};
`

export const TitleCard = styled.h3`
    color:${props => props.color ? props.theme.colors.text : "#fff"};
    font-size:${props => props.fontSize || "16px"};
`

export const ValueCard = styled.h3`
    color:${props => props.color ? props.theme.colors.text : "#fff"};
    font-weight:bold;
    font-size:18px;
    text-align:right;
    margin-top:${props => props.valueMargin || "1.3rem"};
`

export const Card = styled.div`
    width:100%;
    padding-left:12px;
    padding-right:12px;
`



import React from 'react'
import {HalfCard, TitleCard, ValueCard, Card} from './styles.js'

export const CardTotal = ({data, titleColor, valueColor}) => {
    return (
        <>
            <Card>
                <HalfCard>
                    <TitleCard color={titleColor} fontSize="14px">{data.title}</TitleCard>
                </HalfCard>
                <HalfCard align="right">
                    <ValueCard color={valueColor} valueMargin="0px">
                        {Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(data.value)}
                    </ValueCard>
                </HalfCard>
            </Card>
        </>
    )
}

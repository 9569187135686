import React from 'react';

import {Container, Card, Title, Text, Button} from './styles';

import {useHistory} from 'react-router-dom';


export const ModalNew = (props) => {

    const history = useHistory();


    const handleClosed = () => {
        props.setResponse(null)
        history.push(props.redirect)
    }
  

  return (
    <Container className={props.activeModal === true ? " activate" : ""}>
      <Card>
        <figure>
          <img src={props.icon} alt=""/>
        </figure>
        <div>
          <Title>{props.title}</Title>
          <Text>{props.text}</Text>
        </div>
        <Button 
          onClick={handleClosed}
          name="Aceptar"
          primary="true"
          className={props.boton === true ? " success" : ""}
          // className={props.statusModal === true ? " success" : ""}
        >{props.btn}</Button>
        {/* <Btn onClick={props.handleClosed} name="Aceptar" primary="true" /> */}
        
      </Card>
    </Container>
  );
};
import React, { Fragment } from "react"
import {
  ContainerLink,
  Container,
  FigureCircle,
  Button,
  Section,
  CardTitle,
  CardDate,
  CardValue,
} from "./styles.js"
import { MdDeleteForever, MdEdit } from "react-icons/md"
import {
  deleteFinancialInfoItem,
  deleteBudgetItem,
} from "../../../../api/requests.js"
import { ModalDeleteConfirm } from "../../../molecules/Modals/ModalDeleteConfirm/index.js"
import { useHistory } from "react-router-dom"

export const CardFinancialInfo = ({
  id,
  icon,
  color,
  title,
  category,
  value,
  reList,
  setReList,
  redirect,
  redirectEdit,
  updateItems,
  skeleton,
  showIcons,
  hide_delete,
}) => {
  const history = useHistory()

  const [activeModalDelete, setActiveModalDelete] = React.useState(false)

  function editCard() {
    history.push(redirectEdit)
  }

  function deleteCard() {
    if (color === "verde" || color === "rojo") {
      deleteFinancialInfoItem(id)
    } else if (color === "azul" || color === "naranja") {
      deleteBudgetItem(id)
    }
    setActiveModalDelete(false)
    setReList(!reList)
    history.push(redirect)
    updateItems()
  }

  function deleteModal() {
    setActiveModalDelete(true)
  }

  function handleClosed() {
    setActiveModalDelete(false)
  }

  const dateOptionArray = title.toString().split("-")
  const selectedYear = dateOptionArray[0]
  const selectedMonth = dateOptionArray[1]
  const selectedMonthNumber = Number(selectedMonth)

  // eslint-disable-next-line
  const month = new Array()
  month[0] = "Enero"
  month[1] = "Febrero"
  month[2] = "Marzo"
  month[3] = "Abril"
  month[4] = "Mayo"
  month[5] = "Junio"
  month[6] = "Julio"
  month[7] = "Agosto"
  month[8] = "Septiembre"
  month[9] = "Octubre"
  month[10] = "Noviembre"
  month[11] = "Diciembre"

  const dateMonth = month[selectedMonthNumber - 1]
  const dateTitle = `${dateMonth} de ${selectedYear}`

  return (
    <>
      {activeModalDelete === true && (
        <ModalDeleteConfirm
          activeModal={true}
          title="Eliminar Categoría Financiera"
          text="¿Desea eliminar esta categoría financiera?."
          btn="Cancelar"
          btnSubmit="Eliminar"
          deleteCard={deleteCard}
          handleClosed={handleClosed}
        />
      )}
      {!skeleton && (
        <ContainerLink>
          {color === "verde" ? <FigureCircle verde>{icon}</FigureCircle> : ""}
          {color === "rojo" ? <FigureCircle rojo>{icon}</FigureCircle> : ""}
          {color === "naranja" ? (
            <FigureCircle naranja>{icon}</FigureCircle>
          ) : (
            ""
          )}
          {color === "azul" ? <FigureCircle azul>{icon}</FigureCircle> : ""}
          {!color ? <FigureCircle>{icon}</FigureCircle> : ""}
          <Section>
            {color === "verde" && <CardTitle>{title}</CardTitle>}
            {color === "rojo" && <CardTitle>{title}</CardTitle>}
            {color === "azul" && <CardTitle>{category}</CardTitle>}
            {color === "naranja" && <CardTitle>{category}</CardTitle>}
            {color === "azul" && <CardDate>{dateTitle}</CardDate>}
            {color === "naranja" && <CardDate>{dateTitle}</CardDate>}
            {color === "rojo" ? (
              <CardValue rojo>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(value)}
              </CardValue>
            ) : (
              <CardValue>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(value)}
              </CardValue>
            )}
          </Section>
          {showIcons && (
            <Fragment>
              <Button onClick={editCard}>
                <MdEdit size="1.5rem" />
              </Button>
              {!hide_delete && (
                <Button onClick={deleteModal}>
                  <MdDeleteForever size="1.5rem" />
                </Button>
              )}
            </Fragment>
          )}
        </ContainerLink>
      )}
      {skeleton && (
        <Container>
          <FigureCircle skeleton>{icon}</FigureCircle>
          <Section>
            <CardTitle skeleton>{title}</CardTitle>
            <CardValue skeleton>
              {Intl.NumberFormat("es-CO", {
                maximumFractionDigits: "0",
                style: "currency",
                currency: "COP",
              }).format(value)}
            </CardValue>
          </Section>
        </Container>
      )}
    </>
  )
}

export const DataUtil = {
  getData: (type, id, payment_id) => {
    let data = {
      cash: {
        title: "Efectivo",
        type: "EFECTIVO",
        payment_method: "EFECTIVO",
        payment_type: "CONTADO",
        route: "/income-form/cash",
        prev_route: "/income-detail/cash",
        tag_title: "Nuevo ingreso en EFECTIVO",
        summary: "Ingreso: EFECTIVO",
        init_step: 0,
      },
      edit_cash: {
        title: "Efectivo",
        type: "EFECTIVO",
        payment_method: "EFECTIVO",
        payment_type: "CONTADO",
        route: `/income-form/edit_cash/${id}`,
        prev_route: `/income-detail/cash`,
        tag_title: "Editar ingreso en EFECTIVO",
        detail: "#",
        summary: "Ingreso: EFECTIVO",
        init_step: 0,
      },
      bank: {
        title: "Bancos",
        type: "BANCOS",
        payment_method: "BANCO",
        payment_type: "CONTADO",
        route: "/income-form/bank",
        prev_route: "/income-detail/bank",
        tag_title: "Nuevo ingreso en BANCOS",
        summary: "Ingreso: BANCOS",
        init_step: 0,
      },
      edit_bank: {
        title: "Bancos",
        type: "BANCOS",
        payment_method: "BANCO",
        payment_type: "CONTADO",
        route: `/income-form/edit_bank/${id}`,
        prev_route: `/income-detail/bank`,
        tag_title: `Editar ingreso en BANCOS`,
        detail: "#",
        summary: "Ingreso: BANCOS",
        init_step: 0,
      },
      credit: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: "/income-form/credit",
        prev_route: "/income-detail/credit",
        tag_title: "Nuevo ingreso en CRÉDITOS",
        summary: "Ingreso: CRÉDITOS",
        init_step: 0,
      },
      edit_credit: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/income-form/edit_credit/${id}`,
        prev_route: `/income-detail/credit`,
        tag_title: `Editar ingreso en CRÉDITOS`,
        detail: `/income-detail/credit/${id}`,
        summary: "Ingreso: CRÉDITOS",
        init_step: 0,
      },
      // Crear nuevo abono
      payment: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/income-form/payment/${id}`,
        prev_route: `/income-detail/credit/${id}`,
        tag_title: "Nuevo abono en CRÉDITOS",
        is_payment: true,
        init_step: 2,
      },
      // Editar abono
      edit_payment: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/income-form/edit_payment/${id}/${payment_id}`,
        prev_route: `/income-detail/credit/${id}`,
        tag_title: `Editar abono en CRÉDITOS`,
        is_payment: true,
        init_step: 2,
      },
    }

    return data[type]
  },

  getShopping: (type, category, id, payment_id) => {
    let categories = {
      inventories: "inventario",
      properties: "propiedades, planta y equipo",
      others: "otros",
    }
    let category_name = categories[category]
    if (parseInt(category) && !id) id = category

    let data = {
      cash: {
        title: "Efectivo",
        type: "EFECTIVO",
        payment_method: "EFECTIVO",
        payment_type: "CONTADO",
        route: `/shopping-form/cash/${category}`,
        prev_route: `/shopping-detail/cash`,
        tag_title: `Nueva compra en ${category_name} (EFECTIVO)`,
        summary: "Compra: EFECTIVO",
        category: category_name,
        init_step: 0,
      },
      edit_cash: {
        title: "Efectivo",
        type: "EFECTIVO",
        payment_method: "EFECTIVO",
        payment_type: "CONTADO",
        route: `/shopping-form/edit_cash/${category}/${id}`,
        prev_route: `/shopping-detail/cash`,
        tag_title: `Editar compra en ${category_name} (EFECTIVO)`,
        detail: "#",
        summary: "Compra: EFECTIVO",
        category: category_name,
        init_step: 0,
      },
      bank: {
        title: "Bancos",
        type: "BANCOS",
        payment_method: "BANCO",
        payment_type: "CONTADO",
        route: `/shopping-form/bank/${category}`,
        prev_route: "/shopping-detail/bank",
        tag_title: `Nueva compra en ${category_name} (BANCOS)`,
        summary: "Compra: BANCOS",
        category: category_name,
        init_step: 0,
      },
      edit_bank: {
        title: "Bancos",
        type: "BANCOS",
        payment_method: "BANCO",
        payment_type: "CONTADO",
        route: `/shopping-form/edit_bank/${category}/${id}`,
        prev_route: `/shopping-detail/bank`,
        tag_title: `Editar compra en ${category_name} (BANCOS)`,
        detail: "#",
        summary: "Compra: BANCOS",
        category: category_name,
        init_step: 0,
      },
      credit: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/shopping-form/credit/${category}`,
        prev_route: "/shopping-detail/credit",
        tag_title: `Nueva compra en ${category_name} (CRÉDITOS)`,
        summary: "Compra: CRÉDITOS",
        category: category_name,
        init_step: 0,
      },
      edit_credit: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/shopping-form/edit_credit/${category}/${id}`,
        prev_route: `/shopping-detail/credit`,
        tag_title: `Editar compra en ${category_name} (CRÉDITOS)`,
        detail: `/shopping-detail/credit/${id}`,
        summary: "Compra: CRÉDITOS",
        category: category_name,
        init_step: 0,
      },
      // Crear nuevo abono
      payment: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/shopping-form/payment/${category}/${id}`,
        prev_route: `/shopping-detail/credit/${id}`,
        tag_title: "Nuevo abono en CRÉDITOS",
        is_payment: true,
        init_step: 2,
      },
      // Editar abono
      edit_payment: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/shopping-form/edit_payment/${category}/${id}/${payment_id}`,
        prev_route: `/shopping-detail/credit/${id}`,
        tag_title: `Editar abono en CRÉDITOS`,
        is_payment: true,
        init_step: 2,
      },
    }

    return data[type]
  },

  getExpense: (type, id, payment_id) => {
    let data = {
      cash: {
        title: "Efectivo",
        type: "EFECTIVO",
        payment_method: "EFECTIVO",
        payment_type: "CONTADO",
        route: "/expenses-form/cash",
        prev_route: "/expenses-detail/cash",
        tag_title: "Nuevo gasto en EFECTIVO",
        summary: "Gasto: EFECTIVO",
        init_step: 0,
      },
      edit_cash: {
        title: "Efectivo",
        type: "EFECTIVO",
        payment_method: "EFECTIVO",
        payment_type: "CONTADO",
        route: `/expenses-form/edit_cash/${id}`,
        prev_route: `/expenses-detail/cash`,
        tag_title: `Editar gasto en EFECTIVO`,
        detail: "#",
        summary: "Gasto: EFECTIVO",
        init_step: 0,
      },
      bank: {
        title: "Bancos",
        type: "BANCOS",
        payment_method: "BANCO",
        payment_type: "CONTADO",
        route: "/expenses-form/bank",
        prev_route: "/expenses-detail/bank",
        tag_title: "Nuevo gasto en BANCOS",
        summary: "Gasto: BANCOS",
        init_step: 0,
      },
      edit_bank: {
        title: "Bancos",
        type: "BANCOS",
        payment_method: "BANCO",
        payment_type: "CONTADO",
        route: `/expenses-form/edit_bank/${id}`,
        prev_route: `/expenses-detail/bank`,
        tag_title: `Editar gasto en BANCOS`,
        detail: "#",
        summary: "Gasto: BANCOS",
        init_step: 0,
      },
      credit: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: "/expenses-form/credit",
        prev_route: "/expenses-detail/credit",
        tag_title: "Nuevo gasto en CRÉDITOS",
        summary: "Gasto: CRÉDITOS",
        init_step: 0,
      },
      edit_credit: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/expenses-form/edit_credit/${id}`,
        prev_route: `/expenses-detail/credit`,
        tag_title: `Editar gasto en CRÉDITOS`,
        detail: `/expenses-detail/credit/${id}`,
        summary: "Gasto: CRÉDITOS",
        init_step: 0,
      },
      // Crear nuevo abono
      payment: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/expenses-form/payment/${id}`,
        prev_route: `/expenses-detail/credit/${id}`,
        tag_title: "Nuevo abono en CRÉDITOS",
        is_payment: true,
        init_step: 2,
      },
      // Editar abono
      edit_payment: {
        title: "Créditos",
        type: "CRÉDITOS",
        payment_method: null,
        payment_type: "CREDITO",
        route: `/expenses-form/edit_payment/${id}/${payment_id}`,
        prev_route: `/expenses-detail/credit/${id}`,
        tag_title: `Editar abono en CRÉDITOS`,
        is_payment: true,
        init_step: 2,
      },
    }

    return data[type]
  },

  getCategory: (category) => {
    let data = ""
    if (category === 4) {
      data = { name: "Inventarios", value: "inventories" }
    } else if (category === 5) {
      data = { name: "Propiedades planta y equipo", value: "properties" }
    } else if (category === 6) {
      data = { name: "Otros activos", value: "others" }
    }

    return data
  },

  getReport: (type) => {
    let data = {
      balance: {
        title: "Mi balance",
        code: "balance",
        type: "",
        prepare: false,
        is_date: true,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
      results: {
        title: "Mis resultados",
        code: "results",
        type: "",
        prepare: false,
        is_date: true,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
      debts: {
        title: "Mis deudas",
        code: "debts",
        type: "credits/",
        prepare: true,
        is_date: true,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
      debtors: {
        title: "Cuentas por cobrar",
        code: "debtors",
        type: "credits/",
        prepare: true,
        is_date: true,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
      inventory: {
        title: "Movimiento de inventario",
        code: "inventory",
        type: "kardex/",
        prepare: true,
        is_date: true,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
      indicators: {
        title: "Indicadores",
        code: "indicators",
        type: "indicators/",
        prepare: false,
        is_date: false,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
      expenses: {
        title: "Control de gastos",
        code: "expenses",
        type: "expenses/",
        prepare: true,
        is_date: true,
        date_type: "date",
        min_date: null,
        max_date: null,
      },
    }

    if (!type) return data
    else return data[type]
  },

  getGraphic: (type) => {
    let data = {
      sales_products: {
        title: "Estadística de ventas por producto",
        code: "sales_products",
        type: "income_product",
        date_type: "date",
        graphic: "doughnut",
        data_type: "perc",
        legend: true,
      },
      sales_months: {
        title: "Estadística de ventas por meses",
        code: "sales_months",
        type: "income_month",
        date_type: "date",
        graphic: "bar",
        data_type: "coin",
        legend: false,
      },
      shopping_products: {
        title: "Estadística de compras por productos",
        code: "shopping_products",
        type: "shopping_product",
        date_type: "date",
        graphic: "doughnut",
        data_type: "perc",
        legend: true,
      },
      shopping_months: {
        title: "Estadística de compras por meses",
        code: "shopping_months",
        type: "shopping_month",
        date_type: "date",
        graphic: "bar",
        data_type: "coin",
        legend: false,
      },
      income_budget: {
        title: "Presupuestos proyectados y ejecutados de ingresos",
        code: "income_budget",
        type: "income_comparative",
        date_type: "date",
        graphic: "line",
        data_type: "coin",
        legend: true,
      },
      expense_budget: {
        title: "Presupuestos proyectados y ejecutados de gastos",
        code: "expense_budget",
        type: "expense_comparative",
        date_type: "date",
        graphic: "line",
        data_type: "coin",
        legend: true,
      },
    }

    if (!type) return data
    else return data[type]
  },

  getFinancialInfo: (type) => {
    let data = {
      initial_info: {
        title: "Información inicial",
        route: "/initial-information",
        icon: "initial",
      },
      income_budget: {
        title: "Proyecciones ingresos",
        route: "/initial-budget-income",
        icon: "income",
      },
      expense_budget: {
        title: "Proyecciones gastos",
        route: "/initial-budget-expense",
        icon: "expense",
      },
    }

    if (!type) return data
    else return data[type]
  },
}

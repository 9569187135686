import React, {Fragment} from 'react'
import { MdEqualizer, MdInsertDriveFile } from 'react-icons/md'
import { CustomDate } from '../../../../utils/date.js'
import {Container, Title, Row, Column, Button, Input, Label, Select, Option} from './styles.js'

export const CardOption = (
    {
        title, titleColor, button1, button2, isFilter, handleChange, form, type, dateType, inventory, disabled
    }) => {
    return (
        <Container>
            {isFilter ?
                <Fragment>
                    <Title color={titleColor}>{title}</Title>
                    <Row noPad>
                        <Column>
                            <Label>Fecha inicial</Label>
                            <Input
                                style={{ width: '90%' }}
                                ghost
                                type={dateType.date_type}
                                name="from_date"
                                value={form.from_date}
                                onChange={handleChange}
                                min={dateType.min_date ? CustomDate.date4(dateType.min_date) : null}
                                max={dateType.max_date ? CustomDate.date4(dateType.max_date) : CustomDate.date4('')}
                                disabled={disabled}
                            />
                        </Column>
                        <Column>
                            <Label>Fecha final</Label>
                            <Input
                                style={{ width: '90%' }}
                                ghost
                                type={dateType.date_type}
                                name="to_date"
                                value={form.to_date}
                                onChange={handleChange}
                                min={dateType.min_date ? CustomDate.date4(dateType.min_date) : null}
                                max={dateType.max_date ? CustomDate.date4(dateType.max_date) : CustomDate.date4('')}
                                disabled={disabled}
                            />
                        </Column>
                    </Row>
                    {type === 'inventory' ?
                        <Row noPad>
                            <Column size="100">
                                <Label>Producto</Label>
                                <Select 
                                    ghost
                                    type="select"
                                    name="product"
                                    onChange={handleChange}
                                    value={form.product}
                                    required
                                >
                                    <Option value="">Seleccionar...</Option>
                                    {inventory.map((row, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Option value={row.id}>{row.name}</Option>
                                            </React.Fragment>
                                        )
                                    })}
                                </Select>
                            </Column>
                        </Row>
                    :
                        null
                    }
                </Fragment>

            :
                <Fragment>
                    <Title>{title}</Title>
                    <Row>
                        <Column>
                            <Button id="button1" color={button1.color} bg={button1.bg} left
                                onClick={e => button1.clickButton(1)} active={button1.status}
                            >
                                <MdInsertDriveFile size="1rem" style={{ marginRight: '5px' }}/>
                                {button1.title}
                            </Button>
                        </Column>
                        <Column>
                            <Button id="button2" color={button2.color} bg={button2.bg} right
                                onClick={e => button2.clickButton(2)} active={button2.status}
                            >
                                {button2.title}
                                <MdEqualizer size="1rem" style={{ marginLeft: '5px' }}/> 
                            </Button>
                        </Column>
                    </Row>
                </Fragment>
            }
        </Container>
    )
}

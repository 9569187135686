import React from "react"
import { Container, Section, BtnFilter } from "./styles.js"

export const FilterInventories = ({
  handleFilterAll,
  handleFilterProducts,
  handleFilterServices,
  handleFilterSoldOut,
  handleFilterHistory,
}) => {
  const [selectedAll, setSelectedAll] = React.useState(false)
  const [selectedProducts, setSelectedProducts] = React.useState(false)
  const [selectedServices, setSelectedServices] = React.useState(false)
  const [selectedSoldOut, setSelectedSoldOut] = React.useState(false)
  const [selectedHistory, setSelectedHistory] = React.useState(false)

  const filterAll = () => {
    handleFilterAll()
    setSelectedAll(true)
    setSelectedProducts(false)
    setSelectedServices(false)
    setSelectedSoldOut(false)
    setSelectedHistory(false)
  }
  const filterProducts = () => {
    handleFilterProducts()
    setSelectedProducts(true)
    setSelectedAll(false)
    setSelectedServices(false)
    setSelectedSoldOut(false)
    setSelectedHistory(false)
  }
  const filterServices = () => {
    handleFilterServices()
    setSelectedServices(true)
    setSelectedAll(false)
    setSelectedProducts(false)
    setSelectedSoldOut(false)
    setSelectedHistory(false)
  }
  const filterSoldOut = () => {
    handleFilterSoldOut()
    setSelectedSoldOut(true)
    setSelectedAll(false)
    setSelectedProducts(false)
    setSelectedServices(false)
    setSelectedHistory(false)
  }
  const filterHistory = () => {
    handleFilterHistory()
    setSelectedSoldOut(false)
    setSelectedAll(false)
    setSelectedProducts(false)
    setSelectedServices(false)
    setSelectedHistory(true)
  }

  React.useEffect(() => {
    filterAll()
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Section>
        <BtnFilter onClick={filterAll} selected={selectedAll}>
          Todos
        </BtnFilter>
        <BtnFilter onClick={filterProducts} selected={selectedProducts}>
          Productos
        </BtnFilter>
        <BtnFilter onClick={filterServices} selected={selectedServices}>
          Servicios
        </BtnFilter>
        <BtnFilter onClick={filterSoldOut} selected={selectedSoldOut} rojo>
          Agotados
        </BtnFilter>
        <BtnFilter onClick={filterHistory} selected={selectedHistory}>
          Historial
        </BtnFilter>
      </Section>
    </Container>
  )
}

import React, { useEffect } from "react";
import { FormEditInventories } from "../../components/organisms/forms/myInventory/FormEditInventories";

export const FormEditInventoriesContainer = (props) => {
  // eslint-disable-next-line
  const [initialForm, setInitialForm] = React.useState({
    category: "",
    sku: "",
    name: "",
    description: "",
    cost_value: "",
    sale_value: "",
    quantity: "",
    is_active: true,
    is_initial: false,
  });

  const validationsForm = (form) => {
    let errors = {};
    let regexNumbers = /^[0-9]+$/;
    let regexAlphaNumbers = /^[0-9a-zA-ZÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    // let regexComments = /^.{1,255}$/;
    // let regexNumbersSpace = /^[\d]+$/;

    if (!form.sku.trim()) {
      errors.sku = "Este campo es requerido.";
    } else if (!regexNumbers.test(form.sku.trim())) {
      errors.value = "Solo se aceptan números, sin puntos, ni espacios.";
    }

    if (!form.name.trim()) {
      errors.name = "Este campo es requerido.";
    } else if (!regexAlphaNumbers.test(form.name.trim())) {
      errors.name = "Este campo solo acepta letras y números.";
    }

    if (!regexAlphaNumbers.test(form.description.trim())) {
      errors.description = "Este campo solo acepta caractéres alfanuméricos.";
    }

    // if(!form.cost_value.trim()){
    //     errors.cost_value = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.cost_value.trim())) {
    //     errors.cost_value = 'Solo se aceptan números, sin puntos, ni espacios.'
    // }

    // if(!form.sale_value.trim()){
    //     errors.sale_value = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.sale_value.trim())) {
    //     errors.sale_value = 'Solo se aceptan números, sin puntos, ni espacios.'
    // }

    // if(!form.quantity.trim()){
    //     errors.quantity = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.quantity.trim())) {
    //     errors.quantity = 'Solo se aceptan números, sin puntos, ni espacios.'
    // }

    // if(!form.is_active.trim()){
    //     errors.is_active = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.is_active.trim())) {
    //     errors.is_active = 'Se debe seleccionar una opción.'
    // }

    // if(!form.is_initial.trim()){
    //     errors.is_initial = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.is_initial.trim())) {
    //     errors.is_initial = 'Se debe seleccionar una opción.'
    // }

    return errors;
  };

  // eslint-disable-next-line
  useEffect(async () => {
    const initialData = await props.loadInventoryItem(props.idEdit);

    initialForm["id"] = initialData.id;
    initialForm["sku"] = initialData.sku;
    initialForm["category"] = initialData.category;
    initialForm["description"] = initialData.description;
    initialForm["is_active"] = initialData.is_active;
    initialForm["name"] = initialData.name;
    initialForm["name"] = initialData.name;
    initialForm["quantity"] = initialData.stock[0].quantity;
    initialForm["cost_value"] = initialData.stock[0].cost_value;
    initialForm["sale_value"] = initialData.stock[0].sale_value;
    initialForm["is_active"] = initialData.stock[0].is_active;
    initialForm["is_initial"] = initialData.stock[0].is_initial;
    initialForm["imageInitial"] = initialData.image;

    // eslint-disable-next-line
  }, []);

  return (
    <FormEditInventories
      initialForm={initialForm}
      validationsForm={validationsForm}
      idEdit={props.idEdit}
      objItem={props.objItem}
    />
  );
};

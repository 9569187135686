import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { getInventoryItem } from "../../../api/requests"
import { Container, Section } from "./styles"
import { FormEditInitialInventoriesContainer } from "../../../containers/FormEditInitialInventoriesContainer"

export const EditInitialInventoryForm = () => {
  const { id } = useParams()

  const [inventoryItem, setInventoryItem] = React.useState({})

  const loadInventoryItem = async (id) => {
    const response = await getInventoryItem(id)
    if (response.status === 200) {
      setInventoryItem(response.data)
      return response.data
    }
  }

  useEffect(() => {
    async function fetchInventoryItem() {
      await loadInventoryItem(id)
    }
    fetchInventoryItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={`/initial-inventory-detail/${id}`}
          titlePage="Editar inventario inicial"
          darktheme="true"
          tooltip="inventory"
          // pathPrev={Routes.app_my_inventory}
        />
        <FormEditInitialInventoriesContainer
          idEdit={id}
          objtItem={inventoryItem}
          loadInventoryItem={loadInventoryItem}
        />
      </Section>
    </Container>
  )
}

import React from 'react'
import { SubscribeInvitation } from '../../../components/molecules/SubscribeInvitation';

import image from '../../../assets/img/fef_acceder_a_planes.png'

import { Link } from 'react-router-dom'

import { Container, Btn, Figure, Logo } from './styles'

export const SubsInvitation = () => {

    


    return (
        <Container>
            <Figure>
                <Logo src={image} alt="Bienvenido a Finanzas en Forma" />
            </Figure>
            <SubscribeInvitation />
            <Link to='/subscription-plans' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Btn>Ver planes</Btn></Link>

        </Container>
    )
}

import React from 'react'
import { FormContainer } from './styles';

import { getProfile, patchProfile } from '../../../../../api/requests';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../../molecules/Modals/Modal';
import { Utilities } from '../../../../../utils/utilities';
import { Routes } from '../../../../../routes';
import { FormProfileSteps } from '../FormProfileSteps';

export const FormProfile = () => {

    const history = useHistory();
    const [form, setForm] = React.useState({
        user: {}
    })
    const [data, setData] = React.useState({})
    const [activeModal, setActiveModal] = React.useState(false);
    const [modalData, setModalData] = React.useState({
        title:'',
        text: '',
        btn:'',
        is_error: false,
        is_array: false
    });

    React.useEffect(() => {
        async function fetchData() {
            await getDetail();
        }
        fetchData();
    }, [])

    async function getDetail() {
        const response = await getProfile()

        if (response.status === 200) {
            let resp = response.data[0]
            resp.economic_activity = resp.economic_activity ? resp.economic_activity.id : null
            resp.identification_type = resp.identification_type ? resp.identification_type.id : null
            resp.state = resp.city ? resp.city.state.id : null
            resp.city = resp.city ? resp.city.id : null
            resp.first_name = resp.user.first_name
            resp.last_name = resp.user.last_name
            resp.email = resp.user.email
            setForm(resp)
        }
    };

    async function submit(data) {
        let send = data
        send.user = {}
        if (data.first_name) send.user.first_name = data.first_name
        if (data.last_name) send.user.last_name = data.last_name
        if (data.email) send.user.email = data.email
        if (data.city) send.city = parseInt(data.city)
        if (data.state) send.state = parseInt(data.state)
        if (data.economic_activity) send.economic_activity = parseInt(data.economic_activity)
        if (data.identification_type) send.identification_type = parseInt(data.identification_type)

        patchProfile(send).then(response => {
            if(response.status === 200) {
                showMessage(
                    'Actualización satisfactoria', 
                    'Los datos del usuario se han actualizado correctamente.',
                    false
                )
            }
            else if (response.status === 400) {
                let array = Utilities.returnErrors(response.data);
                showMessage('Actualización fallida', array, true, true)
            }
            else {
                showMessage(
                    'Actualización fallida', 
                    "Ha ocurrido un error actualizando la información. Por favor, inténtelo nuevamente.",
                    true
                )
            }
        });
    }

    const showMessage = (title, text, is_error, is_array) => {
        setActiveModal(true)
        setModalData({
            title: title,
            text: text,
            btn: "Aceptar",
            is_error: is_error,
            is_array: is_array,
        })
    }
    
    const handleClosed = () => {
        setActiveModal(false) 
        history.push(Routes.app_my_profile);
    }

    return (
        <>
        <FormContainer>
            <FormProfileSteps
                data={data}
                form={form}
                setData={setData}
                setForm={setForm}
                handleSubmit={submit}
            />
        </FormContainer>
        <Modal
            activeModal={activeModal}
            handleClosed={handleClosed}
            title={modalData.title}
            text={modalData.text}
            isArray={modalData.is_array}
            btn={modalData.btn}
        />
        </>
    )
}

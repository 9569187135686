import React from 'react';

// import {Btn} from '../../atoms/Btn';

import {Container, Card, HeaderCard, ExitButton, Title, Subtitle, Text, TextLight, ContainerButton, Button} from './styles';

export const ModalTerms = (props) => {

  return (
    <Container className={props.activeModalTerms === true ? " activate" : ""}>
      <Card>
        <HeaderCard>
          <ExitButton onClick={props.handleClosedTerms}>X</ExitButton>
        </HeaderCard>
        <Title>TÉRMINOS Y CONDICIONES</Title>
        <Text>
            AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES Declaro de manera libre, expresa, inequívoca e informada, que AUTORIZO a FINANZAS EN FORMA ahora FINKU en adelante , para que, en los términos del literal a) del artículo 6 de la Ley 1581 de 2012, realice la recolección, almacenamiento, uso, circulación, supresión, y en general, tratamiento de mis datos personales, , como el análisis de la información, establecer comunicación directa por cualquier de los medios suministrados, compartir los datos con las organizaciones aliadas en el marco del desarrollo de la plataforma, perfilamiento de los usuarios de acuerdo al sector en el cual se desempeña, validación de la información suministrada. Declaro que se me ha informado de manera clara y comprensible que tengo derecho a conocer, actualizar y rectificar los datos personales proporcionados, a solicitar prueba de esta autorización, a solicitar información sobre el uso que se le ha dado a mis datos personales, a presentar quejas ante la Superintendencia de Industria y Comercio por el uso indebido de mis datos personales, a revocar esta autorización o solicitar la supresión de los datos personales suministrados y a acceder de forma gratuita a los mismos. Declaro que conozco y acepto el Manual de Tratamiento de Datos Personales de FINKU , y que la información por mí proporcionada es veraz, completa, exacta, actualizada y verificable. Mediante la firma del presente documento, manifiesto que reconozco y acepto que cualquier consulta o reclamación relacionada con el Tratamiento de mis datos personales podrá ser elevada verbalmente o por escrito ante FINKU , como Responsable del Tratamiento, cuya página web es:http://www.finku.co y su teléfono de atención es 3185157202 NOMBRE: * EMPRESA: * DIRECCIÓN: * CORREO ELECTRÓNICO: * ¿AUTORIZA EL TRATAMIENTO DE SUS DATOS PERSONALES? *
        </Text>
        <Title>POLÍTICA DE PRIVACIDAD DE DATOS</Title>
        <Text>
          La presente política de privacidad (en adelante, la “Política” o la “Política de Privacidad”) describe el tipo de información que FINKU recopila de los Usuarios, el mecanismo de procesamiento de información que emplea, la forma en que se utiliza dicha información, las circunstancias en que puede ser compartida, y los derechos que tienen los Usuarios sobre esa información.
          Esta Política aplica a los usuarios que descarguen, accedan o utilicen el software FINKU , su sitio web,  sus aplicaciones digitales o cualquier otra plataforma en virtud de la cual presta sus servicios (en adelante, las “Plataformas”). Por el uso de cualquiera de las Plataformas de FINKU, se entiende que el Usuario ha leído y aceptado en todas sus partes la presente Política de Privacidad. En caso contrario, el Usuario deberá abstenerse de acceder a las Plataformas, ya sea directa o indirectamente, y de utilizar cualquier información, servicio o producto provisto por la misma.
          La Política de Privacidad constituirá un acuerdo válido y obligatorio entre el Usuario y la Sociedad en relación con los requisitos de privacidad que se habrán de mantenerse respecto de la utilización por parte de SIIGO de la información que recopile del Usuario.

        </Text>
        <Text>
        <br/>
        <strong>PRIMERO: Información que recopilamos.</strong><br/><br/>
        Para prestar y perfeccionar nuestros servicios, FINKU  recopilará y almacenará la información proporcionada por los Usuarios y aquella obtenida de ellos por medio del uso de sus Plataformas (en adelante, la “Información”).
        La Información recopilada y almacenada comprenderá:<br/>
        <br/>1. Información entregada por el Usuario al registrarse en alguna de las Plataformas de FINKU , o al actualizar su información de cuenta, tales como, nombre, fecha de nacimiento, rol único tributario o documento de identificación tributaria, dirección, correo electrónico, y teléfono.<br/>
        <br/>2. Información sobre el uso y actividad del Usuario de las Plataformas, incluyendo, pero no limitando a la frecuencia, duración de uso, tiempo, frecuencia, rangos de actividad, funciones utilizadas por el Usuario, y cantidad de archivos guardados.<br/>
        <br/>3. Información sobre el dispositivo y la conexión, tal como, sistema operativo, dirección IP, versión del software, navegador, idioma y zona horaria.<br/>
        <br/>4. Información entregada por el Usuario al utilizar el servicio de atención al cliente de FINKU <br/>
        <br/>5. Información recopilada a través de cookies.
        <br/>
        <br/>
        <br/>
        <br/>
        <strong>SEGUNDO: Cómo usamos la información</strong><br/><br/>
        FINKU  utiliza la Información almacenada para operar, desarrollar, mejorar, y analizar sus servicios, pudiendo realizar estudios, mediciones, análisis estadísticos, seguimientos, y proyecciones, así como también, podrá proveer en cualquier momento servicios de análisis de información o de segmentación a terceros que sean redes o proveedores de servicios.
        El uso de la Información de la forma señalada en esta cláusula es expresamente aceptado por el Usuario.
        <br/>
        <br/>
        <br/>
        <br/>
        <strong>TERCERO: Confidencialidad y Seguridad de la Información.</strong><br/><br/>
        FINKU  ha adoptado medidas de seguridad para proteger la Información recolectada de los Usuarios, la que será mantenida de forma estrictamente confidencial.
        La Información será debidamente almacenada en plataformas de seguridad destinadas al efecto, y el acceso a las mismas estará restringido a aquellos empleados, contratistas, representantes y agentes de FINKU  que necesitan conocer tales datos para el desempeño de sus funciones y para el desarrollo o mejora de sus servicios, impidiendo el acceso, modificación o divulgación de la Información por cualquier persona no autorizada por SIIGO o el Usuario. Asimismo, FINKU  declara que no divulgará ni compartirá públicamente la Información, sin el consentimiento expreso del Usuario.
        FINKU  no garantiza, y no responderá de modo alguno, ante eventuales vulneraciones por terceros no autorizados de las medidas de seguridad de las plataformas de almacenaje información, aun cuando utilicen la información de los Usuarios en forma indebida. No obstante, en caso de que se produzca una filtración de datos o cualquier otro tipo de vulneración, FINKU  se compromete a informar a los Usuarios afectados y a adoptar todas las medidas necesarias para la solucionar el problema.
        Sin perjuicio de lo anterior, FINKU  podrá divulgar Información, cuando de buena fe estime que es razonablemente necesaria para (i) salvar su responsabilidad legal; (ii) cuando así lo exija una orden de judicial o de una autoridad administrativa competente, de cualquier naturaleza que sea; y (iii) proteger los derechos, propiedad o seguridad de FINKU , de los Usuarios o de terceros.
        <br/>
        <br/>
        <br/>
        <br/>
        <strong>CUARTO: Usuarios.</strong><br/><br/>
        FINKU  está dirigido a personas con al menos 18 años de edad, que sean completamente capaces y competentes para entender y aceptar la presente Política de Privacidad, y no se hará responsable por la mala comprensión que puedan hacer los menores de 18 años sobre ella y sobre sus implicancias.
        <br/>
        <br/>
        <br/>
        <br/>
        <strong>QUINTO: Derechos de los Usuarios.</strong><br/><br/>
        Los Usuarios podrán solicitar, rectificar, modificar, actualizar o requerir la eliminación de la Información almacenada por FINKU . Asimismo, los Usuarios tendrán derecho a oponerse a que tratemos su información, cuando dicha oposición tiene como base el interés público, los intereses legítimos de FINKU  o los de un tercero. Las referidas solicitudes deberán realizarse por escrito al email admin@finku.co, con indicación de los siguientes datos:
        <br/><br/>1. Nombre completo del Usuario.
        <br/><br/>2. Email
        <br/><br/>3. Teléfono de contacto.
        <br/><br/>4. País de residencia.
        <br/><br/>5. Actividad de recopilación a la que se opone.
        Recibida la solicitud, FINKU  dispondrá de un plazo de [30 días] para concederla o rechazarla, debiendo informar su decisión al Usuario debidamente fundamentada.
        El acceso, rectificación y remoción de datos será realizado por FINKU  de manera gratuita, a menos que para acceder a la solicitud se requiera realizar gastos de cualquier naturaleza, los que serán cargo del Usuario.
        </Text>
        <Title>POLÍTICA PARA EL TRATAMIENTO DE DATOS PERSONALES</Title>
        <Text>
        La presente política para la protección de datos personales tiene como propósito dar cumplimiento a lo establecido en la Ley 1581 de 2012, su decreto reglamentario 1377 de 2013 y demás normas concordantes que regulan el derecho constitucional de los ciudadanos a autorizar el tratamiento de sus datos personales, así como a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellos en las bases de datos o en archivos de entidades públicas y privadas.
        Las presentes políticas y procedimientos aplican a todo dato personal registrado presencial o virtualmente, directamente o a través de terceros, en las bases de datos de FINKU. y las empresas o entidades que hacen parte de su organización (La Compañía), con las excepciones que establece la ley, para su tratamiento (recolección, almacenamiento, uso, circulación o supresión), para que el titular del dato acceda o pueda acceder a cualquier producto, servicio, oferta, promoción, beneficio y/o vinculación soportada en una relación jurídica con La Compañía.
        </Text>
        <Subtitle>Definiciones</Subtitle>
        <Text>
        Acceder en forma gratuita
        <br/><br/><strong>- AUTORIZACIÓN:</strong> Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.
        <br/><br/><strong>- AVISO DE PRIVACIDAD:</strong> Comunicación verbal o escrita generada por el Responsable, dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales.
        <br/><br/><strong>- BASE DE DATOS:</strong> Conjunto organizado de datos personales que sea objeto de Tratamiento.
        <br/><br/><strong>- DATO PERSONAL:</strong> Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
        <br/><br/><strong>- DATO PÚBLICO:</strong> Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales, y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva.
        <br/><br/><strong>- DATO PERSONAL PÚBLICO:</strong> Toda información personal que es de conocimiento libre y abierto para el público en general.
        <br/><br/><strong>- DATO PERSONAL PRIVADO:</strong> Toda información personal que tiene un conocimiento restringido, y en principio privado para el público en general, para efectos de identificación de clientes, prospectos, proveedores, empleados, aliados y demás personas relacionadas con alguno(s) de nuestros productos y/o servicios, y/o con nuestra organización, para efectos de seguridad de las personas y cosas, o para la seguridad de los procesos y de la misma información.
        <br/><br/><strong>- DATO SENSIBLE:</strong> Son los datos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, en los términos que define la ley. La Compañía no trata muchos datos sensibles, aunque en materia laboral, de seguridad social o recursos humanos puede llegar a tener acceso a información relacionada con la salud del empleado o su núcleo familiar; igualmente puede establecer sistemas de identificación biométrica, la captura de imágenes fijas o en movimiento, voz, huellas digitales, fotografías, y otras existentes.
        <br/><br/><strong>- ENCARGADO DEL TRATAMIENTO:</strong> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.
        <br/><br/><strong>- LA COMPAÑÍA:</strong> FINKU y las empresas o entidades que hacen parte de su organización.
        <br/><br/><strong>- OFICIAL DE PROTECCIÓN DE DATOS:</strong> Es la persona que tiene como función la vigilancia y control de la aplicación de la Política de Protección de Datos Personales.
        <br/><br/><strong>- RESPONSABLE DEL TRATAMIENTO:</strong> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos (para los efectos de esta política, ejercerá como Responsable, en principio, la Compañía).
        <br/><br/><strong>- TITULAR:</strong> Persona natural cuyos datos personales sean objeto de Tratamiento, sea cliente, proveedor, empleado, o cualquier tercero que, en razón de una relación comercial o jurídica, suministre datos personales a La Compañía.
        <br/><br/><strong>- TRATAMIENTO:</strong> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
        <br/><br/>En éste documento se establecen las finalidades, medidas y procedimientos que gobernarán el tratamiento de datos personales al interior de la compañía FINKU.
        </Text>
        <Subtitle>1. PRINCIPIOS RECTORES PARA EL TRATAMIENTO DE DATOS PERSONALES</Subtitle>
        <Text>
        La Compañía aplicará de manera armónica e integral los siguientes principios en la recolección, manejo, uso, tratamiento, almacenamiento e intercambio, de datos personales:<br/>
        <br/><br/><strong>a) PRINCIPIO DE LEGALIDAD:</strong> El Tratamiento a que se refiere el presente documento es una actividad reglada que debe sujetarse a lo establecido en la ley y en las demás disposiciones que la desarrollen
        <br/><br/><strong>b) PRINCIPIO DE FINALIDAD:</strong> El Tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual se determina en este mismo documento, y es informada al Titular para efectos de su autorización;
        <br/><br/><strong>c) PRINCIPIO DE LIBERTAD:</strong> El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento;
        <br/><br/><strong>d) PRINCIPIO DE VERACIDAD O CALIDAD:</strong> La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. La Compañía no hará Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error;
        <br/><br/><strong>e) PRINCIPIO DE TRANSPARENCIA:</strong> En el Tratamiento de datos La Compañía garantiza el derecho del Titular a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan;
        <br/><br/><strong>f) PRINCIPIO DE ACCESO Y CIRCULACIÓN RESTRINGIDA:</strong> El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Constitución y la ley. En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la ley;
        Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la presente ley;
        <br/><br/><strong>g) PRINCIPIO DE SEGURIDAD:</strong> La información sujeta a Tratamiento por el Responsable del Tratamiento o Encargado del Tratamiento a que se refiere la ley, será manejada con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;
        <br/><br/><strong>h) PRINCIPIO DE CONFIDENCIALIDAD:</strong> Todas las personas en La Compañía que intervengan en el Tratamiento de datos personales que no tengan la naturaleza de públicos, están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales si mantienen vinculación con La Compañía y cuando ello corresponda al desarrollo de las actividades autorizadas en la presente ley y en los términos de la misma.
        </Text> 
        
        <Subtitle>2. RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE INFORMACIÓN.</Subtitle>
        <Text>
          El responsable del tratamiento de datos personales es FINKU.
          Cuyo domicilio principal es: Carrera 29 No. 31 41 OFICINA 302 Palmira – Valle
          <br/>Correo Electrónico:
          <br/>admin@finku.co
          <br/>Teléfono: 2855615
        </Text> 
        
        <Subtitle>3. TIPO DE INFORMACIÓN SOMETIDA A NUESTRO TRATAMIENTO.</Subtitle>
        <Text>
          La información objeto de tratamiento por parte de <strong>FINKU</strong>. estará constituida únicamente por los datos personales que suministran o han suministrado los titulares, como clientes, prospectos, proveedores, empleados, aliados y demás personas relacionadas con alguno(s) de nuestros productos y/o servicios, y/o con nuestra organización.
        </Text> 
        

        <Subtitle>4. FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES RECOLECTADOS.</Subtitle>
        <Text>
          La finalidad de los datos personales recolectados consistirá en su utilización con fines informativos, comerciales y estadísticos, o para garantizar y mejorar la usabilidad o la operación de nuestros productos y/o servicios, o para efectos administrativos (cotizaciones, facturación, recaudo, pedidos, compras, gestión de pagos, asuntos laborales y/o de gestión de recursos humanos, ofertas de empleo, referencias, certificaciones, etc.), y en general, para otras actividades relacionadas con nuestras políticas, eventos, campañas y noticias.
          <br/><br/>La Compañía dará confidencialidad a los datos personales suministrados por los titulares y no los entregará a terceros con fines comerciales y sin autorización e información previa al titular.
          <br/><br/>La Compañía se reserva el derecho a utilizar la información recolectada con los fines indicados, de acuerdo a lo establecido en esta política, entre otros, para:
          <br/><br/><strong>a)</strong> Procesamiento y administración de sus transacciones como cliente o usuario o prospecto o aliado o empleado de <strong>FINKU</strong>.
          <br/><br/><strong>b)</strong> Ofrecer a través de medios propios o conjuntamente con terceros información de nuevos lanzamientos de productos, servicios, planes, promociones, eventos y/o beneficios.
          <br/><br/><strong>c)</strong> Información adicional que beneficie la operación, soporte, mantenimiento, actualizaciones, garantía de nuestros productos o servicios en caso de incidentes, inconvenientes, requerimientos o fallas.
          <br/><br/><strong>d)</strong> Cumplir con la notificación de información de su interés cuando lo haya solicitado, incluyendo la respuesta a sus PQR y en general peticiones, dudas o cuestionamientos.
          <br/><br/><strong>e)</strong> Estudiar y almacenar información asociada a solicitudes de alguno de nuestros productos que como cliente o futuro cliente debemos conocer para la relación comercial.
          <br/><br/><strong>f)</strong> El envío de comunicaciones relacionadas con las actividades comerciales de <strong>FINKU</strong>, noticias e información útil para nuestra compañía, productos, ofertas, novedades, invitaciones a eventos, ofertas de empleo, propaganda, publicidad y/o encuestas sobre nuestros productos o servicios y/o los productos y servicios de nuestros socios comerciales.
          <br/><br/><strong>g)</strong> Tratamiento de los datos de uso de nuestros productos y/o servicios con fines estadísticos, mercadeo o análisis relacional de información.
          <br/><br/><strong>h)</strong> Tratamiento de los datos para fines de investigación, innovación y desarrollo de nuevos productos y/o servicios.
          <br/><br/><strong>i)</strong> Otras actividades relacionadas con el objeto social que necesariamente deben utilizar la información o datos personales de clientes, prospectos, proveedores, empleados, aliados y demás personas relacionadas con alguno(s) de nuestros productos y/o servicios, y/o con nuestra organización.
          <br/><br/><strong>j)</strong> Exportar esta información aun al exterior, en caso de que el hosting utilizado no esté localizado en Colombia, dentro de los lineamientos legales y aquellos determinados por la entidad de control
          <br/><br/>Este tratamiento lo puede realizar La Compañía a través de medios físicos, electrónico, celular o dispositivo móvil, vía mensajes de texto (SMS), o a través de cualquier medio análogo y/o digital de comunicación, conocido o por conocer.
          <br/><br/>Los titulares de datos personales almacenados en cualquiera de las bases de datos de La Compañía podrán ejercer en cualquier momento sus derechos de acceso, actualización, rectificación y supresión, frente al responsable del tratamiento de La Compañía.
        </Text> 


        <Subtitle>5. DERECHOS Y CONDICIONES DE LEGALIDAD PARA EL TRATAMIENTO DE DATOS.</Subtitle>
        <Text>
          <strong>5.1. DERECHOS DE LOS TITULARES.</strong> 
          <br/><br/>El Titular de los datos personales tendrá los siguientes derechos:
          <br/><br/><strong>a)</strong> Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado;
          <br/><br/><strong>b)</strong> Solicitar prueba de la autorización otorgada al Responsable del Tratamiento salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en la ley;
          <br/><br/><strong>c)</strong> Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales;
          <br/><br/><strong>d)</strong> Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la presente ley y las demás normas que la modifiquen, adicionen o complementen;
          <br/><br/><strong>e)</strong> Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales, en los términos de ley y de la jurisprudencia que la interpreta;
          <br/><br/><strong>f)</strong> Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
          <br/><br/><br/>El derecho de supresión no es un derecho absoluto. La Compañía puede negar o limitar el ejercicio del mismo cuando:
          <br/><br/><br/><strong>a)</strong> El titular de los datos tenga el deber legal o contractual de permanecer en la base de datos.
          <br/><br/><strong>b)</strong> La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.
          <br/><br/><strong>c)</strong> Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.
          <br/><br/><strong>d)</strong> Los datos sean datos de naturaleza pública y correspondan a los registros públicos, los cuales tienen como finalidad su publicidad.
          <br/><br/><strong>e)</strong> Los datos constituyan un soporte para la operación normal de La Compañía y que deba ser conservada por esta en virtud de la Ley o sus procesos internos.
          <br/><br/>Igualmente, el titular de datos personales puede revocar en cualquier momento, el consentimiento al tratamiento de éstos, siempre y cuando, no lo impida una disposición legal o contractual. En este caso puede operar de manera parcial o total.

        </Text> 

        <Text>
          <br/><strong>5.2. AUTORIZACIÓN DEL TITULAR.</strong> 
          <br/><br/>Sin perjuicio de las excepciones previstas en la ley, en el Tratamiento se requiere la autorización previa e informada del Titular, la cual deberá ser obtenida por cualquier medio que pueda ser objeto de consulta posterior.
          <br/><br/>Mediante autorización expresa otorgada a través de mecanismos físicos o electrónicos, entre otros telefónicamente, e mail, los dispuestos en la página web finku.co  y otros medios (Landing page), etc., el titular de la información al registrarse o indicar que autoriza el tratamiento de sus datos personales y que acepta la política incluida en el aviso de privacidad, permite a <strong>FINKU</strong>. recolectar registrar, procesar, difundir y comercializar todos los datos e información que este de forma voluntaria suministre en el momento del registro, limitados en cada caso al tipo de vinculación o base de datos, lo que determina el tipo de información personal solicitada.
          <br/><br/>Al dar su autorización, el titular acepta que los datos personales aportados al momento de su registro, o cualquier otro dato facilitado a La Compañía en cualquier momento, de acuerdo al tipo de vinculación o base de datos, para su acceso a algunos de los servicios, eventos, promociones, información, etc., sean utilizados con la finalidad de facilitar la relación que se establece o se pretende establecer, prestación de los servicios solicitados, para la correcta identificación de los usuarios que solicitan servicios personalizados, para la realización de estudios estadísticos de los usuarios que permitan diseñar mejoras en los servicios prestados, para la gestión de tareas básicas de administración, así como para mantenerle informado, bien por correo electrónico bien por cualquier otro medio de novedades, productos y servicios relacionados con La Compañía.

        </Text> 
        
        <Text>
          <br/><strong>5.3. CASOS EN QUE NO ES NECESARIA LA AUTORIZACIÓN.</strong> 
          <br/><br/>La autorización del Titular no será necesaria cuando se trate de:
          <br/><br/><strong>a)</strong> Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial;
          <br/><br/><strong>b)</strong> Datos de naturaleza pública;
          <br/><br/><strong>c)</strong> Casos de urgencia médica o sanitaria;
          <br/><br/><strong>d)</strong> Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos;
          <br/><br/><strong>e)</strong> Datos relacionados con el Registro Civil de las Personas.
          <br/><br/>Quien acceda a los datos personales sin que medie autorización previa deberá en todo caso cumplir con las disposiciones contenidas en la presente ley.
        </Text> 
        
        <Text>
          <br/><strong>5.4. SUMINISTRO DE LA INFORMACIÓN.</strong> 
          <br/><br/>La información solicitada podrá ser suministrada por cualquier medio, incluyendo los electrónicos, según lo requiera el Titular. La información deberá ser de fácil lectura, sin barreras técnicas que impidan su acceso y deberá corresponder en un todo a aquella que repose en la base de datos.
        </Text> 

        <Text>
          <br/><strong>5.5. DEBER DE INFORMAR AL TITULAR.</strong> 
          <br/><br/>El Responsable del Tratamiento, al momento de solicitar al Titular la autorización, deberá informarle de manera clara y expresa lo siguiente:
          <br/><br/><strong>a)</strong> El Tratamiento al cual serán sometidos sus datos personales y la finalidad del mismo;
          <br/><br/><strong>b)</strong> El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando estas versen sobre datos sensibles o sobre los datos de las niñas, niños y adolescentes;
          <br/><br/><strong>c)</strong> Los derechos que le asisten como Titular;
          <br/><br/><strong>d)</strong> Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos;
          <br/><br/><strong>PARÁGRAFO.</strong> El Responsable del Tratamiento deberá conservar prueba del cumplimiento de lo previsto en el presente artículo y, cuando el Titular lo solicite, entregarle copia de esta.
        </Text> 

        <Text>
          <br/><strong>5.6. PERSONAS A QUIENES SE LES PUEDE SUMINISTRAR LA INFORMACIÓN.</strong> 
          <br/><br/>La información que reúna las condiciones establecidas en la presente ley podrá suministrarse a las siguientes personas:
          <br/><br/><strong>a)</strong> A los Titulares, sus causahabientes o sus representantes legales;
          <br/><br/><TextLight><strong>b)</strong> A las entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial;</TextLight>
          <br/><strong>c)</strong> A los terceros autorizados por el Titular o por la ley.
        </Text> 

        <Text>
          <br/><strong>5.7. TRATAMIENTO DE LOS DATOS PERSONALES.</strong> 
          <br/><br/>Una vez obtenida la autorización del (los) titular(es) respectivos, SIIGO S.A recopilará todos los datos personales del (los) titular(es) en una la base de datos electrónica correspondiente, cuya administración y guarda estará a su cargo.
          <br/><br/><strong>FINKU</strong> evitará el acceso no autorizado de terceros a los datos personales recolectados y ejecutará todas las medidas tendientes a su protección.
          <br/><br/><strong>FINKU</strong> se compromete a cumplir con su obligación de mantener en secreto los datos de carácter privado, así como su deber de tratarlos con confidencialidad, y asume las medidas de índole técnica, organizativa y de seguridad necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, de acuerdo con lo establecido en la ley y en los tratados internacionales suscritos por Colombia que rigen la materia.
          <br/><br/>El Titular responderá, en cualquier caso, por la veracidad de los datos facilitados. <strong>SIIGO S.A.S.</strong> podrá excluir al titular que haya suministrado datos falsos, de cualquier relación generada con base en esa información falsa, sin perjuicio de las demás acciones que procedan.
        </Text> 

        <Text>
          <br/><strong>5.8. TRATAMIENTO DE DATOS SENSIBLES.</strong> 
          <br/><br/>La Compañía no hará tratamiento de datos sensibles, excepto cuando:
          <br/><br/><strong>a)</strong> El Titular haya dado su autorización explícita a dicho Tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización;
          <br/><br/><strong>b)</strong> El Tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización;
          <br/><br/><strong>c)</strong> El Tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del Titular;
          <br/><br/><strong>d)</strong> El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial;
          <br/><br/><strong>e)</strong> El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares.
        </Text> 

        <Text>
          <br/><strong>5.9. DERECHOS DE LOS NIÑOS, NIÑAS Y ADOLESCENTES.</strong> 
          <br/><br/>En el Tratamiento se asegurará el respeto a los derechos prevalentes de los niños, niñas y adolescentes, bajo los lineamientos de ley.
        </Text> 
        
        <Subtitle>6. MECANISMO DE ATENCIÓN A LOS TITULARES DE LA INFORMACIÓN.</Subtitle>
        <Text>
          Con el fin de facilitar la protección de los derechos del (los) titular(es) de la información, <strong>FINKU</strong> establece para ellos el siguiente procedimiento:
          <br/><br/><br/><strong>6.1 Persona o área responsable de la atención de peticiones:</strong>
          <br/><br/>Todas las peticiones, consultas y reclamos que se generen con ocasión del tratamiento de datos personales deberán ser dirigidas a las oficinas principales de <strong>FINKU</strong> en Palmira, así: admin@finku.co o de manera física a la dirección Carrera 29 No. 31-41 oficina 302 . 6. 2 Medios de protección: En caso de que el(los) titular(es) de la información desee(n) ejercer alguno de sus derechos, deberá enviar su solicitud, explicando de forma clara, detallada y precisa el motivo de su requerimiento, los hechos que la sustentan, su información de contacto, los documentos que quiera hacer valer y su(s) pretensión(es).
        </Text> 

        <Subtitle>7. DEBERES DE LOS RESPONSABLES DEL TRATAMIENTO</Subtitle>
        <Text>
          De acuerdo con la Ley, son deberes de los responsables del tratamiento de datos personas, los siguientes, sin perjuicio de las demás disposiciones previstas en la ley:
          <br/><br/><strong>a)</strong> Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data;
          <br/><br/><strong>b)</strong> Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva autorización otorgada por el Titular;
          <br/><br/><strong>c)</strong> Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada;
          <br/><br/><strong>d)</strong> Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;
          <br/><br/><strong>e)</strong> Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible;
          <br/><br/><strong>f)</strong> Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada;
          <br/><br/><strong>g)</strong> Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento;
          <br/><br/><strong>h)</strong> Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la presente ley;
          <br/><br/><strong>i)</strong> Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular;
          <br/><br/><strong>j)</strong> Tramitar las consultas y reclamos formulados en los términos señalados en la ley;
          <br/><br/><strong>k)</strong> Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos;
          <br/><br/><strong>l)</strong> Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo;
          <br/><br/><strong>m)</strong> Informar a solicitud del Titular sobre el uso dado a sus datos;
          <br/><br/><strong>n)</strong> Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.
          <br/><br/><strong>o)</strong> Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
        </Text>

        <Subtitle>8. DEBERES DE LOS ENCARGADOS DEL TRATAMIENTO.</Subtitle>
        <Text>
          Los Encargados del Tratamiento deberán cumplir los siguientes deberes, sin perjuicio de las demás disposiciones previstas en la presente ley y en otras que rijan su actividad
          <br/><br/><strong>a)</strong> Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data;
          <br/><br/><strong>b)</strong> Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;
          <br/><br/><strong>c)</strong> Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de ley;
          <br/><br/><strong>d)</strong> Actualizar la información reportada por los Responsables del Tratamiento dentro de los cinco (5) días hábiles contados a partir de su recibo;
          <br/><br/><strong>e)</strong> Tramitar las consultas y los reclamos formulados por los Titulares en los términos señalados en la ley;
          <br/><br/><strong>f)</strong> Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y, en especial, para la atención de consultas y reclamos por parte de los Titulares;
          <br/><br/><strong>g)</strong> Registrar en la base de datos la leyenda “reclamo en trámite” en la forma en que se regula en la ley;
          <br/><br/><strong>h)</strong> Insertar en la base de datos la leyenda “información en discusión judicial” una vez notificado por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad del dato personal;
          <br/><br/><strong>i)</strong> Abstenerse de circular información que esté siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio;
          <br/><br/><strong>j)</strong> Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella;
          <br/><br/><strong>k)</strong> Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares;
          <br/><br/><strong>l)</strong> Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
          <br/><br/><strong>PARÁGRAFO.</strong> En el evento en que concurran las calidades de Responsable del Tratamiento y Encargado del Tratamiento en la misma persona, le será exigible el cumplimiento de los deberes previstos para cada uno.
          <br/><br/>La Compañía en garantía de los derechos de los Titulares, tiene pactado con sus empleados, que intervenga en cualquier fase del tratamiento de los datos, la obligación de salvaguardar la confidencialidad y limitar el uso de dicha información a los fines específicos para los cuales fueron recolectados.
        </Text>

        <Subtitle>9. AVISO DE PRIVACIDAD</Subtitle>
        <Text>
          La Compañía a través de comunicación verbal o escrita generada por el Responsable, dirigida al Titular para el Tratamiento de sus datos personales, le informa acerca de la existencia de las políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas, las finalidades del Tratamiento que se pretende dar a los datos personales, la privacidad de esta información y, en general, le permitirá acceso a este documento para que previamente a su autorización, pueda consultarlo.
          <br/><br/>Estos avisos los podrá ubicar fácilmente el Titular en el material publicitario, en las Landing page, en la página web, en los emails, al momento de accesar productos o servicios, en las facturas, en los formularios de inscripción para la recolección de datos necesarios para la contratación, facturación, pago, etc.
          <br/><br/><br/><strong>PAGINA WEB</strong>
          <br/><br/>Los mecanismos dispuestos por el responsable para que el titular conozca la política de Tratamiento de la información y los cambios sustanciales que se produzcan en ella o en el Aviso de Privacidad correspondiente: El mecanismo dispuesto es la página Web, website de conocimiento público para todos los titulares, allí se expone la política de tratamiento de datos vigente con los cambios que se puedan genera.
          <br/><br/>Cómo acceder o consultar la política de Tratamiento de información: Para acceder a la política será a través de la página web finku.co en la sección de políticas así como en todo formulario disponible en la página dispuesto para la recolección de cualquier dato personal.
          <br/><br/>Al ingresar a la página finku.co  y todos los sitios de FINKU . o utilizar cualquiera de sus servicios, el titular acepta expresamente los términos de uso, políticas de privacidad y derechos de autor de este website, los cuales pueden ser modificados sin previo aviso en cualquier momento por FINKU.
          <br/><br/>De acuerdo con los presentes términos y condiciones se entiende La Compañía entenderá que el titular es quien consigna la información, y que, en caso de delegarlo, la persona que lo hace tiene la facultad de ingresar información en los sitios y/o publicaciones de FINKU ., como clientes, usuarios o proveedores, empleados, contratistas, proveedores, etc., con el objeto de consultar y/o buscar información contenida en estos.
          <br/><br/>Esta página y su contenido pertenecen a  FINKU ., empresa propietaria de la página finku.co  por esta razón, se prohíbe la reproducción total o parcial, traducción, inclusión, transmisión, transformación, almacenamiento o acceso a través de medios analógicos, digitales o de cualquier otro sistema o tecnología creada, o por crearse, de cualquiera de sus contenidos sin autorización previa y escrita de FINKU.
          <br/><br/>La página finku.co  y demás sitios de FINKU . pueden contener enlaces a websites de terceros. Las políticas de confidencialidad de tales sitios pueden ser diferentes a las políticas de confidencialidad de siigo.com. FINKU. no ejerce control sobre los sitios operados y mantenidos por terceras partes, sus productos o servicios. Por esta razón, no será responsable por la calidad o pertinencia de su contenido.
          <br/><br/>El titular está en la obligación de leer la política de confidencialidad de los sitios enlazados a través siigo.com e ingresará a ellos bajo su propio riesgo.
          <br/><br/>El titular únicamente podrá ingresar a las secciones que le sean autorizadas por FINKU , por lo tanto, se abstendrá de utilizar cualquier medio para violar la seguridad y las restricciones, así como cualquier medida tecnológica.
          <br/><br/>La identificación y la clave de acceso son personales e intransferibles, y el titular será el único responsable por su uso adecuado. Cualquier violación a esta disposición dará lugar a que FINKU  pueda invocar este incumplimiento para terminar la correspondiente relación existente o en curso de establecerse, sin perjuicio del cobro de las indemnizaciones a las que hubiere lugar.
          <br/><br/>Si tiene alguna pregunta, duda o aporte sobre este documento, puede comunicarse con nosotros a través de los siguientes canales de comunicación:
          <br/><br/>Mediante comunicación escrita a través del correo electrónico a correo admin@finku.co o por escrito en la oficina principal, con referencia “Protección de Datos Personales”, ubicada en la Cra.  29 No. 31 – 41 oficina 302 Palmira.
          <br/><br/>El presente documento tiene vigencia a partir del día 01 de enero del 2021, y podrá ser modificado en cualquier momento, en cumplimiento de las obligaciones establecidas en la Ley 1581 de 2012, los decretos reglamentarios y demás normas que complementen, modifiquen o deroguen, así como atendiendo las instrucciones, documentos o reglamentos de la Superintendencia de Industria y Comercio y demás entes de control.
          <br/><br/>El mismo documento y cualquier modificación se mantendrán publicados en la página web: siigo.com.
        </Text>

        <Text>
          <br/><br/><strong>Aviso legal:</strong>
          <br/><br/>Estos estados financieros se elaboran con base en la informacion que usted suministra. Los únicos estados financieros oficiales de su emprendimiento son los certificados por usted y su contador publico.
        </Text>

        
        <ContainerButton>

          <Button 
            onClick={props.handleClosedTerms}
            name="Aceptar"
            primary="true"
            className={props.boton === true ? " success" : ""}
          >{props.btn}</Button>
        </ContainerButton> 
      </Card>
    </Container>
  );
};
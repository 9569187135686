import React, { useEffect, useState } from "react"
import { Routes } from "../../../routes"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { getLiabilityItem } from "../../../api/requests"
import { Container, Section } from "./styles"
import { useLocation, useParams } from "react-router-dom"
import { FormEditLiabilitiesContainer } from "../../../containers/FormEditLiabilitiesContainer"

export const EditInitialLiabilitiesForm = () => {
  const { id } = useParams()
  const location = useLocation()
  const captureId = location.pathname.toString().split("/")
  const idCategory = captureId[2]

  const [liabilityItem, setLiabilityItem] = useState({})

  const loadLiabilityItem = async (id) => {
    const response = await getLiabilityItem(id)

    if (response.status === 200) {
      setLiabilityItem(response.data)
      return response.data
    }
  }

  useEffect(() => {
    async function fetchLiabilityItem() {
      await loadLiabilityItem(id)
    }
    fetchLiabilityItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={Routes.app_initial_liabilities}
          titlePage="Editar pasivo inicial"
          tooltip="passives"
          darktheme
        />
        <FormEditLiabilitiesContainer
          idEdit={idCategory}
          objItem={liabilityItem}
          loadLiabilityItem={loadLiabilityItem}
        />
      </Section>
    </Container>
  )
}

import React from "react"
import { Text, Span, WelcomeSubtitle, WelcomeText } from "./styles"
import { getProfile } from "../../../api/requests"

export const WelcomeUserName = ({ namee = "pedro" }) => {
  const [welcomeName, setWelcomeName] = React.useState({
    user: { first_name: "" },
  })

  const isInitRef = React.useRef(true)

  const capitalizeWelcome = (name = "carlOs") => {
    const lower = name.toLowerCase()
    const capitalizeName = name.charAt(0).toUpperCase() + lower.slice(1)

    return capitalizeName
  }

  const capitalizeWell = capitalizeWelcome(welcomeName.user.first_name)

  // eslint-disable-next-line
  const loadName = async () => {
    const response = await getProfile()
    if (response.status === 200) {
      setWelcomeName(response.data[0])
    }
  }

  // eslint-disable-next-line
  React.useEffect(async () => {
    if (isInitRef.current) {
      await loadName()
      isInitRef.current = false
    }
  }, [isInitRef, loadName])

  return (
    <>
      <Text>
        Hola, <Span> {capitalizeWell}!</Span>
      </Text>
      <WelcomeSubtitle>Te damos la bienvenida</WelcomeSubtitle>
      <WelcomeText>
        Para iniciar te invitamos a{" "}
        <strong>ingresar la información inicial</strong> de tu negocio.
      </WelcomeText>
    </>
  )
}

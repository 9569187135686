import React, { Fragment, useEffect, useState } from "react";
import { Container, Section, Line, SectionList } from "./styles";
import { MdCached } from "react-icons/md";
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo";
import { FilterInventories } from "../../../../components/atoms/FilterInventories";
import { CardLog } from "../../../../components/atoms/cards/CardLog";
import { EmptyMessage } from "../../../../components/atoms/EmptyMessage";
import { CardInventory } from "../../../../components/atoms/cards/CardInventory";
import { Routes } from "../../../../routes";
import { fakeResponse, shareLink } from "../../../../utils/fakeData";
import {
  getInventories,
  getInventoriesPublic,
  getProfilePublic,
} from "../../../../api/requests";
import { useLocation } from "react-router-dom";

export const MyCatalog = () => {
  const [profile, setProfile] = useState({});
  const [listInventories, setListInventories] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listLogs, setListLogs] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [reList, setReList] = useState(false);

  const [skeleton, setSkeleton] = useState(false);

  const [filterAll, setFilterAll] = useState(true);
  const [filterProducts, setFilterProducts] = useState(false);
  const [filterServices, setFilterServices] = useState(false);
  const [filterSoldOut, setFilterSoldOut] = useState(false);
  const [filterHistory, setFilterHistory] = useState(false);

  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const handleFilterAll = () => {
    setFilterAll(true);
    setFilterProducts(false);
    setFilterServices(false);
    setFilterSoldOut(false);
    setFilterHistory(false);
  };

  const handleFilterProducts = () => {
    setFilterProducts(true);
    setFilterAll(false);
    setFilterServices(false);
    setFilterSoldOut(false);
    setFilterHistory(false);
  };

  const handleFilterServices = () => {
    setFilterServices(true);
    setFilterAll(false);
    setFilterProducts(false);
    setFilterSoldOut(false);
    setFilterHistory(false);
  };

  const handleFilterSoldOut = () => {
    setFilterSoldOut(true);
    setFilterAll(false);
    setFilterProducts(false);
    setFilterServices(false);
    setFilterHistory(false);
  };

  const handleFilterHistory = () => {
    setFilterSoldOut(false);
    setFilterAll(false);
    setFilterProducts(false);
    setFilterServices(false);
    setFilterHistory(true);
  };

  async function loadInventories() {
    setSkeleton(true);

    const queryParam = window.location.search;
    // get authorization from query param
    const urlParams = new URLSearchParams(queryParam);
    const authorization = urlParams.get("authorization");
    if (!authorization) {
      console.error("No authorization");
      return;
    }
    const profileResponse = await getProfilePublic(authorization);
    setProfile(profileResponse.data[0]);
    const response = await getInventoriesPublic(authorization);

    if (response.status === 200) {
      let resp = response.data;
      let array_prods = [];
      let array_servs = [];

      resp.forEach((obj) => {
        if (obj.category === "PRODUCTO COMPRADO") array_prods.push(obj);
        if (obj.category === "PRODUCTO FABRICADO") array_prods.push(obj);
        if (obj.category === "SERVICIO") array_servs.push(obj);
      });

      setListInventories(response.data);
      setProducts(array_prods);
      setServices(array_servs);
    }
    setTimeout(() => {
      setSkeleton(false);
    }, 1000);
  }

  useEffect(() => {
    loadInventories();
  }, [reList]);

  return (
    <Container>
      <Section>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <h1 style={{ color: "#0A2733" }}>
            {profile.tradename ? profile.tradename : "Mi Catalogo"}
          </h1>

          <p style={{ fontSize: "1rem", marginTop: "10px" }}>
            {profile?.city?.name}{" "}
          </p>
        </div>

        <TitleAndInfo
          btnPrev={false}
          titlePage="Mi Catalogo"
          hideNotifications
        />
        {!skeleton && listInventories.length > 0 && (
          <FilterInventories
            filterAll={filterAll}
            filterProducts={filterProducts}
            filterServices={filterServices}
            filterSoldOut={filterSoldOut}
            filterHistory={filterHistory}
            handleFilterAll={handleFilterAll}
            handleFilterProducts={handleFilterProducts}
            handleFilterServices={handleFilterServices}
            handleFilterSoldOut={handleFilterSoldOut}
            handleFilterHistory={handleFilterHistory}
          />
        )}
        <Line />
      </Section>

      {!skeleton && listInventories == null && (
        <SectionList count={true}>
          <EmptyMessage message="Error de conexión." />
        </SectionList>
      )}
      {!skeleton && filterAll === true && (
        <Fragment>
          {listInventories.length > 0 ? (
            <SectionList count={listInventories.length < 4}>
              {listInventories.map((inventoryItem, i) => {
                console.log(inventoryItem);
                return (
                  <Fragment key={i}>
                    <CardInventory
                      id={inventoryItem.id}
                      category={inventoryItem.category}
                      sku={inventoryItem.sku}
                      name={inventoryItem.name}
                      description={inventoryItem.description}
                      is_active={inventoryItem.is_active}
                      is_initial={inventoryItem.stock.is_initial}
                      quantity={inventoryItem.stock.quantity}
                      cost_value={inventoryItem.stock.cost_value}
                      sale_value={inventoryItem.stock.sale_value}
                      path={shareLink().replace(window.location.origin, "")}
                      image={inventoryItem.image}
                    />
                  </Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="Aún no existe catalogo registrado." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterProducts === true && (
        <Fragment>
          {products.length > 0 ? (
            <SectionList count={products.length < 4}>
              {products.map((inventoryItem, i) => {
                return (
                  <React.Fragment key={i}>
                    {inventoryItem.category === "PRODUCTO FABRICADO" && (
                      <CardInventory
                        id={inventoryItem.id}
                        category={inventoryItem.category}
                        sku={inventoryItem.sku}
                        name={inventoryItem.name}
                        description={inventoryItem.description}
                        is_active={inventoryItem.is_active}
                        is_initial={inventoryItem.stock.is_initial}
                        quantity={inventoryItem.stock.quantity}
                        cost_value={inventoryItem.stock.cost_value}
                        sale_value={inventoryItem.stock.sale_value}
                        // path={Routes.app_inventory_detail}
                        path={`/inventory-detail/${inventoryItem.id}`}
                        image={inventoryItem.image}
                      />
                    )}
                    {inventoryItem.category === "PRODUCTO COMPRADO" && (
                      <CardInventory
                        id={inventoryItem.id}
                        category={inventoryItem.category}
                        sku={inventoryItem.sku}
                        name={inventoryItem.name}
                        description={inventoryItem.description}
                        is_active={inventoryItem.is_active}
                        is_initial={inventoryItem.stock.is_initial}
                        quantity={inventoryItem.stock.quantity}
                        cost_value={inventoryItem.stock.cost_value}
                        sale_value={inventoryItem.stock.sale_value}
                        // path={Routes.app_inventory_detail}
                        path={`/inventory-detail/${inventoryItem.id}`}
                        image={inventoryItem.image}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="Aún no existe catalogo registrado." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterServices === true && (
        <Fragment>
          {services.length > 0 ? (
            <SectionList count={services.length < 4}>
              {services.map((inventoryItem, i) => {
                return (
                  <React.Fragment key={i}>
                    {inventoryItem.category === "SERVICIO" && (
                      <CardInventory
                        id={inventoryItem.id}
                        category={inventoryItem.category}
                        sku={inventoryItem.sku}
                        name={inventoryItem.name}
                        description={inventoryItem.description}
                        is_active={inventoryItem.is_active}
                        is_initial={inventoryItem.stock.is_initial}
                        quantity={inventoryItem.stock.quantity}
                        cost_value={inventoryItem.stock.cost_value}
                        sale_value={inventoryItem.stock.sale_value}
                        // path={Routes.app_inventory_detail}
                        path={`/inventory-detail/${inventoryItem.id}`}
                        image={inventoryItem.image}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="Aún no existe catalogo registrado." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterSoldOut === true && (
        <Fragment>
          {listInventories.length > 0 ? (
            <SectionList count={listInventories.length < 4}>
              {listInventories.map((inventoryItem, i) => {
                console.log(inventoryItem);
                return (
                  <React.Fragment key={i}>
                    {inventoryItem.category === "SERVICIO" &&
                      inventoryItem.is_active === false && (
                        <CardInventory
                          id={inventoryItem.id}
                          category={inventoryItem.category}
                          sku={inventoryItem.sku}
                          name={inventoryItem.name}
                          description={inventoryItem.description}
                          is_active={inventoryItem.is_active}
                          is_initial={inventoryItem.stock.is_initial}
                          quantity={inventoryItem.stock.quantity}
                          cost_value={inventoryItem.stock.cost_value}
                          sale_value={inventoryItem.stock.sale_value}
                          path={`/inventory-detail/${inventoryItem.id}`}
                          image={inventoryItem.image}
                        />
                      )}
                    {inventoryItem.category === "PRODUCTO FABRICADO" &&
                      inventoryItem.stock.quantity === 0 && (
                        <CardInventory
                          id={inventoryItem.id}
                          category={inventoryItem.category}
                          sku={inventoryItem.sku}
                          name={inventoryItem.name}
                          description={inventoryItem.description}
                          is_active={inventoryItem.is_active}
                          is_initial={inventoryItem.stock.is_initial}
                          quantity={inventoryItem.stock.quantity}
                          cost_value={inventoryItem.stock.cost_value}
                          sale_value={inventoryItem.stock.sale_value}
                          path={Routes.app_inventory_detail}
                          image={inventoryItem.image}
                        />
                      )}
                    {inventoryItem.category === "PRODUCTO COMPRADO" &&
                      inventoryItem.stock.quantity === 0 && (
                        <CardInventory
                          id={inventoryItem.id}
                          category={inventoryItem.category}
                          sku={inventoryItem.sku}
                          name={inventoryItem.name}
                          description={inventoryItem.description}
                          is_active={inventoryItem.is_active}
                          is_initial={inventoryItem.stock.is_initial}
                          quantity={inventoryItem.stock.quantity}
                          cost_value={inventoryItem.stock.cost_value}
                          sale_value={inventoryItem.stock.sale_value}
                          path={Routes.app_inventory_detail}
                          image={inventoryItem.image}
                        />
                      )}
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="No hay información para mostrar." />
            </SectionList>
          )}
        </Fragment>
      )}
      {!skeleton && filterHistory === true && (
        <Fragment>
          {listLogs.length > 0 ? (
            <SectionList count={listLogs.length < 4}>
              {listLogs.map((logItem, i) => {
                return (
                  <React.Fragment key={i}>
                    <CardLog
                      id={logItem.id}
                      created={logItem.created}
                      fiel_change={logItem.fiel_change}
                      type_change={logItem.type_change}
                      inventory={logItem.inventory}
                      new_data={logItem.new_data}
                      old_data={logItem.old_data}
                      icon={<MdCached size="2rem" />}
                      skeleton={skeleton}
                    />
                  </React.Fragment>
                );
              })}
            </SectionList>
          ) : (
            <SectionList count={true}>
              <EmptyMessage message="No hay información para mostrar." />
            </SectionList>
          )}
        </Fragment>
      )}
    </Container>
  );
};

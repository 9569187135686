import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from 'styled-components';
import {theme} from './styles/theme';
// import Context from './Context';
import {App} from './App';
import reportWebVitals from './reportWebVitals';

import {ThemeContext} from './context/ThemeContext'

ReactDOM.render(
  <React.StrictMode>
      {/*<Context.Provider>*/}
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value='red'>
          <App />
        </ThemeContext.Provider>
      </ThemeProvider>
      {/*</Context.Provider>*/}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

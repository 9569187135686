import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
    background: ${props => props.theme.colors.third_clear};
    width:100%;
    height: 100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
        width:100%;
        padding-left:0;
  }
`
export const Section = styled.section`
    background: ${props => props.theme.colors.third_clear};
    max-width:1000px;
    display:flex;
    flex-direction:column;
    flex:0;
    width:100%;
    height:100%;
    padding: 2rem 2rem 1.5rem;
    ${mobile} {
      width:100%;
  }
`

export const SectionList = styled.div`
  background: ${props => props.theme.colors.light};
  height: ${props => props.count ? '100%' : ''};
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  padding: 2rem 2rem 2rem;
  justify-content:space-between;
  max-width:1000px;
  width:100%;
  padding-top: 1.4rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${mobile} {
    padding-bottom: 3.2rem;
  }
`

export const Line = styled.div`
    margin:1.5em 2em 0;
    border-bottom:2px solid ${props => props.theme.colors.dark};
    ${mobile} {
      margin:2em 2em 0;
  }
`


export const ContainerBtn = styled.div`
    width:100%;
    max-width:1000px;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    padding: 1em 2rem 2rem;
    /* background: ${props => props.theme.colors.text_light}; */
    background: ${props => props.theme.colors.third};
    position:absolute;
    bottom:0;
    opacity:.97;
    ${mobile} {
        padding:0 2rem 1rem;
        justify-content:space-between;

    }
  
`

export const Btn = styled.button`
  width: content-fit; 
  width: ${props => ( props.second ? "45%" : "70%")}; 
   /*width: ${props => ( props.second ? "45%" : "70%")}; */
  color: ${props => ( props.white ? props.theme.colors.main : props.ghost ? props.theme.colors.main : "#fff" )};
  background: ${props => ( props.white ? "#fff" : props.ghost ? "transparent" : props.theme.colors.main )};
  border:2px solid ${props => props.theme.colors.main};
  /* margin:0 auto; */
  margin-top:1em;
  padding:1em .5em;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.dark};
    border:2px solid ${props => props.theme.colors.main};
    color: #fff;
  }
`

export const BtnCancel = styled(Btn)`
    border:none;
    width:fit-content;
    padding: 1em 3em;
    color: ${props => props.theme.colors.main};
    &:hover {
        border:none;
        background-color: ${props => props.theme.colors.text};

    }
    ${mobile} {
        padding: 1em 1.5em;
    }
`
import React from "react";

import "moment/locale/es";
import { MdAttachMoney, MdBusiness } from "react-icons/md";

import {
  Container,
  Card,
  Title,
  Subtitle,
  Text,
  TextRequest,
  FigureCircle,
} from "./styles";

import {
  getProfile,
  getSubscriptionBilling,
  getSubscriptionBillingUserValidation,
} from "../../../../api/requests";

export const ModalProfileSubscription = (props) => {
  const [subscriptionData, setSubscriptionData] = React.useState({
    subscription_name: "Subscription_Name",
    final_date: "2022-01-01",
  });
  const [loading, setLoading] = React.useState(true);

  async function subscriptionInfo() {
    await getProfile().then(async (response) => {
      await getSubscriptionBillingUserValidation().then((data) => {
        if (data.is_active) {
          setSubscriptionData({
            subscription_name: data.subscription.subscription.name,
            final_date: data.subscription.final_date,
          });
        }
      });
    });
  }

  React.useEffect(() => {
    async function subscriptionD() {
      setLoading(true);
      await subscriptionInfo();
      setLoading(false);
    }
    subscriptionD();
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Container
            className={props.activeModal === true ? " activate" : ""}
            onClick={props.click}
          >
            <Card>
              <div>
                <FigureCircle color="third">
                  {" "}
                  {props.icon === "bulling" ? (
                    <MdAttachMoney size="2.5rem" color="#0A2733">
                      {" "}
                    </MdAttachMoney>
                  ) : (
                    <MdBusiness size="2.5rem"> </MdBusiness>
                  )}{" "}
                </FigureCircle>{" "}
                <Title> Detalle del Plan </Title> <Subtitle> Premium </Subtitle>{" "}
              </div>{" "}
              <div>
                <Text> Tiempo de suscripción: </Text>{" "}
                <TextRequest>
                  {" "}
                  {subscriptionData.subscription_name}{" "}
                </TextRequest>{" "}
                <Text> Subscripción valida hasta: </Text>{" "}
                <TextRequest> {subscriptionData.final_date} </TextRequest>{" "}
              </div>{" "}
            </Card>{" "}
          </Container>
        </>
      )}
    </>
  );
};

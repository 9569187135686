import React, { useEffect, useState } from "react";
import {
  getProfile,
  getSubscriptionBilling,
  getSubscriptionBillingUserValidation,
} from "../../api/requests";
import { NavBar } from "../../components/atoms/NavBar";
import { RemainingDaysBadge } from "../../components/atoms/RemainingDaysBadge";

export const LayoutApp = ({ children, initial }) => {
  const [initialInfo, setInitialInfo] = useState(
    sessionStorage.getItem("initial_info") === "true"
  );
  const [suscripcionActiva, setSuscripcionActiva] = useState(false);
  const [suscripcionInfo, setSuscripcionInfo] = useState({});

  useEffect(() => {
    setInitialInfo(sessionStorage.getItem("initial_info") === "true");
  }, [initialInfo]);

  useEffect(() => {
    async function resultado() {
      await activeSubscription();
    }
    resultado();
  }, []);

  async function activeSubscription() {
    try {
      return await getProfile().then(async (profile) => {
        return await getSubscriptionBillingUserValidation().then((data) => {
          setSuscripcionInfo(data.subscription);

          setSuscripcionActiva(data.is_active);
        });
      });
    } catch (error) {}
  }

  return (
    <>
      {/* 
        Si las vistas tienen el flag "initial" quiere decir que serán afectadas por la variable 
        initial_info, y estas tienen un tratamiento diferente ya que, el navbar puede mostrarse
        o no. Las vistas que no tienen este flag, tendrán el navbar siempre visible.
      */}
      {initial ? initialInfo && <NavBar /> : suscripcionActiva && <NavBar />}
      {children}
      {suscripcionInfo?.id && (
        <RemainingDaysBadge suscripcionInfo={suscripcionInfo} />
      )}
    </>
  );
};

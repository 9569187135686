import React from 'react';
import { MdEmail, MdHome, MdLocationOn, MdPhone, MdPerson } from 'react-icons/md';
import {TextCard, Card, Row, Column} from './styles.js'

export const ProfileDetail = ({data}) => {

    return ( 
        <Card>
            <Row>
                <Column lg={10} sm={20} xs={20} align="center">
                    <MdPerson size='1.5rem' />
                </Column>
                <Column lg={90} sm={80} xs={80}>
                    <TextCard bold>{data.user.first_name} {data.user.last_name}</TextCard>
                </Column>
            </Row>
            <Row>
                <Column lg={10} sm={20} xs={20} align="center">
                    <MdEmail size='1.5rem' />
                </Column>
                <Column lg={90} sm={80} xs={80}>
                    <TextCard>{data.user.email}</TextCard>
                </Column>
            </Row>
            <Row>
                <Column lg={10} sm={20} xs={20} align="center">
                    <MdPhone size='1.5rem' />
                </Column>
                <Column lg={90} sm={80} xs={80}>
                    <TextCard>{data.cellphone}</TextCard>
                </Column>
            </Row>
            <Row>
                <Column lg={10} sm={20} xs={20} align="center">
                    <MdPhone size='1.5rem' />
                </Column>
                <Column lg={90} sm={80} xs={80}>
                    <TextCard>{data.phone}</TextCard>
                </Column>
            </Row>
            <Row>
                <Column lg={10} sm={20} xs={20} align="center">
                    <MdHome size='1.5rem' />
                </Column>
                <Column lg={90} sm={80} xs={80}>
                    <TextCard>{data.address}</TextCard>
                </Column>
            </Row>
            <Row>
                <Column lg={10} sm={20} xs={20} align="center">
                    <MdLocationOn size='1.5rem' />
                </Column>
                <Column lg={90} sm={80} xs={80}>
                    <TextCard>{data.location}</TextCard>
                </Column>
            </Row>
        </Card>
    )
}

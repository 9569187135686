import React from 'react';
import {Container, Section, SectionList} from './styles';

import { MdAttachMoney } from "react-icons/md";
import {TitleAndInfo} from '../../../../components/molecules/TitleAndInfo';
import {BtnNewCard} from '../../../../components/atoms/cards/BtnNewCard';

import { getIncomes, getIncomesExport } from '../../../../api/requests';

import { CardBudget } from '../../../../components/atoms/cards/CardBudget';
import { CardInitialInfo } from '../../../../components/atoms/cards/CardInitialInfo';
import { CardTotal } from '../../../../components/atoms/cards/CardTotal';
import { ModalOptions } from '../../../../components/molecules/Modals/ModalOptions';
import { DataUtil } from '../../../../utils/data';

export const MyIncome = () => {

    const [incomeData, setIncomeData] = React.useState(
        {
            bank: 0,
            budget: 0,
            cash: 0,
            credit: 0,
            date: "",
            difference: 0,
            real: 0,
            incomeList: []
        }
    )
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])

    React.useEffect(() => {
        async function fetchData() {
            await getIncome(true);
        }
        fetchData();

        let array = []
        array.push(DataUtil.getData('cash'))
        array.push(DataUtil.getData('bank'))
        array.push(DataUtil.getData('credit'))
        setOptions(array)

    }, [])

    async function getIncome(summary) {
        const response = await getIncomes({summary: summary})
        if (response.status === 200) {
            let resp = response.data;
            resp.incomeList = [
                {title: 'Efectivo', value: resp.cash, code: 'cash'},
                {title: 'Bancos', value: resp.bank, code: 'bank'},
                {
                    title: 'Créditos (cuentas por cobrar)',
                    value: resp.credit,
                    code: 'credit',
                    fontColor: 'rojo',
                    iconColor: 'rojo'
                }
            ]
            setIncomeData(resp);
        }
    }

    let handleOpenOptions = (event) => {
        setOpen(event)
    }

    async function handleDownload() {
        const response = await getIncomesExport()

        if (response.status === 200) {
            let file = new File([response.data], '', { type: response.headers["Content-Type"] });
            let url = window.URL.createObjectURL(file);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Reporte - Mis Ingresos.xlsx';
            a.click();
        }
    }

    return (
        <Container>
            <Section>
                <TitleAndInfo
                    btnPrev = {false}
                    titlePage='Mis ingresos'
                    displayDate={incomeData.date}
                    displayDownload={true}
                    download={handleDownload}
                    dateColor="dark"
                    tooltip="income"
                />
                <CardBudget
                    path = "#"
                    first={{title: "Planeado", value: incomeData.budget}}
                    second={{title: "Real", value: incomeData.real}}
                />
                <CardTotal
                    data={{title: "Diferencia:", value: incomeData.difference}}
                    titleColor="dark"
                    valueColor="text"
                />
                <BtnNewCard
                    title='Agregar nuevo ingreso'
                    path={'my-income'}
                    isModal={true}
                    click={e => handleOpenOptions(true)}
                />
                <ModalOptions
                    activeModal={open}
                    options={options}
                    title="Nuevo ingreso"
                    text="Seleccione el tipo de ingreso a registrar"
                    click={e => handleOpenOptions(false)}
                />
            </Section>
            <SectionList>
                {incomeData.incomeList.map((row, index) => (
                    <CardInitialInfo
                        key={index}
                        title={row.title}
                        value={row.value}
                        icon={<MdAttachMoney size='2rem' />}
                        color={row.iconColor}
                        fontColor={row.fontColor}
                        path={DataUtil.getData(row.code).prev_route}
                    />
                ))}
            </SectionList>
        </Container>
    )
}

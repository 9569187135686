import styled from 'styled-components';

// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const SectionListEmpty = styled.section`
    flex:1;
    padding: 1rem 2rem 2rem;
    justify-content:center;
    align-items:center;
    
    width:800px;
    height:fit-content;
    background: ${props => props.theme.colors.light};
    display:flex;
    flex-direction:column;
    
    ${mobile} {
        width:100%;
    }
`

export const MsgEmpty = styled.h3`
    font-size:1.5rem;
    text-align:center;
    color: ${props => props.theme.colors.third};
`

export const Figure = styled.figure`
    color: ${props => props.theme.colors.third};
    margin-bottom:1rem;
    margin-top:3rem;
`
import React from 'react';
import { MdAttachMoney, MdBusiness } from 'react-icons/md';
import {Container, Card, Title, Text, ButtonLink, FigureCircle} from './styles';

export const ModalOptions = (props) => {

  return (
    <Container className={props.activeModal === true ? " activate" : ""} onClick={props.click}>
      <Card>
        <FigureCircle color="third">
          {props.icon === 'business' ?
            <MdBusiness size="2.5rem" color="#0A2733"/>
          :
            <MdAttachMoney size='2.5rem'></MdAttachMoney>
          }
        </FigureCircle>
        
        <Title>{props.title}</Title>
        <Text>{props.text}</Text>

        {props.options.map((row, i) => {
          return (
            <ButtonLink 
              key={i}
              to={{ pathname: row.route }}
              name="Aceptar"
            >
              {row.title}
            </ButtonLink>
          )
        })}
      </Card>
    </Container>
  );
};
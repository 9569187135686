import React, { useEffect, useState } from "react"
import { FormBudgetExpense } from "../../components/organisms/forms/myInfo/FormBudgetExpense"
import { getListFinancialCategoriesLiabilities } from "../../api/requests"

export const FormBudgetExpenseContainer = (props) => {
  const initialForm = {
    // month:'',
    // year:'',
    date: "",
    value: "",
    financial_mov: "GASTO",
    category: "",
  }

  const validationsForm = (form) => {
    let errors = {}
    let regexNumbers = /^[0-9]+$/
    let regexDateYearMonth = /^[0-9]+[/-]+[0-9]+$/
    // let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    // let regexComments = /^.{1,255}$/;
    // let regexNumbersSpace = /^[\d]+$/;
    // let regexAlphaNumbers = /^[0-9a-zA-Z]+$/;
    // let regexYearMonth = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/-](?:0?[1-9]|1[0-2])|(?:29|30)[/-](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/-](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/-]0?2[/-](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;

    if (!form.date.trim()) {
      errors.date = "El campo Fecha es requerido."
    } else if (!regexDateYearMonth.test(form.date.trim())) {
      errors.date = "En este campo debe seleccioanr una fecha correcta."
    }

    if (!form.value.trim()) {
      errors.value = "El campo Valor es requerido."
    } else if (!regexNumbers.test(form.value.trim())) {
      errors.value =
        "El campo Valor solo acepta números, sin puntaciones, ni espacios."
    }

    if (!form.category.trim()) {
      errors.category = "El campo Categoría es requerido."
    } else if (!regexNumbers.test(form.value.trim())) {
      errors.category = "La Categoría seleccionada no ha sido reconocida."
    }

    return errors
  }

  const [listFinancialCategories, setListFinancialCategories] = useState([])

  useEffect(() => {
    async function loadListFinancialCategories() {
      const response = await getListFinancialCategoriesLiabilities(null, true)
      if (response.status === 200) {
        setListFinancialCategories(response.data)
      }
    }

    loadListFinancialCategories()
  }, [])

  return (
    <FormBudgetExpense
      initialForm={initialForm}
      validationsForm={validationsForm}
      listFinancialCategories={listFinancialCategories}
    />
  )
}

import React, { Fragment } from 'react';

import {Container, Card, Title, Text, TextError, Button, ButtonLink} from './styles';

export const Modal = (props) => {

  return (
    <Container className={props.activeModal === true ? " activate" : ""}>
      <Card>
        <figure>
          <img src={props.is_error === true ? props.iconError : props.iconSuccess} alt=""/>
        </figure>
        <Title>{props.title}</Title>

        {props.isArray ?
          <Fragment>
            {props.text.map((row, index) => (
              <Fragment key={index}>
                <TextError><span style={{fontWeight: 'bold'}}>{row.name}</span>{row.error}</TextError>
              </Fragment>
            ))}
          </Fragment>
          :
          <Fragment>
            <Text>{props.text}</Text>
          </Fragment>
        }
        {props.isLink ?
          <ButtonLink 
            to={'#'}
            name="Aceptar"
            primary="true"
            onClick={props.handleClosed}
          >
            {props.btn}
          </ButtonLink>
          :
          <Button 
            onClick={props.handleClosed}
            name="Aceptar"
            primary="true"
            className={props.boton === true ? " success" : ""}
          >
            {props.btn}
          </Button>
        }
      </Card>
    </Container>
  );
};

import axios from "axios"

//const baseUrl = process.env.REACT_APP_BASE_URL
import { Routes } from "../routes"
import { Auth } from "../utils/auth"

export async function signup(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_sign_up}`,
      method: "POST",
      data: requestData,
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function login(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_login}`,
      method: "POST",
      data: requestData,
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function resetPassword(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_reset_password}`,
      method: "POST",
      data: requestData,
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function recoverPassword(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base}/api/reset/`,
      method: "PUT",
      data: requestData,
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getEconomicActivity() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_economic_activities}`,
      method: "GET",
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getListDocuments() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_documents_list}`,
      method: "GET",
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getListStates() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_cities_list}`,
      method: "GET",
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getListCities(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_cities_list + id + "/cities"}`,
      method: "GET",
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getProfile() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_view_user_profile}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getProfilePublic(userId) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_view_user_profile
      }public/${userId}`,
      method: "GET",
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function patchProfile(data) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_view_user_profile}`,
      method: "PATCH",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      "Content-Type": `multipart/form-data;`,
      data: data,
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getListFinancialCategoriesTotals() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_info}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getListFinancialCategoriesAssets(
  initial_info,
  budget_income
) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_financial_categories +
        "?applies_to=ACTIVOS"
      }`,
      method: "GET",
      params: {
        initial_info: initial_info,
        budget_income: budget_income,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getListFinancialCategoriesLiabilities(
  initial_info,
  budget_expenses
) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_financial_categories +
        "?applies_to=PASIVOS"
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        initial_info: initial_info,
        budget_expenses: budget_expenses,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function createFinancialInfo(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_info}`,
      method: "POST",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getAssets() {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_financial_info + "?type=ACTIVOS"
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getAssetItem(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_info + id + "/"}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getReminderOptions() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_reminder_options}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getFinancialCategoriesByParam({
  my_shopping,
  my_expenses,
}) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_categories}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        my_shopping,
        my_expenses,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getLiabilities() {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_financial_info + "?type=PASIVOS"
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getLiabilityItem(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_info + id + "/"}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function deleteFinancialInfoItem(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_info + id + "/"}`,
      method: "DELETE",
      //data: id,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function editFinancialInfoItem(requestData, id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_financial_info + id + "/"}`,
      method: "PATCH",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getBudgetIncome() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_budgets + "?type=INGRESO"}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getBudgetExpense() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_budgets + "?type=GASTO"}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function createBudgets(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_budgets}`,
      method: "POST",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function deleteBudgetItem(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_budgets + id + "/"}`,
      method: "DELETE",
      //data: id,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getBudgetItem(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_budgets + id + "/"}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function editBudgetItem(requestData, id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_budgets + id + "/"}`,
      method: "PATCH",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getInventories(category) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_inventories}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        category: category,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getInventoriesPublic(userId, category) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_inventories}public/${userId}`,
      method: "GET",
      params: {
        category: category,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function createInventories(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_inventories}`,
      method: "POST",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getInventoryItem(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_inventories + id + "/"}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function createStocks(requestData) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_inventories}`,
      method: "POST",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getLogs() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_logs}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function editInventoryItem(requestData, id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_inventories + id + "/"}`,
      method: "PATCH",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function deleteStockItem(id) {
  console.log("deleteStockItem funcion")
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_stock_deactivate + id + "/"}`,
      method: "DELETE",
      //data: id,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    console.log("response")
    console.log(response)
    return response
  } catch (e) {
    console.log(e)
  }
}

// Income
export async function getIncomes({ summary }) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        summary: summary,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getIncomesDetail(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_incomes_detail.replace(":id", id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function editIncomesDetail(id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_incomes_detail.replace(":id", id)
      }`,
      method: "PATCH",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deleteIncomesDetail(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_incomes_detail.replace(":id", id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postIncome(data) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes}`,
      method: "POST",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getIncomeDetail({
  payment_type,
  payment_method,
  payment,
}) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        payment_type: payment_type,
        payment_method: payment_method,
        payment: payment,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postIncomesInventories(data) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes_inventories}`,
      method: "POST",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getIncomesInventories() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes_inventories}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getIncomesPayments(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_incomes_payments.replace(":id", id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postIncomesPayments(id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_incomes_payments.replace(":id", id)
      }`,
      method: "POST",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getClient({ doc_type, doc_number, name }) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes_clients}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        doc_type: doc_type,
        doc_number: doc_number,
        name: name,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getIncomesExport() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes_export}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getPayment(income_id, payment_id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_incomes_payments_edit
          .replace(":income_id", income_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function editPayment(income_id, payment_id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_incomes_payments_edit
          .replace(":income_id", income_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "PATCH",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deletePayment(income_id, payment_id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_incomes_payments_edit
          .replace(":income_id", income_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getIncomePdf(id) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_incomes_pdf.replace(":id", id)}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

// Shopping

export async function getShoppings(summary) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_shoppings}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        summary,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getShoppingsByPayment({
  payment_type,
  payment_method,
  payment,
}) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_shoppings}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        payment_type: payment_type,
        payment_method: payment_method,
        payment: payment,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postShoppings(data) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_shoppings}`,
      method: "POST",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getShoppingsDetail(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_shoppings_detail.replace(":id", id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function editShopping(id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_shoppings_detail.replace(":id", id)
      }`,
      method: "PATCH",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deleteShopping(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_shoppings_detail.replace(":id", id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getShoppingsPaymentsList(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_shoppings_payments.replace(":id", id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postShoppingsPayments(id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_shoppings_payments.replace(":id", id)
      }`,
      method: "POST",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getShoppingsPayment(shopping_id, payment_id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_shoppings_payments_edit
          .replace(":shopping_id", shopping_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getProvider({ doc_type, doc_number, name }) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_shoppings_providers}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        doc_type: doc_type,
        doc_number: doc_number,
        name: name,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function editShoppingsPayment(shopping_id, payment_id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_shoppings_payments_edit
          .replace(":shopping_id", shopping_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "PATCH",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deleteShoppingsPayment(shopping_id, payment_id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_shoppings_payments_edit
          .replace(":shopping_id", shopping_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getShoppingsExport() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_shoppings_export}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

// Expenses

export async function getExpenses({
  summary,
  payment_type,
  payment_method,
  payment,
}) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_expenses}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        summary,
        payment_type,
        payment_method,
        payment,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postExpenses(data) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_expenses}`,
      method: "POST",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      data: data,
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getExpensesDetail(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_expenses_detail.replace(":id", id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function patchExpenses(id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_expenses_detail.replace(":id", id)
      }`,
      method: "PATCH",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deleteExpenses(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_expenses_detail.replace(":id", id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getExpensesPayment(expense_id, payment_id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_expenses_payments_edit
          .replace(":expense_id", expense_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function postExpensesPayment(id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_expenses_payments.replace(":id", id)
      }`,
      method: "POST",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      data: data,
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function patchExpensesPayment(expense_id, payment_id, data) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_expenses_payments_edit
          .replace(":expense_id", expense_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "PATCH",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      data: data,
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deleteExpensesPayment(expense_id, payment_id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base +
        Routes.api_expenses_payments_edit
          .replace(":expense_id", expense_id)
          .replace(":payment_id", payment_id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function putDiscardReminder(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_expenses_detail.replace(":id", id)
      }`,
      method: "PUT",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getNotifications() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_notifications}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function deleteNotifications(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_notifications_detail.replace(":id", id)
      }`,
      method: "DELETE",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getExpensesExport() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_expenses_export}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

// Reports

export async function getReports({ report, from_date, to_date, type }) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + `/api/reports/${type}`}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        report,
        from_date,
        to_date,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}
export async function getExcelReports({
  report,
  from_date,
  to_date,
  type,
  product_id,
}) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + `/api/reports/${type}`}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        report,
        from_date,
        to_date,
        product_id,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}

// Graphics

export async function getGraphic({ from_date, to_date, type }) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_charts}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
      params: {
        type,
        from_date,
        to_date,
      },
    })
    return response
  } catch (e) {
    return e.response
  }
}

// Subscriptions

export async function getSubscriptionPlans() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_subscriptions}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    console.log(e)
    return e.response
  }
}

export async function postSubscriptionBilling(data) {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_subscriptions_billing}`,
      method: "POST",
      data: data,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response
  } catch (e) {
    return e.response
  }
}

export async function getSubscriptionBilling() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_subscriptions_billing}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response.data
  } catch (e) {
    return e.response
  }
}

export async function getSubscriptionBillingUserValidation() {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_subscriptions_billing_user_validated
      }`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response.data
  } catch (e) {
    return e.response
  }
}

export async function subscriptionValidation(requestData, id) {
  console.log("subscriptionValue request")
  console.log(requestData)
  try {
    console.log("requestData")
    console.log(requestData)
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_subscriptions_billing + id + "/"
      }`,
      method: "PATCH",
      data: requestData,
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    console.log("response")
    console.log(response)
    return response
  } catch (e) {
    console.log("error")
    console.log(e)
    return e.response
  }
}

export async function getWompiEvent() {
  try {
    const response = await axios({
      url: `${Routes.api_url_base + Routes.api_wompi_transaction_event}`,
      method: "GET",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response.data
  } catch (e) {
    return e.response
  }
}

export async function setSubsciptionActive(id) {
  try {
    const response = await axios({
      url: `${
        Routes.api_url_base + Routes.api_subscriptions_billing + id + "/"
      }`,
      method: "PUT",
      headers: { Authorization: `Token ${Auth.tokenAuth()}` },
    })
    return response.data
  } catch (e) {
    return e.response
  }
}

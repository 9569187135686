import React, { Fragment } from "react"
import { Bar, Line, Doughnut } from "react-chartjs-2"
import { Container, Section, CardValue } from "./styles"

export const GraphicData = ({
  graphicType,
  type,
  title,
  data,
  dataType,
  legend,
}) => {
  const [dataSend, setDataSend] = React.useState({ labels: [], datasets: [] })

  const colors = {
    backgroundColor: [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
    ],
    borderColor: [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ],
  }

  let options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: legend,
        labels: {
          generateLabels: function (data) {
            let array = []

            let lab = data.config.data.labels
            if (dataType === "perc") {
              let dat = data.config.data.datasets[0]
              // eslint-disable-next-line array-callback-return
              lab.map((obj, index) => {
                array.push({
                  text: `${obj} - ${dat.data[index]}%`,
                  fillStyle: dat.backgroundColor[index],
                  strokeStyle: dat.borderColor[index],
                })
              })
            } else {
              let dat = data.config.data.datasets
              // eslint-disable-next-line array-callback-return
              dat.map((obj, index) => {
                let name = ""
                if (!index) name = obj.label + " ejecutados"
                else name = dat[0].label + " proyectados"

                array.push({
                  text: name,
                  fillStyle: obj.backgroundColor[index],
                  strokeStyle: obj.borderColor[index],
                })
              })
            }

            return array
          },
        },
      },
    },
    scales: {
      y: {
        display: graphicType !== "doughnut",
        ticks: {
          callback: function (value) {
            if (dataType === "coin") {
              if (value.toString().length > 3 && value.toString().length < 7)
                return `$${value / 1000}k`
              else if (value.toString().length >= 7)
                return `$${value / 1000000}m`
              else return `$${value}`
            }
          },
        },
      },
    },
  }

  React.useEffect(() => {
    if (type === "income_budget" || type === "expense_budget") {
      // eslint-disable-next-line array-callback-return
      data.datasets.map((obj) => {
        obj.backgroundColor = colors.backgroundColor
        obj.borderColor = colors.borderColor
        obj.borderWidth = 1
      })
      setDataSend({
        labels: data.labels,
        datasets: data.datasets,
      })
    } else {
      let obj = [
        {
          data: data.datasets,
          backgroundColor: colors.backgroundColor,
          borderColor: colors.borderColor,
        },
      ]
      setDataSend({
        labels: data.labels,
        datasets: obj,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        {graphicType === "bar" && <Bar data={dataSend} options={options} />}
        {graphicType === "line" && <Line data={dataSend} options={options} />}
        {graphicType === "doughnut" && (
          <Doughnut data={dataSend} width={10} height={10} options={options} />
        )}
      </Section>
      <Section left>
        {graphicType !== "doughnut" && (
          <Fragment>
            <CardValue>* Convenciones:</CardValue>
            <CardValue space>m: millones</CardValue>
            <CardValue space>k: miles</CardValue>
          </Fragment>
        )}
      </Section>
    </Container>
  )
}

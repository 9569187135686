import React from "react"
import { FormInitialStocks } from "../../components/organisms/forms/myInfo/FormInitialStocks"
import { useLocation } from "react-router-dom"

export const FormInitialStocksContainer = (props) => {
  const location = useLocation()
  const captureId = location.pathname.toString().split("/")
  const idInventory = captureId[2]

  const initialForm = {
    inventory_id: idInventory,
    cost_value: "",
    sale_value: "",
    quantity: "0",
    is_initial: false,
    is_active: true,
  }

  const validationsForm = (form) => {
    let errors = {}
    let regexNumbers = /^[0-9]+$/
    // let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    // let regexComments = /^.{1,255}$/;
    // let regexNumbersSpace = /^[\d]+$/;
    // let regexAlphaNumbers = /^[0-9a-zA-ZÑñÁáÉéÍíÓóÚúÜü\s]+$/;

    if (!form.cost_value.trim()) {
      errors.cost_value = "Este campo es requerido."
    } else if (!regexNumbers.test(form.cost_value.trim())) {
      errors.cost_value = "Solo se aceptan números, sin puntos, ni espacios."
    }

    if (!form.sale_value.trim()) {
      errors.sale_value = "Este campo es requerido."
    } else if (!regexNumbers.test(form.sale_value.trim())) {
      errors.sale_value = "Solo se aceptan números, sin puntos, ni espacios."
    }

    // if(!form.quantity.trim()){
    //     errors.quantity = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.quantity.trim())) {
    //     errors.quantity = 'Solo se aceptan números, sin puntos, ni espacios.'
    // }

    // if(!form.is_active.trim()){
    //     errors.is_active = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.is_active.trim())) {
    //     errors.is_active = 'Se debe seleccionar una opción.'
    // }

    // if(!form.is_initial.trim()){
    //     errors.is_initial = 'Este campo es requerido.'
    // } else if(!regexNumbers.test(form.is_initial.trim())) {
    //     errors.is_initial = 'Se debe seleccionar una opción.'
    // }

    return errors
  }

  return (
    <FormInitialStocks
      initialForm={initialForm}
      validationsForm={validationsForm}
      categorySelected={props.category}
      editId={props.editId}
    />
  )
}

import React, { useEffect, useState } from "react"
import { CardForm } from "./styles"
import { Modal } from "../../../../molecules/Modals/Modal"
import {
  getReminderOptions,
  getFinancialCategoriesByParam,
} from "../../../../../api/requests"
import { useParams, useHistory } from "react-router-dom"
import { DataUtil } from "../../../../../utils/data"
import { CustomDate } from "../../../../../utils/date"
import { BillingInformation } from "../../general/BillingInformation"
import { ExpenseInformation } from "../../general/ExpenseInformation"

export const FormExpensesSteps = (props) => {
  const history = useHistory()
  const { type, id, payment_id } = useParams()
  const [cancel, setCancel] = useState("")
  const [isPayment, setIsPayment] = useState(false)
  const [expense, setExpense] = useState({})

  const [alertModal, setAlertModal] = useState(false)

  const [reminderOptions, setReminderOptions] = useState([])
  const [financialCategories, setFinancialCategories] = useState([])

  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })

  useEffect(() => {
    if (type === "payment") setIsPayment(true)

    setCancel(DataUtil.getExpense(type, id).prev_route)
    setExpense(DataUtil.getExpense(type, id, payment_id))

    async function fetchData() {
      await getReminderOption()
      await getFinancialCategories()
    }

    fetchData()

    // eslint-disable-next-line
  }, [])

  async function getReminderOption() {
    const response = await getReminderOptions()
    if (response.status === 200) {
      setReminderOptions(response.data)
      // eslint-disable-next-line
      props.setForm({ ...props.form, ["reminder"]: response.data[0] })
    }
  }

  async function getFinancialCategories() {
    const response = await getFinancialCategoriesByParam({ my_expenses: true })
    if (response.status === 200) {
      setFinancialCategories(response.data)
    }
  }

  const handleCloseAlertModal = () => {
    setAlertModal(false)
  }

  async function handleChange(event) {
    let e = event.target
    props.setForm({ ...props.form, [e.name]: e.value })
  }

  const handleFocus = (event) => event.target.select()

  async function _handleSubmit(event, register) {
    if (!expense.is_payment) {
      if (!props.form.description) {
        showAlert(event, "Debe indicar la descripción del gasto")
        return
      } else if (!props.form.value) {
        showAlert(event, "Debe indicar el valor del gasto")
        return
      } else if (!props.form.category_id) {
        showAlert(event, "Debe seleccionar una categoría financiera")
        return
      } else if (!props.form.date) {
        showAlert(event, "Debe indicar la fecha")
        return
      } else if (!props.form.reminder) {
        showAlert(event, "Debe seleccionar un valor para el campo Recordar")
        return
      } else if (parseFloat(props.form.payment)) {
        if (!props.form.payment_method) {
          showAlert(event, "Debe seleccionar el método de pago")
          return
        }
      }
    } else {
      // eslint-disable-next-line
      props.setForm({ ...props.form, ["is_payment"]: true })

      if (!props.form.date) {
        showAlert(event, "Debe seleccionar la fecha")
        return
      } else if (!parseInt(props.form.payment)) {
        showAlert(event, "Debe indicar el valor del abono")
        return
      } else if (!props.form.payment_method) {
        showAlert(event, "Debe seleccionar el método de pago")
        return
      }
    }

    if (register) {
      props.handleSubmit(props.form)
    } else nextStep()
  }

  const showAlert = (event, text) => {
    event.preventDefault()
    setAlertModal(true)
    setModalData({
      title: "¡Alerta!",
      text: text,
      btn: "Aceptar",
    })
    return
  }

  const nextStep = () => {
    props.setFormStep((cur) => cur + 1)
  }

  const backStep = () => {
    if (expense.is_payment) history.push(cancel)
    else props.setFormStep((cur) => cur - 1)
  }

  return (
    <>
      <CardForm onSubmit={_handleSubmit}>
        {props.formStep === 0 && (
          <ExpenseInformation
            form={props.form}
            type={type}
            payment={expense}
            reminderOptions={reminderOptions}
            financialCategories={financialCategories}
            cancel={cancel}
            handleChange={handleChange}
            handleFocus={handleFocus}
            handleSubmit={_handleSubmit}
          />
        )}
        {props.formStep === 2 && (
          <BillingInformation
            form={props.form}
            type={type}
            payment={expense}
            isPayment={isPayment}
            date={CustomDate.date1(props.form.date)}
            handleChange={handleChange}
            handleFocus={handleFocus}
            backStep={backStep}
            handleSubmit={_handleSubmit}
          />
        )}
      </CardForm>
      <Modal
        activeModal={alertModal}
        handleClosed={handleCloseAlertModal}
        title={modalData.title}
        text={modalData.text}
        errors={modalData.errors}
        btn={modalData.btn}
      />
    </>
  )
}

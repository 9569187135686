import React from 'react'
import { MdInfo } from 'react-icons/md'
import {Container, TitleCard, ElementsContainer} from './styles.js'

export const CardEmpty = ({message, top}) => {
    return (
        <>
            <Container>
                <ElementsContainer top={top}>
                    <MdInfo size="2rem" color="#899DBE"></MdInfo>
                    <TitleCard>{message ? message : 'No hay información disponible'}</TitleCard>
                </ElementsContainer>
            </Container>
        </>
    )
}

import styled from 'styled-components'

export const Container = styled.div`
    width:100%;
    height:100px;
    text-align:center;
    margin:auto;
    vertical-align: middle;
`

export const ElementsContainer = styled.div`
    width:100%;
    height:100%;
    text-align:center;
    margin-top: ${props => props.top ? props.top : '50px'};
    vertical-align: middle;
`

export const TitleCard = styled.h3`
    color: ${props => props.theme.colors.text};
    width:100%;
    height:100%;
    font-size: 20px;
`




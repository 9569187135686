import React, { useEffect } from "react"
import { FormContainer } from "./styles"
import { FormIncomeSteps } from "../FormIncomeSteps"
import {
  editIncomesDetail,
  editPayment,
  getClient,
  getIncomesDetail,
  getPayment,
  postIncome,
  postIncomesPayments,
} from "../../../../../api/requests"
import { useHistory, useParams } from "react-router-dom"
import { DataUtil } from "../../../../../utils/data"
import { CustomDate } from "../../../../../utils/date"
import { Modal } from "../../../../molecules/Modals/Modal"
import { Utilities } from "../../../../../utils/utilities"

export const FormIncome = (props) => {
  const history = useHistory()
  const { type, id, payment_id } = useParams()
  const [form, setForm] = React.useState({
    category: "",
    selected_categories: [],
    details: [],
    amount: 1,
    date: CustomDate.date4(),
    payment_type: "",
    payment_method: "",
    payment: "",
    observations: "",
    // client
    client_id: null,
    name: "",
    doc_number: "",
    doc_type: "",
    email: "",
    city: "",
    state: "",
    address: "",
    cellphone: "",
    phone: "",
    is_payment: false,
    value: 0,
    total: 0,
  })

  const [clients, setClients] = React.useState([])
  const [activeModal, setActiveModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })

  useEffect(() => {
    let pay = DataUtil.getData(type)

    setForm({
      ...form,
      payment_method: pay.payment_method,
      payment_type: pay.payment_type,
    })

    if (id) {
      async function fetchData() {
        if (pay.is_payment && payment_id) await getPaymentDetail(id, payment_id)
        else if (!pay.is_payment) await getDetail(id)
      }
      fetchData()
    }

    // eslint-disable-next-line
  }, [])

  async function getClientData(doc_type, doc_number, name) {
    let data = {}
    if (name) data.name = name
    else {
      data.doc_type = doc_type
      data.doc_number = doc_number
    }

    const response = await getClient(data)

    if (response.status === 200) {
      let resp = response.data

      if (name) {
        setClients(resp)
        return
      }

      if (!resp.length) {
        showMessage(
          "No encontrado",
          `El cliente de documento ${doc_number} no se encuentra registrado`,
          true
        )
        setForm({
          ...form,
          // eslint-disable-next-line
          ["name"]: "",
          // eslint-disable-next-line
          ["email"]: "",
          // eslint-disable-next-line
          ["phone"]: "",
          // eslint-disable-next-line
          ["cellphone"]: "",
          // eslint-disable-next-line
          ["address"]: "",
          // eslint-disable-next-line
          ["city"]: "",
          // eslint-disable-next-line
          ["state"]: "",
        })
        return
      }

      if (resp[0]) {
        setForm({
          ...form,
          // eslint-disable-next-line
          ["client_id"]: resp[0].id,
          // eslint-disable-next-line
          ["name"]: resp[0],
          // eslint-disable-next-line
          ["doc_type"]: resp[0].doc_type.id,
          // eslint-disable-next-line
          ["doc_number"]: resp[0].doc_number,
          // eslint-disable-next-line
          ["email"]: resp[0].email,
          // eslint-disable-next-line
          ["phone"]: resp[0].phone,
          // eslint-disable-next-line
          ["cellphone"]: resp[0].cellphone,
          // eslint-disable-next-line
          ["address"]: resp[0].address,
          // eslint-disable-next-line
          ["city"]: resp[0].city ? resp[0].city.id : "",
          // eslint-disable-next-line
          ["state"]: resp[0].city ? resp[0].city.state.id : "",
        })
      }
    }
  }

  async function getDetail(id_detail) {
    const response = await getIncomesDetail(id_detail)

    if (response.status === 200) {
      let resp = response.data

      // eslint-disable-next-line
      resp.details.map((obj) => {
        obj.cost_value = obj.value
        obj.amount = obj.quantity
        obj.name = obj.inventory
        obj.stock_id = obj.stock
      })
      resp.doc_number = resp.client.doc_number
      resp.doc_type = resp.client.doc_type.id
      resp.name = resp.client
      resp.address = resp.client.address
      resp.phone = resp.client.phone
      resp.cellphone = resp.client.cellphone
      resp.email = resp.client.email
      resp.selected_categories = resp.details
      resp.total = resp.value
      resp.payment = id ? 0 : resp.payment
      resp.city = resp.client.city ? resp.client.city.id : null
      resp.state = resp.client.city ? resp.client.city.state.id : null
      resp.client_id = resp.client.id ? resp.client.id : null

      setForm(resp)
    }
  }

  async function getPaymentDetail(id_income, id_payment) {
    const response = await getPayment(id_income, id_payment)

    if (response.status === 200) {
      let resp = response.data
      resp.payment = resp.value
      setForm(resp)
    }
  }

  async function handleSubmit(data) {
    if (type !== "payment" && type !== "edit_payment") {
      let details = []

      // eslint-disable-next-line
      data.selected_categories.map((obj) => {
        details.push({
          stock: obj.stock_id,
          quantity: obj.amount,
          value: obj.cost_value,
        })
      })
      let data_send = {
        details: details,
        date: data.date,
        payment_type: data.payment_type,
        observations: data.observations,
        client: {
          doc_number: data.doc_number,
          doc_type: parseInt(data.doc_type),
          name: data.name ? data.name.name : data.client_name,
          address: data.address,
          phone: data.phone,
          cellphone: data.cellphone,
          email: data.email,
        },
      }
      if (data.payment_method) data_send.payment_method = data.payment_method
      if (parseInt(data.payment)) data_send.payment = data.payment
      if (data.city) data_send.client.city = parseInt(data.city)
      if (data.client_id) data_send.client.id = data.client_id

      if (id) await submitEditIncome(parseInt(id), data_send)
      else await submitIncome(data_send)
    } else {
      let data_send = {
        date: data.date,
        observations: data.observations,
        payment_method: data.payment_method,
        value: parseInt(data.payment),
      }

      if (payment_id)
        await submitEditPayment(parseInt(id), parseInt(payment_id), data_send)
      else await submitPayment(parseInt(id), data_send)
    }
  }

  async function submitIncome(data) {
    postIncome(data).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Ingreso registrado",
          "El registro del nuevo ingreso se ha realizado correctamente.",
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Registro fallido", array, true, true)
      } else {
        showMessage(
          "Registro fallido",
          "Ha ocurrido un error registrando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  async function submitEditIncome(id, data) {
    editIncomesDetail(id, data).then((response) => {
      if (response.status === 200) {
        showMessage(
          "Ingreso actualizado",
          `La actualización del ingreso ${id} se ha realizado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Actualización fallida", array, true, true)
      } else {
        showMessage(
          "Actualización fallida",
          "Ha ocurrido un error registrando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  async function submitPayment(id, data) {
    postIncomesPayments(id, data).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Abono creado",
          `El abono en ${data.payment_method} fue registrado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Abono no creado", array, true, true)
      } else {
        showMessage(
          "Abono no creado",
          `El abono en ${data.payment_method} no pudo ser registrado. Por favor, inténtelo de nuevo.`,
          true
        )
      }
    })
  }

  async function submitEditPayment(id_income, id_payment, data) {
    editPayment(id_income, id_payment, data).then((response) => {
      if (response.status === 200) {
        showMessage(
          "Abono actualizado",
          `El abono ${id_payment} fue actualizado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Actualización fallida", array, true, true)
      } else {
        showMessage(
          "Actualización fallida",
          "Ha ocurrido un error actualizando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const handleClosed = () => {
    setActiveModal(false)

    if (!modalData.is_error) {
      let path = "#"
      path = DataUtil.getData(type, id).prev_route

      history.push(path)
    }
  }

  return (
    <>
      <FormContainer>
        <FormIncomeSteps
          formStep={props.formStep}
          clients={clients}
          form={form}
          setForm={setForm}
          setFormStep={props.setFormStep}
          handleSubmit={handleSubmit}
          handleClient={getClientData}
        />
      </FormContainer>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </>
  )
}

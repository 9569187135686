import React from 'react';
import {Container, Section} from './styles';

import {TitleAndInfo} from '../../../../components/molecules/TitleAndInfo';
import { FormInventoriesContainer } from '../../../../containers/FormInventoriesContainer';

import {Routes} from '../../../../routes'
import { useParams } from 'react-router-dom';


export const InventoryForm = () => {

    const {type} = useParams();

    return (
        <Container>
            <Section>
                <TitleAndInfo 
                    btnPrev
                    pathPrev = {type === 'initial' ? Routes.app_initial_inventory : Routes.app_my_inventory}
                    titlePage='Nuevo inventario'
                    darktheme='true'
                    tooltip="inventory"
                />
                <FormInventoriesContainer />
            </Section>
        </Container>
    )
}

import React, {Fragment} from 'react'
import {CardGradient, Divider, HalfCard, Card, TitleCard, ValueCard} from './styles.js'

export const CardBudget = ({path, first, second, cardColor}) => {
    return (
        <>
            <CardGradient to={path} bg={cardColor}>
                {second ?
                    <Fragment>
                        <HalfCard>
                            <TitleCard>{first.title}</TitleCard>
                            <ValueCard>{Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(first.value)}</ValueCard>
                        </HalfCard>
                        <Divider></Divider>
                        <HalfCard>
                            <TitleCard>{second.title}</TitleCard>
                            <ValueCard>{Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(second.value)}</ValueCard>
                        </HalfCard>
                    </Fragment>
                :
                    <Card>
                        <TitleCard>{first.title}</TitleCard>
                        <ValueCard>{Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(first.value)}</ValueCard> 
                    </Card>
                }
            </CardGradient>
        </>
    )
}

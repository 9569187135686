import React from 'react';
import {SpinnerLoader} from '../../../atoms/SpinnerLoader'

// import {Btn} from '../../atoms/Btn';

import {Container, Card, ContainerSpinner, Title} from './styles';

export const ModalLoading = (props) => {

  return (
    <Container className={props.activeModalLoading === true ? " activate" : ""}>
      <Card>
      <ContainerSpinner>
        <SpinnerLoader/>
      </ContainerSpinner>
        <Title>Cargando</Title>
      </Card>
    </Container>
  );
};
import React from 'react'
import {FigureCircle, Item, Title} from './styles.js'


export const CardReport = ({icon, bg, title, path}) => {

    return (
        <Item to={path}>
            <FigureCircle bg={bg}>
                {icon}
            </FigureCircle>
            <Title color={bg}>{title}</Title>
        </Item>
    )
}

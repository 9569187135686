import React from 'react';
import {SectionListEmpty, Figure, MsgEmpty} from './styles';


import { MdInfo } from "react-icons/md";


export const EmptyMessage = ({message}) => {
    return (
        <SectionListEmpty>
            <Figure><MdInfo size='2rem' /></Figure>
            <MsgEmpty>{message}</MsgEmpty>
        </SectionListEmpty>
    )
}

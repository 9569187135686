import styled from "styled-components"

const tabletStartWidth = 768
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
  background: ${(props) => props.theme.colors.third_clear};
  width: 100%;
  padding: 0 10px 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  -ms-overflow-style: none;
`
export const Section = styled.section`
  background: ${(props) => props.theme.colors.third_clear};
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 1.5rem;
  ${mobile} {
    width: 100%;
  }
`

export const SectionList = styled.div`
  background: ${(props) => props.theme.colors.light};
  height: ${(props) => (props.count ? "100%" : "")};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 2rem 2rem;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  padding-top: 1.4rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${mobile} {
    padding: 1rem;
  }
`

export const Line = styled.div`
  margin: 1.5em 2em 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.dark};
  ${mobile} {
    margin: 2em 2em 0;
  }
`

import React from "react"
import FinancialInfoReducer from "./FinancialInfoReducer"
import FinancialInfoContext from "./FinancialInfoContext"
import { getAssets as getAssetsRequest } from "../../api/requests"

const FinancialInfoState = (props) => {
  const initialState = {
    assets: [],
    seletedAsset: null,
  }
  const [state, dispatch] = React.useReducer(FinancialInfoReducer, initialState)

  async function getAssets() {
    const response = await getAssetsRequest()
    if (response.status === 200) {
      dispatch({
        type: "GET_ASSETS",
        payload: response.data.financial_info,
      })
    }
  }

  const createAssets = (data) => {
    dispatch({
      type: "CREATE_ASSETS",
      payload: data,
    })
  }

  return (
    <FinancialInfoContext.Provider
      value={{
        assets: state.assets,
        getAssets,
        createAssets,
      }}
    >
      {props.children}
    </FinancialInfoContext.Provider>
  )
}

export default FinancialInfoState

import { Redirect, Route } from "react-router-dom";
import { Auth } from "../utils/auth";
import {
  getProfile,
  getSubscriptionBilling,
  getSubscriptionBillingUserValidation,
} from "../api/requests";
import React from "react";

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  initial,
  ...rest
}) => {
  const user = Auth.getUser();
  const [loading, setLoading] = React.useState(true);
  const [suscripcionActiva, setSuscripcionActiva] = React.useState(false);
  const [urlSubscribeValidated, setUrlSubscribeValidated] =
    React.useState(false);

  React.useEffect(() => {
    async function resultado() {
      setLoading(true);
      await activeSubscription().then((val) => {
        setSuscripcionActiva(val);
        pathSubscribeValidated();
        setLoading(false);
      });
    }
    resultado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function pathSubscribeValidated() {
    let result = false;
    if (rest.path === "/subscription-validated") {
      result = true;
    } else {
      result = false;
    }
    setUrlSubscribeValidated(result);
    return result;
  }

  async function activeSubscription() {
    return getProfile().then(async (profile) => {
      try {
        return await getSubscriptionBillingUserValidation().then((data) => {
          setSuscripcionActiva(data.is_active);
          return data.is_active;
        });
      } catch (error) {}
    });
  }

  return (
    <Route {...rest}>
      {user != null ? (
        <>
          {!loading && (
            <>
              {suscripcionActiva ? (
                <>
                  {Layout != null ? (
                    <>
                      <Layout initial={initial}>
                        <Component />
                      </Layout>
                    </>
                  ) : (
                    <Component />
                  )}
                </>
              ) : (
                <>
                  {!urlSubscribeValidated ? (
                    <>
                      <Component />
                      <Redirect to="/subscribe-invitation" />
                    </>
                  ) : (
                    <>
                      {Layout != null ? (
                        <>
                          <Layout initial={initial}>
                            <Component />
                          </Layout>
                        </>
                      ) : (
                        <Component />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};

import React, { useEffect, useState } from "react"
import { Container, Section, SectionList } from "./styles"

import { MdInsertDriveFile } from "react-icons/md"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"

import { CardOption } from "../../../../components/atoms/cards/CardOption"
import { CardReport } from "../../../../components/atoms/cards/CardReport"
import { DataUtil } from "../../../../utils/data"
import { useHistory, useLocation } from "react-router"

export const MyReports = () => {
  const history = useHistory()
  const location = useLocation()
  const [reports, setReports] = useState([])
  const [button1, setButton1] = useState(true)
  const [button2, setButton2] = useState(false)

  useEffect(() => {
    if (location.pathname === "/my-reports/graphic") changeToGraphic()
    else changeToReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let changeToReport = (e) => {
    setButton1(true)
    setButton2(false)

    let dat = DataUtil.getReport(null)
    setReports(Object.values(dat))

    history.push("/my-reports/report")
  }

  let changeToGraphic = (e) => {
    setButton2(true)
    setButton1(false)

    let dat = DataUtil.getGraphic(null)
    setReports(Object.values(dat))

    history.push("/my-reports/graphic")
  }

  let handleClick = (e) => {
    if (e === 1) changeToReport()
    else changeToGraphic()
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev={false}
          titlePage="Mis estados financieros"
          displayDownload={false}
          dateColor="dark"
        />
        <CardOption
          title="Seleccione una de las siguientes opciones"
          button1={{
            title: "Mis informes",
            bg: "green_table",
            color: "white",
            status: button1,
            clickButton: handleClick,
          }}
          button2={{
            title: "Mis gráficas",
            bg: "white",
            color: "text",
            status: button2,
            clickButton: handleClick,
          }}
        />
      </Section>
      <SectionList count={reports.length < 7}>
        {reports.map((row, index) => (
          <CardReport
            key={index}
            title={row.title}
            icon={<MdInsertDriveFile size="2rem" />}
            bg="green_table"
            path={
              button1
                ? `/my-reports/report/${row.code}`
                : `/my-reports/graphic/${row.code}`
            }
          />
        ))}
      </SectionList>
    </Container>
  )
}

import React from "react"
import { Container, Section, SectionList } from "./styles"

import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"

import { CardOption } from "../../../../components/atoms/cards/CardOption"
import { useParams } from "react-router"
import { DataUtil } from "../../../../utils/data"
import { BtnNewCard } from "../../../../components/atoms/cards/BtnNewCard"
import { getGraphic } from "../../../../api/requests"
import { Utilities } from "../../../../utils/utilities"
import { Modal } from "../../../../components/molecules/Modals/Modal"
import { CustomDate } from "../../../../utils/date"
import { GraphicData } from "../GraphicData"

export const GraphicDetail = () => {
  const { type } = useParams()
  const [activeModal, setActiveModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })
  const [form, setForm] = React.useState({
    from_date: CustomDate.date4("last_month"),
    to_date: CustomDate.date4(""),
    product: null,
  })
  const [graphic, setGraphic] = React.useState({
    labels: [],
    datasets: [],
  })

  async function getReport() {
    if (type === "sales_months" || type === "shopping_months") {
      let from_year = form.from_date.split("-")[0]
      let to_year = form.to_date.split("-")[0]

      if (from_year !== to_year) {
        showMessage(
          "Error",
          "El rango de fechas debe estar dentro del mismo año",
          true
        )
        setGraphic({ labels: [], datasets: [] })
        return
      }
    }

    const response = await getGraphic({
      from_date: form.from_date,
      to_date: form.to_date,
      type: DataUtil.getGraphic(type).type,
    })
    if (response.status === 200) {
      setGraphic({ labels: [], datasets: [] })
      setGraphic(response.data)
    } else if (response.status === 400) {
      let array = Utilities.returnErrors(response.data)
      showMessage("Reporte fallido", array, true, true)
    } else {
      showMessage(
        "Reporte fallido",
        "Ha ocurrido un problema generando el reporte. Por favor, inténtelo nuevamente.",
        true
      )
    }
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const handleClosed = () => {
    setActiveModal(false)
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev={true}
          pathPrev="/my-reports/graphic"
          titlePage={DataUtil.getGraphic(type).title}
          displayDownload={false}
          darktheme
        />
        <CardOption
          title={"Filtro de búsqueda"}
          titleColor="white"
          isFilter={true}
          form={form}
          type={type}
          dateType={DataUtil.getGraphic(type)}
          handleChange={handleChange}
        />
        <BtnNewCard
          title="Generar gráfica"
          marginBottom="1px"
          darktheme
          click={getReport}
        />
      </Section>
      <SectionList>
        {graphic.datasets.length > 0 && (
          <GraphicData
            type={type}
            data={graphic}
            title={DataUtil.getGraphic(type).title}
            graphicType={DataUtil.getGraphic(type).graphic}
            dataType={DataUtil.getGraphic(type).data_type}
            legend={DataUtil.getGraphic(type).legend}
          />
        )}
      </SectionList>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </Container>
  )
}

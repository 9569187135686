import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MdLiveHelp } from 'react-icons/md';
import { ReportPdf } from '../ReportPdf';
import { ReportPrepare } from '../ReportPrepare';
import { Tooltip, IconButton } from '@material-ui/core';
import { Container, Section, Label, Value, Row, RowCard, AButton } from './styles';

export const ReportData = ({data, type, fileName}) => {

    return (
        <Container>
            {data.data.length > 0 && (type === 'balance' || type === 'results') ? 
                <Section>
                    {data.data.map((row, index) => (
                        <Row total={row.is_total} color={row.total_color}>
                            <Label title={row.is_title} full>{row.name}</Label>
                            {row.value !== null &&
                                <Value color={row.color} fontSize={row.is_total}>
                                    {row.value}
                                </Value>
                            }
                        </Row>
                    ))} 
                    <PDFDownloadLink document={<ReportPdf data={data}/>}>
                        {({ blob, url, loading, error }) =>
                            <Row>
                                <AButton href={url} download={fileName}>Descargar informe</AButton>
                            </Row>
                        }
                    </PDFDownloadLink>
                </Section>
            :
            (data.data.length > 0 && type === 'indicators') ?
                <Section>
                    {data.data.map(row => (
                        <RowCard>
                            <Label style={{ lineHeight: '1.4' }} full>{row.name}</Label>
                            {row.value !== null &&
                                <Value>
                                    {Intl.NumberFormat('es-CO', { maximumFractionDigits: '2', style: 'currency', currency: 'COP' }).format(row.value)}
                                </Value>
                            }
                            <Tooltip title={row.message} enterTouchDelay="1">
                                <IconButton>
                                    <MdLiveHelp color="#0A2733" size='1.3rem' style={{ margin: 'auto' }}/>
                                </IconButton>
                            </Tooltip>
                        </RowCard>
                    ))} 
                </Section>
            :
                <ReportPrepare
                    message="Para generar un informe se debe seleccionar un rango de fechas de 1 a 12 meses."
                    top="1rem"
                />
            }
        </Container>
    )
}

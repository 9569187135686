import styled from 'styled-components';

// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    padding-bottom:3em;
    ${mobile} {
        padding-bottom:0;

  }
`

export const PatrimonyItem = styled.div`
    margin-top:1.5rem;
    margin-bottom:1.5rem;
    padding-left:1rem;
    display:flex;
    align-items:center;
    /* justify-content:flex-end; */
    /* padding-right:1rem; */
    font-size:.9rem;
    font-weight:500;

    color: ${props => props.theme.colors.main};
    opacity: ${props => (props.skeleton ? '50%' : '')};



`

export const FigureIcon = styled.figure`
    padding:0 .5rem 0 0;
    color: ${props => props.theme.colors.main};
    display:flex;

`

export const PatrimonyValue = styled.p`
  font-weight:600;
  padding-left:.5em;
  color: ${props => props.rojo ? props.theme.colors.red_brand : props.cero ? props.theme.colors.deactive : props.theme.colors.text};

`

export const Value = styled.p`
  font-size: 1.2rem;
`


import React, { useEffect, useState } from "react"
import { Container, Section } from "./styles"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { useParams, useHistory } from "react-router-dom"
import { DataUtil } from "../../../../utils/data"
import { FormExpenses } from "../../../../components/organisms/forms/myExpense/FormExpenses"

export const ExpenseForm = () => {
  const history = useHistory()
  const { type, id } = useParams()
  const [open, setOpen] = useState(false)
  const [formStep, setFormStep] = useState(0)
  const [cancel, setCancel] = useState("")
  const [title, setTitle] = useState("")

  useEffect(() => {
    let selected = DataUtil.getExpense(type, id)

    setCancel(selected.prev_route)
    setTitle(selected.tag_title)
    setFormStep(selected.init_step)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let handleOpenOptions = (event) => {
    setOpen(event)
  }

  let handleChangeStep = () => {
    history.push(cancel)
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={cancel}
          titlePage={title}
          darktheme
          handleChange={handleChangeStep}
          tooltip="expenses"
        />
        <FormExpenses
          handleOpen={handleOpenOptions}
          open={open}
          setFormStep={setFormStep}
          formStep={formStep}
        />
      </Section>
    </Container>
  )
}

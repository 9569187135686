import { createGlobalStyle } from 'styled-components'

const desktopStartWidth = 1023;
const tabletStartWidth = 767;
const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const GlobalStyle = createGlobalStyle `
  
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
    ${'' /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Montserrat, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;    */}
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, h4, h5, p, button {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  button {
    background: transparent;
    border: 0;
    outline: 0
  }

  body {
    background: ${props => props.theme.colors.text_light};
    ${'' /* background: #fefefe; */}
    height: 100vh;
    margin: 0 auto;
    ${'' /* max-width: 500px; */}
    overscroll-behavior: none;
    width: 100%;
    font-size:13px;
    ${tablet} {
      font-size:12px;
    }
    ${mobile} {
      font-size:10px;
    }
  }


  #root {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    min-height: 100vh;
    ${'' /* padding-bottom: 10px; */}
  }


  ${'' /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400&display=swap'); */}
  
  * {
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    ${'' /* font-family: 'Poppins', sans-serif; */}
    font-family: ${props => props.theme.fonts.family};

  }
  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  h1 {
    color:#fff;
    font-size:2.5rem;
    line-height: 1.2em;
  }

  h2 {
    color: ${props => props.theme.colors.dark};
    font-size:1.5rem;
    font-weight:400;
    line-height: 1.2em;
  }

  h3 {
    color: ${props => props.theme.colors.second};
    font-size:.8rem;
    font-weight:500;
  }

  h4 {
    color: ${props => props.theme.colors.dark};
    font-size:1rem;
    font-weight:600;
    line-height: 1.2em;
  }


  h5 {
    color: ${props => props.theme.colors.text};
    font-size:.8rem;
    font-weight:400;
    line-height: 1.2em;
  }

  h6 {
    color: ${props => props.theme.colors.dark};
    font-size:.7rem;
    font-weight:600;
  }
`
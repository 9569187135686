// import axios from 'axios'
// import { Routes } from '../routes'

export const Auth = {
  tokenAuth: () => localStorage.getItem("_token_auth"),
  // tokenRefresh: () => localStorage.getItem('_token_refresh'),
  isAuthenticated: () => {
    if (Auth.tokenAuth() === null || Auth.tokenAuth() === undefined)
      return false
    return true
    // let jwt = parseJwt(Auth.tokenAuth())
    // return jwt.exp >= Date.now() / 1000
  },
  getUser: () => {
    let user = localStorage.getItem("user")
    user = JSON.parse(user)
    // console.log("user")
    // console.log(user)
    if (user === undefined || user == null) return null
    return user
    //if (Auth.tokenAuth() === null) return null
    //
    //const jwt = parseJwt(Auth.tokenAuth())
    //const user = jwt.user
    //user.id = jwt.user_id
    //return user
  },
  authenticate: (token) => {
    sessionStorage.clear()
    localStorage.clear()

    localStorage.setItem("_token_auth", token.access_token)
    sessionStorage.setItem("initial_info", token.initial_info)
    //sessionStorage.setItem('_token_refresh', token.refresh)
    localStorage.setItem("user", JSON.stringify(token.user))
    //sessionStorage.setItem('subscription_active', token.subscription_active)
  },
  logout: () => {
    sessionStorage.clear()
    localStorage.clear()
  },
  // refresh: async () => {
  //   return await axios
  //     .post(Routes.api_url_base + Routes.api_token_refresh, {
  //       refresh: Auth.tokenRefresh()
  //     })
  //     .then((response) => {
  //       sessionStorage.setItem('_token_auth', response.data?.access)
  //       return response
  //     })
  //     .catch((error) => {
  //       return error.response
  //     })
  // },
}

// const parseJwt = (token) => {
//   let base64Url = token.split('.')[1]
//   let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
//   let jsonPayload = decodeURIComponent(
//     atob(base64)
//       .split('')
//       .map(function (c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
//       })
//       .join(''),
//   )
//
//   return JSON.parse(jsonPayload)
// }

import React from 'react';
import {Container, Section} from './styles';

import {TitleAndInfo} from '../../../components/molecules/TitleAndInfo';
import { FormAssetsContainer } from '../../../containers/FormAssetsContainer';

import {Routes} from '../../../routes'


export const InitialAssetsForm = () => {
    return (
        <Container>
            <Section>
                <TitleAndInfo 
                    btnPrev = {true}
                    pathPrev = {Routes.app_initial_assets}
                    titlePage='Nuevo activo inicial'
                    tooltip="assets"
                    darktheme
                />
                <FormAssetsContainer />
            </Section>
        </Container>
    )
}

import styled from "styled-components"
import { Link } from "react-router-dom"
import { fadeIn } from "../../../../styles/animation"

// const desktopStartWidth = 1023;
const tabletStartWidth = 768
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const ContainerLink = styled(Link)`
  /* ${fadeIn({ time: "2s" })}; */
  width: 32%;
  max-height: 32%;
  min-height: 200px;

  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in;
  background: #fff;
  &:hover {
    background: ${(props) => props.theme.colors.dark};
    & > h2,
    h3 {
      color: #fff;
      transition: all 0.5s ease-in-out;
    }
  }
  ${mobile} {
    width: calc(50% - 0.5rem);
    padding: 0.8rem;
    min-height: 180px;
  }
`

export const Container = styled.div`
  ${fadeIn({ time: "1s" })};
  width: 30%;
  min-height: 200px;

  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  &:hover {
    opacity: 0.8;
  }
  ${mobile} {
    width: 47%;
    min-height: 150px;
  }
`

export const SectionTop = styled.section`
  display: flex;
  flex-direction: column;
  /*justify-content:space-between;*/
`

export const WhiteSpace = styled.div`
  display: flex;
  flex: 1;
`

export const SectionBottom = styled.section`
  display: flex;
  flex-direction: column;
`
export const ValueStock = styled.h2`
  color: ${(props) =>
    props.rojo ? props.theme.colors.red_brand : props.theme.colors.text};
  font-size: 1.8em;
  font-weight: 600;
  text-align: right;
  margin-bottom: -0.2em;
  ${mobile} {
    font-size: 1.5em;
  }
`

export const SpanStock = styled.span`
  font-size: 0.5em;
  font-weight: 500;
  ${mobile} {
    font-size: 0.7em;
  }
`

export const LinkStyle = styled(Link)`
  width: auto;
  height: auto;
  color: ${(props) => props.theme.colors.deactive};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerCategory = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`

export const FigureCategory = styled.figure`
  color: ${(props) =>
    props.rojo ? props.theme.colors.red_light : props.theme.colors.text_light};
  opacity: ${(props) => (props.skeleton ? "50%" : "")};
  border-radius: 50%;
  display: flex;
  align-items: center;
`

export const TitleCategory = styled.h4`
  color: ${(props) =>
    props.rojo ? props.theme.colors.red_brand : props.theme.colors.text};
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 600;
  padding-left: 0.5em;
`

export const Section = styled.section`
  padding: 0 0 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CardTitle = styled.h3`
  display: flex;
  color: ${(props) => props.theme.colors.dark};
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.3em;
  line-height: 1.2em;
  font-weight: 500;

  opacity: ${(props) => (props.skeleton ? "0" : "")};
  padding-top: 0.7em;
  transition: all 0.3s ease-in;
  ${mobile} {
    padding-top: 0;
    line-height: 1.2em;
  }
`

export const CardSku = styled.h6`
  color: ${(props) => props.theme.colors.text};
  font-weight: 400;
  font-size: 1em;

  padding-top: 0.2em;
  opacity: ${(props) => (props.skeleton ? "0" : "")};
  ${mobile} {
    font-size: 1.2em;
  }
`

export const CardValue = styled.p`
  font-size: 2em;
  font-weight: 600;
  text-align: right;
  color: ${(props) =>
    props.rojo ? props.theme.colors.red_brand : props.theme.colors.text};
  opacity: ${(props) => (props.skeleton ? "0" : "")};
  ${mobile} {
    font-size: 1.8em;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px 5px;
  width: 100%;

  > img {
    max-width: 90px;
    width: 100%;
    border-radius: 5px;
  }

  > div {
    width: 100%;
  }

  ${mobile} {
    flex-direction: column;
    margin: 10px 0;
    gap: 10px;
    > img {
      max-width: 110px;
    }
  }
`

import React, { useEffect, useState } from "react"
import {
  PreContainer,
  Container,
  SubContainer,
  Section,
  SubSection,
  SectionTitle,
  SubSectionTitle,
  TitleInventory,
  TextCategory,
  InfoContainer,
  TextItem,
  TextInfo,
  Button,
  Line,
  SectionList,
} from "./styles"
import { MdEdit } from "react-icons/md"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../components/atoms/cards/BtnNewCard"
import { EmptyMessage } from "../../../components/atoms/EmptyMessage"
import { CardStock } from "../../../components/atoms/cards/CardStock"
import { MdLocalMall, MdToys, MdWidgets } from "react-icons/md"
import { Routes } from "../../../routes"
import { useParams } from "react-router-dom"
import { getInventoryItem } from "../../../api/requests"
import { useHistory } from "react-router-dom"

export const InitialInventoryDetail = () => {
  const { id } = useParams()

  const [skeleton, setSkeleton] = useState(false)
  const [listStocks, setListStocks] = useState({
    is_active: "",
    stock: [{ id: "", quantity: "" }],
  })

  const capitalizeText = (name = "") => {
    const lower = name.toLowerCase()
    const capitalizeText = name.charAt(0).toUpperCase() + lower.slice(1)
    return capitalizeText
  }

  const capitalizeCategory = capitalizeText(listStocks.category)
  const capitalizeName = capitalizeText(listStocks.name)

  const history = useHistory()

  function editInventory() {
    history.push(`/edit-initial-inventory-form/${id}`)
  }

  async function loadInventoryItem(id) {
    setSkeleton(true)
    const response = await getInventoryItem(id)
    if (response.status === 200) {
      setListStocks(response.data)
      console.log("List inventories")
      console.log(response.data.stock)
    }
    setTimeout(() => {
      setSkeleton(false)
    }, 1000)
  }

  function updateItems() {
    loadInventoryItem(id)
  }

  useEffect(() => {
    loadInventoryItem(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PreContainer>
      <Container>
        <SubContainer>
          <Section>
            <TitleAndInfo
              btnPrev
              pathPrev={Routes.app_initial_inventory}
              titlePage="Detalle del inventario inicial"
              tooltip="inventory"
              // titlePage={listStocks.name}
              // subtitleInfo={listStocks.category}
            />

            <SubSection>
              <SectionTitle>
                <SubSectionTitle>
                  <TextCategory>{capitalizeCategory}</TextCategory>
                  <TitleInventory>{capitalizeName}</TitleInventory>
                </SubSectionTitle>
                <Button onClick={editInventory}>
                  <MdEdit size="1.5rem" />
                </Button>
              </SectionTitle>
              <Line />
              {listStocks.description && (
                <InfoContainer>
                  <TextItem>Descripción:</TextItem>
                  <TextInfo>{listStocks.description}</TextInfo>
                </InfoContainer>
              )}
              {listStocks.is_active && (
                <InfoContainer>
                  <TextItem>Estado:</TextItem>
                  {listStocks.is_active === true && (
                    <TextInfo end>Activo</TextInfo>
                  )}
                  {listStocks.is_active === false && (
                    <TextInfo end>Inactivo</TextInfo>
                  )}
                </InfoContainer>
              )}
            </SubSection>

            <BtnNewCard
              title="Agregar nuevo stock."
              // path = {Routes.app_stock_form}
              path={`/initial-stock-form/${id}`}
            />
          </Section>
        </SubContainer>
        <SectionList>
          {skeleton && (
            <>
              {[1, 2, 3, 4, 5, 6].map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <CardStock title="prueba" value="0" skeleton={skeleton} />
                  </React.Fragment>
                )
              })}
            </>
          )}
          {!skeleton && listStocks === "" && (
            <EmptyMessage message="Aún no existen stocks registrados." />
          )}
          {!skeleton &&
            listStocks !== "" &&
            listStocks.category === "PRODUCTO FABRICADO" &&
            listStocks.stock.map((stockk, i) => {
              return (
                <React.Fragment key={i}>
                  <CardStock
                    id={stockk.id}
                    category={listStocks.category}
                    cost_value={stockk.cost_value}
                    sale_value={stockk.sale_value}
                    available={stockk.available}
                    quantity={stockk.quantity}
                    created={stockk.created}
                    is_active={stockk.is_active}
                    is_initial={stockk.is_initial}
                    icon={<MdWidgets size="2rem" />}
                    skeleton={skeleton}
                    updateItems={updateItems}
                  />
                </React.Fragment>
              )
            })}
          {!skeleton &&
            listStocks !== "" &&
            listStocks.category === "PRODUCTO COMPRADO" &&
            listStocks.stock.map((stockk, i) => {
              return (
                <React.Fragment key={i}>
                  <CardStock
                    id={stockk.id}
                    category={listStocks.category}
                    cost_value={stockk.cost_value}
                    sale_value={stockk.sale_value}
                    available={stockk.available}
                    quantity={stockk.quantity}
                    created={stockk.created}
                    is_active={stockk.is_active}
                    is_initial={stockk.is_initial}
                    icon={<MdLocalMall size="2rem" />}
                    skeleton={skeleton}
                    updateItems={updateItems}
                  />
                </React.Fragment>
              )
            })}
          {!skeleton &&
            listStocks !== "" &&
            listStocks.category === "SERVICIO" &&
            listStocks.stock.map((stockk, i) => {
              return (
                <React.Fragment key={i}>
                  <CardStock
                    id={stockk.inventory_id}
                    category={listStocks.category}
                    cost_value={stockk.cost_value}
                    sale_value={stockk.sale_value}
                    available={stockk.available}
                    quantity={stockk.quantity}
                    created={stockk.created}
                    is_active={stockk.is_active}
                    is_initial={stockk.is_initial}
                    icon={<MdToys size="2rem" />}
                    skeleton={skeleton}
                    updateItems={updateItems}
                  />
                </React.Fragment>
              )
            })}
        </SectionList>
      </Container>
    </PreContainer>
  )
}

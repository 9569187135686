import { Container, Text } from "./styles"

export const RemainingDaysBadge = ({ suscripcionInfo }) => {
  const calculateRemainingDays = (date) => {
    const now = new Date()
    const endDate = new Date(date)

    const diff = Math.abs(endDate - now)
    const remainingDays = Math.floor(diff / (1000 * 60 * 60 * 24))

    return remainingDays
  }

  const remainingDays = calculateRemainingDays(suscripcionInfo.final_date)

  if (remainingDays > 15) return <></>

  return (
    <Container>
      <Text>
        {suscripcionInfo.user.first_name} en {remainingDays} dias termina tu{" "}
        {suscripcionInfo.subscription.amount > 0
          ? `plan de ${suscripcionInfo.subscription.name}!`
          : "prueba gratis!"}
      </Text>
    </Container>
  )
}

import React from "react"
import {
  CardForm,
  Form,
  Section,
  Span,
  Input,
  Option,
  Select,
  ContainerError,
  ErrorText,
  ContainerBtns,
  LinkBtn,
  SubSection,
  TitleStep,
  Datepicker,
  DatePickerWrap,
} from "./styles"
import { BtnAction } from "../../../../atoms/BtnAction"
import { ModalLoading } from "../../../../molecules/Modals/ModalLoading"
import { ModalNew } from "../../../../molecules/Modals/ModalNew"
import { useForm } from "../../../../../hooks/useForm"
import iconSuccess from "../../../../../assets/img/fef-icon-success.png"
import iconError from "../../../../../assets/img/fef-icon-error.png"
import { Routes } from "../../../../../routes"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from "date-fns/locale/es"

registerLocale("es", es)

export const FormEditBudgetExpense = ({
  initialForm,
  validationsForm,
  listFinancialCategories,
  idEdit,
  objItem,
}) => {
  const {
    form,
    errors,
    loading,
    response,
    setResponse,
    handleChange,
    handleBlur,
    handleSubmitEdit,
  } = useForm(initialForm, validationsForm, idEdit)

  return (
    <>
      {loading && <ModalLoading activeModalLoading={true} />}
      {response && (
        <ModalNew
          activeModal={true}
          icon={iconSuccess}
          title="Presupuesto actualizado"
          text="La proyección del presupuesto de ingresos fue actualizada correctamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirect={Routes.app_initial_budget_expense}
        />
      )}
      {response === false && (
        <ModalNew
          activeModal={true}
          icon={iconError}
          title="Presupuesto no actualizado"
          text="La proyección del presupuesto de ingresos no pudo ser actualizada. Intentelo nuevamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirect={Routes.app_initial_budget_expense}
        />
      )}

      <CardForm>
        <Form onSubmit={handleSubmitEdit}>
          <Section>
            <SubSection>
              <TitleStep>Información del presupuesto</TitleStep>
            </SubSection>
            <Span>Fecha*</Span>
            <DatePickerWrap>
              {form.date === "" && (
                <Datepicker
                  name="date"
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  onChange={(e) =>
                    handleChange({ target: { name: "date", value: e } })
                  }
                  value={form.date}
                  locale="es"
                  required
                />
              )}
              {form.date !== "" && (
                <Datepicker
                  name="date"
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  onChange={(e) =>
                    handleChange({ target: { name: "date", value: e } })
                  }
                  value={form.date}
                  locale="es"
                  required
                />
              )}
            </DatePickerWrap>
            <ContainerError>
              {errors.date && <ErrorText>{errors.date}</ErrorText>}
            </ContainerError>

            <Span>Categoría financiera*</Span>
            {form.category === "" && (
              <Select
                ghost
                type="select"
                name="category"
                placeholder="Categoría financiera*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.category}
                required
              >
                <Option value="" ghost="true" nameCategory="prueba nombre">
                  Seleccionar una opción...
                </Option>
                {listFinancialCategories.map((categoryItem, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Option
                        value={categoryItem.id}
                        nameCategory={categoryItem.name}
                      >
                        {categoryItem.name}
                      </Option>
                    </React.Fragment>
                  )
                })}
              </Select>
            )}
            {form.category !== "" && (
              <Select
                type="select"
                name="category"
                placeholder="Categoría financiera*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.category}
                required
              >
                <Option value="" ghost="true">
                  Seleccionar una opción...
                </Option>
                {listFinancialCategories.map((categoryItem, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Option
                        value={categoryItem.id}
                        nameCategory={categoryItem.name}
                      >
                        {categoryItem.name}
                      </Option>
                    </React.Fragment>
                  )
                })}
              </Select>
            )}
            <ContainerError>
              {errors.category && <ErrorText>{errors.category}</ErrorText>}
            </ContainerError>

            <Span>Valor*</Span>
            <Input
              type="text"
              name="value"
              placeholder="ej: $ 300.000"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.value}
              required
            />

            <ContainerError>
              {errors.value && <ErrorText>{errors.value}</ErrorText>}
            </ContainerError>
          </Section>

          <ContainerBtns>
            <LinkBtn to={Routes.app_initial_budget_expense} border second>
              Cancelar
            </LinkBtn>
            <BtnAction darktheme name="Actualizar" />
          </ContainerBtns>
        </Form>
      </CardForm>
    </>
  )
}

import styled from 'styled-components'

// const desktopStartWidth = 1023;
// const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
// const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Btn = styled.input`
  width: ${props => ( props.second || props.darktheme ? "45%" : "70%")};
  color: ${props => ( props.white ? props.theme.colors.main : props.ghost ? props.theme.colors.main : props.darktheme ? props.theme.colors.main : "#fff" )};
  background: ${props => ( props.white ? "#fff" : props.ghost ? "transparent" : props.darktheme ? props.theme.colors.third : props.theme.colors.main )};
  /* margin:0 auto; */
  margin-top:1em;
  padding:1em .5em;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.second};
    color: #fff;


  }
`
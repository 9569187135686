import React, { useEffect } from "react"
import { CardForm } from "./styles"
import { Modal } from "../../../../molecules/Modals/Modal"
import {
  getEconomicActivity,
  getListCities,
  getListDocuments,
  getListStates,
} from "../../../../../api/requests"
import { useHistory } from "react-router-dom"
import { Routes } from "../../../../../routes"
import { UserInformation } from "../UserInformation"

export const FormProfileSteps = (props) => {
  const history = useHistory()
  const [alertModal, setAlertModal] = React.useState(false)
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [documents, setDocuments] = React.useState([])
  const [economicActivities, setEconomicActivities] = React.useState([])
  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })

  useEffect(() => {
    async function fetchData() {
      await getDocuments()
      await getEconomicActivities()
      await getStates()
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (props.form.city) {
        await getCities(props.form.state)
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [props.form.city])

  async function getDocuments() {
    const response = await getListDocuments()
    if (response.status === 200) {
      setDocuments(response.data)
    }
  }

  async function getEconomicActivities() {
    const response = await getEconomicActivity()
    if (response.status === 200) {
      setEconomicActivities(response.data)
    }
  }

  async function getStates() {
    const response = await getListStates()
    if (response.status === 200) {
      setStates(response.data)
    }
  }

  async function getCities(id) {
    const response = await getListCities(id)
    if (response.status === 200) {
      setCities(response.data)
    }
  }

  const handleCloseAlertModal = () => {
    setAlertModal(false)
  }

  async function handleChange(event) {
    let e = event.target

    if (e.name === "state") {
      props.setForm({ ...props.form, [e.name]: e.value })

      if (e.value) await getCities(e.value)
      else setCities([])
    }

    props.setForm({ ...props.form, [e.name]: e.value })
    props.setData({ ...props.data, [e.name]: e.value })
  }

  async function _handleSubmit(event) {
    if (!props.form.tradename) {
      showAlert(event, "Debe indicar el nombre del comercio")
      return
    } else if (!props.form.economic_activity) {
      showAlert(event, "Debe seleccionar la actividad económica del comercio")
      return
    } else if (!props.form.first_name) {
      showAlert(event, "Debe indicar el nombre del usuario")
      return
    } else if (!props.form.last_name) {
      showAlert(event, "Debe indicar el apellido del usuario")
      return
    } else if (!props.form.identification_type) {
      showAlert(event, "Debe seleccionar el tipo de documento del usuario")
      return
    } else if (!props.form.identification_number) {
      showAlert(event, "Debe indicar el número de identificación del usuario")
      return
    } else if (!props.form.cellphone) {
      showAlert(event, "Debe indicar el número celular del usuario")
      return
    } else if (!props.form.state) {
      showAlert(event, "Debe seleccionar un departamento")
      return
    } else if (!props.form.city) {
      showAlert(event, "Debe seleccionar una ciudad")
      return
    } else if (!props.form.address) {
      showAlert(event, "Debe indicar la dirección del usuario")
      return
    }

    props.handleSubmit(props.data)
  }

  const showAlert = (event, text) => {
    event.preventDefault()
    setAlertModal(true)
    setModalData({
      title: "¡Alerta!",
      text: text,
      btn: "Aceptar",
    })
    return
  }

  const backStep = () => {
    history.push(Routes.app_my_profile)
  }

  return (
    <>
      <CardForm onSubmit={_handleSubmit}>
        <UserInformation
          form={props.form}
          economicActivities={economicActivities}
          documents={documents}
          states={states}
          cities={cities}
          backStep={backStep}
          handleChange={handleChange}
          handleSubmit={_handleSubmit}
        />
      </CardForm>
      <Modal
        activeModal={alertModal}
        handleClosed={handleCloseAlertModal}
        title={modalData.title}
        text={modalData.text}
        errors={modalData.errors}
        btn={modalData.btn}
      />
    </>
  )
}

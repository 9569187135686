import React, { useEffect, useState } from "react";
import {
  Container,
  LogosCabecera,
  Cabecera,
  Contenedor,
  Section,
  Header,
  Title,
  Figure,
  Logo,
  FormContainer,
} from "./styles.js";
import logo from "../../assets/img/finku_logo_large.png";
import senaLogo from "../../assets/img/logoSena.png";
import presidenciaLogo from "../../assets/img/logoPresidencia.png";
import fondoEmprenderLogo from "../../assets/img/fondoEmprender.png";
import { FormLogin } from "../../components/organisms/forms/FormLogin";
import { Modal } from "../../components/molecules/Modals/Modal";
import { ModalLoading } from "../../components/molecules/Modals/ModalLoading";
import { getSubscriptionBilling, login } from "../../api/requests.js";
import { Auth } from "../../utils/auth";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import { WompiTransactionValidated } from "../../utils/validateWompiTransaction";

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeModalLoading, setActiveModalLoading] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    btn: "",
  });

  const history = useHistory();

  const handleSubmit = async (data) => {
    setIsLoading(true);
    setActiveModalLoading(true);
    login(data).then(async (response) => {
      if (response.status === 200) {
        Auth.authenticate(response.data);
        setIsLoading(false);
        setActiveModalLoading(false);
        let initial_info = sessionStorage.getItem("initial_info");

        await subValidated(initial_info, data);
      } else if (response.status === 400) {
        setActiveModal(true);
        setModalData({
          title: "Credenciales erroneas",
          text: "El correo electrónico no corresponde con la contraseña, intentelo nuevamente.",
          btn: "Aceptar",
        });
      }
    });
  };

  async function subValidated(initial_info, data) {
    const boolResult = await callSubscriptionBilling(data);

    if (boolResult) {
      if (initial_info === "true") {
        history.push(Routes.app_my_profile);
      } else {
        return history.push(Routes.app_welcome_user);
      }
    } else {
      history.push(Routes.app_subscribe_invitation);
    }
  }

  async function callSubscriptionBilling(data) {
    return await getSubscriptionBilling().then((subscriptions) => {
      // Comprueba si el correo logeado tiene suscripción activa
      let isActive = false;
      for (let i in subscriptions) {
        if (subscriptions[i].user.email === data.email) {
          if (subscriptions[i].subscription_active === true) {
            isActive = true;
          }
        }
      }
      return isActive;
    });
  }

  const handleClosed = () => {
    setActiveModal(false);
    setIsLoading(false);
    setActiveModalLoading(false);
  };

  useEffect(() => {
    document.title = "Inicio de sesión";
  }, []);

  return (
    <>
      {isLoading ? (
        <ModalLoading
          activeModalLoading={activeModalLoading}
          title="Cargando..."
        />
      ) : (
        ""
      )}
      <Container>
        <Section>
          <Cabecera className="logos">
                <Logo src={logo} alt="Logo finku" />
          </Cabecera>
          <Contenedor>
            <Header>

              <Title>
                Inicio  de Sesión
              </Title>
            </Header>
            <FormContainer>
              <FormLogin
                btn="Iniciar Sesión"
                redirect="¿Olvidaste tu contraseña? Ingresa aquí."
                url="/reset-password"
                handleSubmit={handleSubmit}
                // email={email}
                // onChangeEmail={function (e) {setEmail(e.target.value)}}
                // onSubmit={function () {handleSubmit()}}
              />
            </FormContainer>
          </Contenedor>
        </Section>
      </Container>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        btn={modalData.btn}
      />
    </>
  );
};

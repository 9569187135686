import React, { useEffect } from "react"
import { Routes } from "../../../routes"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { getBudgetItem } from "../../../api/requests"
import { Container, Section } from "./styles"
import { useLocation, useParams } from "react-router-dom"
import { FormEditBudgetIncomeContainer } from "../../../containers/FormEditBudgetIncomeContainer"

export const EditInitialBudgetIncomeForm = () => {
  const { id } = useParams()

  const location = useLocation()
  const captureId = location.pathname.toString().split("/")
  const idBudget = captureId[2]

  const loadBudgeItem = async (id) => {
    const response = await getBudgetItem(id)
    if (response.status === 200) {
      return response.data
    }
  }

  useEffect(() => {
    async function fetchBudgeItem() {
      await loadBudgeItem(id)
    }
    fetchBudgeItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={Routes.app_initial_budget_income}
          titlePage="Editar proyección de presupuesto de ingresos"
          tooltip="budget-income"
          darktheme
        />
        <FormEditBudgetIncomeContainer
          idEdit={idBudget}
          loadBudgeItem={loadBudgeItem}
        />
      </Section>
    </Container>
  )
}

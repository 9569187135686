import styled from "styled-components"
import { keyframes } from "styled-components"

const tabletStartWidth = 768
const fourteenHinchesStartWidth = 968
const mobile = `@media (max-width: ${tabletStartWidth}px)`
const fourteenHinches = `@media (max-width: ${fourteenHinchesStartWidth}px)`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.dark};
  display: flex;
  align-items: center;
  flex-direction: column;
  -ms-overflow-style: none;
  ${mobile} {
    width: 100%;
    padding-left: 0;
  }
`
export const Section = styled.section`
  width: 100%;
  height: 100%;
  flex: 0;
  background: ${(props) => props.theme.colors.dark};
  padding: 2rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;

  ${mobile} {
    width: 100%;
  }
`

const showSlow = keyframes`
  0% { opacity:0; transform:translateY(30%)}
  100% {opacity:1; transform:translateY(0%)}
`

export const SectionPlans = styled.section`
  background: ${(props) => props.theme.colors.dark};

  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  justify-content: center;
  gap: 1rem;

  ${mobile} {
    padding-bottom: 3.5rem;
    flex-direction: row;
  }
`

export const CardPlan = styled.div`
  background: ${(props) => props.theme.colors.third};
  width: 100%;
  max-width: 300px;
  height: fit-content;
  min-height: 165px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  border-radius: 20px;
  text-align: center;
  animation: ${showSlow} 0.5s ease-out;
  ${mobile} {
    width: 100%;
    margin-bottom: 2em;
    padding: 1rem;
  }
`

export const TitleCard = styled.h2`
  font-family: ${(props) => props.theme.fonts.family};
  color: ${(props) => props.theme.colors.dark};
  padding: 0 0 0.2em;
  ${mobile} {
  }
`

export const CostCard = styled.h3`
  color: ${(props) => props.theme.colors.second};
  font-size: 1.2rem;
  font-weight: 600;
  ${mobile} {
  }
`

export const BtnCard = styled.button`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.dark};
  padding: 9px 1em;
  border-radius: 999px;
  max-width: 110px;
  width: 100%;
  margin: 1rem auto 0;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mobile} {
  }
`

export const SectionList = styled.div`
  background: ${(props) => props.theme.colors.light};

  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 2rem 2rem;
  padding-top: 1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: space-between;
  ${mobile} {
    padding-bottom: 3.5rem;
  }
`

export const Description = styled.p`
  color: white;
  font-size: 16px;
  padding-top: 40px;
  ${`@media (max-width: ${886}px)`} {
    padding-left: 20px;
  }
  ${mobile} {
    display: none;
  }
`

export const DescriptionText = styled.p`
  color: white;
  font-size: 18px;
  padding-top: 10px;
  ${fourteenHinches} {
    padding-left: 20px;
  }
  ${mobile} {
    display: none;
  }
`

import React from "react";
import {
  createFinancialInfo,
  editFinancialInfoItem,
  createBudgets,
  editBudgetItem,
  createInventories,
  createStocks,
  editInventoryItem,
} from "../api/requests";
import { useParams } from "react-router-dom";

export const useForm = (initialForm, validateForm, idEdit) => {
  const [form, setForm] = React.useState(initialForm);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState(null);
  // eslint-disable-next-line
  const [editId, setEditId] = React.useState(idEdit);
  const { type } = useParams();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "date") {
      let d = new Date(e.target.value);
      let m = "" + (d.getMonth() + 1);
      let month = m.toString().length === 1 ? "0" + m : m;
      let year = d.getFullYear();
      // eslint-disable-next-line
      setForm({ ...form, ["date"]: `${year}-${month}` });
    }
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form));

    if (Object.keys(errors).length === 0) {
      setLoading(true);

      let formData = new FormData();
      const dataForm = Object.entries(form);
      dataForm.map((data) => {
        const [key, value] = data;
        formData.append(key, value);
        return [];
      });
      if (e.target?.image?.files[0] !== undefined)
        formData.append("inventory_image", e.target.image.files[0]);

      if (
        initialForm.category !== undefined &&
        initialForm.value !== undefined &&
        initialForm.financial_type === "ACTIVOS" &&
        Object.keys(initialForm).length === 3
      ) {
        createFinancialInfo(form).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.category !== undefined &&
        initialForm.value !== undefined &&
        initialForm.financial_type === "PASIVOS" &&
        Object.keys(initialForm).length === 3
      ) {
        createFinancialInfo(form).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.date !== undefined &&
        initialForm.category !== undefined &&
        initialForm.value !== undefined &&
        initialForm.financial_mov === "INGRESO" &&
        Object.keys(initialForm).length === 4
      ) {
        createBudgets(form).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.date !== undefined &&
        initialForm.category !== undefined &&
        initialForm.value !== undefined &&
        initialForm.financial_mov === "GASTO" &&
        Object.keys(initialForm).length === 4
      ) {
        createBudgets(form).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.category !== undefined &&
        initialForm.sku !== undefined &&
        initialForm.name !== undefined &&
        initialForm.description !== undefined &&
        initialForm.cost_value !== undefined &&
        initialForm.sale_value !== undefined &&
        initialForm.quantity !== undefined &&
        initialForm.is_active !== undefined &&
        initialForm.is_initial !== undefined
      ) {
        if (type === "initial") form.is_initial = true;

        createInventories(formData).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else {
        setLoading(false);
        setResponse(true);
      }
    } else {
      return;
    }
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form));

    if (Object.keys(errors).length === 0) {
      setLoading(true);

      let formData = new FormData();
      const dataForm = Object.entries(form);
      dataForm.map((data) => {
        const [key, value] = data;
        formData.append(key, value);
        return [];
      });
      if (e.target?.image?.files[0] !== undefined)
        formData.append("inventory_image", e.target.image.files[0]);

      if (
        initialForm.category !== undefined &&
        initialForm.value !== undefined &&
        Object.keys(initialForm).length === 2
      ) {
        editFinancialInfoItem(form, editId).then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.date !== undefined &&
        initialForm.category !== undefined &&
        initialForm.value !== undefined &&
        Object.keys(initialForm).length === 4
      ) {
        editBudgetItem(form, editId).then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.cost_value !== undefined &&
        initialForm.sale_value !== undefined &&
        initialForm.quantity !== undefined &&
        initialForm.inventory_id !== undefined &&
        initialForm.is_initial !== undefined &&
        initialForm.is_active !== undefined &&
        Object.keys(initialForm).length === 6
      ) {
        createStocks(form, editId).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400 || response.status === 405) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else if (
        initialForm.sku !== undefined &&
        initialForm.name !== undefined &&
        initialForm.description !== undefined &&
        initialForm.cost_value !== undefined &&
        initialForm.sale_value !== undefined &&
        initialForm.quantity !== undefined &&
        initialForm.is_active !== undefined &&
        initialForm.is_initial !== undefined &&
        initialForm.id !== undefined
      ) {
        if (type === "initial") form.is_initial = true;
        editInventoryItem(formData, editId).then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setResponse(true);
          } else if (response.status === 400) {
            setLoading(false);
            setResponse(false);
          }
        });
      } else {
        setLoading(false);
        setResponse(false);
      }
    } else {
      return;
    }
  };

  return {
    form,
    errors,
    loading,
    response,
    setResponse,
    handleChange,
    handleBlur,
    handleSubmit,
    handleSubmitEdit,
  };
};

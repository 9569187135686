import React from 'react'
import {Container, Figure, Section, CardTitle, CircleIcon} from './styles.js'


export const BtnNewCard = ({title, path, click, darktheme, ghost, disabled, marginBottom, marginTop}) => {

    return (
        <Container to={path} onClick={disabled ? null : click} 
            darktheme={darktheme} ghost={ghost} 
            marginBottom={marginBottom} marginTop={marginTop}
            disabled
        >
            <Figure>
                <CircleIcon size='2.5rem'/>
            </Figure>
            <Section>
                <CardTitle>{title}</CardTitle>
            </Section>
        </Container>
    )
}

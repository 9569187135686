import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
  width:100%;
  height:100%;
  padding-left:23%;
  background: ${props => props.theme.colors.third_clear};
  display:flex;
  align-items:center;
  flex-direction:column;
  -ms-overflow-style: none;
  ${mobile} {
    width:100%;
    padding-left:0;
  }
`

export const Section = styled.section`
  max-width:1000px;
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  flex:0;
  background: ${props => props.theme.colors.third_clear};
  padding: 2rem 2rem 1.5rem;
  display:flex;
  flex-direction:column;

  ${mobile} {
    width:100%;
  }
`

export const SectionList = styled.div`
    background: ${props => props.theme.colors.light};
    height: ${props => props.count ? '100%' : ''};
    width:100%;
    max-width:1000px;
    display:flex;
    flex-wrap:wrap;
    padding: 0 2rem 2rem;
    padding-top: 1rem;
    justify-content:space-between;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    ${mobile} {
      padding-bottom: 4rem;
    }
`
import React from "react"
import {
  CardForm,
  Form,
  Section,
  Span,
  Input,
  Option,
  Select,
  ContainerError,
  ErrorText,
  ContainerBtns,
  LinkBtn,
  SubSection,
  TitleStep,
} from "./styles"
import { BtnAction } from "../../../../atoms/BtnAction"
import { ModalLoading } from "../../../../molecules/Modals/ModalLoading"
import { ModalNew } from "../../../../molecules/Modals/ModalNew"
import { useForm } from "../../../../../hooks/useForm"
import iconSuccess from "../../../../../assets/img/fef-icon-success.png"
import iconError from "../../../../../assets/img/fef-icon-error.png"
import { Routes } from "../../../../../routes"

export const FormLiabilities = ({
  initialForm,
  validationsForm,
  listFinancialCategories,
}) => {
  const {
    form,
    errors,
    loading,
    response,
    setResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm)

  return (
    <>
      {loading && <ModalLoading activeModalLoading={true} />}
      {response && (
        <ModalNew
          activeModal={true}
          icon={iconSuccess}
          title="Pasivo registrado"
          text="El pasivo fue registrado correctamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirect={Routes.app_initial_liabilities}
        />
      )}
      {response === false && (
        <ModalNew
          // title='Pasivo no registrado '
          // text="El pasivo no pudo ser registrado. Intentelo nuevamente."
          activeModal={true}
          icon={iconError}
          title="Pasivo no registrado"
          text="Revisa si esta categoría financiera ya fue inscrita anteriormente."
          btn="Aceptar"
          setResponse={setResponse}
          redirect={Routes.app_initial_liabilities}
        />
      )}

      <CardForm>
        <Form onSubmit={handleSubmit}>
          <Section>
            <SubSection>
              <TitleStep>Información de pasivo inicial</TitleStep>
            </SubSection>
            <Span>Categoría financiera*</Span>
            {form.category === "" && (
              <Select
                ghost
                type="select"
                name="category"
                placeholder="Categoría financiera*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.category}
                required
              >
                <Option value="" ghost="true">
                  Seleccionar una opción...
                </Option>
                {listFinancialCategories.map((categoryItem, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Option value={categoryItem.id}>
                        {categoryItem.name}
                      </Option>
                    </React.Fragment>
                  )
                })}
              </Select>
            )}
            {form.category !== "" && (
              <Select
                type="select"
                name="category"
                placeholder="Categoría financiera*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.category}
                required
              >
                <Option value="" ghost="true">
                  Seleccionar una opción...
                </Option>
                {listFinancialCategories.map((categoryItem, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Option value={categoryItem.id}>
                        {categoryItem.name}
                      </Option>
                    </React.Fragment>
                  )
                })}
              </Select>
            )}
            <ContainerError>
              {errors.category && <ErrorText>{errors.category}</ErrorText>}
            </ContainerError>

            <Span>Valor*</Span>
            <Input
              type="text"
              name="value"
              placeholder="ej: $ 300.000"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.value}
              required
            />

            <ContainerError>
              {errors.value && <ErrorText>{errors.value}</ErrorText>}
            </ContainerError>
          </Section>

          <ContainerBtns>
            <LinkBtn to={Routes.app_initial_liabilities} border second>
              Cancelar
            </LinkBtn>
            <BtnAction darktheme name="Crear pasivo" />
          </ContainerBtns>
        </Form>
      </CardForm>
    </>
  )
}

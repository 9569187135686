import styled from 'styled-components';

// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
  width:100%;
  height:100%;
  background-color:${props => props.theme.colors.main};
  /* background-color:rgba(0, 0, 0, 0.8); */
  /* overflow:none; */
  position:fixed;
  top:0;
  left:0;
  z-index:200;
  display:flex;
  justify-content:center;
  align-items:center;
  transition:all 500ms ease;
  opacity:0;
  visibility:hidden;
  /* transform:translateY(-30%); */
  &.activate {
    /* transform:translateY(0%); */
    opacity:1;
    visibility:visible;

  }
`

export const Card = styled.div`
  width:400px;
  min-height:500px;
  border-radius:20px;
  /* background-color:#fff; */
  background-color:${props => props.theme.colors.main};
  padding:4em 3em;
  display:flex;
  flex-direction:column;
  justify-content:center;
  text-align:center;
  transition:all 2s ease;
  /* box-shadow: 1px 7px 25px rgba(0, 0, 0, 0.6); */
  ${mobile} {
    width:85%;
  }

`

export const ContainerSpinner = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    padding-bottom:3em;

`

export const Title = styled.h3`
  font-size:1.5rem;
  color: #fff;
  /* color:#999999; */
  /* color:#30cb00; */
  padding-bottom:1em;
`
export const Text = styled.p`
  font-size:1.2em;
  font-weight:400;
  color:#ffffff;
  padding-top:1em;
  ${mobile}{
    font-size:.9rem;
  }
`


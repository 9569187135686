import styled from 'styled-components'

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Title = styled.h3`
    color:${props => props.color ? props.theme.colors[props.color] : props.theme.colors.dark};
    font-size:${props => props.fontSize || "15px"};
`

export const Container = styled.div`
    width:100%;
    padding-left:5px;
    padding-right:5px;
    margin-top: 1rem;
`

export const Row = styled.div`
    width:100%;
    display: flex;
    padding-left: ${props => props.noPad ? '' : '12px'};
    padding-right: ${props => props.noPad ? '' : '12px'};
    margin-top: 1rem;
`

export const Column = styled.div`
    width: ${props => props.size ? props.size : '50'}%;
`

export const Button = styled.button`
    width: 100%;
    padding: .7rem;
    border-radius: 6px;
    border-top-left-radius: ${props => props.right ? '0px' : ''};
    border-bottom-left-radius: ${props => props.right ? '0px' : ''};
    border-top-right-radius: ${props => props.left ? '0px' : ''};
    border-bottom-right-radius: ${props => props.left ? '0px' : ''};
    color: ${props => props.active ? props.theme.colors.white : props.theme.colors.text};
    background: ${props => props.active ? props.theme.colors.green_table : props.theme.colors.white};
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: ${props => props.disabled ? 'default' : 'cursor'};
    &:hover {
      color: ${props => props.theme.colors.third};
    }
`

export const Label = styled.label`
  color: ${props => ( props.ghost ? "transparent" : props.theme.colors.third)};
  font-size: .8rem;
`

export const Input = styled.input`
  padding:.5rem 0;
  width:100%;
  border-bottom: 1px solid #fff;
  color: ${props => props.disabled ? props.theme.colors.disabled : '#fff'};
  placeholder:#fff;
  background:transparent;
  outline:none;
  font-size: 1em;
  margin-bottom:1em;
  ::-webkit-calendar-picker-indicator {
	filter: invert(1);
  }
  ${mobile} {
    font-size: .9rem;
  }
`

export const Select = styled.select`
  padding:.5rem 0;
  width:100%;
  border-bottom: 1px solid #fff;
  color:#fff;
  font-family: ${props => props.theme.fonts.family};
  font-size: 1em;
  background:transparent;
  outline:none;
  margin-bottom:1em;
  ${mobile} {
    font-size: .9rem;
  }
  option {
    color: ${props => props.theme.colors.main};
    background: ${props => props.theme.colors.light};
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
  option:checked {
    background: ${props => props.theme.colors.second};
  }
`

export const Option = styled.option`
  color: red;
`



import React from 'react';
import {CardForm, Form, Section, TitleStep, Span, Input, BtnNext, BtnText} from './styles';
import {Link} from 'react-router-dom';


export const FormLogin = (props) => {

    const [formValues, setFormValues] = React.useState({
        email:'',
        password:''
    })

    const handleChange = (event) => {
        const {name, value} = event.target
        // console.log(name, value)

        setFormValues({ ...formValues, [name]: value })
    }

    const _handleSubmit = (event) => {
        event.preventDefault();
        props.handleSubmit({ ...formValues })
        // console.log("handle submit login")
        // console.log(formValues)
        
        event.target.reset()
        setFormValues({
            email:'',
            password:''
        })
    }

    return (
        <>
            <CardForm>
                <Form onSubmit={_handleSubmit}>
                    <>
                        <Section>
                            <TitleStep>Información de contacto</TitleStep>
                            <Input
                                placeholder="Correo electrónico*"
                                type="email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                required
                            />
                            <Input
                                placeholder="Contraseña*"
                                type="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                                required
                            />
                        </Section>
                        <BtnNext
                            /* onClick={completeFormStep} */
                            type="submit"
                        >Iniciar Sesión</BtnNext>
                    </>
                </Form>
                <Link to="/sign-up" style={{textDecoration:"none"}}>
                    <BtnNext ghost
                    /* onClick={completeFormStep} */
                    >Registrarse</BtnNext>
                </Link>
                <Span>
                    <Link to={props.url} style={{textDecoration:"none"}}>
                        <BtnText>{props.redirect}</BtnText>
                    </Link>
                </Span>
            </CardForm>
        </>
    )
}
import React, { useEffect, useState } from "react"
import { CardForm } from "./styles"
import { Modal } from "../../../../molecules/Modals/Modal"
import {
  getInventories,
  getListCities,
  getListDocuments,
  getListStates,
} from "../../../../../api/requests"
import { useParams, useHistory } from "react-router-dom"
import { DataUtil } from "../../../../../utils/data"
import { CustomDate } from "../../../../../utils/date"
import { AddItem } from "../../general/AddItem"
import { ClientInformation } from "../../general/ClientInformation"
import { BillingInformation } from "../../general/BillingInformation"
import { AddInventory } from "../../../../../pages/modules/inventories/AddInventory"

export const FormShoppingSteps = (props) => {
  const history = useHistory()
  const { type, category, id, payment_id } = useParams()
  const [cancel, setCancel] = useState("")
  const [isPayment, setIsPayment] = useState(false)
  const [shopping, setShopping] = useState({})
  const [activeModal, setActiveModal] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [inventory, setInventory] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [documents, setDocuments] = useState([])
  const [selected, setSelected] = useState({
    index: 0,
    name: "",
    amount: 1,
    cost_value: 0,
    sale_value: 0,
  })
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })
  // eslint-disable-next-line
  const [modalInformation, setModalInformation] = useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })

  useEffect(() => {
    if (type === "payment") setIsPayment(true)
    else setIsPayment(false)

    setCancel(DataUtil.getShopping(type, category, id).prev_route)
    setShopping(DataUtil.getShopping(type, category, id, payment_id))

    async function fetchData() {
      await getInventory()
      await getStates()
      await getDocuments()
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    async function fetchData() {
      if (props.form.state && !cities.length) await getCities(props.form.state)
    }
    fetchData()
    // eslint-disable-next-line
  }, [props.form])

  // Load data for select
  async function getInventory() {
    const response = await getInventories("PRODUCTO COMPRADO")
    if (response.status === 200) {
      setInventory(response.data)
    }
  }

  async function getDocuments() {
    const response = await getListDocuments()
    if (response.status === 200) {
      setDocuments(response.data)
    }
  }

  async function getStates() {
    const response = await getListStates()
    if (response.status === 200) {
      setStates(response.data)
    }
  }

  async function getCities(id) {
    const response = await getListCities(id)
    if (response.status === 200) {
      setCities(response.data)
    }
  }

  // Handle change data
  async function handleChange(event, select_value) {
    let e = event.target

    if (e.name === "category") {
      setSelected({
        ...selected,
        index: parseInt(e.value),
        name: inventory[e.value].name,
        cost_value: inventory[e.value].stock.cost_value,
        sale_value: inventory[e.value].stock.sale_value,
      })
      // eslint-disable-next-line
      props.setForm({ ...props.form, ["amount"]: 0 })
    } else if (e.name === "amount") {
      setSelected({ ...selected, amount: e.value })
    } else if (e.name === "state") {
      props.setForm({ ...props.form, [e.name]: e.value })

      if (e.value) await getCities(e.value)
      else setCities([])
    } else if (e.name === "cost_value") {
      setSelected({ ...selected, [e.name]: e.value })
    } else if (e.name === "sale_value") {
      setSelected({ ...selected, [e.name]: e.value })
    } else if (e.name === "client_name") {
      if (e.value.length > 2) {
        await props.handleProvider(null, null, e.value)
      }
    }

    if (e.name === "name") {
      if (select_value) {
        props.setForm({
          ...props.form,
          name: select_value,
          doc_type: select_value.doc_type.id,
          doc_number: select_value.doc_number,
        })
        setCities([])
        await _handleProvider(null, select_value)
      }
    } else {
      props.setForm({ ...props.form, [e.name]: e.value })
    }
  }

  const handleChangeInventory = (event) => {
    let e = event.target
    let value = e.value
    if (e.name === "sale_value" || e.name === "cost_value")
      value = parseInt(e.value)
    props.setFormInventory({ ...props.formInventory, [e.name]: value })
  }

  async function _handleProvider(e, data) {
    if (data) {
      await props.handleProvider(data.doc_type.id, data.doc_number)
    } else if (props.form.doc_type && props.form.doc_number) {
      await props.handleProvider(props.form.doc_type, props.form.doc_number)
    }
  }

  // Handle list of items
  async function handleAdd() {
    // Valida si el producto/servicio a agregar ya se encuentra en la tabla
    let array = [...props.form.selected_categories]
    let found = array.some((el) => el.id === inventory[selected.index].id)

    if (!parseInt(selected.amount)) {
      selected.amount = 1
      // eslint-disable-next-line
      setSelected({ ...selected, ["amount"]: 1 })
    }
    if (!found) {
      let invent = inventory[selected.index]
      array.push({
        id: invent.id,
        name: invent.name,
        amount: selected.amount ? selected.amount : 1,
        sale_value: selected.sale_value
          ? selected.sale_value
          : invent.stock.sale_value,
        cost_value: selected.cost_value
          ? selected.cost_value
          : invent.stock.cost_value,
        value: selected.cost_value
          ? selected.cost_value
          : invent.stock.cost_value,
      })

      let total_sum = 0
      // eslint-disable-next-line
      array.map((obj) => {
        total_sum += obj.amount * obj.cost_value
      })

      props.setForm({
        ...props.form,
        // eslint-disable-next-line
        ["selected_categories"]: array,
        // eslint-disable-next-line
        ["total"]: total_sum,
      })
    }
  }

  const handleAddProduct = (event) => {
    if (!props.form.description) {
      showAlert(event, "Debe indicar el nombre del producto")
      return
    } else if (!parseInt(props.form.amount)) {
      showAlert(event, "Debe indicar la cantidad del producto")
      return
    } else if (!props.form.value) {
      showAlert(event, "Debe indicar el precio del producto")
      return
    }

    let array = [...props.form.selected_categories]
    let found = array.some((el) => el.description === props.form.description)

    if (!found) {
      array.push({
        description: props.form.description,
        value: parseFloat(props.form.value),
        quantity: props.form.amount ? parseInt(props.form.amount) : 1,

        name: props.form.description,
        cost_value: parseFloat(props.form.value),
        amount: props.form.amount ? parseInt(props.form.amount) : 1,
      })

      let total_sum = 0
      // eslint-disable-next-line
      array.map((obj) => {
        total_sum += obj.quantity * obj.value
      })

      props.setForm({
        ...props.form,
        // eslint-disable-next-line
        ["description"]: "",
        // eslint-disable-next-line
        ["amount"]: 1,
        // eslint-disable-next-line
        ["value"]: 0,
        // eslint-disable-next-line
        ["selected_categories"]: array,
        // eslint-disable-next-line
        ["total"]: total_sum,
      })
    }
  }

  async function handleRemove(e, index) {
    e.preventDefault()

    let array = [...props.form.selected_categories]
    array.splice(index, 1)

    let total_sum = 0
    // eslint-disable-next-line
    array.map((obj) => {
      total_sum += obj.amount * obj.cost_value
    })

    props.setForm({
      ...props.form,
      // eslint-disable-next-line
      ["selected_categories"]: array,
      // eslint-disable-next-line
      ["total"]: total_sum,
    })
  }

  const handleFocus = (event) => event.target.select()

  // Handle send data to create
  async function _handleSubmit(event, register) {
    if (!shopping.is_payment) {
      if (props.form.selected_categories.length <= 0) {
        showAlert(event, "Debe agregar mínimo un producto o servicio")
        return
      } else if (!props.form.name && props.formStep === 1) {
        if (!props.form.client_name) {
          showAlert(event, "Debe indicar el nombre del proveedor")
          return
        }
      } else if (!props.form.doc_type && props.formStep === 1) {
        showAlert(event, "Debe seleccionar un tipo de documento")
        return
      } else if (!props.form.doc_number && props.formStep === 1) {
        showAlert(event, "Debe indicar el número de documento")
        return
      } else if (!props.form.date && props.formStep === 2) {
        showAlert(event, "Debe seleccionar la fecha")
        return
      } else if (parseInt(props.form.payment) && props.formStep === 2) {
        if (!props.form.payment_method) {
          showAlert(event, "Debe seleccionar el método de pago")
          return
        }
      }
    } else {
      // eslint-disable-next-line
      props.setForm({ ...props.form, ["is_payment"]: true })

      if (!props.form.date && props.formStep === 2) {
        showAlert(event, "Debe seleccionar la fecha")
        return
      } else if (!parseInt(props.form.payment) && props.formStep === 2) {
        showAlert(event, "Debe indicar el valor del abono")
        return
      } else if (!props.form.payment_method && props.formStep === 2) {
        showAlert(event, "Debe seleccionar el método de pago")
        return
      }
    }

    if (register) {
      props.handleSubmit(props.form)
    } else nextStep()
  }

  async function _handleCreate(event) {
    if (!props.formInventory.name) {
      showAlert(event, "Debe indicar el nombre del producto")
      return
    } else if (!props.formInventory.cost_value) {
      showAlert(event, "Debe indicar el costo del producto")
      return
    } else if (!props.formInventory.sale_value) {
      showAlert(event, "Debe indicar el precio de venta del producto")
      return
    } else if (!props.formInventory.sku) {
      showAlert(event, "Debe indicar la referencia del producto")
      return
    }

    async function fetchData() {
      await props.handleCreate()
      await getInventory()
    }
    fetchData()
  }

  // Manage modals open and close
  const handleClosed = () => {
    setActiveModal(false)
  }

  const handleCloseAlertModal = () => {
    setAlertModal(false)
  }

  const openFormInventory = () => {
    props.setFormStep("inventory")
  }

  const closeFormInventory = () => {
    props.clearForm()
    props.setFormStep(0)
  }

  const showAlert = (event, text) => {
    event.preventDefault()
    setAlertModal(true)
    setModalData({
      title: "¡Alerta!",
      text: text,
      btn: "Aceptar",
    })
    return
  }

  // Manage steps change
  const nextStep = () => {
    props.setFormStep((cur) => cur + 1)
  }

  const backStep = () => {
    if (shopping.is_payment) history.push(cancel)
    else props.setFormStep((cur) => cur - 1)
  }

  return (
    <>
      <CardForm onSubmit={_handleSubmit}>
        {props.formStep === 0 && (
          <AddItem
            form={props.form}
            selected={selected}
            inventory={inventory}
            activeModal={activeModal}
            modalInformation={modalInformation}
            type={type}
            payment={shopping}
            category={category}
            cancel={cancel}
            openFormInventory={openFormInventory}
            handleChange={handleChange}
            handleFocus={handleFocus}
            handleAdd={handleAdd}
            handleAddProduct={handleAddProduct}
            handleRemove={handleRemove}
            handleClosed={handleClosed}
            handleSubmit={_handleSubmit}
          />
        )}
        {props.formStep === 1 && (
          <ClientInformation
            form={props.form}
            clients={props.clients}
            category={category}
            documents={documents}
            states={states}
            cities={cities}
            backStep={backStep}
            handleChange={handleChange}
            handleSearch={_handleProvider}
            handleSubmit={_handleSubmit}
          />
        )}
        {props.formStep === 2 && (
          <BillingInformation
            form={props.form}
            type={type}
            payment={shopping}
            isPayment={isPayment}
            backStep={backStep}
            date={CustomDate.date1(props.form.date)}
            handleChange={handleChange}
            handleFocus={handleFocus}
            handleSubmit={_handleSubmit}
          />
        )}
        {props.formStep === "inventory" && (
          <AddInventory
            form={props.form}
            formInventory={props.formInventory}
            selected={selected}
            inventory={inventory}
            activeModal={activeModal}
            modalInformation={modalInformation}
            type={type}
            payment={shopping}
            category={category}
            cancel={cancel}
            handleFocus={handleFocus}
            openFormInventory={openFormInventory}
            closeFormInventory={closeFormInventory}
            handleChangeInventory={handleChangeInventory}
            handleCreate={_handleCreate}
          />
        )}
      </CardForm>
      <Modal
        activeModal={alertModal}
        handleClosed={handleCloseAlertModal}
        title={modalData.title}
        text={modalData.text}
        errors={modalData.errors}
        btn={modalData.btn}
      />
    </>
  )
}

import styled from 'styled-components';

// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.8);
  /* overflow:none; */
  position:fixed;
  top:0;
  left:0;
  z-index:200;
  display:flex;
  justify-content:center;
  align-items:center;
  transition:all 500ms ease;
  opacity:0;
  visibility:hidden;
  /* transform:translateY(-30%); */
  &.activate {
    /* transform:translateY(0%); */
    opacity:1;
    visibility:visible;

  }
`

export const Card = styled.div`
  width:400px;
  min-height:500px;
  border-radius:20px;
  /* background-color:#fff; */
  background-color:${props => props.theme.colors.main};
  padding:4em 3em;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  text-align:center;
  transition:all 2s ease;
  box-shadow: 1px 7px 25px rgba(0, 0, 0, 0.6);
  ${mobile} {
    width:85%;
  }

`
export const Title = styled.h2`
  font-size:2.5em;
  color: #fff;
  /* color:#999999; */
  /* color:#30cb00; */
  padding-bottom:1em;
`
export const Text = styled.p`
  font-size:1.2em;
  font-weight:400;
  color:#ffffff;
  /* padding-top:1em; */
  ${mobile}{
    font-size:.9rem;
  }
`
export const Btn = styled.button`
  width:100%;
  text-align:center;
  color:#fff;
  background-color:#00c0ff;
  border:2px solid #00c0ff;
  margin-top:1em;
  padding:1em .5em;
  border-radius:5px;
  font-weight:bold;
  font-size:.9rem;
  text-transform:uppercase;
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color:#05a3ce;
  }
  ${mobile} {
    width:100%;
  }
`


export const Button = styled.button`
  background-color: ${props => props.primary ? "#fff" : props.theme.colors.dark};
  /* background-color: ${props => props.theme.colors.main}; */
  min-width: 200px;
  max-width: 400px;
  color: ${props => ( props.ghost ? "#fff" : props.theme.colors.main)};
  text-align:center;
  border:3px solid ${props => props.primary ? "#fff" : props.theme.colors.main};
  border-radius:30px;
  padding:1em 2em;
  color:${props => props.primary ? props.theme.colors.dark : props.theme.colors.second};
  /* text-transform: uppercase; */
  font-weight:500;
  font-size:.9rem;
  margin-top:2em;
  transition: all 0.3s ease-in;
  ${mobile} {
    width: 100%;
    font-size:1.5em;
  }
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.second};
    color: #fff;
    /* color:${props => props.primary ? props.theme.colors.main : props.theme.colors.dark}; */
  }
  &:active {
    transition: all 0.5s ease-in-out;
    opacity:.7;
  }
  &.success {
    background-color: ${props => props.theme.colors.second};
    border:2px solid ${props => props.theme.colors.second};
    &:hover {
      transition: all 0.5s ease-in-out;
      background-color:${props => props.theme.colors.dark};
      color:${props => props.theme.colors.second};
    }
    &:active {
      transition: all 0.5s ease-in-out;
      opacity:.7;
    }
  }
`
import styled from "styled-components"
import { NavLink } from "react-router-dom"

// const desktopStartWidth = 1023;
const tabletStartWidth = 768
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.nav`
  background: ${(props) => props.theme.colors.white};
  width: 23%;
  /* min-width:220px; */
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  height: 100%;
  z-index: 100;
  padding: 2rem 0;
  -webkit-box-shadow: 4px -3px 11px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px -3px 11px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 4px -3px 11px -7px rgba(0, 0, 0, 0.75);

  ${mobile} {
    background: ${(props) => props.theme.colors.main_opacity};
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5px;
    bottom: 0;
    height: auto;
  }
`

export const FigureBtn = styled.figure`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.light};

  ${mobile} {
    display: none;
  }
`

export const Figure = styled.figure`
  padding: 1rem;
  display: flex;
  justify-content: center;
  ${mobile} {
    display: none;
  }
`

export const Logo = styled.img`
  height: 90px;
  ${mobile} {
    display: none;
  }
`

export const BtnItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 1.5em 2em;
  transition: all 0.3s ease-in;
  &:hover {
    background: ${(props) => props.theme.colors.dark};
    transition: all 0.5s ease-in-out;

    & > h3,
    figure {
      color: ${(props) => props.theme.colors.third};
      transition: all 0.3s ease-in;
      &:hover {
        transition: all 0.5s ease-in-out;
        ${mobile} {
          color: #fff;
        }
      }
    }
  }
  &:active {
    background: ${(props) => props.theme.colors.third};
    color: ${(props) => props.theme.colors.main};
    opacity: 50%;
    transition: all 0.5s ease-in-out;
  }
  &.item_active {
    background: ${(props) => props.theme.colors.dark};
    opacity: 100%;
    transition: all 0.5s ease-in-out;

    & > h3,
    figure {
      color: ${(props) => props.theme.colors.third};
      font-weight: 600;
      transition: all 0.3s ease-in;
      &:hover {
        transition: all 0.5s ease-in-out;
      }
      ${mobile} {
        color: ${(props) => props.theme.colors.third};
        &:hover {
          transition: all 0.5s ease-in-out;
          color: ${(props) => props.theme.colors.third};
        }
      }
    }
  }

  ${mobile} {
    padding: 0;
    opacity: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 2px;
    flex: 1;
    & > h3 {
      font-size: 0.7rem;
    }
    & > h3,
    figure {
      display: flex;
      padding: 0;
    }
    &:hover {
      background: ${(props) => props.theme.colors.main};
      opacity: 100%;

      & > h3,
      figure {
        &:hover {
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
`

export const FigureItem = styled.figure`
  width: 25px;
  height: 25px;
  /* background-color:pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.main};

  ${mobile} {
    color: ${(props) => props.theme.colors.light};
    width: 28px;
    height: 28px;
  }
`

export const SubTitleMenu = styled.h5`
  color: ${(props) => props.theme.colors.third};
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  text-align: center;
  ${mobile} {
    display: none;
  }
`
export const TitleItem = styled.h3`
  padding-left: 0.7rem;
  color: ${(props) => props.theme.colors.main};
  font-weight: bold;
  ${mobile} {
    color: ${(props) => props.theme.colors.white};
    display: none;
  }
`

import React, { useRef } from 'react'
import { MdBusiness } from 'react-icons/md'
import {TextCard, Card, FigureCircle, Row, Column, Input, Img} from './styles.js'


export const TradeImage = ({img, data, handleChangeImage}) => {

    const fileInput = useRef(null)

    return (
        <Card>
            <Row>
                <Column align="center" lg={10} sm={20} xs={20}>
                    <FigureCircle top cancel onClick={() => fileInput.current.click()}>
                        {img ? 
                            <Img src={img} alt="image"></Img>
                        :
                            <MdBusiness size='3.5rem' />
                        }
                        <Input type="file" name="myImage" ref={fileInput} onChange={handleChangeImage} />
                    </FigureCircle>
                </Column>
                <Column align="left" lg={90} sm={80} xs={80}>
                    <TextCard title>{data.tradename}</TextCard>
                    <TextCard subtitle>{data.identification}</TextCard>
                    <TextCard text>{data.activity}</TextCard>
                </Column>
            </Row>
        </Card>
    )
}

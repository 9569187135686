export const Utilities = {
    
    addZeros: (num, size) => {
        if (num) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }
        else return "";
    },
    fields: (field) => {
        let data = {
            details: 'Producto/Servicio: ',
            client: 'Datos del cliente: ',
            date: 'Fecha: ',
            payment_type: 'Tipo de pago: ',
            payment_method: 'Método de pago: ',
            doc_type: 'Tipo de documento: ',
            city: 'Ciudad: ',
            name: 'Nombre del cliente: ',
            doc_number: 'Número de documento: ',
            address: 'Dirección: ',
            phone: 'Teléfono: ',
            cellphone: 'Celular: ',
            email: 'Correo: ',
            observations: 'Observaciones: ',
            observation: 'Observaciones: ',
            quantity: 'Cantidad (producto/servicio): ',
            validations_error: '',
            validation_errors: '',
            validations_errors: '',
            value: "Valor: ",
            category_id: "Categoría: ",
            first_name: 'Primer nombre: ',
            last_name: 'Apellido: ',
            tradename: 'Comercio: ',
            identification_number: 'Número de identificación: ',
            identification: 'Número de identificación: ',
        }
        return data[field];
    },
    returnErrors: (data) => {
        if (!data) return [];
        let array = [];

        for (let row in data) {

            if (typeof(data[row]) === 'string') {
                array.push({name: Utilities.fields(row), error: data[row]})
            }
            else {
                for (let detail in data[row]) {
                    if (typeof(data[row][detail]) === 'string') {
                        array.push({name: Utilities.fields(row), error: data[row][detail]})
                    }
                    else {
                        for (let detail_row in data[row][detail]) {
                            if (typeof(data[row][detail][detail_row]) === 'string') {
                                array.push({name: Utilities.fields(detail), error: data[row][detail][detail_row]})
                            }
                        }
                    }
                }
            }
        }

        return array;
    },
    returnReport: (data, type) => {
        if (!data) return [];
        let array = [];

        if (type === 'indicators' || type === 'headers') {
            for (let row in data) {
                if (row !== 'from_date' && row !== 'to_date') {
                    if (typeof(data[row]) === 'string' || typeof(data[row]) === 'number') {
                        let name = row
                        if (type === 'headers') name = ''
                        array.push({name: name, value: data[row]})
                    }
                }
            }
        }
        else {
            for (let row in data) {
    
                if (row !== 'header') {
                    if (type === 'balance') array.push({name: row, is_title: true, value: ' '})
    
                    if (typeof(data[row]) === 'string' || typeof(data[row]) === 'number') {
                        let value = data[row]

                        if (typeof(data[row]) === 'number') {
                            value = Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(data[row])
                        }

                        if (type === 'balance') {
                            array.push({name: row, value: value})
                        }
                        else {
                            let color = row === 'Costo de mercancias' || row === 'Gastos administrativos y de ventas' ? 'red_brand' : 'text'
                            let total = row === 'Utilidad bruta' || row === 'Utilidad operacional'
                            let total_color = '#CBEAF6'
                            array.push({name: row, value: value, color: color, is_total: total, total_color: total_color})
                        }
                    }
                    else {
                        for (let detail in data[row]) {
                            if (typeof(data[row][detail]) === 'string' || typeof(data[row][detail]) === 'number') {
                                let value = data[row][detail]

                                if (typeof(data[row][detail]) === 'number') {
                                    value = Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(data[row][detail])
                                }

                                let color = row === 'Pasivos' ? 'red_brand' : 'text'
                                let total = false
                                let total_color = ''
                                let title = detail
    
                                if (detail === 'Total' || detail === 'Capital') {
                                    total = true 
                                    total_color = row === 'Pasivos' ? '#F4DFED' : '#CBEAF6' 
                                    title = detail === 'Total' ? `${detail} ${row}` : detail
                                }
                                array.push({name: title, value: value, color: color, is_total: total, total_color: total_color})
                            }
                        }
                    }
                }            
            }
        }

        return array;
    },
}
import styled from 'styled-components'

const mobile_sm = `@media (max-width: 768px)`
const mobile_xs = `@media (max-width: 350px)`

const mobile_768 = `@media (max-width: 768px)`
const mobile_500 = `@media (max-width: 500px)`
const mobile_400 = `@media (max-width: 400px)`
const mobile_330 = `@media (max-width: 330px)`


export const Container = styled.div`
    width:100%;
    background: ${props => props.theme.colors.dark};
    padding:.5rem 1rem;
    border-radius:1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    margin-bottom:1rem;
    padding-top:.8rem;
    padding-bottom:1rem;
`

export const Section = styled.section`
    padding:0 0 0 1rem;
    flex:1;
    flex-direction:column;
    justify-content:center;    
`

export const FigureCircle = styled.figure`
    background: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.text};
    width:3rem;
    height:3rem;
    flex-direction:column;
    color:#fff;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const CardTitle = styled.h6`
    align-items:center;
    color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.text)};
    font-size: ${props => props.size ? `${props.size}rem` : '1rem'};
    margin-bottom: .2rem;
    text-align: ${props => props.align ? props.align : 'center'};
`

export const CardSubtitle = styled.span`
    color: ${props => (props.color ? props.theme.colors[props.color] : '#fff')};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    font-size: ${props => (props.size ? props.size : '.8rem')};
    text-align: ${props => props.align ? props.align : 'left'};
`

export const CardCategory = styled.span`
    color: ${props => (props.color ? props.theme.colors[props.color] : '#fff')};
    font-size: ${props => (props.size ? props.size : '.8rem')};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    text-align: left;
    margin-left: 2px;
`

export const CardValue = styled.span`
    color: ${props => (props.color ? props.theme.colors[props.color] : '#fff')};
    font-size: ${props => (props.size ? props.size : '.8rem')};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    text-align: left;
    margin-left: 2px;
    
    width: 100%;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    
    ${mobile_768} {
        width: 300px;
    }
    ${mobile_500} {
        width: 200px;
    }
    ${mobile_400} {
        width: 160px;
    }
    ${mobile_330} {
        width: 120px;
    }
`

export const Button = styled.button`
    width:auto;
    height:auto;
    color: ${props => props.text ? props.theme.colors[props.text] : '#fff'};
    background:  ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.third};
    padding: .6rem;
    border-radius: 1rem;
    min-width: 90%;
    margin-top: .6rem;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:all 500ms ease;
    &:hover {
        opacity: .7;
    }
    &:disabled {
        opacity: .5;
        cursor: default
    }
`

export const Separator = styled.div`
    width: 100%;
`

export const Row = styled.div`
    display:flex;   
    margin-top: ${props => props.top ? props.top : '.2rem'};
`

export const Column = styled.div`
    width: ${props => props.lg ? `${props.lg}%` : '50%'};
    text-align: ${props => props.align ? props.align : 'center'};
    ${mobile_sm} {
        width: ${props => props.sm ? `${props.sm}%` : '50%'};
    }
    ${mobile_xs} {
        width: ${props => props.xs ? `${props.xs}%` : '50%'};
    }
`

export const Ship = styled.div`
    background:  ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.third};
    padding: .2rem;
    border-radius: 1rem;
    font-size: .6rem;
    min-width: 30%;
`
import React, { useEffect, useState } from "react"
import { Container, Section, SectionList } from "./styles"
import { MdTimeline } from "react-icons/md"
import { TitleAndInfo } from "../../../components/molecules/TitleAndInfo"
import { BtnNewCard } from "../../../components/atoms/cards/BtnNewCard"
import { EmptyMessage } from "../../../components/atoms/EmptyMessage"
import { CardFinancialInfo } from "../../../components/atoms/cards/CardFinancialInfo"
import { Routes } from "../../../routes"
import { getBudgetIncome } from "../../../api/requests"

export const InitialBudgetIncome = () => {
  const [listBudgetIncome, setListBudgetIncome] = useState([])
  const [reList, setReList] = useState(false)
  const [skeleton, setSkeleton] = useState(false)
  const [initialInfo, setInitialInfo] = useState(false)

  useEffect(() => {
    setInitialInfo(sessionStorage.getItem("initial_info") === "true")
  }, [])

  function updateItems() {
    loadBudgetIncome()
  }

  async function loadBudgetIncome() {
    const response = await getBudgetIncome()
    if (response.status === 200) {
      setListBudgetIncome(response.data.budgets)
      setTimeout(() => {
        setSkeleton(false)
      }, 1000)
    }
  }

  useEffect(() => {
    setSkeleton(true)
    loadBudgetIncome()
  }, [reList])

  return (
    <Container navbar={initialInfo}>
      <Section>
        <TitleAndInfo
          btnPrev={true}
          pathPrev={Routes.app_initial_information}
          titlePage="Presupuesto ingresos proyectados"
          subtitleInfo="Es momento de registrar la proyección del presupuesto de ingresos."
          tooltip="budget-income"
        />
        <BtnNewCard
          title="Agregar nuevo presupuesto."
          path={Routes.app_initial_budget_income_form}
        />
      </Section>
      <SectionList count={listBudgetIncome.length < 4}>
        {skeleton && (
          <>
            {[1, 2, 3].map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <CardFinancialInfo
                    title="prueba"
                    value="0"
                    skeleton={skeleton}
                  />
                </React.Fragment>
              )
            })}
          </>
        )}
        {!skeleton && listBudgetIncome.length === 0 && (
          <EmptyMessage message="Aún no existen Presupuestos de ingresos registrados." />
        )}
        {!skeleton &&
          listBudgetIncome.map((budgetItem, i) => {
            return (
              <React.Fragment key={i}>
                <CardFinancialInfo
                  id={budgetItem.id}
                  title={budgetItem.date}
                  value={budgetItem.value}
                  category={budgetItem.category.name}
                  color="azul"
                  icon={<MdTimeline size="2rem" />}
                  reList={reList}
                  setReList={setReList}
                  redirect={Routes.app_initial_budget_income}
                  redirectEdit={`/edit-initial-budget-income-form/${budgetItem.id}`}
                  updateItems={updateItems}
                  showIcons={true}
                />
              </React.Fragment>
            )
          })}
      </SectionList>
    </Container>
  )
}

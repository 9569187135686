import {
  getWompiEvent,
  setSubsciptionActive,
  getSubscriptionBilling,
} from "../api/requests"

export const WompiTransactionValidated = {
  wompiValidation: (userId) => {
    // Saca el valor del user_id de que viene incluido en la referencia, que equivale al numero antes del primer guion (-)
    function transactionReference(reference) {
      let arr = reference.split("-")
      return arr
    }

    // Metodo que resta 5 horas a la fecha
    function subtractTimeFromDate(objDate) {
      var numberOfMlSeconds = objDate.getTime()
      var addMlSeconds = 5 * 60 * 60000
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds)

      return newDateObj
    }

    function dateFormat(date) {
      let year = date.toLocaleString("default", { year: "numeric" })
      let month = date.toLocaleString("default", { month: "2-digit" })
      let day = date.toLocaleString("default", { day: "2-digit" })
      let formattedDate = year + "-" + month + "-" + day
      return formattedDate
    }

    // Activa la subscripcion del usuario
    async function wompiEventValidated() {
      try {
        // Se obtienen la respuesta de wompi cuando termina una transacción
        await getWompiEvent().then(async (response) => {
          let hoy = new Date(Date.now())
          if (response.length > 0) {
            for (let i = 0; i < response.length; i++) {
              let transaction_date = new Date(response[i]["sent_at"])
              // Se le restan 5 horas a la hora de wompi para que coincida con la hora local
              let date_transaction_format =
                subtractTimeFromDate(transaction_date)
              // Se filtran las transacciónes de wompi  por la fecha de hoy

              if (dateFormat(date_transaction_format) === dateFormat(hoy)) {
                let reference_user_id = transactionReference(
                  response[i].reference
                )
                // Compara el userId del usuario logeado con el id del usuario de la transacción que viene incluida en la referencia

                if (userId.toString() === reference_user_id[0]) {
                  // Se filtran por las transacciones con status "APPROVED"
                  if (response[i].status === "APPROVED") {
                    // Se obtienen las subscripcionBilling
                    await getSubscriptionBilling().then(
                      async (subscriptionBilling) => {
                        let todayUserSubscription = []

                        for (let m = 0; m < subscriptionBilling.length; m++) {
                          // Se filtran las subscripciones por el día de hoy

                            // Filtra las subcripiones por las que pertenecen al usuario logeado
                            if (subscriptionBilling[m].user.id === userId) {
                              // Se filtran por las subscripciones que no estan activas
                              if (
                                subscriptionBilling[m].subscription_active ===
                                false
                              ) {
                                // Hasta este punto tenemos las subscripciones del día de hoy no activas del usuario
                                let reference_sub_id = transactionReference(
                                  response[i].reference
                                )
                                // Filtra por la subs que coinciden con el id de la transaccion aprobada
                                if (
                                  subscriptionBilling[m]["subscription"][
                                    "id"
                                  ].toString() === reference_sub_id[1]
                                ) {
                                  todayUserSubscription.push(
                                    subscriptionBilling[m]
                                  )
                                  // Se activa la subscripción con id del usuario y id de subscripción que coincidan con la transacción aprobada del usuario
                                  return await setSubsciptionActive(
                                    todayUserSubscription[0].id
                                  ).then(console.log("Se activó la cuenta"))
                                }
                              } else {
                                //console.log(
                                //  "El usuario no tiene subscripciones para activar"
                                //);
                              }
                            } else {
                              //console.log(
                              //  "el usuario no tiene subs del día de hoy"
                              //);
                            }
                          }
                        
                      }
                    )
                  } else {
                    //console.log(
                    //  "El usuario no tiene transacciones aprobadas el día de hoy"
                    //);
                  }
                } else {
                  //console.log("El usuario no tiene transacciones el día de hoy")
                }
              }
            }
          } else {
            //console.log("Hoy no hay wompi transactions");
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    return wompiEventValidated()
  },
}

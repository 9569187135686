import React, { useEffect, useState } from "react"
import { Container, Section, TitleStep } from "./styles"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { FormStocksContainer } from "../../../../containers/FormStocksContainer"
import { CardInventoryDetail } from "../../../../components/atoms/cards/CardInventoryDetail"
import { MdLocalMall } from "react-icons/md"
import { Routes } from "../../../../routes"
import { useParams } from "react-router-dom"
import { getInventoryItem } from "../../../../api/requests"

export const StockForm = () => {
  const { id } = useParams()
  const [listInventoryItem, setListInventoryItem] = useState({
    category: "",
    stock: [{ id: "", quantity: "" }],
  })

  async function loadInventoryItem(id) {
    const response = await getInventoryItem(id)
    if (response.status === 200) {
      setListInventoryItem(response.data)
    }
  }

  useEffect(() => {
    loadInventoryItem(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Section>
        <TitleAndInfo
          // pathPrev = {Routes.app_inventory_detail}
          btnPrev
          pathPrev={`/inventory-detail/${listInventoryItem.id}`}
          titlePage="Nuevo Stock"
          darktheme="true"
          tooltip="inventory"
        />
        <CardInventoryDetail
          // redirectEdit={`/edit-initial-assets-form/${assetItem.id}`}
          id={listInventoryItem.id}
          title={listInventoryItem.category}
          value={listInventoryItem.name}
          icon={<MdLocalMall size="2rem" />}
          redirect={Routes.app_initial_assets}
          darktheme="true"
        />
        <TitleStep>Información del Stock</TitleStep>
        <FormStocksContainer
          editId={id}
          category={listInventoryItem.category}
        />
      </Section>
    </Container>
  )
}

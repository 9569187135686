import React, { Fragment, useEffect, useState } from "react"
import { Container, Section, Title, IconWallet } from "./styles"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import {
  deleteNotifications,
  getExpensesDetail,
  getNotifications,
} from "../../../../api/requests"
import { DataUtil } from "../../../../utils/data"
import { CardNotification } from "../../../../components/atoms/cards/CardNotification"
import { CardEmpty } from "../../../../components/atoms/cards/CardEmpty"
import { useHistory } from "react-router"
import { Modal } from "../../../../components/molecules/Modals/Modal"
import { Utilities } from "../../../../utils/utilities"

export const Notifications = () => {
  const history = useHistory()
  const [notifications, setNotifications] = useState([])
  const [newNotifications, setNewNotifications] = useState([])
  const [activeModal, setActiveModal] = useState(false)
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })

  useEffect(() => {
    async function fetchData() {
      await getNotification()
    }
    fetchData()
  }, [])

  async function getNotification() {
    const response = await getNotifications()
    if (response.status === 200) {
      let array = []
      let arrayNew = []
      let resp = response.data

      // eslint-disable-next-line array-callback-return
      resp.map((obj) => {
        obj.color = "pink"
        obj.show = true

        if (obj.status === "DESCARTADA" || obj.status === "ACEPTADA")
          obj.show = false
        if (obj.status === "DESCARTADA") obj.color = "text"
        obj.title = obj.status.replace(/.$/, "O")

        if (obj.status !== "ACTIVA") array.push(obj)
        else arrayNew.push(obj)
      })

      setNotifications(array)
      setNewNotifications(arrayNew)
    }
  }

  async function getDetail(id) {
    const response = await getExpensesDetail(id)
    if (response.status === 200) {
      let resp = response.data

      if (resp.payment_method === "BANCO") resp.type = "bank"
      else if (resp.payment_method === "EFECTIVO") resp.type = "cash"
      else resp.type = "credit"

      return resp
    }
  }

  async function handleReject(id) {
    const response = await deleteNotifications(id)
    if (response.status === 204) {
      showMessage(
        "Recordatorio descartado",
        `El recordatorio # ${Utilities.addZeros(id, 4)} fue descartado`,
        false
      )
      await getNotification()
    }
  }

  async function handleAccept(id) {
    let detail = await getDetail(id)
    history.push({
      pathname: DataUtil.getExpense(detail.type).route,
      state: { state: true, id: id },
    })
  }

  let handleBack = () => {
    history.goBack()
  }

  const handleClosed = () => {
    setActiveModal(false)
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={"#"}
          titlePage={"Notificaciones"}
          darktheme
          handleChange={handleBack}
        />

        <Title>Nuevas: </Title>
        {!newNotifications.length ? (
          <CardEmpty
            message="No hay notificaciones nuevas para mostrar"
            top="1px"
          />
        ) : (
          <Fragment>
            {newNotifications.map((row) => (
              <CardNotification
                key={row.id}
                title="Recordatorio"
                titleSecond="Gasto: "
                titleThird="Categoría: "
                titleFourth="Valor: "
                data={row}
                icon={<IconWallet size="2.2rem" color="dark" />}
                color={row.color}
                clickReject={handleReject}
                clickAccept={handleAccept}
              />
            ))}
          </Fragment>
        )}

        <Title>Anteriores: </Title>
        {!notifications.length ? (
          <CardEmpty message="No hay notificaciones para mostrar" top="1px" />
        ) : (
          <Fragment>
            {notifications.map((row) => (
              <CardNotification
                key={row.id}
                title="Recordatorio"
                titleSecond="Gasto: "
                titleThird="Categoría: "
                titleFourth="Valor: "
                data={row}
                icon={<IconWallet size="2.2rem" color="dark" />}
                color={row.color}
                clickReject={handleReject}
                clickAccept={handleAccept}
              />
            ))}
          </Fragment>
        )}
      </Section>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </Container>
  )
}

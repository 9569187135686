import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.8);
  position:fixed;
  top:0;
  left:0;
  z-index:200;
  display:flex;
  justify-content:center;
  align-items:center;
  transition:all 500ms ease;
  opacity:0;
  visibility:hidden;
  &.activate {
    opacity:1;
    visibility:visible;
  }
`

export const Card = styled.div`
  width:400px;
  min-height:500px;
  border-radius:20px;
  background-color:${props => props.theme.colors.main};
  padding:4em 3em;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  text-align:center;
  transition:all 2s ease;
  box-shadow: 1px 7px 25px rgba(0, 0, 0, 0.6);
  ${mobile} {
    width:85%;
  }
`

export const Title = styled.h3`
  font-size:1.4rem;
  color: #fff;
  line-height:0rem;
`

export const Text = styled.p`
  font-size:.8rem;
  margin-bottom:1.6rem;
  font-weight:400;
  color:#ffffff;
  ${mobile}{
    font-size:.9rem;
  }
`

export const FigureCircle = styled.figure`
    width:4rem;
    height:4rem;
    background: ${props => props.theme.colors[props.color] || '#FFF'};
    color:#fff;
    border-radius:50%;
    margin-left:auto;
    margin-right:auto;
    display:flex;
    justify-content:center;
    align-items:center;
`

const ButtonStyle = css`
  width:100%;
  color:#fff;
  font-size:20px;
  font-weight:bold;
  font-family: ${props => props.theme.fonts.family};
  background: ${props => props.theme.colors.dark};
  padding:1.3rem 1rem;
  border-radius:16px;
  letter-spacing: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .05);
  align-items:center;
`

export const Button = styled.button`
  ${ButtonStyle}
`

export const ButtonLink = styled(Link)`
  ${ButtonStyle}
`
import styled from 'styled-components'

export const Container = styled.div`
    width:100%;
    text-align:center;
    margin:auto;
    vertical-align: middle;
`

export const ElementsContainer = styled.div`
    width:100%;
    height:100%;
    text-align:center;
    margin-top: ${props => props.top ? props.top : '50px'};
    vertical-align: middle;
`

export const TitleCard = styled.h3`
    color: ${props => props.theme.colors.text};
    width:100%;
    font-size: 16px;
    margin-top: 1rem;
`

export const Button = styled.button`
  width: 100%;
  margin: auto;
  color: ${props => props.theme.colors.third};
  background: ${props => props.theme.colors.dark};
  font-weight: bold;
  padding: 1.2rem;
  border-radius: 2rem;
  margin: 1rem 0;
`




import styled from 'styled-components';

// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const PreContainer = styled.div`

    width:100%;
    height:100%;
    background: ${props => props.theme.colors.text_light};
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
        width:100%;
  }
`
export const Container = styled.div`
    max-width:800px;

    width:100%;
    min-height:100%;
    background: ${props => props.theme.colors.light};
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
        width:100%;
        padding-left:0;
  }
`

export const SubContainer = styled.div`
    max-width:800px;
    width:100%;
    display:flex;
    align-items:center;
    background: ${props => props.theme.colors.third};
    margin-bottom:1.5rem;
    ${mobile} {
        border-radius:0 0 10px 10px; 
    }

`

export const Section = styled.section`
    max-width:800px;
    width:100%;
    height:fit-content;
    background: ${props => props.theme.colors.third};

    padding: 2rem 2rem 1rem;
    margin: 0 auto;
    display:flex;
    /* justify-content:space-between; */
    flex-direction:column;
    ${mobile} {
        border-radius:0 0 10px 10px; 
    }
`

export const SubSection = styled.section`
    padding:1em 0;

`

export const SectionTitle = styled.section`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-left:1em;

`

export const SubSectionTitle = styled.section`
    display:flex;
    flex-direction:column;
    flex:1;
`

export const TitleInventory = styled.h4`
    ${mobile} {
  }
`

export const TextItem = styled.h5`
    font-weight:500;
    padding-left:1em;
    color: ${props => props.theme.colors.main};
    font-weight:700;
    ${mobile} {
  }
`

export const InfoContainer = styled.section`
    display:flex;
    flex-direction:row;
    
    ${mobile} {
  }
`
export const TextCategory = styled.h5`
    font-weight:500;
    color: ${props => props.theme.colors.main};
    padding-bottom:.5rem;


`

export const TextDescription = styled.h5`
    color: ${props => props.theme.colors.main};
    font-weight:500;
    padding-bottom:.5rem;
    padding-left:2em;

    ${mobile} {
  }
`


export const TextInfo = styled.h5`
    color: ${props => props.theme.colors.main};
    font-weight:500;
    padding-bottom: ${props => (props.end ? '' : '.5rem')};
    padding-left:.5em;

    ${mobile} {
  }
`

export const Button = styled.button`
    width:40px;
    height:40px;
    border-radius:50%;
    color: #fff;
    background: ${props => props.theme.colors.main};
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:1rem;
    transition:all 500ms ease;
    &:hover {
        color: ${props => props.theme.colors.third};
    }
`

export const Line = styled.div`
    margin:1em 0;
    border-bottom:1px solid ${props => props.theme.colors.main};
    ${mobile} {
        margin:1.5em 0;

  }
`

export const SectionList = styled(Section)`
    height:fit-content;
    flex-direction:row;
    flex-wrap:wrap;
    padding: 0 2rem 2rem;
    /* flex-direction:column-reverse; */
    justify-content:space-between;
    align-items:center;
    background: ${props => props.theme.colors.light};

    ${mobile} {
        padding-bottom:3.2rem;
        

  }


`
export const shareLink = () =>
  `${window.location.origin}/my-catalog?authorization=${
    JSON.parse(localStorage.getItem("user"))?.id || ""
  }`;

export const fakeResponse = {
  data: [
    {
      id: 9,
      name: "servicio contable",
      category: "SERVICIO",
      sku: "0003",
      is_active: true,
      description: "outsourcing",
      total: null,
      stock: {
        id: 56,
        quantity: 0,
        available: 0,
        cost_value: 40000,
        sale_value: 120000,
        is_initial: false,
        is_active: true,
        created: "2023-05-04",
      },
    },
    {
      id: 56,
      name: "Queso cuajada x 500 gr",
      category: "PRODUCTO COMPRADO",
      sku: "0005",
      is_active: true,
      description: "Queso de vaca",
      total: 56,
      stock: {
        id: 58,
        quantity: 5,
        available: 1,
        cost_value: 7000,
        sale_value: 8400,
        is_initial: false,
        is_active: true,
        created: "2023-05-01",
      },
    },
    {
      id: 50,
      name: "Yogurt de chocolate x 1 lt",
      category: "PRODUCTO FABRICADO",
      sku: "0001",
      is_active: true,
      description: "leche de vaca",
      total: null,
      stock: {
        id: 45,
        quantity: 1,
        available: 1,
        cost_value: 10000,
        sale_value: 12000,
        is_initial: false,
        is_active: false,
        created: "2023-03-08",
      },
    },
    {
      id: 57,
      name: "Queso Crema x 400 gr",
      category: "PRODUCTO COMPRADO",
      sku: "0006",
      is_active: true,
      description: "Queso crema marca alpina",
      total: 15,
      stock: {
        id: 59,
        quantity: 5,
        available: 5,
        cost_value: 10000,
        sale_value: 13000,
        is_initial: false,
        is_active: true,
        created: "2023-05-01",
      },
    },
    {
      id: 59,
      name: "Kanekalon x 250 gr",
      category: "PRODUCTO COMPRADO",
      sku: "00010",
      is_active: true,
      description: "Extensión de cabello",
      total: null,
      stock: {
        id: 62,
        quantity: 0,
        available: 0,
        cost_value: 6500,
        sale_value: 8000,
        is_initial: false,
        is_active: false,
        created: "2023-05-15",
      },
    },
    {
      id: 60,
      name: "Kamekalon x250gr",
      category: "PRODUCTO COMPRADO",
      sku: "22435",
      is_active: true,
      description: "Extensión de cabello para trensas",
      total: null,
      stock: {
        id: 63,
        quantity: 0,
        available: 0,
        cost_value: 6500,
        sale_value: 8000,
        is_initial: false,
        is_active: false,
        created: "2023-05-15",
      },
    },
    {
      id: 61,
      name: "MaxiAretas",
      category: "PRODUCTO FABRICADO",
      sku: "00011",
      is_active: true,
      description: "Laminada en oro, con pepas Mayuki",
      total: 2,
      stock: {
        id: 64,
        quantity: 4,
        available: 2,
        cost_value: 25646,
        sale_value: 34600,
        is_initial: false,
        is_active: true,
        created: "2023-05-16",
      },
    },
    {
      id: 62,
      name: "ZAPATOS BLANCOS DEPORTIVOS",
      category: "PRODUCTO FABRICADO",
      sku: "00078956",
      is_active: true,
      description: "Color blanco con lineas",
      total: 5,
      stock: {
        id: 65,
        quantity: 15,
        available: 5,
        cost_value: 70000,
        sale_value: 120000,
        is_initial: false,
        is_active: true,
        created: "2023-05-16",
      },
    },
    {
      id: 63,
      name: "Suela de zapato blanco",
      category: "PRODUCTO COMPRADO",
      sku: "00078956566",
      is_active: true,
      description: "ARRIENDO DE LOCAL",
      total: 1,
      stock: {
        id: 81,
        quantity: 5,
        available: 1,
        cost_value: 4000,
        sale_value: 150000,
        is_initial: false,
        is_active: true,
        created: "2023-05-25",
      },
    },
    {
      id: 64,
      name: "diseño de piezas graficas",
      category: "SERVICIO",
      sku: "1999588",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 67,
        quantity: 0,
        available: 0,
        cost_value: 25000,
        sale_value: 50000,
        is_initial: false,
        is_active: true,
        created: "2023-05-16",
      },
    },
    {
      id: 67,
      name: "UÑAS ACRILICAS POR UNIDAD",
      category: "PRODUCTO COMPRADO",
      sku: "6987645674",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 68,
        quantity: 0,
        available: 0,
        cost_value: 10000,
        sale_value: 15000,
        is_initial: false,
        is_active: false,
        created: "2023-05-18",
      },
    },
    {
      id: 68,
      name: "BOLSO DE CUERO CON PERLAS ROJAS",
      category: "PRODUCTO FABRICADO",
      sku: "224599845",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 69,
        quantity: 5,
        available: 0,
        cost_value: 45000,
        sale_value: 100000,
        is_initial: false,
        is_active: false,
        created: "2023-05-19",
      },
    },
    {
      id: 69,
      name: "ASESORIA DE MODA PARA CLIENTES",
      category: "SERVICIO",
      sku: "99987565",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 70,
        quantity: 0,
        available: 0,
        cost_value: 45000,
        sale_value: 100000,
        is_initial: false,
        is_active: true,
        created: "2023-05-19",
      },
    },
    {
      id: 70,
      name: "SIDECARS WOW",
      category: "PRODUCTO FABRICADO",
      sku: "25678945616",
      is_active: true,
      description: "",
      total: 2,
      stock: {
        id: 72,
        quantity: 5,
        available: 2,
        cost_value: 1600000,
        sale_value: 2900000,
        is_initial: false,
        is_active: true,
        created: "2023-05-23",
      },
    },
    {
      id: 71,
      name: "VITAMINA DIABETIZA",
      category: "PRODUCTO COMPRADO",
      sku: "48960",
      is_active: true,
      description: "",
      total: 40,
      stock: {
        id: 74,
        quantity: 40,
        available: 40,
        cost_value: 50000,
        sale_value: 80000,
        is_initial: false,
        is_active: true,
        created: "2023-05-10",
      },
    },
    {
      id: 72,
      name: "Impresión gran formato laser",
      category: "SERVICIO",
      sku: "89678945280",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 75,
        quantity: 0,
        available: 0,
        cost_value: 5000,
        sale_value: 10000,
        is_initial: false,
        is_active: true,
        created: "2023-05-25",
      },
    },
    {
      id: 73,
      name: "Vaso decorado",
      category: "PRODUCTO FABRICADO",
      sku: "459764789246",
      is_active: true,
      description: "",
      total: 1,
      stock: {
        id: 76,
        quantity: 10,
        available: 1,
        cost_value: 15000,
        sale_value: 30000,
        is_initial: false,
        is_active: true,
        created: "2023-05-25",
      },
    },
    {
      id: 58,
      name: "Brasier encaje rojo y cadenitas",
      category: "PRODUCTO FABRICADO",
      sku: "0007",
      is_active: true,
      description: "tela suave",
      total: 4,
      stock: {
        id: 61,
        quantity: 5,
        available: 4,
        cost_value: 50000,
        sale_value: 70000,
        is_initial: false,
        is_active: true,
        created: "2023-05-04",
      },
    },
    {
      id: 54,
      name: "Consultoría 1A1 por 1 hora",
      category: "SERVICIO",
      sku: "0004",
      is_active: true,
      description: "Se acompañara al cliente por una hora",
      total: null,
      stock: {
        id: 51,
        quantity: 0,
        available: 0,
        cost_value: 40000,
        sale_value: 100000,
        is_initial: true,
        is_active: true,
        created: "2023-05-04",
      },
    },
    {
      id: 74,
      name: "CAFÉ TOSTADO GR 500",
      category: "PRODUCTO FABRICADO",
      sku: "458790647",
      is_active: true,
      description: "cafe tostado de más alta calidad",
      total: 5,
      stock: {
        id: 79,
        quantity: 15,
        available: 5,
        cost_value: 11500,
        sale_value: 30000,
        is_initial: false,
        is_active: true,
        created: "2023-05-25",
      },
    },
    {
      id: 75,
      name: "VINO DE MORA 750 ml",
      category: "PRODUCTO FABRICADO",
      sku: "478459710649694",
      is_active: true,
      description: "",
      total: 74,
      stock: {
        id: 82,
        quantity: 80,
        available: 74,
        cost_value: 14000,
        sale_value: 21000,
        is_initial: false,
        is_active: true,
        created: "2023-05-26",
      },
    },
    {
      id: 76,
      name: "MORAS 250 GR",
      category: "PRODUCTO COMPRADO",
      sku: "44789646466988",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 83,
        quantity: 0,
        available: 0,
        cost_value: 5000,
        sale_value: 5000,
        is_initial: false,
        is_active: false,
        created: "2023-05-26",
      },
    },
    {
      id: 77,
      name: "LIBRO DE LINA",
      category: "PRODUCTO FABRICADO",
      sku: "7816365123614",
      is_active: true,
      description: "",
      total: 51,
      stock: {
        id: 85,
        quantity: 100,
        available: 51,
        cost_value: 30000,
        sale_value: 80000,
        is_initial: false,
        is_active: true,
        created: "2023-05-26",
      },
    },
    {
      id: 78,
      name: "PAPEL DE LIBRO",
      category: "PRODUCTO COMPRADO",
      sku: "478465669",
      is_active: true,
      description: "",
      total: 50,
      stock: {
        id: 87,
        quantity: 50,
        available: 50,
        cost_value: 5000,
        sale_value: 7000,
        is_initial: false,
        is_active: true,
        created: "2023-05-26",
      },
    },
    {
      id: 79,
      name: "UÑAS ACRILICAS DECORADAS",
      category: "SERVICIO",
      sku: "7889465478954",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 88,
        quantity: 0,
        available: 0,
        cost_value: 15000,
        sale_value: 100000,
        is_initial: false,
        is_active: true,
        created: "2023-05-26",
      },
    },
    {
      id: 80,
      name: "BATIDO VERDE VIDA 100gr",
      category: "PRODUCTO FABRICADO",
      sku: "752654999481310200",
      is_active: true,
      description: "pepino, manzana",
      total: 34,
      stock: {
        id: 89,
        quantity: 50,
        available: 34,
        cost_value: 500,
        sale_value: 2000,
        is_initial: false,
        is_active: true,
        created: "2023-05-29",
      },
    },
    {
      id: 81,
      name: "PEPINO 1000gr",
      category: "PRODUCTO COMPRADO",
      sku: "7789999",
      is_active: true,
      description: "",
      total: 3,
      stock: {
        id: 91,
        quantity: 3,
        available: 3,
        cost_value: 5000,
        sale_value: 5000,
        is_initial: false,
        is_active: true,
        created: "2023-05-29",
      },
    },
    {
      id: 82,
      name: "Entrenamiento personalizado",
      category: "SERVICIO",
      sku: "15738",
      is_active: true,
      description: "1 hora",
      total: null,
      stock: {
        id: 92,
        quantity: 0,
        available: 0,
        cost_value: 15000,
        sale_value: 35000,
        is_initial: false,
        is_active: true,
        created: "2023-05-30",
      },
    },
    {
      id: 83,
      name: "Camisetas deportivas",
      category: "PRODUCTO COMPRADO",
      sku: "678557883",
      is_active: true,
      description: "",
      total: 39,
      stock: {
        id: 94,
        quantity: 50,
        available: 39,
        cost_value: 10000,
        sale_value: 25000,
        is_initial: false,
        is_active: true,
        created: "2023-05-30",
      },
    },
    {
      id: 84,
      name: "ROSAS X 15 UNIDADES ROJAS",
      category: "PRODUCTO FABRICADO",
      sku: "784979477",
      is_active: true,
      description: "",
      total: 7,
      stock: {
        id: 95,
        quantity: 10,
        available: 7,
        cost_value: 10000,
        sale_value: 25000,
        is_initial: false,
        is_active: true,
        created: "2023-05-30",
      },
    },
    {
      id: 85,
      name: "lapiz labial rojo",
      category: "PRODUCTO COMPRADO",
      sku: "158999",
      is_active: true,
      description: "",
      total: 12,
      stock: {
        id: 98,
        quantity: 12,
        available: 12,
        cost_value: 5000,
        sale_value: 8000,
        is_initial: false,
        is_active: true,
        created: "2023-05-29",
      },
    },
    {
      id: 86,
      name: "sombras",
      category: "PRODUCTO COMPRADO",
      sku: "88795556",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 97,
        quantity: 0,
        available: 0,
        cost_value: 15000,
        sale_value: 28000,
        is_initial: false,
        is_active: false,
        created: "2023-05-30",
      },
    },
    {
      id: 87,
      name: "SERVICIO DE LAVANDERÍA A DOMICILIO",
      category: "SERVICIO",
      sku: "0478946",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 100,
        quantity: 0,
        available: 0,
        cost_value: 50000,
        sale_value: 150000,
        is_initial: false,
        is_active: true,
        created: "2023-05-31",
      },
    },
    {
      id: 88,
      name: "Ramo margaritas",
      category: "PRODUCTO COMPRADO",
      sku: "000001111",
      is_active: true,
      description: "X 8 palo",
      total: 9,
      stock: {
        id: 102,
        quantity: 10,
        available: 9,
        cost_value: 7258,
        sale_value: 9798,
        is_initial: false,
        is_active: true,
        created: "2023-05-31",
      },
    },
    {
      id: 89,
      name: "GURUMI NIÑA",
      category: "PRODUCTO FABRICADO",
      sku: "478945664",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 103,
        quantity: 3,
        available: 0,
        cost_value: 30000,
        sale_value: 60000,
        is_initial: false,
        is_active: false,
        created: "2023-06-01",
      },
    },
    {
      id: 90,
      name: "LANAS X 50gr",
      category: "PRODUCTO COMPRADO",
      sku: "7899455432510",
      is_active: true,
      description: "",
      total: 10,
      stock: {
        id: 105,
        quantity: 10,
        available: 10,
        cost_value: 10000,
        sale_value: 10000,
        is_initial: false,
        is_active: true,
        created: "2023-06-01",
      },
    },
    {
      id: 91,
      name: "BUÑUELO RELLENO AREQUIPE 1 UNIDAD",
      category: "PRODUCTO FABRICADO",
      sku: "788945067887799",
      is_active: true,
      description: "",
      total: 5,
      stock: {
        id: 106,
        quantity: 50,
        available: 5,
        cost_value: 500,
        sale_value: 2000,
        is_initial: false,
        is_active: true,
        created: "2023-06-03",
      },
    },
    {
      id: 92,
      name: "Producto Pijama Formal",
      category: "PRODUCTO FABRICADO",
      sku: "47856",
      is_active: true,
      description: "Pijama compuesta por camisa de botones y short",
      total: 9,
      stock: {
        id: 107,
        quantity: 10,
        available: 9,
        cost_value: 22000,
        sale_value: 50000,
        is_initial: false,
        is_active: true,
        created: "2023-06-16",
      },
    },
    {
      id: 93,
      name: "Pulsera Tejida Guayu",
      category: "PRODUCTO COMPRADO",
      sku: "246548",
      is_active: true,
      description: "Esta pulsera",
      total: null,
      stock: {
        id: 108,
        quantity: 0,
        available: 0,
        cost_value: 6000,
        sale_value: 12000,
        is_initial: false,
        is_active: false,
        created: "2023-06-20",
      },
    },
    {
      id: 94,
      name: "Placa acrílico Cliente final",
      category: "PRODUCTO FABRICADO",
      sku: "JC032",
      is_active: true,
      description: "Placa para identificar a la mascota (Gato, Perro y conejo)",
      total: 3,
      stock: {
        id: 109,
        quantity: 5,
        available: 3,
        cost_value: 3500,
        sale_value: 15000,
        is_initial: true,
        is_active: true,
        created: "2023-07-05",
      },
    },
    {
      id: 95,
      name: "Placa resina cliente final",
      category: "PRODUCTO COMPRADO",
      sku: "JC033",
      is_active: true,
      description: "Placa para identificar a la mascota (Gato, Perro y conejo)",
      total: 7,
      stock: {
        id: 113,
        quantity: 10,
        available: 7,
        cost_value: 6000,
        sale_value: 12000,
        is_initial: false,
        is_active: true,
        created: "2023-07-04",
      },
    },
    {
      id: 96,
      name: "Placa resina Cliente final 2",
      category: "PRODUCTO COMPRADO",
      sku: "JC034",
      is_active: true,
      description: "Placa para identificar a la mascota (Gato, Perro y conejo)",
      total: null,
      stock: {
        id: 112,
        quantity: 0,
        available: 0,
        cost_value: 6000,
        sale_value: 12000,
        is_initial: false,
        is_active: false,
        created: "2023-07-05",
      },
    },
    {
      id: 97,
      name: "ARETES SOL",
      category: "PRODUCTO FABRICADO",
      sku: "78903",
      is_active: true,
      description: "Par de aretes de 3cm color dorado en forma de sol",
      total: 49,
      stock: {
        id: 114,
        quantity: 50,
        available: 49,
        cost_value: 30000,
        sale_value: 60000,
        is_initial: false,
        is_active: true,
        created: "2023-07-06",
      },
    },
    {
      id: 105,
      name: "PAPEL DE 10 METROS",
      category: "PRODUCTO COMPRADO",
      sku: "DDFGFGF",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 126,
        quantity: 0,
        available: 0,
        cost_value: 10000,
        sale_value: 10000,
        is_initial: false,
        is_active: false,
        created: "2023-07-12",
      },
    },
    {
      id: 106,
      name: "STIKER PARA LA VENTA",
      category: "PRODUCTO FABRICADO",
      sku: "0000948",
      is_active: true,
      description: "",
      total: 600,
      stock: {
        id: 128,
        quantity: 600,
        available: 600,
        cost_value: 7000,
        sale_value: 9000,
        is_initial: false,
        is_active: true,
        created: "2023-07-12",
      },
    },
    {
      id: 108,
      name: "producto adesivo",
      category: "PRODUCTO COMPRADO",
      sku: "78903gf",
      is_active: true,
      description: "",
      total: null,
      stock: {
        id: 130,
        quantity: 0,
        available: 0,
        cost_value: 60000,
        sale_value: 70000,
        is_initial: false,
        is_active: false,
        created: "2023-07-12",
      },
    },
    {
      id: 109,
      name: "ARETES SOL oro",
      category: "PRODUCTO FABRICADO",
      sku: "dddda3e4",
      is_active: true,
      description: "asdfd",
      total: null,
      stock: {
        id: 131,
        quantity: 100,
        available: 100,
        cost_value: 30000,
        sale_value: 60000,
        is_initial: false,
        is_active: false,
        created: "2023-07-12",
      },
    },
    {
      id: 55,
      name: "Yogurt 2 litro Fresa",
      category: "PRODUCTO FABRICADO",
      sku: "0002",
      is_active: true,
      description: "Bebida láctea con sabor a fresa",
      total: 32,
      stock: {
        id: 54,
        quantity: 10,
        available: 2,
        cost_value: 8000,
        sale_value: 11000,
        is_initial: false,
        is_active: true,
        created: "2023-05-04",
      },
    },
    {
      id: 115,
      name: "Pantalones negro xl",
      category: "PRODUCTO FABRICADO",
      sku: "7594036hysdjkru",
      is_active: true,
      description: "Pantanlon,",
      total: 4,
      stock: {
        id: 138,
        quantity: 5,
        available: 4,
        cost_value: 25000,
        sale_value: 50000,
        is_initial: false,
        is_active: true,
        created: "2023-07-20",
      },
    },
    {
      id: 116,
      name: "Tela 10 metros",
      category: "PRODUCTO COMPRADO",
      sku: "906856jgf",
      is_active: true,
      description: "color negro",
      total: 1,
      stock: {
        id: 142,
        quantity: 1,
        available: 1,
        cost_value: 200000,
        sale_value: 200000,
        is_initial: false,
        is_active: true,
        created: "2023-07-20",
      },
    },
    {
      id: 117,
      name: "hilos de colores",
      category: "PRODUCTO COMPRADO",
      sku: "ifhjhgif",
      is_active: true,
      description: "",
      total: 1,
      stock: {
        id: 141,
        quantity: 1,
        available: 1,
        cost_value: 12000,
        sale_value: 12000,
        is_initial: false,
        is_active: true,
        created: "2023-07-20",
      },
    },
    {
      id: 118,
      name: "Desayuno sorpresa Amaranto",
      category: "PRODUCTO FABRICADO",
      sku: "1124553",
      is_active: true,
      description: "Que tiene o que trae ese desayuno",
      total: 4,
      stock: {
        id: 143,
        quantity: 5,
        available: 4,
        cost_value: 249000,
        sale_value: 340000,
        is_initial: false,
        is_active: true,
        created: "2023-08-02",
      },
    },
  ],
  status: 200,
  statusText: "OK",
  headers: {
    "content-length": "15240",
    "content-type": "application/json",
  },
  config: {
    url: "http://localhost:8000/api/inventories/",
    method: "get",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: "Token 15a529cd5201a09ee34a3877d4a842f0805e16a2",
    },
    params: {},
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    maxContentLength: -1,
    maxBodyLength: -1,
    transitional: {
      silentJSONParsing: true,
      forcedJSONParsing: true,
      clarifyTimeoutError: false,
    },
  },
  request: {},
};

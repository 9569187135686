import { MdSearch } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import styled, { css } from 'styled-components'

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const CardForm = styled.form`
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
`

export const Form = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  text-align:center;
  height:100%;
`

export const Section = styled.section`
  width:100%;
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  padding-top:1.5rem;
`

export const TitleStep = styled.h3`
  margin:auto;
  padding-bottom:2rem;
  color: ${props => props.theme.colors.third};
`

export const Input = styled.input`
  padding:.5rem 0;
  width:100%;
  border-bottom: 1px solid #fff;
  color:#fff;
  placeholder:#fff;
  background:transparent;
  outline:none;
  font-size: 1em;
  margin-bottom:1em;
  &::-webkit-input-placeholder {
    color: #fff;
  }
  ${mobile} {
    font-size: .9rem;
  }
`

export const Select = styled.select`
  padding:.5rem 0;
  width:100%;
  border-bottom: 1px solid #fff;
  color:#fff;
  font-family: ${props => props.theme.fonts.family};
  font-size: 1em;
  background:transparent;
  outline:none;
  margin-bottom:1em;
  ${mobile} {
    font-size: .9rem;
  }
  option {
    color: ${props => props.theme.colors.main};
    background: ${props => props.theme.colors.light};
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
  option:checked {
    background: ${props => props.theme.colors.second};
  }
`

export const Label = styled.label`
  color: ${props => ( props.ghost ? "transparent" : props.theme.colors.third)};
  font-size:.7rem;
  align-self:flex-start;
`

export const ContainerBtns = styled.div`
  display:flex;
  width:100%;
  padding-bottom:1em;
  justify-content:space-between;
`

const ButtonStyle = css`
  margin-top:1em;
  padding:1em .5em;
  border-radius:30px;
  height:fit-content;
  font-weight:500;
  font-size:.9rem;
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    color: #fff;
  }
`

export const BtnNext = styled.button`
  width: ${props => (props.second ? "45%" : "70%")};
  color: ${props => (props.darktheme ? props.theme.colors.main : props.theme.colors.third)};
  background: ${props => (props.darktheme ? props.theme.colors.third : props.theme.colors.main)};
  border: ${props => '3px solid ' + (props.darktheme ? props.theme.colors.main : props.theme.colors.third)};
  &:hover {
    background-color: ${props => props.theme.colors.second};
  }
  ${ButtonStyle}
`

export const BtnLink = styled(Link)`
  width: ${props => (props.second ? "45%" : "70%")};
  color: ${props => (props.darktheme ? props.theme.colors.main : props.theme.colors.third)};
  background: ${props => (props.darktheme ? props.theme.colors.third : props.theme.colors.main)};
  border: ${props => '3px solid ' + (props.darktheme ? props.theme.colors.main : props.theme.colors.third)};
  &:hover {
    background-color: ${props => props.theme.colors.second};
  }
  ${ButtonStyle}
`

export const BtnIcon = styled.button`
  border-radius: 10px;
  padding: .3rem;
  color: ${props => props.theme.colors.red_brand};
`

export const SubSection = styled.section`
  width: ${props => ( props.small ? '45%' : '100%')};
  display:flex;
  flex-direction: ${props => ( props.flex ? 'row' : 'column')};
  min-height: ${props => props.table ? '280px' : ''};
  max-height: ${props => props.table ? '280px' : ''};
  margin-bottom: 1rem;
`

export const Option = styled.option`
  color: red;
`

export const ContainerError = styled.div`
  // height:4em;
  align-self:flex-start;
`

export const Table = styled.div`
  width:100%;
  height:90%;
  border-radius:10px;
  font-size:13px;
  background: ${props => props.theme.colors.green_table};
`

export const Content = styled.div`
  width:100%;
  height:65%;
  overflow-y: scroll;
  background: ${props => props.theme.colors.green_table};
`

export const Row = styled.div`
  display:flex;
  width:100%;
  padding:.5rem 1.5rem;
  color:#FFF;
`

export const Column = styled.div`
  width: ${props => props.size || '50%'};
  text-align: ${props => props.align || 'left'};
  font-size: ${props => props.fontSize};
  margin: auto;
  text-overflow: ellipsis;
  padding-right: .5rem;
`

export const Header = styled.div`
  display:flex;
  width:100%;
  padding:.5rem 1rem;
  margin-bottom:1rem;
  border-bottom: ${props => '1px solid ' + props.theme.colors.third};
  color: ${props => props.theme.colors.third};
`

export const Footer = styled.div`
  display:flex;
  width:100%;
  padding:.7rem 1rem;
  margin-top:1rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.third};
  background: ${props => props.theme.colors.dark};
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
`

export const StyledTextArea = styled.textarea`
  resize: none;
  margin-top:.6rem;
  width: 100%;
  height: 5rem;
  overflow: hidden;
  border: none;
  padding: .5rem;
  color: #fff;
  background: ${props => props.theme.colors.green_table};
`; 

export const IconSearch = styled(MdSearch)`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.main}; 
  margin: auto;
  background: ${props => props.theme.colors.third}; 
  padding: .3rem;
  border-radius: 50%;
`;

export const AutoComplete = styled(Autocomplete)`
  padding:.5rem 0;
  width:100%;
  color:#FFF;
  font-family: ${props => props.theme.fonts.family};
  background:transparent;
  outline:none;
  margin-bottom:1em;
  font-size: 1em;
  option:checked {
    background: ${props => props.theme.colors.second};
  }
  .MuiInputBase-root {
    color: #FFF;
    font-size: 1em;
  }
  .MuiIconButton-root {
    display: none;
  }
  .MuiInput-underline {
    border-bottom: 1px solid #FFF;
  }
  ${mobile} {
    font-size: .9rem;
  }
  
`


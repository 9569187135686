import React, { Fragment } from 'react'
import { CustomDate } from '../../../../utils/date.js'
import {
    Container, FigureCircle, Section, CardTitle, CardSubtitle, CardValue,
    CardCategory, Row, Column, Button, Ship
} from './styles.js'

export const CardNotification = (
    {
        icon, color, title, titleSecond, titleThird, titleFourth, data,
        clickReject, clickAccept
    }) => {

    return (
        <>
            <Container>
                <Row>
                    <Column lg="8" sm="15" xs="15">
                        <FigureCircle color={color}>{icon}</FigureCircle>
                    </Column>
                
                    <Column lg="92" sm="85" xs="85">
                        <Section>
                            <Row>
                                <Column lg="60" sm="60" xs="60">
                                    <CardTitle color={color} align="left">{title}</CardTitle>
                                </Column>
                                <Column lg="40" sm="40" xs="40" align="right">
                                    <CardSubtitle color="white" size=".7">{CustomDate.date1(data.expense.date)}</CardSubtitle>
                                </Column>
                            </Row>

                            <Row>
                                <Ship color={data.color}>{data.title}</Ship>
                            </Row>

                            <Row>
                                <CardSubtitle color={color}>{titleSecond}</CardSubtitle>
                                <CardValue>{data.expense.description}</CardValue>
                            </Row>

                            <Row>
                                <CardSubtitle color={color}>{titleThird}</CardSubtitle>
                                <CardCategory>{data.expense.category}</CardCategory>
                            </Row>

                            <Row top=".5rem">
                                <CardSubtitle size="1rem" bold>{titleFourth}</CardSubtitle>
                                <CardValue color={color} size="1rem" bold>
                                    {Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(data.expense.value)}
                                </CardValue>
                            </Row>

                            {data.show &&
                                <Row>
                                    <Column>
                                        <Button onClick={e => clickReject(data.id)} color="text" text="dark">Rechazar</Button>
                                    </Column>
                                    <Column>
                                        <Button onClick={e => clickAccept(data.expense.id)} color="pink" text="dark">Aceptar</Button>
                                    </Column>
                                </Row>
                            }
                        </Section>
                    </Column>
                </Row>
            </Container>
        </>
    )
}

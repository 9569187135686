import React, { Fragment } from 'react';
import { CustomDate } from '../../../../../utils/date';
import {
    Form, Section, Input, Select, Label, ContainerBtns, 
    BtnNext, SubSection, Option, BtnLink, StyledTextArea,
    TitleStep
} from './styles';

export const ExpenseInformation = (props) => {

    return (
        <Form>
            <Section>
                <SubSection>
                    <TitleStep>Información del gasto</TitleStep>
                </SubSection>

                <SubSection>
                    <Label>Descripción del gasto *</Label>
                    <Input
                        type="text"
                        name="description"
                        onChange={props.handleChange}
                        value={props.form.description}
                        required
                    />
                </SubSection>

                <SubSection small={props.type !== 'credit' && props.type !== 'edit_credit'}>
                    <Label>Valor del gasto *</Label>
                    <Input
                        type="number"
                        name="value"
                        onFocus={props.handleFocus}
                        onChange={props.handleChange}
                        value={props.form.value}
                        disabled={!props.form.edit_value}
                        required
                    />
                </SubSection>

                { (props.type === 'credit' || props.type === 'edit_credit' || props.payment.is_payment) &&
                    <Fragment>
                        <SubSection small>
                            <Label>Método de pago</Label>
                            <Select 
                                ghost
                                type="select"
                                name="payment_method"
                                onChange={props.handleChange}
                                value={props.form.payment_method}
                                disabled={props.type === 'edit_credit'}
                            >
                                <Option value="" ghost>Seleccionar...</Option>
                                <Option value="EFECTIVO">Efectivo</Option>
                                <Option value="BANCO">Banco</Option>
                            </Select>
                        </SubSection>

                        <SubSection small>
                            <Label>Valor a abonar</Label>
                            <Input
                                type="number"
                                name="payment"
                                onChange={props.handleChange}
                                onFocus={props.handleFocus}
                                value={props.form.payment}
                                disabled={props.type === 'edit_credit'}
                            />
                        </SubSection>
                        
                    </Fragment>
                }

                <SubSection small={props.type !== 'credit' && props.type !== 'edit_credit'}>
                    <Label>Categoría financiera *</Label>
                    <Select 
                        ghost
                        type="select"
                        name="category_id"
                        onChange={props.handleChange}
                        value={props.form.category_id}
                        required
                    >
                        <Option value="">Seleccionar categoría</Option>
                        {props.financialCategories.map((row, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Option value={row.id}>{row.name}</Option>
                                </React.Fragment>
                            )
                        })}
                    </Select>
                </SubSection>

                <SubSection small>
                    <Label>Fecha *</Label>
                    <Input
                        ghost
                        type="date"
                        name="date"
                        onChange={props.handleChange}
                        value={props.form.date}
                        max={CustomDate.date4('')}
                        required
                    />
                </SubSection>

                <SubSection small>
                    <Label>Recordar *</Label>
                    <Select 
                        ghost
                        type="select"
                        name="reminder"
                        onChange={props.handleChange}
                        value={props.form.reminder}
                        required
                    >
                        {props.reminderOptions.map((row, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Option value={row}>{row}</Option>
                                </React.Fragment>
                            )
                        })}
                    </Select>
                </SubSection>

                <SubSection>
                    <Label>Observaciones</Label>
                    <StyledTextArea
                        type="text-area"
                        name="observations"
                        onChange={props.handleChange}
                        value={props.form.observations}
                    />
                </SubSection>

            </Section>
                
            <ContainerBtns>
                <BtnLink second to={props.cancel}>
                    Cancelar
                </BtnLink>
                <BtnNext second onClick={e => props.handleSubmit(e, true)} type="button" darktheme>
                    Enviar
                </BtnNext>
            </ContainerBtns>
        </Form>
    );
};
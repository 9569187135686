import React, { useEffect } from "react"
import { FormContainer } from "./styles"

import {
  getExpensesDetail,
  getExpensesPayment,
  patchExpenses,
  patchExpensesPayment,
  postExpenses,
  postExpensesPayment,
} from "../../../../../api/requests"
import { useHistory, useParams } from "react-router-dom"
import { DataUtil } from "../../../../../utils/data"
import { CustomDate } from "../../../../../utils/date"
import { Modal } from "../../../../molecules/Modals/Modal"
import { Utilities } from "../../../../../utils/utilities"
import { FormExpensesSteps } from "../FormExpensesSteps"

export const FormExpenses = (props) => {
  const history = useHistory()
  const { type, id, payment_id } = useParams()
  const [form, setForm] = React.useState({
    category_id: null,
    description: "",
    date: CustomDate.date4(),
    observations: "",
    value: 0,
    payment_type: "",
    payment_method: "",
    payment: 0,
    reminder: "",
    edit_value: true,
  })
  const [expense, setExpense] = React.useState({})
  const [activeModal, setActiveModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })

  useEffect(() => {
    let pay = DataUtil.getExpense(type)
    setExpense(pay)

    let id_edit = id

    if (history.location.state) {
      id_edit = history.location.state.id
    }

    if (id_edit) {
      async function fetchData() {
        if (pay.is_payment && payment_id)
          await getPaymentDetail(id_edit, payment_id)
        else if (!pay.is_payment) await getDetail(id_edit)
      }
      fetchData()
    }

    // eslint-disable-next-line
  }, [])

  async function getDetail(id_detail) {
    const response = await getExpensesDetail(id_detail)

    if (response.status === 200) {
      let resp = response.data

      if (history.location.state) {
        resp.date = CustomDate.date4()
        resp.reminder_id = id_detail
      }
      resp.category_id = resp.category.id
      resp.edit_value = true

      if (type === "edit_credit") {
        resp.edit_value = !resp.payment
        resp.payment = 0
      }
      setForm(resp)
    }
  }

  async function getPaymentDetail(id_expense, id_payment) {
    const response = await getExpensesPayment(id_expense, id_payment)

    if (response.status === 200) {
      let resp = response.data
      resp.payment = resp.value
      setForm(resp)
    }
  }

  async function handleSubmit(data) {
    if (type !== "payment" && type !== "edit_payment") {
      data.category_id = parseInt(data.category_id)
      data.value = parseFloat(data.value)
      data.payment_type = expense.payment_type
      data.payment_method =
        type === "credit" ? data.payment_method : expense.payment_method
      if (data.payment) data.payment = parseFloat(data.payment)

      if (id) await submitEditExpense(id, data)
      else await submitExpense(data)
    } else {
      let data_send = {
        date: data.date,
        observations: data.observations,
        payment_method: data.payment_method,
        value: parseInt(data.payment),
      }

      if (payment_id)
        await submitEditPayment(parseInt(id), parseInt(payment_id), data_send)
      else await submitPayment(parseInt(id), data_send)
    }
  }

  async function submitExpense(data) {
    let data_send = {}
    if (data.reminder_id) {
      data_send.category_id = data.category_id
      data_send.description = data.description
      data_send.observations = data.observations
      data_send.date = data.date
      data_send.value = data.value
      data_send.payment_type = data.payment_type
      data_send.payment_method = data.payment_method
      data_send.reminder = data.reminder
      data_send.payment = data.payment
      data_send.reminder_id = data.reminder_id
    } else {
      data_send = data
    }

    postExpenses(data_send).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Gasto registrado",
          "El registro del nuevo gasto se ha realizado correctamente.",
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Registro fallido", array, true, true)
      } else {
        showMessage(
          "Registro fallido",
          "Ha ocurrido un error registrando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  async function submitEditExpense(id, data) {
    let send_data = {
      category_id: data.category_id,
      description: data.description,
      observations: data.observations,
      date: data.date,
      value: data.value,
      reminder: data.reminder,
    }

    patchExpenses(id, send_data).then((response) => {
      if (response.status === 200) {
        showMessage(
          "Gasto actualizado",
          `La actualización del gasto ${id} se ha realizado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Actualización fallida", array, true, true)
      } else {
        showMessage(
          "Actualización fallida",
          "Ha ocurrido un error registrando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  async function submitPayment(id, data) {
    postExpensesPayment(id, data).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Abono creado",
          `El abono en ${data.payment_method} fue registrado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Abono no creado", array, true, true)
      } else {
        showMessage(
          "Abono no creado",
          `El abono en ${data.payment_method} no pudo ser registrado. Por favor, inténtelo de nuevo.`,
          true
        )
      }
    })
  }

  async function submitEditPayment(id_expense, id_payment, data) {
    patchExpensesPayment(id_expense, id_payment, data).then((response) => {
      if (response.status === 200) {
        showMessage(
          "Abono actualizado",
          `El abono ${id_payment} fue actualizado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Actualización fallida", array, true, true)
      } else {
        showMessage(
          "Actualización fallida",
          "Ha ocurrido un error actualizando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const handleClosed = () => {
    setActiveModal(false)

    if (!modalData.is_error) {
      let path = "#"
      path = DataUtil.getExpense(type, id).prev_route

      history.push(path)
    }
  }

  return (
    <>
      <FormContainer>
        <FormExpensesSteps
          formStep={props.formStep}
          form={form}
          setForm={setForm}
          setFormStep={props.setFormStep}
          handleSubmit={handleSubmit}
        />
      </FormContainer>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </>
  )
}

export const theme = {
    colors: {
      main: '#0F3849',
      main_opacity: '#1B4856',
      second: '#34B687',
      third: '#95e8d4',
      deactive: '#CBEAF6',
      disabled: '#8B9EA7',
      light: '#EEF8FC',
      dark: '#0A2733',
      main_contrast: '#F45D7E',
      text: '#899DBE',
      text_light: '#CAD4E2',
      placeholder: '#6B6E71',
      green_table: '#1E4D5B',
      pink: '#FA9FCA',
      white: '#FFFFFF',
      third_clear: '#B9EFDF',
      
      green_light: '#B2E6BB',
      green_brand: '#56C869',
      green_contrast: '#2D8B3D',
      
      blue_light: '#C2E7FF',
      blue_brand: '#1FA5FF',
      blue_contrast: '#0062A3',
      blue_finished: '#33B3D1',
      
      red_light: '#FFC2DE',
      red_brand: '#FF70B3',
      red_contrast: '#EC1E21',
      
      orange_light: '#FFDDC2',
      orange_brand: '#FFC392',
      orange_contrast: '#F56E00',

      linear_gradient_blue: 'linear-gradient(90deg, rgba(62,192,182,1) 0%, rgba(88,160,255,1) 100%);',
      linear_gradient_pink_red: 'linear-gradient(90deg, rgba(232,109,171,1) 0%, rgba(236,30,33,1) 100%);',
      linear_gradient_pink: 'linear-gradient(90deg, rgba(250,159,203,1) 0%, rgba(236,120,178,1) 100%);',
      linear_gradient_green: 'linear-gradient(90deg, rgba(125,211,88,1) 0%, rgba(16,192,179,1) 100%);'
    },
    layout: {
      container: '2rem',
      gutter: '1rem'
    },
    fonts: {
      family: "'Poppins', sans-serif",
      size:'1rem'
    },
    breakpoints: {
      xs: '20rem',
      sm: '36rem',
      md: '48rem',
      lg: '62rem',
      xl: '75rem'
    }
  }
import styled from 'styled-components';

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    -ms-overflow-style: none;
    ${mobile} {
      width:100%;
      padding-left:0;
    }
`

export const Section = styled.section`
    max-width:1000px;
    width:100%;
    height:100%;
    margin-top:2rem;
    flex:0;
    display:flex;
    flex-direction:column;
    ${mobile} {
      width:100%;
    }
`

export const CardValue = styled.p`
    color: ${props => props.theme.colors.placeholder};
    margin-left: ${props => props.space ? '.5rem' : ''};
    font-size:.8rem;
    text-align:left !important;
`


import React from 'react';
import {Container, Section} from './styles';

import {TitleAndInfo} from '../../../components/molecules/TitleAndInfo';
import { FormBudgetExpenseContainer } from '../../../containers/FormBudgetExpenseContainer';

import {Routes} from '../../../routes'


export const InitialBudgetExpenseForm = () => {
    return (
        <Container>
            <Section>
                <TitleAndInfo 
                    btnPrev
                    pathPrev = {Routes.app_initial_budget_expense}
                    titlePage='Nueva proyección de presupuesto de gastos'
                    tooltip="budget-expense"
                    darktheme
                />
                <FormBudgetExpenseContainer />
            </Section>
        </Container>
    )
}

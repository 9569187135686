import styled from 'styled-components'

export const Text = styled.h2`
`
export const Span = styled.span`
    font-weight:700;
`

export const WelcomeText = styled.p`
    padding:3em 2em;
    font-size:1.5em;
    text-align:center;

`
import React, { useEffect, useState } from "react"
import { FormEditLiabilities } from "../../components/organisms/forms/myInfo/FormEditLiabilities"

import { getListFinancialCategoriesLiabilities } from "../../api/requests"

export const FormEditLiabilitiesContainer = (props) => {
  // eslint-disable-next-line
  const [initialForm, setInitialForm] = useState({
    category: "",
    value: "",
  })

  // const editIdd = editId

  const validationsForm = (form) => {
    let errors = {}
    // let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    // let regexComments = /^.{1,255}$/;
    // let regexNumbersSpace = /^[\d]+$/;
    // let regexNumbers = /^[0-9]+$/;
    // let regexAlphaNumbers = /^[0-9a-zA-Z]+$/;

    // if(!form.category.trim()){
    //     errors.category = 'El campo Categoría financiera es requerido.'
    // } else if(!regexNumbers.test(form.category.trim())) {
    //     errors.category = 'En el campo Categoría financiera debe seleccionar una categoría validaS.'
    // }

    // if(!form.value.trim()){
    //     errors.value = 'El campo Valor es requerido.'
    // } else if(!regexNumbers.test(form.value.trim())) {
    //     errors.value = 'El campo Valor solo acepta números, sin puntaciones, ni espacios.'
    // }

    return errors
  }

  const [listFinancialCategories, setListFinancialCategories] = useState([])

  // eslint-disable-next-line
  useEffect(async () => {
    const prueba = await props.loadLiabilityItem(props.idEdit)

    // setInitialForm({...initialForm, category: prueba.category.id, value: prueba.value})
    initialForm["category"] = prueba.category.id
    initialForm["value"] = prueba.value
    // setInitialForm({category:prueba.category.name, value:"300"})
    // setInitialForm({category:prueba.category.name, value:prueba.value})

    async function loadListFinancialCategoriesLiabilities() {
      const response = await getListFinancialCategoriesLiabilities(true)
      if (response.status === 200) {
        setListFinancialCategories(response.data)
      }
    }
    loadListFinancialCategoriesLiabilities()

    // setInitialForm(props.objItem)
    // eslint-disable-next-line
  }, [])

  return (
    <FormEditLiabilities
      initialForm={initialForm}
      validationsForm={validationsForm}
      listFinancialCategories={listFinancialCategories}
      idEdit={props.idEdit}
      objItem={props.objItem}
      // liabilityItem={props.liabilityItem}
    />
  )
}

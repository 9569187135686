export const Routes = {
  // App URL
  app_url_base: process.env.REACT_APP_URL,
  //app_url_base: 'http://localhost:3000',
  app_home: "/",
  app_sign_up: "/sign-up",
  app_login: "/login",
  app_logout: "/logout",
  app_reset_password: "/reset-password",
  app_recover_password: "/changepassword/:token",

  app_subscribe_invitation: "/subscribe-invitation",
  app_welcome_user: "/welcome-user",
  app_initial_information: "/initial-information",
  app_initial_assets: "/initial-assets",
  app_initial_assets_form: "/initial-assets-form",
  app_edit_initial_assets_form: "/edit-initial-assets-form/:id",
  app_initial_liabilities: "/initial-liabilities",
  app_initial_liabilities_form: "/initial-liabilities-form",
  app_edit_initial_liabilities_form: "/edit-initial-liabilities-form/:id",
  app_initial_budget_income: "/initial-budget-income",
  app_initial_budget_income_form: "/initial-budget-income-form",
  app_edit_initial_budget_income_form: "/edit-initial-budget-income-form/:id",
  app_initial_budget_expense: "/initial-budget-expense",
  app_initial_budget_expense_form: "/initial-budget-expense-form",
  app_edit_initial_budget_expense_form: "/edit-initial-budget-expense-form/:id",
  app_initial_inventory: "/initial-inventory",
  app_initial_inventory_form: "/initial-inventory-form",
  app_initial_inventory_detail: "/initial-inventory-detail/:id",
  app_initial_stock_form: "/initial-stock-form/:id",
  app_edit_initial_inventory_form: "/edit-initial-inventory-form/:id",

  app_my_profile: "/my-profile",
  app_profile_form: "/my-profile/form",

  app_my_catalog: "/my-catalog",
  app_my_inventory: "/my-inventory",
  app_inventory_form: "/inventory-form",
  app_inventory_detail: "/inventory-detail/:id",
  app_stock_form: "/stock-form/:id",
  app_edit_inventory_form: "/edit-inventory-form/:id",

  app_income: "/my-income",
  app_income_type: "/income-detail/:type",
  app_income_payment: "/income-detail/:type/:id",
  app_income_form: "/income-form/:type",

  app_shopping: "/my-shopping",
  app_shopping_type: "/shopping-detail/:type",
  app_shopping_payment: "/shopping-detail/:type/:id",
  app_shopping_form: "/shopping-form/:type",

  app_expenses: "/my-expenses",
  app_expenses_type: "/expenses-detail/:type",
  app_expenses_payment: "/expenses-detail/:type/:id",

  app_notifications: "/notifications",

  app_reports: "/my-reports",
  app_reports_type: "/my-reports/:param/:type",

  app_subscription_plans: "/subscription-plans",
  app_subscription_validated: "/subscription-validated",

  // API URI
  api_url_base: process.env.REACT_APP_API_URL,
  //api_url_base: 'http://localhost:8000',
  api_login: "/api/login/",
  api_token_refresh: "/api/token/refresh",
  api_sign_up: "/api/register/",
  api_reset_password: "/api/reset/",
  api_update_password: "/api/reset/",
  api_update_user_profile: "/api/profiles/",
  api_view_user_profile: "/api/profiles/",
  api_country_list: "/api/countries/<int:pk>/states/",
  // api_states_list: '/api/states/<int:pk>/cities/',

  api_cities_list: "/api/states/",
  api_states_list: "/api/countries/",
  api_documents_list: "/api/documents/",
  api_economic_activities: "/api/economic_activities/",
  api_financial_categories: "/api/financial_categories/",
  api_financial_info: "api/financial_info/",
  api_budgets: "/api/budgets/",

  api_inventories: "/api/inventories/",
  api_logs: "/api/logs/",
  api_stock_deactivate: "/api/stocks/",

  api_reminder_options: "/api/reminders_options/",

  // api_users_list: '/api/users/',
  // api_create_user: '/api/signup/',
  // api_detail_update_user: '/api/users/:userId',

  // Income
  api_incomes: "/api/incomes/",
  api_incomes_detail: "/api/incomes/:id/",
  api_incomes_inventories: "/api/incomes/inventories/",
  api_incomes_payments: "/api/incomes/:id/payments/",
  api_incomes_payments_edit: "/api/incomes/:income_id/payments/:payment_id",
  api_incomes_clients: "/api/clients/",
  api_incomes_export: "/api/incomes/export/",
  api_incomes_pdf: "/api/incomes/:id/pdf",

  // Shopping
  api_shoppings: "/api/shoppings/",
  api_shoppings_detail: "/api/shoppings/:id",
  api_shoppings_payments: "/api/shoppings/:id/payments/",
  api_shoppings_payments_edit:
    "/api/shoppings/:shopping_id/payments/:payment_id",
  api_shoppings_providers: "/api/providers/",
  api_shoppings_export: "/api/shoppings/export/",

  // Expenses
  api_expenses: "/api/expenses/",
  api_expenses_detail: "/api/expenses/:id/",
  api_expenses_export: "/api/expenses/export/",
  api_expenses_payments: "/api/expenses/:id/payments/",
  api_expenses_payments_edit: "/api/expenses/:expense_id/payments/:payment_id",

  api_notifications: "/api/notifications/",
  api_notifications_detail: "/api/notifications/:id/",

  // Reports
  api_reports: "/api/reports/",

  // Graphic
  api_charts: "/api/charts/",

  // Subscriptions
  api_subscriptions: "/api/subscriptions/",
  api_subscriptions_billing: "/api/subscriptions_billing/",
  api_subscriptions_billing_user_validated:
    "/api/subscriptions_billing/validated-user",

  //Wompi Event's
  //Get wompi event
  api_wompi_transaction_event: "/api/subscriptions_billing/validated/",
}

import React from "react"
import { Container, Section } from "./styles"

import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"

import { useParams, useHistory } from "react-router-dom"
import { DataUtil } from "../../../../utils/data"
import { FormIncome } from "../../../../components/organisms/forms/myIncome/FormIncome"

export const IncomeForm = () => {
  const history = useHistory()
  const { type, id } = useParams()
  const [open, setOpen] = React.useState(false)
  const [formStep, setFormStep] = React.useState(0)
  const [payment, setPayment] = React.useState({})
  const [cancel, setCancel] = React.useState("")
  const [title, setTitle] = React.useState("")

  React.useEffect(() => {
    let selected = DataUtil.getData(type, id)

    setCancel(selected.prev_route)
    setFormStep(selected.init_step)
    setTitle(selected.tag_title)
    setPayment(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {}, [formStep])

  let handleOpenOptions = (event) => {
    setOpen(event)
  }

  let handleChangeStep = () => {
    if (formStep > 0 && !payment.is_payment) setFormStep(formStep - 1)
    else history.push(cancel)
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={formStep > 0 ? "#" : cancel}
          titlePage={title}
          darktheme
          handleChange={handleChangeStep}
          tooltip="income"
        />
        <FormIncome
          handleOpen={handleOpenOptions}
          open={open}
          setFormStep={setFormStep}
          formStep={formStep}
        />
      </Section>
    </Container>
  )
}

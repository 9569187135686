import styled from 'styled-components';

// const desktopStartWidth = 1023;
// const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
// const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
    min-height:100%;
    height:100hv;
    background: ${props => props.theme.colors.dark};
    display:flex;
    align-items:center;
    justify-content:center;
`
export const Section = styled.section`
    width:800px;
    height:100hv;
    background: ${props => props.theme.colors.main};
    padding: 2rem;
    display:flex;
    /* justify-content:space-between; */
    flex-direction:column;
`
import React from 'react'
import {Subtitle} from './styles'

export const SubtitleInfo = ({subtitleInfo}) => {
    return (
        <Subtitle>
            {subtitleInfo}
        </Subtitle>
    )
}

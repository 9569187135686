import React, { Fragment } from 'react';
import {
    TitleStep, Form, Section, Input, Select, Label, ContainerBtns, 
    BtnNext, SubSection, Option, StyledTextArea
} from './styles';
import { CardSummary } from '../../../../atoms/cards/CardSummary';
import { CustomDate } from '../../../../../utils/date';

export const BillingInformation = (props) => {

    return (
        <Form>
            <Section>
                <SubSection>
                    <TitleStep>Información de facturación</TitleStep>
                </SubSection>

                <SubSection small={props.type === 'credit' || props.type === 'edit_credit' || props.payment.is_payment}>
                    <Label>Fecha</Label>
                    <Input
                        ghost
                        type="date"
                        name="date"
                        onChange={props.handleChange}
                        value={props.form.date}
                        max={CustomDate.date4('')}
                        required
                    />
                </SubSection>

                <SubSection small></SubSection>

                { props.type === 'credit' || props.type === 'edit_credit' || props.payment.is_payment ?
                    <Fragment>
                        <SubSection small={props.type === 'credit' || props.type === 'edit_credit' || props.payment.is_payment}>
                            <Label>Método de pago</Label>
                            <Select 
                                ghost
                                type="select"
                                name="payment_method"
                                onChange={props.handleChange}
                                value={props.form.payment_method}
                                disabled={props.type === 'edit_credit'}
                            >
                                <Option value="" ghost>Seleccionar...</Option>
                                <Option value="EFECTIVO">Efectivo</Option>
                                <Option value="BANCO">Banco</Option>
                            </Select>
                        </SubSection>

                        { !props.payment.is_payment &&
                            <SubSection small>
                                <Label>Valor del crédito</Label>
                                <Input
                                    type="number"
                                    name="total"
                                    value={props.form.total}
                                    disabled
                                />
                            </SubSection>
                        }

                        <SubSection small={props.type === 'credit' || props.type === 'edit_credit' || props.payment.is_payment}>
                            <Label>Valor a abonar</Label>
                            <Input
                                type="number"
                                name="payment"
                                onChange={props.handleChange}
                                onFocus={props.handleFocus}
                                value={props.form.payment}
                                disabled={props.type === 'edit_credit'}
                            />
                        </SubSection>

                        <SubSection>
                            <Label>Observaciones</Label>
                            <StyledTextArea
                                type="text-area"
                                name="observations"
                                onChange={props.handleChange}
                                value={props.form.observations}
                            />
                        </SubSection>
                        
                    </Fragment>
                    :
                    <Fragment>
                        <SubSection>
                            <Label>Observaciones</Label>
                            <StyledTextArea
                                type="text-area"
                                name="observations"
                                onChange={props.handleChange}
                                value={props.form.observations}
                            />
                        </SubSection>

                        <SubSection>
                            <CardSummary
                                title="RESUMEN:"
                                background="dark"
                                data={{
                                    subTitle: props.payment.summary,
                                    name: props.form.name ? props.form.name.name : props.form.client_name,
                                    category: props.payment.category,
                                    date: props.form.date && props.date,
                                    total: props.form.total,
                                    tags: props.payment
                                }}
                            />
                        </SubSection>
                    </Fragment>
                }

            </Section>
            
            <ContainerBtns>
                <BtnNext second ghost onClick={props.backStep} type="button">
                    Regresar
                </BtnNext>
                <BtnNext second onClick={e => props.handleSubmit(e, true)} type="button" darktheme>
                    Enviar
                </BtnNext>
            </ContainerBtns>
        </Form>
    );
};
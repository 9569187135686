import React from 'react';
import {
    TitleStep, Form, Section, Input, Select, Label, ContainerBtns, 
    BtnNext, SubSection, Option, IconSearch, AutoComplete
} from './styles';
import { TextField } from '@material-ui/core';

export const ClientInformation = (props) => {

    return (
        <Form>
            <Section>
                <SubSection>
                    <TitleStep>Información del {props.category ? 'proveedor' : 'cliente'}</TitleStep>
                </SubSection>

                <SubSection small>
                    <Label>Tipo de documento *</Label>
                    <Select 
                        ghost
                        type="select"
                        name="doc_type"
                        onChange={props.handleChange}
                        value={props.form.doc_type}
                        required
                    >
                        <Option value="">Seleccionar tipo documento</Option>
                        {props.documents.map((row, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Option value={row.id}>{row.name}</Option>
                                </React.Fragment>
                            )
                        })}
                    </Select>
                </SubSection>

                <SubSection small style={{ width: '40%' }}>
                    <Label>Nro. de documento *</Label>
                    <Input
                        type="number"
                        name="doc_number"
                        onChange={props.handleChange}
                        value={props.form.doc_number}
                        required
                    />
                </SubSection>

                <SubSection small style={{ width: '10%' }}>
                    <Label></Label>
                    <IconSearch onClick={props.handleSearch}></IconSearch>
                </SubSection>
                
                <SubSection>
                    <Label>Nombre del {props.category ? 'proveedor' : 'cliente'} *</Label>
                    <AutoComplete
                        name="name"
                        options={props.clients}
                        value={props.form.name}
                        getOptionLabel={(option) => option.name ? option.name : ""}
                        onChange={(e, newValue) => { props.handleChange({target: {name: 'name'}}, newValue) }}
                        noOptionsText={`Escriba el nombre del ${props.category ? 'proveedor' : 'cliente'}`}
                        clearOnBlur={false}
                        renderInput={(params) => 
                            <TextField 
                                {...params} variant="standard" 
                                name="client_name" 
                                value={props.form.client_name}
                                onChange={props.handleChange}
                            />
                        }
                    />
                </SubSection>

                <SubSection>
                    <Label>Correo electrónico</Label>
                    <Input
                        type="text"
                        name="email"
                        onChange={props.handleChange}
                        value={props.form.email}
                        required
                    />
                </SubSection>

                <SubSection small>
                    <Label>Teléfono</Label>
                    <Input
                        type="text"
                        name="phone"
                        onChange={props.handleChange}
                        value={props.form.phone}
                        required
                    />
                </SubSection>

                <SubSection small>
                    <Label>Celular</Label>
                    <Input
                        type="text"
                        name="cellphone"
                        onChange={props.handleChange}
                        value={props.form.cellphone}
                        required
                    />
                </SubSection>

                <SubSection>
                    <Label>Dirección</Label>
                    <Input
                        type="text"
                        name="address"
                        onChange={props.handleChange}
                        value={props.form.address}
                        required
                    />
                </SubSection>

                <SubSection small>
                    <Label>Departamento</Label>
                    <Select 
                        ghost
                        type="select"
                        name="state"
                        onChange={props.handleChange}
                        value={props.form.state}
                    >
                        <Option value="">Seleccionar departamento</Option>
                        {props.states.map((row, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Option value={row.id}>{row.name}</Option>
                                </React.Fragment>
                            )
                        })}
                    </Select>
                </SubSection>

                <SubSection small>
                    <Label>Ciudad</Label>
                    <Select 
                        ghost
                        type="select"
                        name="city"
                        onChange={props.handleChange}
                        value={props.form.city}
                    >
                        <Option value="">Seleccionar ciudad</Option>
                        {props.cities.map((row, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Option value={row.id}>{row.name}</Option>
                                </React.Fragment>
                            )
                        })}
                    </Select>
                </SubSection>

            </Section>

            <ContainerBtns>
                <BtnNext second ghost onClick={props.backStep} type="button">
                    Regresar
                </BtnNext>
                <BtnNext second onClick={props.handleSubmit} type="button" darktheme>
                    Siguiente
                </BtnNext>
            </ContainerBtns>
        </Form>
    );
};
import styled from "styled-components"
import { Link } from "react-router-dom"

/* const desktopStartWidth = 1023; */
const tabletStartWidth = 768
/* const tablet = `@media (max-width: ${desktopStartWidth}px)` */
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const CardForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* padding:3em 2em; */
  text-align: center;
  /* background:pink; */
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;

  > input {
    display: none;
  }

  > label {
    aspect-ratio: 1 / 1;
    border: 2px dashed #95e8d4;
    position: relative;
    max-width: 300px;
    cursor: pointer;

    & img {
      max-width: 300px;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    & svg {
      position: absolute;
      max-width: 50px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > * {
    flex: 1;
    width: 100%;
    min-width: 200px;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background:red; */
  height: 100%;
`
export const Section = styled.section`
  width: 100%;
  /* height:40vh; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1.5rem;
`

export const SubSection = styled.section`
  width: ${(props) => (props.small ? "45%" : "100%")};
  display: flex;
  flex-direction: ${(props) => (props.flex ? "row" : "column")};
`

export const SectionCheckbox = styled.section`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.small ? "45%" : "100%")};
`
export const SubSectionCheckbox = styled.section`
  display: flex;
  justify-content: ${(props) => (props.product ? "" : "space-between")};
  margin-right: 15em;
  margin-bottom: 1em;
  padding-top: ${(props) => (props.product ? "2em" : "")};
  ${mobile} {
    margin-right: 1em;
  }
`

export const Input = styled.input`
  padding: 0.1em 0;
  width: 100%;
  /* border:1px solid #999999; */
  border-bottom: 2px solid ${(props) => props.theme.colors.third};
  color: #fff;
  background: transparent;
  outline: none;
  font-size: 1em;
  margin-bottom: 0.1em;
  &::-webkit-input-placeholder {
    color: "#fff";
    opacity: 100%;
  }
  ${mobile} {
    font-size: 1rem;
  }
`

export const InputCheckbox = styled.input`
  margin-left: 0.8rem;
  font-size: 1em;
  height: 15px;
  width: 15px;
  margin-bottom: 1.5em;
  input[type="checkbox"]:checked {
    color: red;
    background: green;
    box-shadow: 0 0 0 3px hotpink;
  }
  input:focus + & {
    box-shadow: 0 0 0 3px pink;
    color: red;
    background: green;
  }
`

export const Select = styled.select`
  padding: 0.1rem 0;
  width: 100%;
  /* border:1px solid #999999; */
  border-bottom: 2px solid ${(props) => props.theme.colors.third};
  color: ${(props) => (props.ghost ? props.theme.colors.placeholder : "#fff")};

  font-family: ${(props) => props.theme.fonts.family};
  font-weight: 400;

  /* background:rgba(255, 255, 255,.2); */
  background: transparent;
  outline: none;
  margin-bottom: 0.1em;

  /* appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none; */
  ${mobile} {
    font-size: 1rem;
  }
  option {
    color: ${(props) => props.theme.colors.main};

    background: ${(props) => props.theme.colors.light};
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
  option:checked {
    /*background: red linear-gradient(0deg, red 0%, red 100%); */
    background: ${(props) => props.theme.colors.second};
    color: ${(props) => props.theme.colors.main};
  }
`

export const Option = styled.option`
  /* color: ${(props) =>
    props.ghost ? props.theme.colors.red_brand : props.theme.colors.second}; */
  color: red;
`
export const ContainerError = styled.div`
  /* background:red; */
  height: 4em;
  align-self: flex-start;
`

export const ErrorText = styled.p`
  color: ${(props) =>
    props.ghost ? "transparent" : props.theme.colors.red_brand};
  font-weight: 600;
  text-align: start;
  &.ghost {
    color: blue;
  }
`
export const Label = styled.label`
  color: ${(props) => (props.ghost ? "transparent" : props.theme.colors.third)};
  font-size: 0.7rem;
  /* padding: 1em 0 0; */
  align-self: flex-start;
`

export const ContainerBtns = styled.div`
  display: flex;
  width: 100%;
  /* padding:3em 2em; */
  padding-bottom: 1em;
  /* background:pink; */
  justify-content: space-between;
`

export const LinkBtn = styled(Link)`
  width: ${(props) => (props.second ? "45%" : "70%")};
  color: ${(props) =>
    props.white ? props.theme.colors.main : props.ghost ? "#fff" : "#fff"};
  background: ${(props) =>
    props.white
      ? "#fff"
      : props.ghost
      ? "transparent"
      : props.theme.colors.main};
  border: 3px solid
    ${(props) => (props.white ? "#fff" : props.ghost ? "#fff" : "transparent")};
  /* margin-top: ${(props) => (props.ghost ? "1.5em" : "")}; */
  /* margin-bottom: ${(props) => (props.ghost ? "1.5em" : "")}; */
  margin-top: 1em;

  /* opacity: ${(props) => (props.ghost ? "50%" : "")}; */
  padding: 1em 0.5em;
  border-radius: 30px;
  font-weight: 500;
  font-size: 0.9rem;
  height: fit-content;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${(props) => props.theme.colors.second};
    color: #fff;
    opacity: 100%;
  }
`

export const BtnText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: italic;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    text-decoration: underline;
  }
`

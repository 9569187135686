import React from 'react';
import {
    Form, Section, Input, Select, Label, ContainerBtns, 
    BtnNext, SubSection, Option, Table, Header, Row, Column, Footer, 
    Content, BtnIcon, BtnLink
} from './styles';
import {Modal} from "../../../../molecules/Modals/Modal";
import { BtnNewCard } from '../../../../atoms/cards/BtnNewCard';
import { MdDeleteForever } from 'react-icons/md';

export const AddItem = (props) => {

    return (
        <Form>
            <Section>

                { props.category === 'others' || props.category === 'properties' ?
                    <React.Fragment>
                        <SubSection small style={{ width: '70%' }}>
                            <Label>
                                Descripción (nombre) *
                            </Label>
                            <Input
                                type="text"
                                name="description"
                                onChange={props.handleChange}
                                onFocus={props.handleFocus}
                                value={props.form.description}
                                disabled={props.type === 'edit_credit'}
                                required
                            />
                        </SubSection>
                        <SubSection small style={{ width: '20%' }}>
                            <Label>
                                Cantidad *
                            </Label>
                            <Input
                                type="number"
                                name="amount"
                                onChange={props.handleChange}
                                onFocus={props.handleFocus}
                                value={props.form.amount}
                                disabled={props.type === 'edit_credit'}
                                required
                            />
                        </SubSection>
                        <SubSection>
                            <Label>
                                Precio *
                            </Label>
                            <Input
                                type="number"
                                name="value"
                                onChange={props.handleChange}
                                onFocus={props.handleFocus}
                                value={props.form.value}
                                disabled={props.type === 'edit_credit'}
                                required
                            />
                        </SubSection>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <SubSection small>
                            <Label>
                                {props.category ? 'Productos' : 'Productos o servicios'}
                            </Label>
                            <Select 
                                ghost
                                type="select"
                                name="category"
                                onChange={props.handleChange}
                                value={props.form.category}
                                disabled={props.type === 'edit_credit'}
                                required
                            >
                                <Option value="">Seleccionar...</Option>
                                {props.inventory.map((row, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Option value={index}>{row.name}</Option>
                                        </React.Fragment>
                                    )
                                })}
                            </Select>
                        </SubSection>
                        
                        <SubSection small>
                            <Label>
                                Cantidad
                            </Label>
                            <Input
                                type="number"
                                name="amount"
                                onChange={props.handleChange}
                                onFocus={props.handleFocus}
                                value={props.selected.amount}
                                disabled={props.type === 'edit_credit'}
                                required
                            />
                        </SubSection>

                        {props.income &&
                            <SubSection>
                                <Label>
                                    Valor de venta
                                </Label>
                                <Input
                                    type="number"
                                    name="value"
                                    onChange={props.handleChange}
                                    onFocus={props.handleFocus}
                                    value={props.selected.value}
                                    required
                                />
                            </SubSection>
                        }
                        
                        { props.category &&
                            <React.Fragment>
                                <SubSection small>
                                    <Label>
                                        Costo
                                    </Label>
                                    <Input
                                        type="number"
                                        name="cost_value"
                                        onChange={props.handleChange}
                                        onFocus={props.handleFocus}
                                        value={props.selected.cost_value}
                                        disabled={props.type === 'edit_credit'}
                                        required
                                    />
                                </SubSection>
                                
                                <SubSection small>
                                    <Label>
                                        Precio de venta
                                    </Label>
                                    <Input
                                        type="number"
                                        name="sale_value"
                                        onChange={props.handleChange}
                                        onFocus={props.handleFocus}
                                        value={props.selected.sale_value}
                                        disabled={props.type === 'edit_credit'}
                                        required
                                    />
                                </SubSection>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }                

                <SubSection small={props.category === 'inventories'}>
                    <BtnNewCard
                        title={`Agregar ${props.category ? '' : 'producto o servicios'}`}
                        path={'#'}
                        isModal={true}
                        darktheme
                        click={
                            props.category === 'properties' || props.category === 'others' 
                            ? props.handleAddProduct 
                            : props.handleAdd
                        }
                        disabled={props.type === 'edit_credit'}
                    />
                    <Modal
                        activeModal={props.activeModal}
                        handleClosed={props.handleClosed}
                        title={props.modalInformation.title}
                        text={props.modalInformation.text}
                        btn="Cerrar"
                        isLink={true}
                    />
                </SubSection>

                { props.category === 'inventories' &&
                    <SubSection small>
                        <BtnNewCard
                            title='Registrar nuevo inventario'
                            path={'#'}
                            ghost
                            click={props.openFormInventory}
                            disabled={props.type === 'edit_credit'}
                        />
                        <Modal
                            activeModal={props.activeModal}
                            handleClosed={props.handleClosed}
                            title={props.modalInformation.title}
                            text={props.modalInformation.text}
                            btn="Cerrar"
                            isLink={true}
                        />
                    </SubSection>
                }

                <SubSection table>
                    <Table>
                        
                        <Header>
                            <Column size="40%">Producto/Servicio</Column>
                            <Column size="25%">Cantidad</Column>
                            <Column size="25%">Valor unitario</Column>
                            <Column size="10%"><MdDeleteForever size='1.1rem'/></Column>
                        </Header>
                        <Content>
                            { props.form.selected_categories.map((row, i) => {
                                return (
                                    <Row type="content" key={i}>
                                        <Column size="40%">{row.name}</Column>
                                        <Column size="25%" align="center">{row.amount ? row.amount : '-'}</Column>
                                        <Column size="25%" align="center">
                                            {Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(row.cost_value)}
                                        </Column>
                                        <Column size="10%" align="center">
                                            <BtnIcon 
                                                onClick={e => props.handleRemove(e, i)} 
                                                disabled={props.type === 'edit_credit'} 
                                            >
                                                <MdDeleteForever size='1.1rem' />
                                            </BtnIcon>
                                        </Column>
                                    </Row>
                                )
                            })}
                        </Content>
                        <Footer>
                            <Column size="40%">Total {props.payment.title}:</Column>
                            <Column size="25%"></Column>
                            <Column size="25%" fontSize="18px" align="center">
                                {Intl.NumberFormat('es-CO', { maximumFractionDigits: '0', style: 'currency', currency: 'COP' }).format(props.form.total)}
                            </Column>
                            <Column size="10%"></Column>
                        </Footer>
                    </Table>
                </SubSection>
            </Section>
                
            <ContainerBtns>
                <BtnLink second to={props.cancel}>
                    Cancelar
                </BtnLink>
                <BtnNext second onClick={props.handleSubmit} type="button" darktheme>
                    Siguiente
                </BtnNext>
            </ContainerBtns>
        </Form>
    );
};
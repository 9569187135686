import React from 'react'
import {WelcomeUserName } from '../../../components/molecules/WelcomeUserName';

import image from '../../../assets/img/fef-welcome_img.png'

import {Link} from 'react-router-dom'

import {Container, Btn, Figure, Logo} from './styles'

export const WelcomeUser = () => {
    return (
        <Container>
            <Figure>
                <Logo src={image} alt="Bienvenido a Finanzas en Forma" />
            </Figure>
            <WelcomeUserName />
            <Link to='/initial-information' style={{width:'100%', display:'flex', justifyContent:'center'}}><Btn>Comenzar</Btn></Link>

        </Container>
    )
}

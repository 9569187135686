import React, { useEffect, useState } from "react"
import { getListFinancialCategoriesTotals } from "../../../api/requests"
import { CardInitialInfo } from "../../atoms/cards/CardInitialInfo"
import { MdAttachMoney } from "react-icons/md"
import { MdMoneyOff } from "react-icons/md"
import { MdAccountBalance } from "react-icons/md"
import { MdTimeline } from "react-icons/md"
import {
  Container,
  PatrimonyItem,
  FigureIcon,
  PatrimonyValue,
  Value,
} from "./styles"
import { Routes } from "../../../routes/"

export const ListInitialInfo = () => {
  const [listFinancialCategoriesTotals, setListFinancialCategoriesTotals] =
    useState({
      assets: 0,
      liabilities: 0,
      budget_income: 0,
      budget_expense: 0,
    })

  const [skeleton, setSkeleton] = useState(false)

  async function loadListFinancialCategoriesTotals() {
    const response = await getListFinancialCategoriesTotals()
    if (response.status === 200) {
      setListFinancialCategoriesTotals(response.data)
      setTimeout(() => {
        setSkeleton(false)
      }, 500)
    }
  }

  // eslint-disable-next-line
  useEffect(async () => {
    setSkeleton(true)
    await loadListFinancialCategoriesTotals()
  }, [])

  return (
    <Container>
      {skeleton && (
        <>
          <CardInitialInfo
            title="Activos iniciales"
            value="0"
            skeleton={skeleton}
          />
          <CardInitialInfo
            title="Pasivos iniciales"
            value="0"
            skeleton={skeleton}
          />
          <PatrimonyItem skeleton={skeleton}>
            <FigureIcon>
              <MdAccountBalance size="1rem" />
            </FigureIcon>
            Patrimonio:
          </PatrimonyItem>
          <CardInitialInfo
            title="Presupuestos ingresos"
            value="0"
            skeleton={skeleton}
          />
          <CardInitialInfo
            title="Presupuestos gastos"
            value="0"
            skeleton={skeleton}
          />
        </>
      )}
      {!skeleton && (
        <>
          <CardInitialInfo
            title="Activos iniciales"
            value={listFinancialCategoriesTotals.assets}
            color="verde"
            icon={<MdAttachMoney size="2rem" />}
            path={Routes.app_initial_assets}
          />
          <CardInitialInfo
            title="Pasivos iniciales"
            value={listFinancialCategoriesTotals.liabilities}
            color="rojo"
            icon={<MdMoneyOff size="2rem" />}
            path={Routes.app_initial_liabilities}
          />
          <PatrimonyItem>
            <FigureIcon>
              <MdAccountBalance size="1.5rem" />
            </FigureIcon>
            Patrimonio:
            <Value>
              {listFinancialCategoriesTotals.patrimony > 0 && (
                <PatrimonyValue>
                  {Intl.NumberFormat("es-CO", {
                    maximumFractionDigits: "0",
                    style: "currency",
                    currency: "COP",
                  }).format(listFinancialCategoriesTotals.patrimony)}
                </PatrimonyValue>
              )}
              {listFinancialCategoriesTotals.patrimony === 0 && (
                <PatrimonyValue cero>
                  {Intl.NumberFormat("es-CO", {
                    maximumFractionDigits: "0",
                    style: "currency",
                    currency: "COP",
                  }).format(listFinancialCategoriesTotals.patrimony)}
                </PatrimonyValue>
              )}
              {listFinancialCategoriesTotals.patrimony < 0 && (
                <PatrimonyValue rojo>
                  {Intl.NumberFormat("es-CO", {
                    maximumFractionDigits: "0",
                    style: "currency",
                    currency: "COP",
                  }).format(listFinancialCategoriesTotals.patrimony)}
                </PatrimonyValue>
              )}
            </Value>
          </PatrimonyItem>
          <CardInitialInfo
            title="Presupuestos ingresos"
            value={listFinancialCategoriesTotals.budget_income}
            color="azul"
            icon={<MdTimeline size="2rem" />}
            path={Routes.app_initial_budget_income}
            actualMonth="true"
          />
          <CardInitialInfo
            title="Presupuestos gastos"
            value={listFinancialCategoriesTotals.budget_expense}
            color="naranja"
            icon={<MdTimeline size="2rem" />}
            path={Routes.app_initial_budget_expense}
            actualMonth="true"
          />
        </>
      )}
    </Container>
  )
}

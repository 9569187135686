import React from "react"
import { Container, Section, SectionList } from "./styles"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { CardOption } from "../../../../components/atoms/cards/CardOption"
import { useParams } from "react-router"
import { DataUtil } from "../../../../utils/data"
import { BtnNewCard } from "../../../../components/atoms/cards/BtnNewCard"
import {
  getExcelReports,
  getInventories,
  getReports,
} from "../../../../api/requests"
import { Utilities } from "../../../../utils/utilities"
import { Messages } from "../../../../utils/messages"
import { Modal } from "../../../../components/molecules/Modals/Modal"
import { CustomDate } from "../../../../utils/date"
import { ReportData } from "../ReportData"
import { ReportPrepare } from "../ReportPrepare"

export const ReportDetail = () => {
  const { type } = useParams()
  const [activeModal, setActiveModal] = React.useState(false)
  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })
  const [form, setForm] = React.useState({
    from_date: CustomDate.date4("last_month"),
    to_date: CustomDate.date4(""),
    product: null,
  })
  const [report, setReport] = React.useState({ data: [], headers: {} })
  const [inventory, setInventory] = React.useState([])
  const [loading, setLoading] = React.useState(null)
  const [message, setMessage] = React.useState("")

  React.useEffect(() => {
    if (type === "inventory") {
      async function fetchData() {
        await getInventory(true)
      }
      fetchData()
    }
    if (type === "indicators") {
      setForm({
        // eslint-disable-next-line no-useless-computed-key
        ["from_date"]: CustomDate.date4("init_year"),
        // eslint-disable-next-line no-useless-computed-key
        ["to_date"]: CustomDate.date4("last_day_month"),
      })
    }

    setMessage(
      "Para generar un informe se debe seleccionar un rango de fechas de 1 a 12 meses."
    )
    setLoading(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getInventory() {
    const response = (await getInventories("PRODUCTO COMPRADO")).data
    const response_2 = (await getInventories("PRODUCTO FABRICADO")).data
    setInventory(response.concat(response_2))
  }

  async function selectReport() {
    if (DataUtil.getReport(type).prepare) getPrepareReport()
    else await getReport()
  }

  async function getReport() {
    const response = await getReports({
      report: type,
      from_date: form.from_date,
      to_date: form.to_date,
      type: DataUtil.getReport(type).type,
    })
    if (response.status === 200) {
      let data = {}
      data.data = Utilities.returnReport(response.data, type)
      data.headers = Utilities.returnReport(response.data.header, "headers")

      if (type === "indicators") {
        // eslint-disable-next-line array-callback-return
        data.data.map((obj) => {
          obj.message = Messages.indicators(obj.name)
        })
      }
      setReport(data)
    } else if (response.status === 400) {
      let array = Utilities.returnErrors(response.data)
      showMessage("Reporte fallido", array, true, true)
    } else {
      showMessage(
        "Reporte fallido",
        "Ha ocurrido un problema generando el reporte. Por favor, inténtelo nuevamente.",
        true
      )
    }
  }

  let getPrepareReport = () => {
    if (!form.product && type === "inventory") {
      showMessage("Error", "Debe seleccionar un producto", true)
      return
    }
    if (type === "expenses") {
      let from_year = form.from_date.split("-")[0]
      let to_year = form.to_date.split("-")[0]

      if (from_year !== to_year) {
        showMessage(
          "Error",
          "El rango de fechas debe estar dentro del mismo año",
          true
        )
        return
      }
    }

    setMessage("Generando informe")
    setLoading("processing")

    setTimeout(() => {
      setMessage(`${DataUtil.getReport(type).title}.xlsx`)
      setLoading("processed")
    }, 1000)
  }

  async function getReportMovement() {
    const response = await getExcelReports({
      from_date: form.from_date,
      to_date: form.to_date,
      type: DataUtil.getReport(type).type,
      product_id: form.product,
      report: type,
    })

    if (response.status === 200) {
      let file = new File([response.data], "", {
        type: response.headers["Content-Type"],
      })
      let url = window.URL.createObjectURL(file)
      let a = document.createElement("a")
      a.href = url
      a.download = `${DataUtil.getReport(type).title}.xlsx`
      a.click()
    } else {
      showMessage(
        "Reporte fallido",
        "Ha ocurrido un problema generando el reporte. Por favor, inténtelo nuevamente.",
        true
      )
    }
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const handleClosed = () => {
    setActiveModal(false)
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev={true}
          pathPrev="/my-reports/report"
          titlePage={DataUtil.getReport(type).title}
          displayDownload={false}
          darktheme
        />
        <CardOption
          title={
            type === "indicators"
              ? "Año en curso (por defecto)"
              : "Filtro de búsqueda"
          }
          titleColor="white"
          isFilter={true}
          form={form}
          type={type}
          dateType={DataUtil.getReport(type)}
          inventory={inventory}
          disabled={type === "indicators"}
          handleChange={handleChange}
        />
        <BtnNewCard
          title="Generar informe"
          marginBottom="1px"
          darktheme
          click={selectReport}
        />
      </Section>
      <SectionList>
        {!DataUtil.getReport(type).prepare ? (
          <ReportData
            data={report}
            fileName={DataUtil.getReport(type).title}
            type={type}
          />
        ) : (
          <ReportPrepare
            message={message}
            loading={loading}
            click={getReportMovement}
            top="1rem"
          />
        )}
      </SectionList>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </Container>
  )
}

import styled from 'styled-components'
import {Link} from 'react-router-dom'

// const desktopStartWidth = 1023;
// const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
// const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
`
export const Section = styled.section`
    width:100%;
    display:flex;
`
export const Subsection = styled.section`
    width:100%;
    display:flex;
    flex-direction:column;
    padding-bottom:.3rem;

`
export const SectionTitle = styled.section`
    width:auto;
    display:flex;
`

export const TitlePage = styled.h4`
    flex:1;
    display:flex;
    align-items:center;
    color: ${({darktheme}) => (darktheme ? '#fff' : '')};

`

export const SubtitlePage = styled.span`
    font-size: .8rem;
    align-items:left;
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.text};
`

export const BtnInfo = styled.button`
        width:40px;
        height:40px;
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left:.3rem;
        color: ${props => 
            (props.darktheme && props.info) ? props.theme.colors.third :
            props.darktheme ? props.theme.colors.white :
            props.theme.colors.main};
        opacity: ${props => props.darktheme ? '' : '70%'};
        transition: all 0.3s ease-in;
        &:hover {
            color: ${props => props.theme.colors.second};
            opacity:70%;
            transition: all 0.5s ease-in-out;
        }
        &:disabled {
            color: ${props => props.theme.colors.second};
            opacity: 40%;
            cursor: default;
        }
`
export const BtnPrev = styled(Link)`
        width:30px;
        height:40px;
        /* background-color:pink; */
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left:0;
        margin-right:1rem;
        color: ${props => (props.darktheme ? '#fff' : props.theme.colors.dark)};

`
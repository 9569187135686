import React from 'react';
import {Btn} from './styles'

export const BtnAction = ({name, second, white, ghost, darktheme}) => {
  return(
    <Btn 
      value={name}
      type="submit"
      second={second}
      white={white}
      ghost={ghost}
      darktheme={darktheme}
    >
    </Btn>
  )
}
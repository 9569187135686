import React from "react";
import {
  ContainerLink,
  SectionTop,
  WhiteSpace,
  SectionBottom,
  ValueStock,
  SpanStock,
  Container,
  ContainerCategory,
  FigureCategory,
  TitleCategory,
  Section,
  CardTitle,
  CardSku,
  CardValue,
  ContainerTitle,
} from "./styles.js";

import { MdLocalMall, MdToys, MdWidgets } from "react-icons/md";

export const CardInventory = ({
  icon,
  redirect,
  color,
  category,
  sku,
  stock,
  name,
  is_active,
  quantity,
  sale_value,
  cost_value,
  path,
  url_detail,
  actualMonth,
  skeleton,
  image,
}) => {
  const capitalizeText = (name = "poDructo pruebA") => {
    const lower = name.toLowerCase();
    const capitalizeText = name.charAt(0).toUpperCase() + lower.slice(1);
    return capitalizeText;
  };

  // const capitalizeWell = capitalizeWelcome(namee)
  const capitalizeName = capitalizeText(name);

  return (
    <>
      {!skeleton && (
        <ContainerLink to={path}>
          <SectionTop>
            {quantity <= 0 && (
              <>
                <ContainerCategory>
                  {category === "PRODUCTO FABRICADO" && (
                    <>
                      <FigureCategory rojo>
                        <MdWidgets size="2rem" />
                      </FigureCategory>
                      <TitleCategory rojo>Producto Fabricado</TitleCategory>
                    </>
                  )}
                  {category === "PRODUCTO COMPRADO" && (
                    <>
                      <FigureCategory rojo>
                        <MdLocalMall size="2rem" />
                      </FigureCategory>
                      <TitleCategory rojo>Producto Comprado</TitleCategory>
                    </>
                  )}
                  {category === "SERVICIO" && is_active === false && (
                    <>
                      <FigureCategory rojo>
                        <MdToys size="2rem" />
                      </FigureCategory>
                      <TitleCategory rojo>Servicio</TitleCategory>
                    </>
                  )}
                  {category === "SERVICIO" && is_active === true && (
                    <>
                      <FigureCategory>
                        <MdToys size="2rem" />
                      </FigureCategory>
                      <TitleCategory>Servicio</TitleCategory>
                    </>
                  )}
                </ContainerCategory>
              </>
            )}
            {quantity > 0 && (
              <>
                <ContainerCategory>
                  {category === "PRODUCTO FABRICADO" && (
                    <>
                      <FigureCategory>
                        <MdWidgets size="2rem" />
                      </FigureCategory>
                      <TitleCategory>Producto Fabricado</TitleCategory>
                    </>
                  )}
                  {category === "PRODUCTO COMPRADO" && (
                    <>
                      <FigureCategory>
                        <MdLocalMall size="2rem" />
                      </FigureCategory>
                      <TitleCategory>Producto Comprado</TitleCategory>
                    </>
                  )}
                  {category === "SERVICIO" && is_active === true && (
                    <>
                      <FigureCategory>
                        <MdToys size="2rem" />
                      </FigureCategory>
                      <TitleCategory>Servicio</TitleCategory>
                    </>
                  )}
                  {category === "SERVICIO" && is_active === false && (
                    <>
                      <FigureCategory rojo>
                        <MdToys size="2rem" />
                      </FigureCategory>
                      <TitleCategory rojo>Servicio</TitleCategory>
                    </>
                  )}
                </ContainerCategory>
              </>
            )}
          </SectionTop>
          <WhiteSpace />

          <SectionBottom>
            <ContainerTitle>
              <div>
                <CardTitle>{capitalizeName}</CardTitle>
                <CardSku>Ref: {sku}</CardSku>
              </div>
              {image && <img alt={capitalizeName} src={image} />}
            </ContainerTitle>

            {quantity <= 0 && (
              <>
                {category === "PRODUCTO FABRICADO" && (
                  <ValueStock rojo>
                    {quantity}
                    <SpanStock> Stock</SpanStock>
                  </ValueStock>
                )}
                {category === "PRODUCTO COMPRADO" && (
                  <ValueStock rojo>
                    {quantity}
                    <SpanStock> Stock</SpanStock>
                  </ValueStock>
                )}

                {category === "SERVICIO" && is_active === true && (
                  <ValueStock>
                    <SpanStock> Disponible</SpanStock>
                  </ValueStock>
                )}
                {category === "SERVICIO" && is_active === false && (
                  <ValueStock rojo>
                    <SpanStock> No disponible</SpanStock>
                  </ValueStock>
                )}
              </>
            )}
            {quantity > 0 && (
              <>
                {category === "PRODUCTO FABRICADO" && (
                  <ValueStock>
                    {quantity}
                    <SpanStock> Stock</SpanStock>
                  </ValueStock>
                )}
                {category === "PRODUCTO COMPRADO" && (
                  <ValueStock>
                    {quantity}
                    <SpanStock> Stock</SpanStock>
                  </ValueStock>
                )}

                {category === "SERVICIO" && is_active === true && (
                  <ValueStock>
                    <SpanStock> Disponible</SpanStock>
                  </ValueStock>
                )}
                {category === "SERVICIO" && is_active === false && (
                  <ValueStock rojo>
                    <SpanStock> No disponible</SpanStock>
                  </ValueStock>
                )}
              </>
            )}

            {category === "PRODUCTO FABRICADO" && quantity <= 0 && (
              <CardValue rojo>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(cost_value)}
              </CardValue>
            )}
            {category === "PRODUCTO FABRICADO" && quantity > 0 && (
              <CardValue>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(cost_value)}
              </CardValue>
            )}
            {category === "PRODUCTO COMPRADO" && quantity <= 0 && (
              <CardValue rojo>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(cost_value)}
              </CardValue>
            )}
            {category === "PRODUCTO COMPRADO" && quantity > 0 && (
              <CardValue>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(cost_value)}
              </CardValue>
            )}
            {category === "PRODUCTO" && quantity <= 0 && (
              <CardValue rojo>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(sale_value)}
              </CardValue>
            )}
            {category === "PRODUCTO" && quantity > 0 && (
              <CardValue>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(sale_value)}
              </CardValue>
            )}
            {category === "SERVICIO" && is_active === true && (
              <CardValue>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(sale_value)}
              </CardValue>
            )}
            {category === "SERVICIO" && is_active === false && (
              <CardValue rojo>
                {Intl.NumberFormat("es-CO", {
                  maximumFractionDigits: "0",
                  style: "currency",
                  currency: "COP",
                }).format(sale_value)}
              </CardValue>
            )}
          </SectionBottom>
        </ContainerLink>
      )}
      {skeleton && (
        <Container>
          <FigureCategory skeleton></FigureCategory>
          <Section>
            <CardTitle skeleton>prueba</CardTitle>
            <CardValue skeleton>0</CardValue>
          </Section>
        </Container>
      )}
    </>
  );
};

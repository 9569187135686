import React from 'react';
import {Container, Section} from './styles';

import {TitleAndInfo} from '../../../components/molecules/TitleAndInfo';
import { FormInitialInventoriesContainer } from '../../../containers/FormInitialInventoriesContainer';

import {Routes} from '../../../routes'


export const InitialInventoryForm = () => {
    return (
        <Container>
            <Section>
                <TitleAndInfo 
                    btnPrev
                    pathPrev = {Routes.app_initial_inventory}
                    titlePage='Nuevo inventario inicial'
                    darktheme='true'
                    tooltip="inventory"
                />
                <FormInitialInventoriesContainer />
            </Section>
        </Container>
    )
}

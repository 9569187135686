import React from 'react'
import { MdExitToApp } from 'react-icons/md'
import {Container, Figure, Section, CardTitle} from './styles.js'


export const Logout = ({title, click, darktheme, ghost, marginBottom, marginTop}) => {

    return (
        <Container onClick={click} 
            darktheme={darktheme} ghost={ghost} 
            marginBottom={marginBottom} marginTop={marginTop}
            disabled
        >
            <Section>
                <CardTitle>{title}</CardTitle>
            </Section>
            <Figure>
                <MdExitToApp size='2.5rem' color='#95e8d4'/>
            </Figure>
        </Container>
    )
}

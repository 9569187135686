import React from 'react'
import {TitleCard, LinkCard, Card, Line, Row, Column} from './styles.js'


export const Subtitle = ({title, btnName, to, btnPlan, click}) => {

    return (
        
        <>
            <Card>
                <Row>
                    <Column wd={70}>
                        <TitleCard>{title}</TitleCard>
                    </Column>
                    <Column align="right" wd={30}>
                        <LinkCard onClick = {click}>{btnPlan}</LinkCard>
                    </Column>
                    <Column align="right" wd={30}>
                        <LinkCard to={to}>{btnName}</LinkCard>
                    </Column>
                    
                </Row>
            </Card>
            <Line/>
        </>
    )
}

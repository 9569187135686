import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"

const tabletStartWidth = 768
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.main};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease;
`

export const Figure = styled.figure`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
`

export const Logo = styled.img`
  margin-bottom: 1em;
  padding: 0;
  height: 150px;
  ${mobile} {
    margin-bottom: 1em;
    height: 200px;
  }
`

export const Title = styled.h2`
  font-size: 1.4rem;
  color: #fff;
  line-height: 0rem;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  ${mobile} {
    padding-bottom: 4rem;
  }
`

export const Subtitle = styled.h3`
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #ffffff;
  ${mobile} {
    font-size: 0.9rem;
  }
`

export const Text = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: #ffffff;
  ${mobile} {
    font-size: 1rem;
  }
`

export const TextRequest = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.third};
  padding-top: 0.2rem;
  padding-bottom: 1rem;
  ${mobile} {
    font-size: 1.5rem;
  }
`

const showSlow = keyframes`
  0% { opacity:0; transform:translateY(30%)}
  100% {opacity:1; transform:translateY(0%)}
`

export const Button = styled(Link)`
  margin-top: 3rem;
  color: ${(props) => props.theme.colors.main};
  background: ${(props) => props.theme.colors.second};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 3rem;
  border-radius: 50px;
  transition: all 500ms ease;
  font-size: 1.2rem;
  font-weight: 500;
  animation: ${showSlow} 0.5s ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.third};
  }
  ${mobile} {
    font-size: 1.3rem;
    padding: 1rem 3rem;
  }
`

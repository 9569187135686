import React from "react"
import {
  ContainerLink,
  Container,
  FigureCircle,
  Section,
  SubSection,
  SpanNegrita,
  SpanStock,
  Line,
  SectionValue,
  CardTitle,
} from "./styles.js"

export const CardLog = ({
  id,
  icon,
  inventory,
  created = "",
  title = "prueba",
  type_change,
  new_data,
  skeleton,
}) => {
  const capitalizeText = (name = "inventario") => {
    const lower = name.toLowerCase()
    const capitalizeText = name.charAt(0).toUpperCase() + lower.slice(1)

    return capitalizeText
  }

  const capitalizeName = capitalizeText(inventory)
  const dateOptionArray = created.toString().split("-")
  const selectedYear = dateOptionArray[0]
  const selectedMonth = dateOptionArray[1]
  const selectedDayHour = dateOptionArray[2]
  const dayOptionArray = selectedDayHour.toString().split(" ")
  const selectedDay = dayOptionArray[0]
  const selectedHour = dayOptionArray[1]

  const selectedMonthNumber = Number(selectedMonth)

  // eslint-disable-next-line
  const month = new Array()
  month[0] = "Enero"
  month[1] = "Febrero"
  month[2] = "Marzo"
  month[3] = "Abril"
  month[4] = "Mayo"
  month[5] = "Junio"
  month[6] = "Julio"
  month[7] = "Agosto"
  month[8] = "Septiembre"
  month[9] = "Octubre"
  month[10] = "Noviembre"
  month[11] = "Diciembre"
  const dateMonth = month[selectedMonthNumber - 1]

  const dateTitle = `${dateMonth} ${selectedDay} de ${selectedYear} (${selectedHour})`

  return (
    <>
      {!skeleton && (
        <ContainerLink>
          <FigureCircle>{icon}</FigureCircle>
          <Section>
            <SubSection>
              <SpanNegrita>{dateTitle}</SpanNegrita>
            </SubSection>
            <Line />
            <SubSection>
              <CardTitle>{capitalizeName}</CardTitle>
            </SubSection>
            <SubSection value>
              <SectionValue>{type_change}</SectionValue>
            </SubSection>
            <SubSection value>
              <SpanStock>{new_data}</SpanStock>
            </SubSection>
          </Section>
        </ContainerLink>
      )}
      {skeleton && (
        <Container>
          <FigureCircle skeleton>{icon}</FigureCircle>
          <Section>
            <CardTitle skeleton>{title}</CardTitle>
          </Section>
        </Container>
      )}
    </>
  )
}

import styled from 'styled-components'

/* const desktopStartWidth = 1023; */
const tabletStartWidth = 768;
/* const tablet = `@media (max-width: ${desktopStartWidth}px)` */
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const CardForm = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
  /* padding:3em 2em; */
  text-align:center;
  /* background:pink; */

`

export const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  /* background:red; */
  height:100%;

`
export const Section = styled.section`
  width:100%;
  /* height:40vh; */
  display:flex;
  flex-direction:column;
  align-items:center;
`

export const TitleStep = styled.h3`
  padding-bottom:2rem;

`

export const Input = styled.input`
  padding:.5rem 0;
  width:100%;
  /* border:1px solid #999999; */
  border-bottom: 1px solid #fff;
  color:#fff;
  placeholder:#fff;
  background:transparent;
  outline:none;
  font-size: 1em;
  margin-bottom:1em;
  &::-webkit-input-placeholder {
    color: #fff;
  }
  ${mobile} {
    font-size: .9rem;
  }
`

export const Span = styled.span`
  /* padding: 1em 0 0; */
`

export const BtnNext = styled.button`
  width: ${props => ( props.second ? "45%" : "70%")};
  color: ${props => ( props.ghost ? "#fff" : props.theme.colors.main)};
  background: ${props => ( props.ghost ? "transparent" : "#fff")};
  border:3px solid #fff;
  margin-top:8em;
  padding:1em .5em;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.second};
    color: #fff;
  }
  @media (min-height:577px){
    margin-top:-5rem;
  }
`

export const BtnText = styled.p`
    display:flex;
    align-items:center;
    justify-content:center;
    color: #fff;
    font-style:italic;
    text-decoration:none;
    font-size: .8rem;
    transition: all 0.3s ease-in;
    &:hover {
        transition: all 0.5s ease-in-out;
        text-decoration:underline;
    }
    @media (min-height:577px){
      margin-top:-5rem;
      
    }
`


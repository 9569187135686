export const CustomDate = {
    
    formatDate: (date, is_month_name, param) => {
        let array_date = [];
        let year = ''
        let month = ''
        let day = ''

        if (date) {
            array_date = date.split("-");
            year = array_date[0];
            if (is_month_name) month = CustomDate.getMonthName(parseInt(array_date[1]));
            else month = array_date[1];
            day = array_date[2];
        }
        else {
            let d = new Date();
            day = d.getDate().toString().length === 1 ? '0' + d.getDate() : d.getDate()

            if (param === 'last_month') month = d.getMonth()
            else if (param === 'last_day_month') {
                month = d.getMonth()
                day = new Date(d.getFullYear(), month, 0).getDate();
            }
            else if (param === 'init_year') {
                month = 1
                day = 1
            }
            else month = d.getMonth() + 1

            day = day.toString().length === 1 ? '0' + day : day
            month = month.toString().length === 1 ? '0' + month : month
            year = d.getFullYear()
            if (is_month_name) month = CustomDate.getMonthName(month)
        }
        
        return {month: month, day: day, year: year};
    },
    date1: (date) => {
        let res = CustomDate.formatDate(date, true);
        return `${res.month} ${res.day}, ${res.year}` 
    },
    date2: (date) => {
        let res = CustomDate.formatDate(date, true);
        return `${res.month} ${res.day} de ${res.year}`
    },
    date3: () => {
        let res = CustomDate.formatDate('', true);
        return `${res.month}, ${res.year}`;
    },
    date4: (param) => {
        let res = CustomDate.formatDate(null, null, param);
        return `${res.year}-${res.month}-${res.day}`;
    },
    getMonthName: (num) => {
        let month = "";
        let months = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ]
        
        month = months[num - 1] ? months[num - 1] : 'Enero';
        return month
    },
}
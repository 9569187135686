import React from "react"
import {
  ContainerLink,
  Container,
  SectionFigure,
  FigureCircle,
  TextNull,
  Button,
  Section,
  SubSection,
  SpanNegrita,
  SpanStock,
  Line,
  SectionValue,
  SpanValue,
  CardTitle,
  CardValue,
} from "./styles.js"
import { MdMoreVert } from "react-icons/md"
import { deleteStockItem } from "../../../../api/requests.js"
import { ModalDeleteConfirm } from "../../../molecules/Modals/ModalDeleteConfirm/index.js"

export const CardStock = ({
  id,
  icon,
  color,
  category,
  created = "",
  is_active,
  cost_value,
  sale_value,
  available,
  quantity,
  value,
  title = "2021-08",
  reList,
  setReList,
  updateItems,
  redirect,
  redirectEdit,
  skeleton,
}) => {
  const [activeModalDisabled, setActiveModalDisabled] = React.useState(false)

  async function deactivateCard() {
    await deleteStockItem(id)
    setActiveModalDisabled(false)
    await updateItems()
  }

  function deleteModal() {
    setActiveModalDisabled(true)
  }

  function handleClosed() {
    setActiveModalDisabled(false)
  }

  const dateOptionArray = created.toString().split("-")
  const selectedYear = dateOptionArray[0]
  const selectedMonth = dateOptionArray[1]
  const selectedDay = dateOptionArray[2]
  const selectedMonthNumber = Number(selectedMonth)

  // eslint-disable-next-line
  const month = new Array()
  month[0] = "Enero"
  month[1] = "Febrero"
  month[2] = "Marzo"
  month[3] = "Abril"
  month[4] = "Mayo"
  month[5] = "Junio"
  month[6] = "Julio"
  month[7] = "Agosto"
  month[8] = "Septiembre"
  month[9] = "Octubre"
  month[10] = "Noviembre"
  month[11] = "Diciembre"

  const dateMonth = month[selectedMonthNumber - 1]
  const dateTitle = `${dateMonth} ${selectedDay} de ${selectedYear}`

  return (
    <>
      {activeModalDisabled === true && (
        <ModalDeleteConfirm
          activeModal={true}
          title="Anular Stock"
          text="¿Desea anular este stock?."
          btn="Cancelar"
          btnSubmit="Anular"
          deleteCard={deactivateCard}
          handleClosed={handleClosed}
        />
      )}
      {!skeleton && (
        <ContainerLink>
          {category === "PRODUCTO FABRICADO" &&
          available > 0 &&
          is_active === true ? (
            <FigureCircle>{icon}</FigureCircle>
          ) : (
            ""
          )}
          {category === "PRODUCTO FABRICADO" &&
          available > 0 &&
          is_active === false ? (
            <SectionFigure>
              <FigureCircle deactive>{icon}</FigureCircle>
              <TextNull>Anulado</TextNull>
            </SectionFigure>
          ) : (
            ""
          )}
          {category === "PRODUCTO FABRICADO" &&
          available < 1 &&
          is_active === true ? (
            <FigureCircle rojo>{icon}</FigureCircle>
          ) : (
            ""
          )}
          {category === "PRODUCTO FABRICADO" &&
          available < 1 &&
          is_active === false ? (
            <SectionFigure>
              <FigureCircle deactive>{icon}</FigureCircle>
              <TextNull>Anulado</TextNull>
            </SectionFigure>
          ) : (
            ""
          )}
          {category === "SERVICIO" && available > 0 && is_active === true ? (
            <FigureCircle>{icon}</FigureCircle>
          ) : (
            ""
          )}
          {category === "SERVICIO" && available < 1 && is_active === true ? (
            <FigureCircle>{icon}</FigureCircle>
          ) : (
            ""
          )}
          {category === "SERVICIO" && available > 0 && is_active === false ? (
            <SectionFigure>
              <FigureCircle deactive>{icon}</FigureCircle>
              <TextNull>Anulado</TextNull>
            </SectionFigure>
          ) : (
            ""
          )}
          {category === "SERVICIO" && available < 1 && is_active === false ? (
            <SectionFigure>
              <FigureCircle deactive>{icon}</FigureCircle>
              <TextNull>Anulado</TextNull>
            </SectionFigure>
          ) : (
            ""
          )}

          <Section>
            <SubSection>
              <SpanNegrita>{dateTitle}</SpanNegrita>
              {category === "PRODUCTO FABRICADO" ? (
                available > 0 ? (
                  <SpanStock>
                    Stock: <SpanNegrita>{available}</SpanNegrita>/{quantity}
                  </SpanStock>
                ) : (
                  <SpanStock rojo>
                    Stock: {available}
                    <SpanNegrita>/{quantity}</SpanNegrita>
                  </SpanStock>
                )
              ) : (
                ""
              )}
              {category === "SERVICIO" ? (
                is_active === true ? (
                  <SpanStock>
                    <SpanNegrita>Disponible</SpanNegrita>
                  </SpanStock>
                ) : (
                  <SpanStock rojo>
                    <SpanNegrita>No disponible</SpanNegrita>
                  </SpanStock>
                )
              ) : (
                ""
              )}
            </SubSection>
            <Line />
            <SubSection value>
              <SectionValue>
                Valor costo
                {is_active === true ? (
                  <SpanValue rojo>
                    {Intl.NumberFormat("es-CO", {
                      maximumFractionDigits: "0",
                      style: "currency",
                      currency: "COP",
                    }).format(cost_value)}
                  </SpanValue>
                ) : (
                  <SpanValue null>
                    {Intl.NumberFormat("es-CO", {
                      maximumFractionDigits: "0",
                      style: "currency",
                      currency: "COP",
                    }).format(cost_value)}
                  </SpanValue>
                )}
              </SectionValue>
              <SectionValue>
                Valor venta
                {is_active === true ? (
                  <SpanValue>
                    {Intl.NumberFormat("es-CO", {
                      maximumFractionDigits: "0",
                      style: "currency",
                      currency: "COP",
                    }).format(sale_value)}
                  </SpanValue>
                ) : (
                  <SpanValue null>
                    {Intl.NumberFormat("es-CO", {
                      maximumFractionDigits: "0",
                      style: "currency",
                      currency: "COP",
                    }).format(cost_value)}
                  </SpanValue>
                )}
              </SectionValue>
            </SubSection>
          </Section>
          {is_active === true && (
            <Button onClick={deleteModal}>
              <MdMoreVert size="1.5rem" />
            </Button>
          )}
          {is_active === false && <Button deactive></Button>}
        </ContainerLink>
      )}
      {skeleton && (
        <Container>
          <FigureCircle skeleton>{icon}</FigureCircle>
          <Section>
            <CardTitle skeleton>{title}</CardTitle>
            <CardValue skeleton>
              {Intl.NumberFormat("es-CO", {
                maximumFractionDigits: "0",
                style: "currency",
                currency: "COP",
              }).format(value)}
            </CardValue>
          </Section>
        </Container>
      )}
    </>
  )
}

import React from "react"
import {
  CardForm,
  Form,
  Section,
  TitleStep,
  Span,
  Input,
  BtnNext,
  BtnText,
} from "./styles"
import { Link } from "react-router-dom"

export const FormResetPassword = (props) => {
  const [formValues, setFormValues] = React.useState({
    email: "",
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  const _handleSubmit = (event) => {
    event.preventDefault()
    props.handleSubmit({ ...formValues })

    event.target.reset()
    setFormValues({
      email: "",
    })
  }

  // const submitData = (event) => {
  //   event.preventDefault()
  //   props.onSubmit()
  // }

  return (
    <>
      <CardForm>
        <Form onSubmit={_handleSubmit}>
          <>
            <Section>
              <TitleStep>Ingresar correo electrónico registrado</TitleStep>
              <Input
                placeholder="Correo electrónico*"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                // value={props.email}
                // onChange={handleInputChange}
                // onChange={props.onChangeEmail}
                required
              />
            </Section>
            <BtnNext /* onClick={completeFormStep} */ type="submit">
              Enviar Solicitud
            </BtnNext>
            <Span>
              <Link to={props.url} style={{ textDecoration: "none" }}>
                <BtnText>{props.redirect}</BtnText>
              </Link>
            </Span>
          </>
          {/* 
            <BtnAction
              btn={props.btn}
              onClick={completeFormStep}
              type="button"
            />
          */}
        </Form>
      </CardForm>
    </>
  )
}

import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
  

export const ReportPdf = (props) => {

    const styles = StyleSheet.create({
        page: {
          padding: 30,
          fontSize: 12,
          fontWeight: 'normal',
        },
        section: {
            width: '100%',
            display: 'flex',
            marginBottom: 40,
        },
        rowTitle: {
            width: '100%',
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        header: {
            fontWeight: 800,
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            padding: 2
        },
        right: {
            textAlign: 'right'
        },
        isTotal: {
            textTransform: 'uppercase',
            marginBottom: 10,
            borderBottomColor: '#B8B8B8',
            borderBottom: 1
        },
        isTitle: {
            textTransform: 'uppercase',
            fontWeight: 800
        },
        isNotTitle: {
            marginLeft: '10px'
        },
        table: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginVertical: 12,
            borderWidth: 0,
            fontSize: 12,
            marginTop: 20
        },
        row: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        column: {
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            padding: 4
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.section}>
                    {props.data.headers.map((row, i) => {
                        return (
                            <View style={styles.rowTitle} key={'title' + i}>
                                <Text style={styles.header}>{row.value}</Text>
                            </View>
                        )
                    })}
                </View>

                <View style={styles.table}>
                    {props.data.data.map((row, i) => {
                        return (
                            <View style={[row.is_total && styles.isTotal, row.is_title && styles.isTitle, !row.is_title && styles.isNotTitle, styles.row]} key={i}>
                                <Text style={styles.column}>{row.name}</Text>
                                <Text style={[styles.column, styles.right]}>{row.value}</Text>
                            </View>
                        )
                    })}
                </View>
            </Page>
        </Document>
    )
}
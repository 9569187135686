import React, { useEffect, useState } from "react"
import {
  Container,
  Section,
  Header,
  Title,
  Figure,
  Logo,
  FormContainer,
} from "./styles.js"
import logo from "../../assets/img/finku-logo.png"
import { FormSignUp } from "../../components/organisms/forms/FormSignUp"
import { Modal } from "../../components/molecules/Modals/Modal"
import { ModalLoading } from "../../components/molecules/Modals/ModalLoading"
import { postSubscriptionBilling, signup } from "../../api/requests.js"
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes/index.js"
import { Utilities } from "../../utils/utilities.js"
import moment from "moment"
import "moment/locale/es"

export const SignUp = () => {
  const history = useHistory()
  const [formStep, setFormStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [activeModalLoading, setActiveModalLoading] = useState(false)
  const [activeModal, setActiveModal] = useState(false)
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })

  const dateNow = new Date()
  const formatDate = moment(dateNow).add(2, "years").format("YYYY-MM-DD")
  const reminderDate = moment(formatDate).add(-2, "days").format("YYYY-MM-DD")

  const handleSubmit = (data) => {
    setIsLoading(true)
    setActiveModalLoading(true)
    signup(data).then(async (response) => {
      if (response.status === 201) {
        if (response.data?.user.id) {
          const subscriptionData = {
            user_id: response.data.user.id,
            subscription_id: 4,
            initial_date: new Date().toISOString().split("T")[0],
            final_date: formatDate,
            subscription_active: true,
            reminder_date: reminderDate,
            reminder_send: false,
          }
          await postSubscriptionBilling(subscriptionData).then((response) => {
            if (response.status === 200) {
              setActiveModal(true)
              setModalData({
                title: "Usuario Registrado",
                text: "El registro de usuario se ha realizado correctamente",
                btn: "Aceptar",
              })
            } else if (response.status === 400) {
              postSubscriptionBilling(subscriptionData).then((res) => {
                setActiveModal(true)
                setModalData({
                  title: "Usuario Registrado",
                  text: "El registro de usuario se ha realizado correctamente",
                  btn: "Aceptar",
                })
              })
            } else {
              postSubscriptionBilling(subscriptionData).then((res) => {
                setActiveModal(true)
                setModalData({
                  title: "Usuario Registrado",
                  text: "El registro de usuario se ha realizado correctamente",
                  btn: "Aceptar",
                })
              })
            }
          })
        } else {
          setActiveModal(true)
          setModalData({
            title: "Usuario Registrado",
            text: "El registro de usuario se ha realizado correctamente",
            btn: "Aceptar",
          })
        }
      } else if (response.status === 400 || response.status === 404) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Registro fallido", array, true, true)
      }
    })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const handleClosed = () => {
    setActiveModal(false)
    setIsLoading(false)
    setActiveModalLoading(false)
    if (modalData.errorEmail || modalData.error) {
      setFormStep(0)
    } else if (modalData.errorIdentification) {
      setFormStep(1)
    } else {
      history.push(Routes.app_login)
    }
  }

  useEffect(() => {
    document.title = "Registro de usuario"
  }, [])

  return (
    <>
      {isLoading ? (
        <ModalLoading
          activeModalLoading={activeModalLoading}
          title="Cargando..."
        />
      ) : (
        ""
      )}
      <Container>
        <Section>
          <Header>
            <Title>
              Registro de Usuario
            </Title>
          </Header>
          <FormContainer>
            <FormSignUp
              formStep={formStep}
              setFormStep={setFormStep}
              title="Registro de usuario"
              btn="Registrarse"
              redirect="¿Ya tienes una cuenta? Inicia sesión."
              url="/login"
              handleSubmit={handleSubmit}
              onSubmit={function () {
                handleSubmit()
              }}
            />
          </FormContainer>
        </Section>
      </Container>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </>
  )
}

import styled from 'styled-components'
import { fadeIn } from '../../../../styles/animation';


// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const ContainerLink = styled.div`
    width:100%;
    background:#fff;
    padding:.5rem 1.5rem .5rem 1rem;
    border-radius:.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:1rem;
    transition:all .5s ease-in;
    &:hover {
        opacity:.8;
        transition:all .3s ease;
    }
    ${mobile} {
        width:100%;
    }
`
export const Container = styled(ContainerLink)`
    ${fadeIn({time:'2s'})};

`

export const Button = styled.button`
    width:auto;
    height:auto;
    color: ${props => props.theme.colors.deactive};
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:.7rem;
    padding-left:.3rem;
    transition:all 500ms ease;
    &:hover {
        color: ${props => props.theme.colors.third};


    }
`
export const FigureCircle = styled.figure`
    width:2.5rem;
    height:2.5rem;
    background: ${props => (
        props.verde ? props.theme.colors.green_light :
        props.rojo ? props.theme.colors.red_light :
        props.naranja ? props.theme.colors.orange_light :
        props.azul ? props.theme.colors.blue_light : props.theme.colors.deactive

    )};

    opacity: ${props => (props.skeleton ? '50%' : '')};
    box-shadow: ${props => (props.skeleton ? '' : ' 0 10px 14px rgba(0, 0, 0, .05)')};
    color:#fff;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const Section = styled.section`
    padding-left: 1rem;
    flex:1;
    display:flex;
    flex-direction:column;
`
export const SubSection = styled.section`
    display:flex;
    justify-content:space-between;
    align-items:flex-end;

`
export const SpanNegrita = styled.span`
    font-weight:600;
`
export const SpanStock = styled.span`
    font-size:1em;
    color: ${props => (
        props.rojo ? props.theme.colors.red_brand
        :
        ''
    )};
    ${mobile} {
        font-size:1.2em;
    }
`
export const Line = styled.div`
    margin:.3em 0 ;
    border-bottom:2px solid ${props => props.theme.colors.deactive};
`

export const SectionValue = styled.section`
    font-weight:500;
    font-size:1em;
    color: ${props => props.theme.colors.text};
    display:flex;
    flex-direction:column;
    align-items:center;

`
export const SpanValue = styled.span`
    font-weight:600;
    font-size:1.3em;
    color: ${props => (
        props.rojo ? props.theme.colors.red_brand
        :
        props.theme.colors.text

    )};
`
export const CardTitle = styled.h3`
    color: ${props => (props.skeleton ? '#fff' : props.theme.colors.dark)};
    font-size:1.3em;

`

export const CardValue = styled.p`
    font-size:.7rem;
    font-weight:500;
    color: ${props => (
        props.rojo ? props.theme.colors.red_brand
        :
        props.skeleton ? '#fff'
        :
        props.theme.colors.text
    )};

`


import styled from "styled-components"
import { Link } from "react-router-dom"

const tabletStartWidth = 768
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const FigureCircle = styled.figure`
  width: 3rem;
  margin-bottom: 1rem;
  height: 3rem;
  background: ${(props) => props.theme.colors[props.bg]};
  opacity: ${(props) => (props.skeleton ? "50%" : "")};
  box-shadow: ${(props) =>
    props.skeleton ? "" : " 0 10px 14px rgba(0, 0, 0, .05)"};
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h3`
  margin: auto;
  text-align: center;
  color: ${(props) => props.theme.colors[props.color]};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: bold;
`

export const Item = styled(Link)`
  width: 30%;
  margin: 0.1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  padding: 1.5rem 0.8rem;
  border-radius: 1.3rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 148px;
  &:hover {
    background: ${(props) => props.theme.colors.dark};
    transition: all 0.4s ease-in-out;
    ${Title} {
      color: ${(props) => props.theme.colors.white};
    }
    ${FigureCircle} {
      background: #546871;
    }
  }
  ${mobile} {
    width: 45%;
    margin: 0.3rem;
  }
`

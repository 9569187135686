import React, { useEffect, useState } from "react"
import { FormLiabilities } from "../../components/organisms/forms/myInfo/FormLiabilities"

import {
  getListFinancialCategoriesLiabilities,
  getLiabilities,
} from "../../api/requests"

export const FormLiabilitiesContainer = (props) => {
  const initialForm = {
    category: "",
    value: "",
    financial_type: "PASIVOS",
  }

  const validationsForm = (form) => {
    let errors = {}
    let regexNumbers = /^[0-9]+$/
    // let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    // let regexComments = /^.{1,255}$/;
    // let regexNumbersSpace = /^[\d]+$/;
    // let regexAlphaNumbers = /^[0-9a-zA-Z]+$/;

    if (!form.category.trim()) {
      errors.category = "El campo Categoría financiera es requerido."
    } else if (!regexNumbers.test(form.category.trim())) {
      errors.category =
        "En el campo Categoría financiera debe seleccionar una categoría validaS."
    }

    if (!form.value.trim()) {
      errors.value = "El campo Valor es requerido."
    } else if (!regexNumbers.test(form.value.trim())) {
      errors.value =
        "El campo Valor solo acepta números, sin puntaciones, ni espacios."
    }

    return errors
  }

  const [listFinancialCategories, setListFinancialCategories] = useState([])

  useEffect(() => {
    async function loadListFinancialCategoriesLiabilities() {
      const response = await getListFinancialCategoriesLiabilities(true)
      if (response.status === 200) {
        setListFinancialCategories(response.data)
      }
    }
    loadListFinancialCategoriesLiabilities()
  }, [])

  useEffect(() => {
    async function loadListFinancialCategoriesLiabilitiesFilter() {
      const response = await getLiabilities()
      const filterItem = response.data.financial_info
      // const removedItem = listFinancialCategories

      for (let i = 0; i <= filterItem.length - 1; i++) {
        let objFilter = filterItem[i].category
        let removedItem = { objFilter }
        console.log(removedItem)
        if (objFilter.id === 7) {
          // var removedItem = filterItem.splice(0, 1)
        }
      }
      // console.log('fin del ciclo')
      // console.log(removedItem)
      if (response.status === 200) {
        // setListFinancialCategories(response.data)
        // console.log("List listFinancialCategories filter")
        // console.log(listFinancialCategories)
      }
    }
    loadListFinancialCategoriesLiabilitiesFilter()
  }, [])

  return (
    <FormLiabilities
      initialForm={initialForm}
      validationsForm={validationsForm}
      listFinancialCategories={listFinancialCategories}
    />
  )
}

import styled from 'styled-components'

const desktopStartWidth = 1023;
const tabletStartWidth = 768;
const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: ${props => props.theme.colors.main};
  padding:2rem;

  /* background-color:red; */

  /* background: url(${(props)=>props.imgUrl}); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: cover; */
  ${mobile} {
    /* min-height:100vh; */
    padding:0;

  }

`

export const Section = styled.section`
    max-width:400px;
    width:100%;
    /* background-color:green; */
    height:100%;
    max-height:700px;
    background: ${props => props.theme.colors.main_opacity};


    padding:2rem;
    border-radius:20px;
    box-shadow:0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    ${mobile} {
        box-shadow:none;
    }
`

export const Header = styled.div`
    width:100%;
    /* background-color:green; */
    margin-bottom:2rem;

`

export const Title = styled.h1`
    margin-top:4rem;
    text-align:center;
`

export const Figure = styled.figure`
  margin:0;
  padding:0;
  display:flex;
  justify-content:flex-end;
`

export const Logo = styled.img`
  margin:0;
  padding:0;
  height:150px;
  ${tablet} {
    /* height:200px; */
}
  ${mobile} {
    height:140px;
  }
`

export const FormContainer = styled.div`
    width:100%;
    /* background-color:blue; */
    height:67%;
`

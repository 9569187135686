import React, { useEffect, useState } from "react"
import { FormAssets } from "../../components/organisms/forms/myInfo/FormAssets"
import { getListFinancialCategoriesAssets } from "../../api/requests"

export const FormAssetsContainer = (props) => {
  const initialForm = {
    category: "",
    value: "",
    financial_type: "ACTIVOS",
    // name:'',
    // category_detail:{
    //     id:'',
    //     name:'',
    //     applies_to:'ACTIVOS'
    // },
  }

  const validationsForm = (form) => {
    let errors = {}
    let regexNumbers = /^[0-9]+$/
    // let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    // let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    // let regexComments = /^.{1,255}$/;
    // let regexNumbersSpace = /^[\d]+$/;
    // let regexAlphaNumbers = /^[0-9a-zA-Z]+$/;

    // if(!form.financial_category.trim()){
    //     errors.financial_category = 'El campo Categoría financiera es requerido.'
    // } else if(!regexText.test(form.financial_category.trim())) {
    //     errors.financial_category = 'El campo Categoría financiera solo acepta letras y espacios en blanco.'
    // }

    if (!form.category.trim()) {
      errors.category = "El campo Categoría financiera es requerido."
    } else if (!regexNumbers.test(form.category.trim())) {
      errors.category =
        "En el campo Categoría financiera debe seleccionar una categoría valida."
    }

    if (!form.value.trim()) {
      errors.value = "El campo Valor es requerido."
    } else if (!regexNumbers.test(form.value.trim())) {
      errors.value =
        "El campo Valor solo acepta números, sin puntaciones, ni espacios."
    }

    return errors
  }

  const [listFinancialCategories, setListFinancialCategories] = useState([])

  useEffect(() => {
    async function loadListFinancialCategories() {
      const response = await getListFinancialCategoriesAssets(true)
      if (response.status === 200) {
        setListFinancialCategories(response.data)
      }
    }

    loadListFinancialCategories()
  }, [])

  return (
    <FormAssets
      initialForm={initialForm}
      validationsForm={validationsForm}
      listFinancialCategories={listFinancialCategories}
    />
  )
}

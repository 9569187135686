export const Messages = {

    indicators: (name) => {
        let data = {
            'Liquidez': 'Entre más alto sea, significa mayor capacidad de cubrir los pasivos.',
            'Endeudamiento': 'No debe ser superior a 0.6, entre más se aproxime a 1, mayor nivel de endeudamiento.',
            'Rotación de cartera': 'Presenta el número de días en que se recuperan las cuentas por cobrar, entre menor sea quiere decir que mejor es la gestión del recaudo de cartera.',
            'Margen bruto de rentabilidad': 'Permite conocer la rentabilidad de las ventas frente al costo de ventas. Entre más alto sea, quiere decir que el producto es rentable antes de gastos.',
            'Margen operacional de rentabilidad': 'Permite conocer la rentabilidad final del negocio después de cubrir costos y gastos. Entre más alto sea, quiere decir que la utilidad es mayor.',
        }

        return data[name];
    },

    general: (name) => {
        let data = {
            'assets': 'Aquí ingresas todo lo que tienes.',
            'passives': 'Aquí ingresas todo lo que debes.',
            'budget-income': 'Los ingresos que esperas tener en el periodo seleccionado.',
            'budget-expense': 'Los gastos que esperas tener en el periodo seleccionado.',
            'inventory': 'Aquí ingresas el inventario inicial de todos tus productos, es decir, lo que tienes para vender.',
            'income': 'Aquí registras tus ingresos en efectivo, bancos o en créditos.',
            'shopping': 'Aquí registras tus compras en efectivo, bancos o créditos.',
            'expenses': 'Aquí ingresas cada uno de tus gastos pagados en efectivo, por bancos o a crédito.',
        }

        return data[name];
    }
}
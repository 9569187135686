import React from "react";
import { Container, Section, SectionList } from "./styles";

import { MdBusiness } from "react-icons/md";
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo";

import { getProfile, patchProfile } from "../../../../api/requests";

import { ModalProfileSubscription } from "../../../../components/molecules/Modals/ModalProfileSubscription";
import { ModalOptions } from "../../../../components/molecules/Modals/ModalOptions";
import { DataUtil } from "../../../../utils/data";
import { ProfileDetail } from "../ProfileDetail";
import { Subtitle } from "../../../../components/molecules/Subtitle";
import { TradeImage } from "../../../../components/molecules/TradeImage";
import { Routes } from "../../../../routes";
import { CardModal } from "../../../../components/atoms/cards/CardModal";
import { Logout } from "../../../../components/atoms/cards/Logout";
import { Modal } from "../../../../components/molecules/Modals/Modal";
import { Utilities } from "../../../../utils/utilities";
import { Auth } from "../../../../utils/auth";

export const MyProfile = () => {
  const [profile, setProfile] = React.useState({
    user: {},
    logo: {},
  });
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [activeModal, setActiveModal] = React.useState(false);
  const [activeModalPlan, setActiveModalPlan] = React.useState(false);

  const [modalData, setModalData] = React.useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  });

  React.useEffect(() => {
    async function fetchData() {
      await getProfiles(true);
    }
    fetchData();

    let dat = DataUtil.getFinancialInfo(null);
    setOptions(Object.values(dat));
  }, []);

  async function getProfiles() {
    const response = await getProfile();
    //console.log("response.data");
    //console.log(response.data);
    if (response.status === 200) {
      if (response.data) {
        let resp = response.data[0];
        resp.identification = `${resp.identification_type.name}: ${resp.identification_number}`;
        resp.activity = resp.economic_activity.name;
        resp.location = `${resp.city.name}, ${resp.city.state.name}`;
        resp.image = null;
        resp.image = resp.logo
          ? `${process.env.REACT_APP_API_URL}/${resp.logo.image}`
          : null;
        setProfile(resp);
      }
    }
  }

  let handleOpenOptionsSubscription = (openModal) => {
    setActiveModalPlan(openModal);
  };

  let handleOpenOptions = (event) => {
    setOpen(event);
  };

  async function handleChangeImage(event) {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      let form_data = new FormData();
      form_data.append("logo", img);

      patchProfile(form_data).then(async (response) => {
        if (!response) {
          showMessage(
            "Error",
            "Ha ocurrido un error cargando la imagen.",
            false
          );
          return;
        }

        if (response.status === 200) {
          showMessage(
            "Actualización satisfactoria",
            "El logo del comercio ha sido actualizado.",
            false
          );
          await getProfiles();
        } else if (response.status === 400) {
          let array = Utilities.returnErrors(response.data);
          showMessage("Actualización fallida", array, true, true);
        } else {
          showMessage(
            "Actualización fallida",
            "Ha ocurrido un error actualizando la información. Por favor, inténtelo nuevamente.",
            true
          );
        }
      });
    }
  }

  const handleClosed = () => {
    setActiveModal(false);
  };

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true);
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    });
  };

  let logout = () => {
    Auth.logout();
    window.location.reload();
  };

  return (
    <Container>
      <Section>
        <TitleAndInfo btnPrev={false} titlePage="Mi perfil" />
      </Section>{" "}
      <SectionList>
        <Subtitle
          title="Información del perfil"
          btnName="EDITAR"
          btnPlan="MI PLAN"
          click={(e) => handleOpenOptionsSubscription(true)}
          to={Routes.app_profile_form}
        />{" "}
        <TradeImage
          img={profile.image}
          data={profile}
          handleChangeImage={handleChangeImage}
        />{" "}
        <ProfileDetail data={profile} />{" "}
        <CardModal
          title="Información financiera"
          value="Activos, pasivos, proyecciones..."
          isText={true}
          icon={<MdBusiness size="1.8rem" />}
          color="third"
          click={(e) => handleOpenOptions(true)}
        />{" "}
        <Logout title="CERRAR SESIÓN" click={logout} />{" "}
      </SectionList>{" "}
      <ModalOptions
        activeModal={open}
        options={options}
        title="Información financiera"
        text="Seleccione una opción"
        click={(e) => handleOpenOptions(false)}
        icon="business"
      />
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />{" "}
      <ModalProfileSubscription
        activeModal={activeModalPlan}
        title="Detalle del Plan"
        text="Checkout"
        click={(e) => handleOpenOptionsSubscription(false)}
        icon="bulling"
      />
    </Container>
  );
};

import React from "react"
import { GlobalStyle } from "./styles/GlobalStyle"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { LayoutApp } from "./layouts/LayoutApp"
import { Routes } from "./routes"
import { PrivateRoute } from "./routes/PrivateRoute"
import { SignUp } from "./pages/SignUp"
import { Login } from "./pages/Login"
import { RecoverPassword } from "./pages/RecoverPassword"
import { ResetPassword } from "./pages/ResetPassword"
import { WelcomeUser } from "./pages/initialInfo/WelcomeUser"
import { InitialInformation } from "./pages/initialInfo/InitialInformation"
import { InitialAssets } from "./pages/initialInfo/InitialAssets"
import { InitialAssetsForm } from "./pages/initialInfo/InitialAssetsForm"
import { EditInitialAssetsForm } from "./pages/initialInfo/EditInitialAssetsForm"
import { InitialLiabilities } from "./pages/initialInfo/InitialLiabilities"
import { InitialLiabilitiesForm } from "./pages/initialInfo/InitialLiabilitiesForm"
import { EditInitialLiabilitiesForm } from "./pages/initialInfo/EditInitialLiabilitiesForm"
import { InitialBudgetIncome } from "./pages/initialInfo/InitialBudgetIncome"
import { InitialBudgetIncomeForm } from "./pages/initialInfo/InitialBudgetIncomeForm"
import { EditInitialBudgetIncomeForm } from "./pages/initialInfo/EditInitialBudgetIncomeForm"
import { InitialBudgetExpense } from "./pages/initialInfo/InitialBudgetExpense"
import { InitialBudgetExpenseForm } from "./pages/initialInfo/InitialBudgetExpenseForm"
import { EditInitialBudgetExpenseForm } from "./pages/initialInfo/EditInitialBudgetExpenseForm"
import { InitialInventory } from "./pages/initialInfo/InitialInventory"
import { InitialInventoryForm } from "./pages/initialInfo/InitialInventoryForm"
import { InitialInventoryDetail } from "./pages/initialInfo/InitialInventoryDetail"
import { InitialStockForm } from "./pages/initialInfo/InitialStockForm"
import { EditInitialInventoryForm } from "./pages/initialInfo/EditInitialInventoryForm"

import { MyInventory } from "./pages/modules/inventories/MyInventory"
import { InventoryForm } from "./pages/modules/inventories/InventoryForm"
import { InventoryDetail } from "./pages/modules/inventories/InventoryDetail"
import { StockForm } from "./pages/modules/inventories/StockForm"
import { EditInventoryForm } from "./pages/modules/inventories/EditInventoryForm"

import { MyIncome } from "./pages/modules/income/MyIncome"
import { IncomeDetail } from "./pages/modules/income/IncomeDetail"
import { PaymentDetail } from "./pages/modules/income/PaymentDetail"
import { IncomeForm } from "./pages/modules/income/IncomeForm"

import { MyShopping } from "./pages/modules/shopping/MyShopping"
import { ShoppingDetail } from "./pages/modules/shopping/ShoppingDetail"
import { ShoppingPaymentDetail } from "./pages/modules/shopping/ShoppingPaymentDetail"
import { ShoppingForm } from "./pages/modules/shopping/ShoppingForm"

import { MyExpenses } from "./pages/modules/expenses/MyExpenses"
import { ExpenseDetail } from "./pages/modules/expenses/ExpenseDetail"
import { ExpensePaymentDetail } from "./pages/modules/expenses/ExpensePaymentDetail"
import { ExpenseForm } from "./pages/modules/expenses/ExpenseForm"
import { Notifications } from "./pages/modules/expenses/Notifications"

import { MyReports } from "./pages/modules/reports/MyReports"
import { ReportDetail } from "./pages/modules/reports/ReportDetail"
import { GraphicDetail } from "./pages/modules/reports/GraphicDetail"

import { MyProfile } from "./pages/modules/profile/MyProfile"
import { ProfileForm } from "./pages/modules/profile/ProfileForm"

import { SubscriptionPlans } from "./pages/modules/subscriptions/SubscriptionPlans"
import { SubscriptionValidated } from "./pages/modules/SubscriptionValidated"

import { NotFound } from "./pages/NotFound"

import FinancialInfoState from "./context/financialInfo/FinancialInfoState"

import { SubsInvitation } from "./pages/initialInfo/InitialSubscribeInvitation"
import { MyCatalog } from "./pages/modules/inventories/MyCatalog"

import "react-datepicker/dist/react-datepicker.css"

export const App = () => {
  return (
    <FinancialInfoState>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route component={SignUp} path={Routes.app_sign_up} exact />
          <Route path={Routes.app_login} exact>
            <Login />
          </Route>{" "}
          <Route path={Routes.app_logout} exact>
            <div> Cerrar sesión </div>{" "}
          </Route>{" "}
          <Route path={Routes.app_recover_password} exact>
            <RecoverPassword />
          </Route>{" "}
          <Route path={Routes.app_reset_password} exact>
            <ResetPassword />
          </Route>
          <PrivateRoute
            component={SubsInvitation}
            path={Routes.app_subscribe_invitation}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InitialInformation}
            initial={true}
            path={Routes.app_initial_information}
            exact
          />
          <PrivateRoute
            component={WelcomeUser}
            path={Routes.app_welcome_user}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InitialInformation}
            initial={true}
            path={Routes.app_initial_information}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={MyProfile}
            path={Routes.app_my_profile}
            exact
          />
          <PrivateRoute
            component={ProfileForm}
            path={Routes.app_profile_form}
          />
          <PrivateRoute
            layout={LayoutApp}
            component={SubscriptionPlans}
            path={Routes.app_subscription_plans}
            exact
          />
          <PrivateRoute
            component={SubscriptionValidated}
            path={Routes.app_subscription_validated}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InitialAssets}
            initial={true}
            path={Routes.app_initial_assets}
            exact
          />
          <PrivateRoute
            component={InitialAssetsForm}
            path={Routes.app_initial_assets_form}
            exact
          />
          <PrivateRoute
            component={EditInitialAssetsForm}
            path={Routes.app_edit_initial_assets_form}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InitialLiabilities}
            initial={true}
            path={Routes.app_initial_liabilities}
            exact
          />
          <PrivateRoute
            component={InitialLiabilitiesForm}
            path={Routes.app_initial_liabilities_form}
            exact
          />
          <PrivateRoute
            component={EditInitialLiabilitiesForm}
            path={Routes.app_edit_initial_liabilities_form}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InitialBudgetIncome}
            initial={true}
            path={Routes.app_initial_budget_income}
            exact
          />
          <PrivateRoute
            component={InitialBudgetIncomeForm}
            path={Routes.app_initial_budget_income_form}
            exact
          />
          <PrivateRoute
            component={EditInitialBudgetIncomeForm}
            path={Routes.app_edit_initial_budget_income_form}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InitialBudgetExpense}
            initial={true}
            path={Routes.app_initial_budget_expense}
            exact
          />
          <PrivateRoute
            component={InitialBudgetExpenseForm}
            path={Routes.app_initial_budget_expense_form}
            exact
          />
          <PrivateRoute
            component={EditInitialBudgetExpenseForm}
            path={Routes.app_edit_initial_budget_expense_form}
            exact
          />
          <PrivateRoute
            component={InitialInventory}
            path={Routes.app_initial_inventory}
            exact
          />
          <PrivateRoute
            component={InitialInventoryForm}
            path={Routes.app_initial_inventory_form}
            exact
          />
          <PrivateRoute
            component={InitialInventoryDetail}
            path={Routes.app_initial_inventory_detail}
            exact
          />
          <PrivateRoute
            component={InitialStockForm}
            path={Routes.app_initial_stock_form}
            exact
          />
          <PrivateRoute
            component={EditInitialInventoryForm}
            path={Routes.app_edit_initial_inventory_form}
            exact
          />
          <Route
            component={MyCatalog}
            path={Routes.app_my_catalog}
            exact
          ></Route>
          <PrivateRoute
            layout={LayoutApp}
            component={MyInventory}
            path={Routes.app_my_inventory}
            exact
          ></PrivateRoute>
          <PrivateRoute
            component={InventoryForm}
            path={"/inventory-form/:type?"}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={InventoryDetail}
            path={Routes.app_inventory_detail}
            exact
          />
          <PrivateRoute
            component={StockForm}
            path={Routes.app_stock_form}
            exact
          />
          <PrivateRoute
            component={EditInventoryForm}
            path={Routes.app_edit_inventory_form}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={MyIncome}
            path={Routes.app_income}
            exact
          ></PrivateRoute>{" "}
          <PrivateRoute
            layout={LayoutApp}
            component={IncomeDetail}
            path={Routes.app_income_type}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={PaymentDetail}
            path={Routes.app_income_payment}
            exact
          />
          <PrivateRoute
            component={IncomeForm}
            path={"/income-form/:type/:id?/:payment_id?"}
          />
          <PrivateRoute
            layout={LayoutApp}
            component={MyShopping}
            path={Routes.app_shopping}
            exact
          ></PrivateRoute>{" "}
          <PrivateRoute
            layout={LayoutApp}
            component={ShoppingDetail}
            path={Routes.app_shopping_type}
            exact
          />
          <PrivateRoute
            layout={LayoutApp}
            component={ShoppingPaymentDetail}
            path={Routes.app_shopping_payment}
            exact
          />
          <PrivateRoute
            component={ShoppingForm}
            path={"/shopping-form/:type/:category?/:id?/:payment_id?"}
          />
          <PrivateRoute
            layout={LayoutApp}
            component={MyExpenses}
            path={Routes.app_expenses}
            exact
          ></PrivateRoute>{" "}
          <PrivateRoute
            layout={LayoutApp}
            component={ExpenseDetail}
            path={Routes.app_expenses_type}
            exact
          ></PrivateRoute>{" "}
          <PrivateRoute
            layout={LayoutApp}
            component={ExpensePaymentDetail}
            path={Routes.app_expenses_payment}
            exact
          />
          <PrivateRoute
            component={ExpenseForm}
            path={"/expenses-form/:type/:id?/:payment_id?"}
          />
          <PrivateRoute
            component={Notifications}
            path={Routes.app_notifications}
          />
          <PrivateRoute
            layout={LayoutApp}
            component={MyReports}
            path={"/my-reports/:param/"}
            exact
          ></PrivateRoute>{" "}
          <PrivateRoute
            layout={LayoutApp}
            component={ReportDetail}
            path={"/my-reports/report/:type?"}
            exact
          ></PrivateRoute>{" "}
          <PrivateRoute
            layout={LayoutApp}
            component={GraphicDetail}
            path={"/my-reports/graphic/:type?"}
            exact
          ></PrivateRoute>
          <Route path="/" exact>
            <Login />
          </Route>{" "}
          <Route component={NotFound} />{" "}
        </Switch>{" "}
      </Router>{" "}
      <div className="App"> </div>{" "}
    </FinancialInfoState>
  )
}

import { MdAttachMoney } from 'react-icons/md'
import styled from 'styled-components'

const mobile_sm = `@media (max-width: 768px)`
const mobile_xs = `@media (max-width: 350px)`

export const Container = styled.div`
    width:100%;
    background: ${props => props.bg ? props.theme.colors[props.bg] : "#fff"};
    padding:.5rem 1rem;
    border-radius:.8rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    justify-content:space-between;
    align-items:center;
    margin-bottom:1rem;
`

export const Title = styled.h3`
    color: ${props => props.color ? props.theme.colors[props.color] : "#fff"};
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem;
`

export const Text = styled.p`
    display:block;
    color: ${props => props.color ? props.theme.colors[props.color] : "#fff"};
    text-align: ${props => props.align ? props.align : "left"};
    font-size: ${props => props.fontSize ? props.fontSize : "11.2px"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "normal"};
    margin-top: ${props => props.marginTop};
    &:first-letter {
        text-transform: uppercase;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    padding: .5rem .8rem;
`

export const Column = styled.div`
    width: ${props => props.lg+'%' || '50%'};
    text-align: ${props => props.align || 'left'};
    font-size: ${props => props.fontSize};
    margin-top: ${props => props.marginTop};
    ${mobile_sm} {
        width: ${props => props.sm+'%' || '50%'};
    }
    ${mobile_xs} {
        width: ${props => props.xs+'%' || '50%'};
    }
`

export const FigureCircle = styled.figure`
    width:2.5rem;
    height:2.5rem;
    background: ${props => props.theme.colors[props.color] || props.theme.colors.third};
    opacity: ${props => (props.skeleton ? '50%' : '')};
    box-shadow: ${props => (props.skeleton ? '' : ' 0 10px 14px rgba(0, 0, 0, .05)')};
    color:#fff;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const IconCash = styled(MdAttachMoney)`
    color: ${props => props.theme.colors[props.color] || props.theme.colors.dark};
`
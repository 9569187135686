import React from "react"
import {
  ContainerLink,
  Container,
  FigureCircle,
  Section,
  SubSection,
  CardTitle,
  CardSubtitle,
} from "./styles.js"
import {
  deleteFinancialInfoItem,
  deleteBudgetItem,
} from "../../../../api/requests.js"
import { ModalDeleteConfirm } from "../../../molecules/Modals/ModalDeleteConfirm/index.js"
import { useHistory } from "react-router-dom"

export const CardInventoryDetail = ({
  id,
  icon,
  color,
  title,
  value,
  reList,
  setReList,
  redirect,
  redirectEdit,
  updateItems,
  skeleton,
  darktheme,
}) => {
  const history = useHistory()

  const [activeModalDelete, setActiveModalDelete] = React.useState(false)

  const capitalizeText = (name = "categoria") => {
    const lower = name.toLowerCase()
    const capitalizeText = name.charAt(0).toUpperCase() + lower.slice(1)

    return capitalizeText
  }

  const capitalizeCategory = capitalizeText(title)
  const capitalizeName = capitalizeText(value)

  function deleteCard() {
    if (color === "verde" || color === "rojo") {
      deleteFinancialInfoItem(id)
    } else if (color === "azul" || color === "naranja") {
      deleteBudgetItem(id)
    }
    setActiveModalDelete(false)
    setReList(!reList)
    history.push(redirect)
    updateItems()
  }

  function handleClosed() {
    setActiveModalDelete(false)
  }

  return (
    <>
      {activeModalDelete === true && (
        <ModalDeleteConfirm
          activeModal={true}
          title="Eliminar Inventario"
          text="¿Desea eliminar este inventario?."
          btn="Cancelar"
          btnSubmit="Eliminar"
          deleteCard={deleteCard}
          handleClosed={handleClosed}
        />
      )}
      {!skeleton && (
        <ContainerLink darktheme={darktheme}>
          {color === "rojo" ? <FigureCircle rojo>{icon}</FigureCircle> : ""}
          {!color ? (
            <FigureCircle darktheme={darktheme}>{icon}</FigureCircle>
          ) : (
            ""
          )}
          <Section>
            <SubSection>
              <CardSubtitle darktheme={darktheme}>
                {capitalizeCategory}
              </CardSubtitle>
              <CardSubtitle darktheme={darktheme}>Ref: {id}</CardSubtitle>
            </SubSection>
            <CardTitle darktheme={darktheme}>{capitalizeName}</CardTitle>
          </Section>
        </ContainerLink>
      )}
      {skeleton && (
        <Container>
          <FigureCircle skeleton>{icon}</FigureCircle>
          <Section>
            <CardTitle skeleton>{title}</CardTitle>
            <CardSubtitle skeleton>
              {Intl.NumberFormat("es-CO", {
                maximumFractionDigits: "0",
                style: "currency",
                currency: "COP",
              }).format(value)}
            </CardSubtitle>
          </Section>
        </Container>
      )}
    </>
  )
}

import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const Card = styled.div `
    width:100%;
    display:flex;
    padding-left:12px;
    padding-right:12px;
    margin-top: .6rem;
`

export const TitleCard = styled.h3 `
    color:${props => props.theme.colors.dark};
    text-align: ${props => props.align || 'left'};
    font-size:${props => props.fontSize || "16px"};
    font-weight: bold;
    margin-bottom: .5rem;
    font-size: 12px;
`

export const LinkCard = styled(Link)
`
    color:${props => props.theme.colors.third};
    text-align: ${props => props.align || 'left'};
    font-size:${props => props.fontSize || "16px"};
    margin-bottom: .5rem;
    font-weight: bold;
    font-size: 12px;
`

export const Line = styled.div `
    margin:.4em 0 ;
    margin-bottom: 1rem;
    border-bottom:1px solid ${props => props.theme.colors.dark};
`

export const Row = styled.div `
    width:100%;
    display:flex;
`

export const Column = styled.div `
    text-align: ${props => props.align || 'left'};
    position: ${props => props.custom ? 'relative' : ''};
    width: ${props => props.wd || '50'}%;
    margin-right:0;
    right:0;
`
import { GET_ASSETS, CREATE_ASSETS } from "../types"

// eslint-disable-next-line
export default (state, action) => {
  const { payload, type } = action

  switch (type) {
    case GET_ASSETS:
      return {
        ...state,
        assets: payload,
      }
    case CREATE_ASSETS:
      return {
        ...state,
        assets: payload,
      }

    default:
      return state
  }
}

import React, { useEffect, useState } from "react"
import { Container, Section } from "./styles"
import { TitleAndInfo } from "../../../../components/molecules/TitleAndInfo"
import { useParams, useHistory } from "react-router-dom"
import { DataUtil } from "../../../../utils/data"
import { FormShopping } from "../../../../components/organisms/forms/myShopping/FormShopping"

export const ShoppingForm = () => {
  const history = useHistory()
  const { type, category, id } = useParams()
  const [open, setOpen] = useState(false)
  const [formStep, setFormStep] = useState(0)
  const [shopping, setShopping] = useState({})
  const [cancel, setCancel] = useState("")
  const [title, setTitle] = useState("")

  useEffect(() => {
    let selected = DataUtil.getShopping(type, category, id)

    setCancel(selected.prev_route)
    setFormStep(selected.init_step)
    setTitle(selected.tag_title)
    setShopping(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formStep === "inventory") setTitle("Nuevo inventario")
    else setTitle(DataUtil.getShopping(type, category, id).tag_title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStep])

  let handleOpenOptions = (event) => {
    setOpen(event)
  }

  let handleChangeStep = () => {
    if (formStep > 0 && !shopping.is_payment) setFormStep(formStep - 1)
    else if (formStep === "inventory") setFormStep(0)
    else history.push(cancel)
  }

  return (
    <Container>
      <Section>
        <TitleAndInfo
          btnPrev
          pathPrev={formStep > 0 || formStep === "inventory" ? "#" : cancel}
          titlePage={title}
          darktheme
          handleChange={handleChangeStep}
          tooltip="shopping"
        />
        <FormShopping
          handleOpen={handleOpenOptions}
          open={open}
          setFormStep={setFormStep}
          formStep={formStep}
        />
      </Section>
    </Container>
  )
}

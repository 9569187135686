import React from "react"
import {
  CardForm,
  Form,
  Section,
  SubSection,
  Label,
  Input,
  ContainerError,
  ErrorText,
  ContainerBtns,
  LinkBtn,
} from "./styles"
import { BtnAction } from "../../../../atoms/BtnAction"
import { ModalLoading } from "../../../../molecules/Modals/ModalLoading"
import { ModalNew } from "../../../../molecules/Modals/ModalNew"
import { useForm } from "../../../../../hooks/useForm"
import iconSuccess from "../../../../../assets/img/fef-icon-success.png"
import iconError from "../../../../../assets/img/fef-icon-error.png"

export const FormStocks = ({
  initialForm,
  validationsForm,
  categorySelected,
  editId,
}) => {
  const {
    form,
    errors,
    loading,
    response,
    setResponse,
    handleChange,
    handleBlur,
    handleSubmitEdit,
  } = useForm(initialForm, validationsForm, editId)

  return (
    <>
      {loading && <ModalLoading activeModalLoading={true} />}
      {response && (
        <ModalNew
          activeModal={true}
          icon={iconSuccess}
          title="Stock registrado"
          text="El stock fue registrado correctamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirectEdit={`/inventory-detail/${editId}`}
          redirect={`/inventory-detail/${editId}`}
          //redirect={Routes.app_my_inventory}
        />
      )}
      {response === false && (
        <ModalNew
          activeModal={true}
          icon={iconError}
          title="Stock no registrado"
          text="El stock no pudo ser registrado. Intentelo nuevamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirectEdit={`/inventory-detail/${editId}`}
          // redirect={Routes.app_my_inventory}
        />
      )}

      <CardForm>
        <Form onSubmit={handleSubmitEdit}>
          <Section>
            <SubSection small>
              <Label>Costo*</Label>
              <Input
                type="text"
                name="cost_value"
                placeholder="ej: $ 50.000"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.cost_value}
                required
              />
              <ContainerError>
                {errors.cost_value && (
                  <ErrorText>{errors.cost_value}</ErrorText>
                )}
              </ContainerError>
            </SubSection>

            <SubSection small>
              <Label>Precio de venta*</Label>
              <Input
                type="text"
                name="sale_value"
                placeholder="ej: $ 120.000"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.sale_value}
                required
              />
              <ContainerError>
                {errors.sale_value && (
                  <ErrorText>{errors.sale_value}</ErrorText>
                )}
              </ContainerError>
            </SubSection>

            {categorySelected === "PRODUCTO FABRICADO" && (
              <>
                <SubSection small>
                  <Label>Stock*</Label>
                  <Input
                    type="text"
                    name="quantity"
                    placeholder="ej: 75"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={form.quantity}
                    required
                  />
                  <ContainerError>
                    {errors.quantity && (
                      <ErrorText>{errors.quantity}</ErrorText>
                    )}
                  </ContainerError>
                </SubSection>
              </>
            )}
            {categorySelected === "PRODUCTO COMPRADO" && (
              <>
                <SubSection small>
                  <Label>Stock*</Label>
                  <Input
                    type="text"
                    name="quantity"
                    placeholder="ej: 75"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={form.quantity}
                    required
                  />
                  <ContainerError>
                    {errors.quantity && (
                      <ErrorText>{errors.quantity}</ErrorText>
                    )}
                  </ContainerError>
                </SubSection>
              </>
            )}
          </Section>

          <ContainerBtns>
            <LinkBtn
              // to={Routes.app_my_inventory}
              to={`/inventory-detail/${editId}`}
              second="true"
              ghost="true"
            >
              Cancelar
            </LinkBtn>
            <BtnAction second name="Crear" darktheme />
          </ContainerBtns>
        </Form>
      </CardForm>
    </>
  )
}

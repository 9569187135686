import React, { useEffect, useState } from "react"
import { getEconomicActivity } from "../../../../api/requests"
import { getListDocuments } from "../../../../api/requests"
import { getListStates } from "../../../../api/requests"
import { getListCities } from "../../../../api/requests"
import {
  CardForm,
  Form,
  Section,
  TitleStep,
  Span,
  Input,
  InputCheckbox,
  Select,
  ContainerCheckbox,
  Label,
  ContainerBtns,
  ContainerCaptcha,
  BtnNext,
  BtnText,
  BtnTerms,
  BtnPolitics,
} from "./styles"
// import {BtnAction} from '../../../atoms/BtnAction';
import { Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { ModalTerms } from "../../../molecules/Modals/ModalTerms"
import { Modal } from "../../../molecules/Modals/Modal"

export const FormSignUp = (props) => {
  const [economicActivities, setEconomicActivities] = useState([])
  const [listDocuments, setListDocuments] = useState([])
  const [listStates, setListStates] = useState([])
  const [listCities, setListCities] = useState([])
  const [stateVar, setStateVar] = useState("")

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    first_name: "",
    last_name: "",
    tradename: "",
    phone: "",
    cellphone: "",
    identification_type: "",
    identification_number: "",
    economic_activity: "",
    terms_and_conds: "",
    personal_data: process.env.VERCEL_ENV === "production" ? "" : true,
    address: "",
    city: "",
  })

  const [confirmPassword, setConfirmPassword] = useState(false)
  const [lengthPassword, setLengthPassword] = useState(false)
  const [lengthFirstName, setLengthFirstName] = useState(false)
  const [lengthLastName, setLengthLastName] = useState(false)
  const [lengthTradename, setLengthTradename] = useState(false)
  const [lengthPhone, setLengthPhone] = useState(false)
  const [lengthCellphone, setLengthCellphone] = useState(false)
  const [lengthIdentificationNumber, setLengthIdentificationNumber] =
    useState(false)

  useEffect(() => {
    function passwordLength() {
      if (formValues.password.length > 7) {
        setLengthPassword(true)
      } else {
        setLengthPassword(false)
      }
      return passwordLength
    }
    passwordLength()

    function passwordConfirm() {
      if (
        formValues.password.length > 0 &&
        formValues.password === formValues.password_confirmation
      ) {
        setConfirmPassword(true)
      } else {
        setConfirmPassword(false)
      }
      return passwordConfirm
    }
    passwordConfirm()

    function firstNameLength() {
      if (formValues.first_name.length < 141) {
        setLengthFirstName(true)
      } else {
        setLengthFirstName(false)
      }
      return firstNameLength
    }
    firstNameLength()

    function lastNameLength() {
      if (formValues.last_name.length < 141) {
        setLengthLastName(true)
      } else {
        setLengthLastName(false)
      }
      return lastNameLength
    }
    lastNameLength()

    function identificationNumberLength() {
      if (formValues.identification_number.length < 21) {
        setLengthIdentificationNumber(true)
      } else {
        setLengthIdentificationNumber(false)
      }
      return identificationNumberLength
    }

    identificationNumberLength()

    function tradenameLength() {
      if (formValues.tradename.length < 201) {
        setLengthTradename(true)
      } else {
        setLengthTradename(false)
      }

      return tradenameLength
    }

    tradenameLength()

    function phoneLength() {
      if (formValues.phone.length < 11) {
        setLengthPhone(true)
      } else {
        setLengthPhone(false)
      }

      return phoneLength
    }

    phoneLength()

    function cellphoneLength() {
      if (formValues.cellphone.length < 11) {
        setLengthCellphone(true)
      } else {
        setLengthCellphone(false)
      }

      return cellphoneLength
    }

    cellphoneLength()
  }, [
    formValues.password,
    formValues.password_confirmation,
    formValues.first_name,
    formValues.last_name,
    formValues.identification_number,
    formValues.tradename,
    formValues.phone,
    formValues.cellphone,
  ])

  const [activeModal, setActiveModal] = useState(false)
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    errors: "",
    btn: "",
  })

  const handleClosed = () => {
    setActiveModal(false)
  }

  const [activeModalTerms, setActiveModalTerms] = useState(false)

  const termsModal = (event) => {
    setActiveModalTerms(true)
  }

  const handleClosedTerms = () => {
    setActiveModalTerms(false)
  }

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    })
  }

  const _handleSubmit = (event) => {
    if (lengthPassword === false) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Contraseña demasiado corta",
        text: "La contraseña debe tener almenos 8 caracteres.",
        btn: "Aceptar",
      })
    } else if (confirmPassword === false) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Contraseñas incorrectas",
        text: "Las contraseñas registradas no coinciden, intentelo nuevamente.",
        btn: "Aceptar",
      })
    } else if (lengthFirstName === false && props.formStep === 1) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Nombre demasiado largo",
        text: "El nombre(s) no puede tener mas de 140 caracteres.",
        btn: "Aceptar",
      })
    } else if (lengthLastName === false && props.formStep === 1) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Apellido demasiado largo",
        text: "El apellido(s) no puede tener mas de 140 caracteres.",
        btn: "Aceptar",
      })
    } else if (lengthIdentificationNumber === false && props.formStep === 1) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Número de identificación demasiado largo",
        text: "El número de identificación no puede tener mas de 20 caracteres.",
        btn: "Aceptar",
      })
    } else if (lengthTradename === false && props.formStep === 2) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Nombre comercial demasiado largo",
        text: "El nombre comercial no puede tener mas de 200 caracteres.",
        btn: "Aceptar",
      })
    } else if (lengthPhone === false && props.formStep === 3) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Teléfono demasiado largo",
        text: "El teléfono no puede tener mas de 10 caracteres.",
        btn: "Aceptar",
      })
    } else if (lengthCellphone === false && props.formStep === 3) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Celular demasiado largo",
        text: "El celular no puede tener mas de 13 caracteres.",
        btn: "Aceptar",
      })
    } else if (!formValues.personal_data && props.formStep === 4) {
      event.preventDefault()
      setActiveModal(true)
      setModalData({
        title: "Captcha",
        text: "Debe aceptar el captcha.",
        btn: "Aceptar",
      })
    } else if (props.formStep < 4) {
      event.preventDefault()
      nextStep()
    } else {
      event.preventDefault()
      props.handleSubmit({ ...formValues })
      event.target.reset()
    }
  }

  const nextStep = (event) => {
    props.setFormStep((cur) => cur + 1)
  }

  const backStep = (event) => {
    props.setFormStep((cur) => cur - 1)
  }

  const captcha = React.useRef(null)

  function onChangeCaptcha(value) {
    if (captcha.current.getValue()) {
      setFormValues((formValues.personal_data = true), ...formValues)
    }
    if (!value) {
      setFormValues({ ...formValues, personal_data: false })
    }
  }

  const handleCities = React.useCallback(async (event) => {
    setStateVar(event.target.value)
    const response = await getListCities(event.target.value)
    if (response.status === 200) {
      setListCities(response.data)
    }
  }, [])

  useEffect(() => {
    async function loadEconomicActivity() {
      const response = await getEconomicActivity()
      if (response.status === 200) {
        setEconomicActivities(response.data)
      }
    }

    loadEconomicActivity()

    async function loadListDocuments() {
      const response = await getListDocuments()
      if (response.status === 200) {
        setListDocuments(response.data)
      }
    }

    loadListDocuments()

    async function loadListStates() {
      const response = await getListStates()
      if (response.status === 200) {
        setListStates(response.data)
      }
    }

    loadListStates()
  }, [])

  return (
    <CardForm>
      <Form onSubmit={_handleSubmit}>
        {props.formStep === 0 && (
          <>
            <Section>
              <TitleStep>Información de usuario</TitleStep>
              <Input
                placeholder="Correo electrónico*"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                required
              />
              <Input
                placeholder="Contraseña*"
                type="password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
                required
              />
              <Input
                placeholder="Confirmar contraseña*"
                type="password"
                name="password_confirmation"
                value={formValues.password_confirmation}
                onChange={handleChange}
                required
              />
            </Section>
            <BtnNext
              /* onClick={nextStep} */
              type="submit"
            >
              Siguiente
            </BtnNext>
            <Span>
              <Link to={props.url} style={{ textDecoration: "none" }}>
                <BtnText>{props.redirect}</BtnText>
              </Link>
            </Span>
          </>
        )}
        {props.formStep === 1 && (
          <>
            <Section>
              <TitleStep>Información personal</TitleStep>
              <Input
                placeholder="Nombre(s)*"
                type="text"
                name="first_name"
                value={formValues.first_name}
                onChange={handleChange}
                required
              />
              <Input
                placeholder="Apellido(s)*"
                type="text"
                name="last_name"
                value={formValues.last_name}
                onChange={handleChange}
                required
              />
              <Select
                name="identification_type"
                value={formValues.identification_type}
                onChange={handleChange}
                required
              >
                <option value="" selected>
                  Tipo de identificación*
                </option>
                {listDocuments.map((document, i) => {
                  return (
                    <React.Fragment key={i}>
                      <option value={document.id}>{document.name}</option>
                    </React.Fragment>
                  )
                })}
              </Select>
              <Input
                placeholder="Número de identificación*"
                type="text"
                name="identification_number"
                value={formValues.identification_number}
                onChange={handleChange}
                required
              />
            </Section>
            <ContainerBtns>
              <BtnNext second ghost onClick={backStep} type="button">
                Regresar
              </BtnNext>
              <BtnNext
                second
                /* onClick={nextStep} */
                type="submit"
              >
                Siguiente
              </BtnNext>
            </ContainerBtns>
          </>
        )}
        {props.formStep === 2 && (
          <>
            <Section>
              <TitleStep>Información Comercial</TitleStep>
              <Input
                placeholder="Nombre comercial*"
                type="text"
                name="tradename"
                value={formValues.tradename}
                onChange={handleChange}
                required
              />
              <Select
                name="economic_activity"
                value={parseInt(formValues.economic_activity)}
                onChange={handleChange}
                required
              >
                <option value="" selected>
                  Tipo de actividad económica*
                </option>
                {economicActivities.map((activity, i) => {
                  return (
                    <React.Fragment key={i}>
                      <option value={activity.id}>{activity.name}</option>
                    </React.Fragment>
                  )
                })}
              </Select>
            </Section>
            <ContainerBtns>
              <BtnNext second ghost onClick={backStep} type="button">
                Regresar
              </BtnNext>
              <BtnNext
                second
                /* onClick={nextStep} */
                type="submit"
              >
                Siguiente
              </BtnNext>
            </ContainerBtns>
          </>
        )}
        {props.formStep === 3 && (
          <>
            <Section>
              <TitleStep>Información de contacto</TitleStep>
              <Input
                placeholder="Teléfono*"
                type="tel"
                name="phone"
                value={formValues.phone}
                onChange={handleChange}
                required
              />
              <Input
                placeholder="Celular*"
                type="tel"
                name="cellphone"
                value={formValues.cellphone}
                onChange={handleChange}
                required
              />
              <Input
                placeholder="Dirección*"
                type="text"
                name="address"
                value={formValues.address}
                onChange={handleChange}
                required
              />
              <Select
                name="state"
                value={stateVar}
                onChange={handleCities}
                required
              >
                <option value="" selected>
                  Seleccionar departamento*
                </option>
                {listStates.map((state, i) => {
                  return (
                    <React.Fragment key={i}>
                      <option value={state.id}>{state.name}</option>
                    </React.Fragment>
                  )
                })}
              </Select>
              <Select
                name="city"
                value={formValues.city}
                onChange={handleChange}
                required
              >
                <option value="" selected>
                  Seleccionar ciudad*
                </option>
                {listCities.map((city, i) => {
                  return (
                    <React.Fragment key={i}>
                      <option value={city.id}>{city.name}</option>
                    </React.Fragment>
                  )
                })}
              </Select>
            </Section>
            <ContainerBtns>
              <BtnNext second ghost onClick={backStep} type="button">
                Regresar
              </BtnNext>
              <BtnNext
                second
                /* onClick={nextStep} */
                type="submit"
              >
                Siguiente
              </BtnNext>
            </ContainerBtns>
          </>
        )}
        {props.formStep === 4 && (
          <>
            <Section>
              <TitleStep>Aceptación de terminos</TitleStep>
              <ContainerCheckbox>
                <InputCheckbox
                  placeholder="Aceptar Terminos y condiciones*"
                  type="checkbox"
                  id="termsAndConds"
                  name="terms_and_conds"
                  value={formValues.terms_and_conds}
                  onChange={handleChange}
                  required
                />
                <Label>
                  Aceptar
                  <BtnTerms
                    /* onClick={termsModal} */
                    onClick={termsModal}
                    type="button"
                  >
                    {" "}
                    terminos y condiciones
                  </BtnTerms>
                  y
                  <BtnPolitics
                    /* onClick={termsModal} */
                    onClick={termsModal}
                    type="button"
                  >
                    {" "}
                    Políticas de privacidad de datos.
                  </BtnPolitics>
                </Label>
              </ContainerCheckbox>
              {process.env.VERCEL_ENV === "production" && (
                <ContainerCaptcha>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LcMmiMeAAAAAIN6CLlAK-ps8TIWSajMVq7W2_S3"
                    onChange={onChangeCaptcha}
                    name="personal_data"
                    value={formValues.personal_data}
                    required
                  />
                </ContainerCaptcha>
              )}
            </Section>
            <ContainerBtns>
              <BtnNext second ghost onClick={backStep} type="button">
                Regresar
              </BtnNext>
              <BtnNext
                second
                /* onClick={nextStep} */
                type="submit"
              >
                Registrarse
              </BtnNext>
            </ContainerBtns>
            {activeModalTerms !== false ? (
              <ModalTerms
                activeModalTerms={activeModalTerms}
                handleClosedTerms={handleClosedTerms}
                btn="Cerrar"
              />
            ) : (
              ""
            )}
          </>
        )}
        {/* <BtnAction
          btn={props.btn}
          onClick={nextStep}
          type="button"
        />*/}
      </Form>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        errors={modalData.errors}
        btn={modalData.btn}
      />
    </CardForm>
  )
}

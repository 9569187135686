import React, { useEffect, useState } from "react"
import { FormContainer } from "./styles"
import {
  createInventories,
  getFinancialCategoriesByParam,
  getShoppingsDetail,
  postShoppings,
  editShopping,
  postShoppingsPayments,
  editShoppingsPayment,
  getShoppingsPayment,
  getProvider,
} from "../../../../../api/requests"
import { useHistory, useParams } from "react-router-dom"
import { DataUtil } from "../../../../../utils/data"
import { CustomDate } from "../../../../../utils/date"
import { Modal } from "../../../../molecules/Modals/Modal"
import { Utilities } from "../../../../../utils/utilities"
import { FormShoppingSteps } from "../FormShoppingSteps"

export const FormShopping = (props) => {
  const history = useHistory()
  const { type, category, id, payment_id } = useParams()
  const [form, setForm] = useState({
    category: "",
    selected_categories: [],
    details: [],
    amount: 1,
    date: CustomDate.date4(),
    payment_type: "",
    payment_method: "",
    payment: "",
    observations: "",
    // provider
    name: "",
    doc_number: "",
    doc_type: "",
    email: "",
    city: "",
    state: "",
    address: "",
    cellphone: "",
    phone: "",
    is_payment: false,
    value: 0,
    total: 0,
  })
  const [formInventory, setFormInventory] = useState({
    category: "PRODUCTO COMPRADO",
    name: "",
    description: "",
    cost_value: 0,
    sale_value: 0,
    sku: "",
    is_active: true,
  })
  const [providers, setProviders] = useState([])
  const [activeModal, setActiveModal] = useState(false)
  const [modalData, setModalData] = useState({
    title: "",
    text: "",
    btn: "",
    is_error: false,
    is_array: false,
  })

  useEffect(() => {
    let pay = DataUtil.getShopping(type, category)

    setForm({
      ...form,
      payment_method: pay.payment_method,
      payment_type: pay.payment_type,
    })

    if (id) {
      async function fetchData() {
        if (pay.is_payment && payment_id) await getPaymentDetail(id, payment_id)
        else if (!pay.is_payment) await getDetail(id)
      }
      fetchData()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    clearForm()
  }, [props.formStep])

  async function getProviderData(doc_type, doc_number, name) {
    let data = {}
    if (name) data.name = name
    else {
      data.doc_type = doc_type
      data.doc_number = doc_number
    }

    const response = await getProvider(data)

    if (response.status === 200) {
      let resp = response.data

      if (name) {
        setProviders(resp)
        return
      }

      if (!resp.length) {
        showMessage(
          "No encontrado",
          `El proveedor de documento ${doc_number} no se encuentra registrado`,
          true
        )
        setForm({
          ...form,
          // eslint-disable-next-line
          ["name"]: "",
          // eslint-disable-next-line
          ["email"]: "",
          // eslint-disable-next-line
          ["phone"]: "",
          // eslint-disable-next-line
          ["cellphone"]: "",
          // eslint-disable-next-line
          ["address"]: "",
          // eslint-disable-next-line
          ["city"]: "",
          // eslint-disable-next-line
          ["state"]: "",
        })
        return
      }

      if (resp[0]) {
        setForm({
          ...form,
          // eslint-disable-next-line
          ["provider_id"]: resp[0].id,
          // eslint-disable-next-line
          ["name"]: resp[0],
          // eslint-disable-next-line
          ["doc_type"]: resp[0].doc_type.id,
          // eslint-disable-next-line
          ["doc_number"]: resp[0].doc_number,
          // eslint-disable-next-line
          ["email"]: resp[0].email,
          // eslint-disable-next-line
          ["phone"]: resp[0].phone,
          // eslint-disable-next-line
          ["cellphone"]: resp[0].cellphone,
          // eslint-disable-next-line
          ["address"]: resp[0].address,
          // eslint-disable-next-line
          ["city"]: resp[0].city ? resp[0].city.id : "",
          // eslint-disable-next-line
          ["state"]: resp[0].city ? resp[0].city.state.id : "",
        })
      }
    }
  }

  async function getDetail(id_detail) {
    const response = await getShoppingsDetail(id_detail)

    if (response.status === 200) {
      let resp = response.data

      // eslint-disable-next-line
      resp.details.map((obj) => {
        obj.cost_value = obj.value
        obj.amount = obj.quantity
        obj.name = obj.description
      })
      resp.doc_number = resp.provider.doc_number
      resp.doc_type = resp.provider.doc_type.id
      resp.name = resp.provider
      resp.address = resp.provider.address
      resp.phone = resp.provider.phone
      resp.cellphone = resp.provider.cellphone
      resp.email = resp.provider.email
      resp.selected_categories = resp.details
      resp.total = resp.value
      resp.amount = 1
      resp.value = id ? 0 : resp.value
      resp.payment = id ? 0 : resp.payment
      resp.city = resp.provider.city ? resp.provider.city.id : null
      resp.state = resp.provider.city ? resp.provider.city.state.id : null
      resp.provider_id = resp.provider.id ? resp.provider.id : null

      setForm(resp)
    }
  }

  async function getPaymentDetail(id_shopping, id_payment) {
    const response = await getShoppingsPayment(id_shopping, id_payment)

    if (response.status === 200) {
      let resp = response.data
      resp.payment = resp.value
      setForm(resp)
    }
  }

  async function handleCreate() {
    createInventories(formInventory).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Inventario registrado",
          "El registro del nuevo inventario se ha realizado correctamente.",
          false
        )
        clearForm()
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Registro fallido", array, true, true)
      }
    })
  }

  async function handleSubmit(data) {
    if (type !== "payment" && type !== "edit_payment") {
      let details = []

      // eslint-disable-next-line
      data.selected_categories.map((obj) => {
        if (category !== "inventories") {
          details.push({
            id: obj.id,
            description: obj.description,
            value: obj.value,
            quantity: obj.quantity,
          })
        } else {
          details.push({
            inventory_id: obj.inventory_id ? obj.inventory_id : obj.id,
            quantity: obj.amount,
            value: obj.value,
            sale_value: obj.sale_value ? obj.sale_value : obj.value,
          })
        }
      })
      let data_send = {
        details: details,
        date: data.date,
        payment_type: data.payment_type,
        observations: data.observations,
        provider: {
          doc_number: data.doc_number,
          doc_type: parseInt(data.doc_type),
          name: data.name ? data.name.name : data.client_name,
          address: data.address,
          phone: data.phone,
          cellphone: data.cellphone,
          email: data.email,
        },
      }
      data_send.category_id = await getFinancialShopping()
      if (data.payment_method) data_send.payment_method = data.payment_method
      if (parseInt(data.payment)) data_send.payment = data.payment
      if (data.city) data_send.provider.city = parseInt(data.city)
      if (data.provider_id) data_send.provider.id = data.provider_id

      if (id) await submitEditShopping(parseInt(id), data_send)
      else await submitShopping(data_send)
    } else {
      let data_send = {
        date: data.date,
        observations: data.observations,
        payment_method: data.payment_method,
        value: parseInt(data.payment),
      }

      if (payment_id)
        await submitEditPayment(parseInt(id), parseInt(payment_id), data_send)
      else await submitPayment(parseInt(id), data_send)
    }
  }

  async function getFinancialShopping() {
    const response = await getFinancialCategoriesByParam({ my_shopping: true })

    if (response.status === 200) {
      let id = ""
      if (category === "inventories") id = 4
      if (category === "properties") id = 5
      if (category === "others") id = 6
      let found = response.data.find((el) => el.id === id)
      return found.id
    }
  }

  async function submitShopping(data) {
    postShoppings(data).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Compra registrada",
          "El registro de la compra se ha realizado correctamente.",
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Registro fallido", array, true, true)
      } else {
        showMessage(
          "Registro fallido",
          "Ha ocurrido un error registrando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  async function submitEditShopping(id, data) {
    editShopping(id, data).then((response) => {
      if (response.status === 200) {
        showMessage(
          "Compra actualizada",
          `La actualización de la compra ${id} se ha realizado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Actualización fallida", array, true, true)
      } else {
        showMessage(
          "Actualización fallida",
          "Ha ocurrido un error actualizando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  async function submitPayment(id, data) {
    postShoppingsPayments(id, data).then((response) => {
      if (response.status === 201) {
        showMessage(
          "Abono creado",
          `El abono en ${data.payment_method} fue registrado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Abono no creado", array, true, true)
      } else {
        showMessage(
          "Abono no creado",
          `El abono en ${data.payment_method} no pudo ser registrado. Por favor, inténtelo de nuevo.`,
          true
        )
      }
    })
  }

  async function submitEditPayment(id_income, id_payment, data) {
    editShoppingsPayment(id_income, id_payment, data).then((response) => {
      if (response.status === 200) {
        showMessage(
          "Abono actualizado",
          `El abono ${id_payment} fue actualizado correctamente.`,
          false
        )
      } else if (response.status === 400) {
        let array = Utilities.returnErrors(response.data)
        showMessage("Actualización fallida", array, true, true)
      } else {
        showMessage(
          "Actualización fallida",
          "Ha ocurrido un error actualizando la información. Por favor, inténtelo nuevamente.",
          true
        )
      }
    })
  }

  const showMessage = (title, text, is_error, is_array) => {
    setActiveModal(true)
    setModalData({
      title: title,
      text: text,
      btn: "Aceptar",
      is_error: is_error,
      is_array: is_array,
    })
  }

  const handleClosed = () => {
    setActiveModal(false)

    if (props.formStep === "inventory") {
      props.setFormStep(0)
      return
    } else if (!modalData.is_error) {
      let path = "#"
      path = DataUtil.getShopping(type, category, id).prev_route

      history.push(path)
      return
    }
  }

  const clearForm = () => {
    setFormInventory({
      category: "PRODUCTO COMPRADO",
      name: "",
      description: "",
      cost_value: 0,
      sale_value: 0,
      sku: "",
      is_active: true,
    })
  }

  return (
    <>
      <FormContainer>
        <FormShoppingSteps
          clients={providers}
          setForm={setForm}
          form={form}
          setFormStep={props.setFormStep}
          formStep={props.formStep}
          setFormInventory={setFormInventory}
          formInventory={formInventory}
          clearForm={clearForm}
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          handleProvider={getProviderData}
        />
      </FormContainer>
      <Modal
        activeModal={activeModal}
        handleClosed={handleClosed}
        title={modalData.title}
        text={modalData.text}
        isArray={modalData.is_array}
        btn={modalData.btn}
      />
    </>
  )
}

import React from "react"
import {
  CardForm,
  Form,
  Section,
  SubSection,
  Label,
  Input,
  Option,
  Select,
  ContainerError,
  ErrorText,
  ContainerBtns,
  LinkBtn,
  Wrapper,
} from "./styles"
import { BtnAction } from "../../../../atoms/BtnAction"
import { ModalLoading } from "../../../../molecules/Modals/ModalLoading"
import { ModalNew } from "../../../../molecules/Modals/ModalNew"
import { useForm } from "../../../../../hooks/useForm"
import iconSuccess from "../../../../../assets/img/fef-icon-success.png"
import iconError from "../../../../../assets/img/fef-icon-error.png"
import { Routes } from "../../../../../routes"

export const FormInitialInventories = ({ initialForm, validationsForm }) => {
  const {
    form,
    errors,
    loading,
    response,
    setResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm)

  const handleImage = (event) => {
    const file = event.target.files[0]
    const label = document.querySelector("[for='image']")

    if (file) {
      const reader = new FileReader()

      reader.onload = function (e) {
        const img = document.createElement("img")
        img.src = e.target.result
        label.innerHTML = ""
        label.appendChild(img)
      }

      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      {loading && <ModalLoading activeModalLoading={true} />}
      {response && (
        <ModalNew
          activeModal={true}
          title="Inventario inicial registrado"
          icon={iconSuccess}
          text="El inventario inicial fue registrado correctamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirect={Routes.app_my_inventory}
        />
      )}
      {response === false && (
        <ModalNew
          activeModal={true}
          title="Inventario inicial no registrado"
          icon={iconError}
          text="Revisa si el producto/servicio ya fue inscrito anteriormente."
          // title='Pasivo no registrado '
          // text="El pasivo no pudo ser registrado. Intentelo nuevamente."
          btn="Aceptar"
          setResponse={setResponse}
          redirect={Routes.app_my_inventory}
        />
      )}

      <CardForm>
        <Form onSubmit={handleSubmit}>
          <Section>
            <Wrapper>
              <input
                type="file"
                accept="image/*"
                id="image"
                name="image"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  fill="#95e8d4"
                >
                  <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                </svg>
              </label>
              <div>
                <SubSection>
                  <Label>Categoría*</Label>
                  {form.category === "" && (
                    <Select
                      ghost
                      type="select"
                      name="category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={form.category}
                      required
                    >
                      <Option value="" ghost="true">
                        Seleccionar...
                      </Option>
                      <Option value="PRODUCTO FABRICADO">
                        Producto fabricado
                      </Option>
                      <Option value="PRODUCTO COMPRADO">
                        Producto comprado
                      </Option>
                      <Option value="SERVICIO">Servicio</Option>
                    </Select>
                  )}
                  {form.category !== "" && (
                    <Select
                      type="select"
                      name="category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={form.category}
                      required
                    >
                      <Option value="" ghost="true">
                        Seleccionar...
                      </Option>
                      <Option value="PRODUCTO FABRICADO">
                        Producto fabricado
                      </Option>
                      <Option value="PRODUCTO COMPRADO">
                        Producto comprado
                      </Option>
                      <Option value="SERVICIO">Servicio</Option>
                    </Select>
                  )}
                  <ContainerError>
                    {errors.category && (
                      <ErrorText>{errors.category}</ErrorText>
                    )}
                  </ContainerError>
                </SubSection>
              </div>
            </Wrapper>

            <SubSection>
              <Label>Nombre*</Label>
              <Input
                type="text"
                name="name"
                placeholder="Producto ABC"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.name}
                required
              />
              <ContainerError>
                {errors.name && <ErrorText>{errors.name}</ErrorText>}
              </ContainerError>
            </SubSection>

            <SubSection>
              <Label>Descripción (opcional)</Label>
              <Input
                type="text-area"
                name="description"
                placeholder="ej: Color blanco..."
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.description}
              />
              <ContainerError>
                {errors.description && (
                  <ErrorText>{errors.description}</ErrorText>
                )}
              </ContainerError>
            </SubSection>

            <SubSection small>
              <Label>Costo*</Label>
              <Input
                type="text"
                name="cost_value"
                placeholder="ej: $ 50.000"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.cost_value}
                required
              />
              <ContainerError>
                {errors.cost_value && (
                  <ErrorText>{errors.cost_value}</ErrorText>
                )}
              </ContainerError>
            </SubSection>

            <SubSection small>
              <Label>Precio de venta*</Label>
              <Input
                type="text"
                name="sale_value"
                placeholder="ej: $ 120.000"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.sale_value}
                required
              />
              <ContainerError>
                {errors.sale_value && (
                  <ErrorText>{errors.sale_value}</ErrorText>
                )}
              </ContainerError>
            </SubSection>

            <SubSection small>
              <Label>Referencia*</Label>
              <Input
                type="text"
                name="sku"
                placeholder="ej: 001"
                onBlur={handleBlur}
                onChange={handleChange}
                value={form.sku}
                required
              />
              <ContainerError>
                {errors.sku && <ErrorText>{errors.sku}</ErrorText>}
              </ContainerError>
            </SubSection>

            {form.category === "PRODUCTO FABRICADO" && (
              <>
                <SubSection small>
                  <Label>Stock*</Label>
                  <Input
                    type="text"
                    name="quantity"
                    placeholder="ej: 75"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={form.quantity}
                    required
                  />
                  <ContainerError>
                    {errors.quantity && (
                      <ErrorText>{errors.quantity}</ErrorText>
                    )}
                  </ContainerError>
                </SubSection>
              </>
            )}

            {form.category === "PRODUCTO COMPRADO" && (
              <>
                <SubSection small>
                  <Label>Stock*</Label>
                  <Input
                    type="text"
                    name="quantity"
                    placeholder="ej: 75"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={form.quantity}
                    required
                  />
                  <ContainerError>
                    {errors.quantity && (
                      <ErrorText>{errors.quantity}</ErrorText>
                    )}
                  </ContainerError>
                </SubSection>
              </>
            )}
          </Section>

          <ContainerBtns>
            <LinkBtn to={Routes.app_my_inventory} second="true" ghost="true">
              Cancelar
            </LinkBtn>
            <BtnAction second name="Crear" darktheme />
          </ContainerBtns>
        </Form>
      </CardForm>
    </>
  )
}

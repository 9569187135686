import styled from 'styled-components'


// const desktopStartWidth = 1023;
const tabletStartWidth = 768;
// const tablet = `@media (max-width: ${desktopStartWidth}px)`
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Container = styled.div`
    width:100%;
    padding-top: .5rem;
    display:flex;
    justify-content:center;
    align-items:center;
    &:hover {
    }
    ${mobile} {
    }
`

export const Section = styled.section`
    width:60%;
    display:flex;
    justify-content:space-between;
    align-items:space-between;
    &:hover {
    }
    ${mobile} {
        width:100%;
    }
`

export const BtnFilter = styled.button`
    font-size:.7rem;
    font-weight:500;
    color: ${props => (props.selected ? "#fff" : props.rojo ? props.theme.colors.red_brand : props.theme.colors.dark)};
    background: ${props => (props.rojo && props.selected ? props.theme.colors.red_brand : props.selected ? props.theme.colors.text : '')};
    padding:0 .4rem;
    border-radius:.3rem;
    
    &:hover {
    }
    ${mobile} {
    }
`
import React from "react"
import {
  TitleStep,
  Form,
  Section,
  Input,
  Select,
  Label,
  ContainerBtns,
  BtnNext,
  SubSection,
  Option,
} from "./styles"

export const UserInformation = (props) => {
  return (
    <Form>
      <Section>
        <SubSection>
          <TitleStep>Información del emprendimiento</TitleStep>
        </SubSection>

        <SubSection>
          <Label>Nombre de la empresa *</Label>
          <Input
            type="text"
            name="tradename"
            onChange={props.handleChange}
            value={props.form.tradename}
            required
          />
        </SubSection>

        <SubSection>
          <Label>Actividad económica *</Label>
          <Select
            ghost
            type="select"
            name="economic_activity"
            onChange={props.handleChange}
            value={props.form.economic_activity}
            required
          >
            <Option value="">Seleccionar actividad</Option>
            {props.economicActivities.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <Option value={row.id}>{row.name}</Option>
                </React.Fragment>
              )
            })}
          </Select>
        </SubSection>

        <SubSection>
          <TitleStep>Información del usuario</TitleStep>
        </SubSection>

        <SubSection small>
          <Label>Nombre *</Label>
          <Input
            type="text"
            name="first_name"
            onChange={props.handleChange}
            value={props.form.first_name}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Apellido *</Label>
          <Input
            type="text"
            name="last_name"
            onChange={props.handleChange}
            value={props.form.last_name}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Tipo de documento *</Label>
          <Select
            ghost
            type="select"
            name="identification_type"
            onChange={props.handleChange}
            value={props.form.identification_type}
            required
          >
            <Option value="">Seleccionar tipo documento</Option>
            {props.documents.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <Option value={row.id}>{row.name}</Option>
                </React.Fragment>
              )
            })}
          </Select>
        </SubSection>

        <SubSection small>
          <Label>Número de identificación *</Label>
          <Input
            type="text"
            name="identification_number"
            onChange={props.handleChange}
            value={props.form.identification_number}
            required
          />
        </SubSection>

        <SubSection>
          <TitleStep>Información de contacto</TitleStep>
        </SubSection>

        <SubSection>
          <Label>Correo electrónico</Label>
          <Input
            type="text"
            name="email"
            onChange={props.handleChange}
            value={props.form.email}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Teléfono</Label>
          <Input
            type="text"
            name="phone"
            onChange={props.handleChange}
            value={props.form.phone}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Celular *</Label>
          <Input
            type="text"
            name="cellphone"
            onChange={props.handleChange}
            value={props.form.cellphone}
            required
          />
        </SubSection>

        <SubSection small>
          <Label>Departamento *</Label>
          <Select
            ghost
            type="select"
            name="state"
            onChange={props.handleChange}
            value={props.form.state}
          >
            <Option value="">Seleccionar departamento</Option>
            {props.states.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <Option value={row.id}>{row.name}</Option>
                </React.Fragment>
              )
            })}
          </Select>
        </SubSection>

        <SubSection small>
          <Label>Ciudad *</Label>
          <Select
            ghost
            type="select"
            name="city"
            onChange={props.handleChange}
            value={props.form.city}
          >
            <Option value="">Seleccionar ciudad</Option>
            {props.cities.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <Option value={row.id}>{row.name}</Option>
                </React.Fragment>
              )
            })}
          </Select>
        </SubSection>

        <SubSection>
          <Label>Dirección *</Label>
          <Input
            type="text"
            name="address"
            onChange={props.handleChange}
            value={props.form.address}
            required
          />
        </SubSection>
      </Section>

      <ContainerBtns>
        <BtnNext second ghost onClick={props.backStep} type="button">
          Cancelar
        </BtnNext>
        <BtnNext second onClick={props.handleSubmit} type="button" darktheme>
          Actualizar
        </BtnNext>
      </ContainerBtns>
    </Form>
  )
}

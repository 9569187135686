import styled from 'styled-components'
import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';

/* const desktopStartWidth = 1023; */
const tabletStartWidth = 768;
/* const tablet = `@media (max-width: ${desktopStartWidth}px)` */
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const CardForm = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
  /* padding:3em 2em; */
  text-align:center;
  /* background:pink; */

`

export const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  /* background:red; */
  height:100%;

`

export const Section = styled.section`
  width:100%;
  /* height:40vh; */
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-top:3rem;
`

export const SubSectionInput = styled.section`
    width:100%;
    background:blue;
    display:flex;
    justify-content:space-between;
`

export const SectionInput = styled.section`
  display:flex;
  flex-direction: column;
  background:red;
  width:45%;
  

`



export const Input = styled.input`
  padding:.5rem 0;
  /* border:1px solid #999999; */
  border-bottom: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  width:100%;
  background:transparent;
  outline:none;
  font-size: 1em;
  margin-bottom:.5em;
  &::-webkit-input-placeholder {
    color: ${props => props.theme.colors.white};
  }
  ${mobile} {
    font-size: 1rem;
  }
`


export const Select = styled.select`
  padding:.5rem 0;
  width:100%;
  /* border:1px solid #999999; */
  border-bottom: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};


  font-family: ${props => props.theme.fonts.family};
  font-size: 1em;
  /* background:rgba(255, 255, 255,.2); */
  background:transparent;
  outline:none;
  margin-bottom:1em;

  /* appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none; */
  ${mobile} {
    font-size: 1rem;
  }
    option {
        color: ${props => props.theme.colors.main};

        background: ${props => props.theme.colors.light};
        font-weight: small;
        display: flex;
        white-space: pre;
        min-height: 20px;
        padding: 0px 2px 1px;
    }
    option:checked {
        /*background: red linear-gradient(0deg, red 0%, red 100%); */
        background: ${props => props.theme.colors.second};
        color: ${props => props.theme.colors.main};


    }

`


export const Option = styled.option`
  /* color: ${props => ( props.ghost ? props.theme.colors.red_brand : props.theme.colors.second)}; */
  color: red;


`
export const ContainerError = styled.div`
  /* background:red; */
  height:4em;
  align-self:flex-start;

`

export const ErrorText = styled.p`
  color: ${props => ( props.ghost ? "transparent" : props.theme.colors.red_brand)};
  font-weight:600;
  text-align:start;
  &.ghost {
    color:blue;
  }
`
export const Span = styled.span`
  color: ${props => ( props.ghost ? "transparent" : props.theme.colors.third)};
  font-size:.7rem;
  /* padding: 1em 0 0; */
  align-self:flex-start;
`

export const ContainerBtns = styled.div`
  display:flex;
  width:100%;
  /* padding:3em 2em; */
  padding-bottom:1em;
  /* background:pink; */
  justify-content:space-between;

`

export const LinkBtn = styled(Link)`
  width: ${props => ( props.second ? "45%" : "70%")};
  color: ${props => ( props.white ? props.theme.colors.main : props.ghost ? props.theme.colors.main : "#fff" )};
  background: ${props => ( 
    props.white ? "#fff" : 
    props.ghost ? "transparent" : 
    props.border ? props.theme.colors.dark : 
    props.theme.colors.main )};
  border:3px solid ${props => ( 
    props.white ? "#fff" : 
    props.ghost ? props.theme.colors.main : 
    props.border ? props.theme.colors.third :
    "transparent" )};
  /* margin-top: ${props => ( props.ghost ? "1.5em" : "")}; */
  /* margin-bottom: ${props => ( props.ghost ? "1.5em" : "")}; */
  margin-top:1em;

  /* opacity: ${props => ( props.ghost ? "50%" : "")}; */
  padding:1em .5em;
  border-radius:30px;
  font-weight:500;
  font-size:.9rem;
  height:fit-content;
  /* text-transform:uppercase; */
  transition: all 0.3s ease-in;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: ${props => props.theme.colors.second};
    color: #fff;
    opacity:100%
  }
`

export const BtnText = styled.p`
    display:flex;
    align-items:center;
    justify-content:center;
    color: #fff;
    font-style:italic;
    text-decoration:none;
    font-size: .8rem;
    transition: all 0.3s ease-in;
    &:hover {
        transition: all 0.5s ease-in-out;
        text-decoration:underline;
    }
`

export const SubSection = styled.section`
  width: ${props => ( props.small ? '45%' : '100%')};
  display:flex;
  flex-direction: ${props => ( props.flex ? 'row' : 'column')};
  min-height: ${props => props.table ? '280px' : ''};
  max-height: ${props => props.table ? '280px' : ''};
  margin-bottom: 1rem;
`

export const TitleStep = styled.h3`
  margin:auto;
  padding-bottom:2rem;
  color: ${props => props.theme.colors.third};
`

export const DatePickerWrap = styled.div`
  width: 100%;
  & > div.react-datepicker-wrapper {
    width: 100%;
  }
  & > div > div.react-datepicker__input-container {
    width: 100%;
  }
  & > div > div.react-datepicker__input-container input {
    width: 100%;
  }
`

export const Datepicker = styled(DatePicker)`
  color: ${props => props.theme.colors.white};
  font-size:1em;
  width: 100%;
  border-bottom: 1px solid #FFF;
  background: none;
  margin-top: .5rem;
  padding-bottom: .5rem;
  
  ${mobile} {
    font-size:1rem;
  }
`

